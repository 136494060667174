import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirm = () => {
    this.props.confirmNotice();
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getTotalvalue() {
    const {
      notifications
    } = this.props;
    let total = 0;
    for (let key in notifications) {
      if (notifications.hasOwnProperty(key)) {
        total+= parseInt(notifications[key].VALOR_GARANTIZADO);
      }
    }
    return total;
  }

  render() {
    const {
      notifications,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Confirmar aviso de no pago
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Número de facturas</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{Object.keys(notifications).length}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Total factura:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">${this.getNumber(this.getTotalvalue())}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="button-primary"
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    block
                  >
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}


ConfirmChange.propTypes = {
  notifications: PropTypes.object,
  confirmNotice: PropTypes.func.isRequired,
};

ConfirmChange.defaultProps = {
  notifications: [],
  confirmNotice: () => {},
};

export default ConfirmChange;
