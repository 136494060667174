import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirmAction = () => {
    this.props.confirmAction(this.props.newUser);
  }

  render() {
    const {
      show,
      onHide,
      newUser,
    } = this.props;
    let actionText = newUser && newUser.status === 'I' && newUser.action === 'ACTUALIZAR' ? 'desactivar' : (newUser && newUser.action === 'RETIRAR' ? 'eliminar' : 'reactivar');
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Desactivar perfil
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="not-reservation-text">
                      Usted va a {actionText} el  usuario <span className="not-reservation-text_bold">{newUser ? newUser.firstName : ''} {newUser ? newUser.lastName : ''}</span> identificado con <span className="not-reservation-text_bold">{newUser ? newUser.documentType : ''} {newUser ? newUser.documentNumber : ''}</span> ¿Desea continuar?
                    </Col>
                    <Col xs={6} className="reserve-aproved-button-container">
                      <Button
                        className="button-send"
                        variant="success"
                        onClick={(e) => {
                            e.preventDefault();
                            this.confirmAction();
                        }}
                        block
                      >
                        Aceptar
                      </Button>
                    </Col>
                    <Col xs={6} className="reserve-aproved-button-container">
                      <Button
                        className="button-primary"
                        variant="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            this.cancel();
                        }}
                        block
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  newUser: PropTypes.object,
  updateModal: PropTypes.bool,
  confirmAction: PropTypes.func.isRequired,
};

ConfirmChange.defaultProps = {
  newUser: null,
  updateModal: false,
  confirmAction: () => {},
};

export default ConfirmChange;
