import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Col, Button, Modal } from 'react-bootstrap';


class ErrorModal extends Component {

  checkChange = () => event => {
    this.setState({ termsAccepted: event.target.checked });
  };

  confirm = () => {
    this.props.confirm();
  }

  render() {
    const {
      show,
      onHide,
      errorText,
      errorTitle
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="terms-modal-title">
            {errorTitle}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="terms-modal-body">
            <Col xs={12} className="terms-modal_body-description">
              {errorText}
            </Col>
            <Col xs={12} md={{span: 8, offset: 2}} className="error-modal_button-container">
              <Button
                className="button-send"
                variant="success"
                onClick={(e) => {
                  e.preventDefault();
                  this.confirm();
                }}
                block
              >
                Aceptar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  errorText: PropTypes.string,
  errorTitle: PropTypes.string,
};

ErrorModal.defaultProps = {
  errorText: 'Petición Erronea',
  errorTitle: '!Error!',
};

export default ErrorModal;
