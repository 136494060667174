import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import newlogo from '../../assets/logos/newlogo.svg';
import videoicon from '../../assets/icons/videoicon.svg';
import close from '../../assets/icons/close.svg';
import videoimg from '../../assets/images/videoimg.png';
import './login-background.scss';

class LoginBackground extends Component {
  state = {
    videOpen: false,
  };

  openVideo = () => {
    this.setState({
      videOpen: !this.state.videOpen,
    });
  };

  render() {
    const { videOpen } = this.state;
    return (
      <Col md={12} className='loginbackground'>
        {/* <div className='background-blur' /> */}
        <div className='login_video-info full-center hide-mobile'>
          <Col xs={12}>
            <Row>
              {/* <div>
                {!videOpen ? (
                  <div className='video-container full-center'>
                    <img
                      className='onboarding-video-img'
                      src={videoimg}
                      alt='covifactura logo'
                    />
                    <div
                      className='play-button'
                      onClick={(e) => {
                        this.openVideo();
                      }}
                    >
                      <div className='play-icon' />
                    </div>
                    <div
                      className='open-video-link'
                      onClick={(e) => {
                        this.openVideo();
                      }}
                    >
                      Ver video
                    </div>
                  </div>
                ) : (
                  <div className='video-container'>
                    <video width='100%' controls autoPlay>
                      <source
                        poster={videoimg}
                        src='/onboarding.mp4'
                        type='video/mp4'
                      />
                    </video>
                  </div>
                )}
              </div>
               */}
              <Col xs={6} className='slogan-text-container full-center'>
                <Row>
                  <Col xs={12} className='slogan-text'>
                    <img
                      className='login-logo-background'
                      src={newlogo}
                      alt='covifactura logo'
                    />
                  </Col>
                  <Col xs={12} className='slogan-text'>
                    TRANQUILO, NOSOTROS LO
                    <br />
                    APOYAMOS
                  </Col>
                  <Col xs={12} className='slogan-desciption-text'>
                    Incrementamos las ventas de su empresa,
                    <br />
                    garantizando la protección de su cartera.
                  </Col>
                  <div className='slogan-video' onClick={this.openVideo}>
                    Conozca más{' '}
                    <img
                      className='video-logo'
                      src={videoicon}
                      alt='video logo'
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
        {videOpen && (
          <div className='video-modal'>
            <div className='video-container'>
              <button
                type='button'
                className='btn-close btn-close-white'
                onClick={this.openVideo}
              >
                <img src={close} alt='close logo' />
              </button>
              <video width='100%' controls autoPlay>
                <source src='/loginvideo.mp4' type='video/mp4' />
              </video>
            </div>
          </div>
        )}
      </Col>
    );
  }
}

export default LoginBackground;
