import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './terms.scss';
import * as actions from '../../redux/actions'
import { Col } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import Loading from '../loading';

class Terms extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      term: null
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  componentDidMount() {
    let paramsString = this.props.location.search
    const params = new URLSearchParams(paramsString);
    const term = params.get('termino');
    this.setState({
      term: term ? term: 2
    }, () => {
      this.loadData()
    })

  }


  loadData() {
    this.props.actions.getUser();
  }

  getTerms = (user) => {
    this.props.actions.getTerms({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      termsType: this.state.term
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.getTerms(nextProps.user);
    }
  }

  goHome = () => {
    this.props.history.push(`/`);
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  render() {
    const { menuOpen } = this.state;
    const { terms, loading } = this.props;
    return (
      <div className="terms">
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            <Col xs={12} className="general-main-card">
              { loading && (
                <Loading />
              )}
              <div
                xs={12}
                className="goback-container"
                onClick={(e) => {
                    e.preventDefault();
                    this.goHome();
                }}
              >
                <div className="goback-action">
                  <span>
                    <img src={arrowback} alt="arrow back" className="goback-icon" />
                  </span>
                  <span className="goback-text">Volver</span>
                </div>
              </div>
              <Col xs={12}>
                <span className="terms-main-card_title">
                  Términos y condiciones
                </span>
              </Col>
              <Col xs={12} className="terms-main-card_divider" >
                <hr />
              </Col>
              { terms && (
                <Col xs={12} className="terms-main-card_body-text" dangerouslySetInnerHTML={{__html: terms}}>
                  {/*
                    <span >
                    COVINOC S.A., es una sociedad Anónima, legalmente constituida bajo las leyes de la República de Colombia, identificada con NIT 860028462-1, podrán contactarnos al siguiente correo electrónico: incluir correo o al número telefónico 3420000, o en nuestra oficina principal ubicada en la Avenida Calle 19 No 7 – 48 Piso 2 en la ciudad de Bogotá.<br/><br/>

                    El uso de esta página Web es una manifestación voluntaria del cliente, interesado, deudor, en adelante el “CLIENTE” de que está de acuerdo con nuestros Términos y Condiciones del sitio “micovinoc”, si el CLIENTE no está de acuerdo, no usara esta página Web, no debe continuar y debe salir de la misma, de igual manera acepta que seguirá las instrucciones que se encuentran en el sitio Web. COVINOC S.A., podrá revisar, modificar, estas condiciones actualizando esta página sin necesidad de previo aviso, por favor lea cuidadosamente y revise está página periódicamente, pues estos términos son vinculantes para el CLIENTE.<br/><br/>

                    Al introducir los datos, el CLIENTE da el consentimiento a COVINOC S.A., para efectuar la recolección de datos y su uso, así como para proporcionarlos según los servicios o el uso de los productos solicitados, al suministrar la información COVINOC S.A. la tratará conforme a su POLITICA DE PRIVACIDAD DE LA INFORMACIÓN, igualmente, toda la información proporcionada a COVINOC S.A., sólo será utilizada por la misma, sus agentes, aliados estratégicos, filiales, subsidiarias y proveedores de servicios.<br/><br/>

                    La página Web “micovinoc” le permite acceder a algunas funciones disponibles en el sitio web www.covifactura.com dicho acceso se regirá por la POLITICA DE TRATAMIENTO DE LA INFORMACIÓN.<br/><br/>

                    Los servicios de COVINOC S.A., no están dirigidos a niños, niñas y adolescentes por lo que se les solicita, abstenerse de contratar el producto, servicio, registrarse para cualquier espacio de Interacción de COVINOC S.A., o solicitar algún beneficio, en caso de que usted sea una persona menor de dieciocho (18) años. Si por algún motivo algún padre, madre o responsable de la custodia de un niño menor de (18) años, nos ha suministrado la información sobre dicho niño, deberá mandar un email a incluir correo indicando que debemos remover la información.<br/><br/>

                    No podemos certificar que la información personal que el CLIENTE envía a cualquier página Web que esté vinculada a la nuestra esté segura. Estas son paginas externas que están vinculas por un link y operan independiente.
                    </span>
                    */}
                  </Col>
              )}
              {/*
                <Col xs={12} md={{ span: 4, offset: 4 }} className="center-horizontally terms-main-card_button-container">
                <Button className="button-primary" variant="primary" block>
                Aceptar
                </Button>
                </Col>
                */}
            </Col>
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    terms,
    loading
  } = state.document;
  let {
      user
  } = state.user;
  return {
    terms,
    user,
    loading
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
