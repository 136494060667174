import * as exonerationService from '../services';
import {
  GET_GUARANTEESTOEXONERATE_REQUEST,
  GET_GUARANTEESTOEXONERATE_SUCCESS,
  GET_GUARANTEESTOEXONERATE_FAILURE,
  EXONERATEGUARANTE_REQUEST,
  EXONERATEGUARANTE_SUCCESS,
  EXONERATEGUARANTE_FAILURE,
  SEND_BULKEXONERATION_REQUEST,
  SEND_BULKEXONERATION_SUCCESS,
  SEND_BULKEXONERATIONFINAL_SUCCESS,
  SEND_BULKEXONERATION_FAILURE,
  RESET_EXPORT_BULKEXONERATION_REQUEST,
  EXPORT_BULKEXONERATION_REQUEST,
  EXPORT_BULKEXONERATION_SUCCESS,
  EXPORT_BULKEXONERATION_FAILURE,
  RESET_GUARANTEESTOEXONERATE,
  GENERAL_ERROR,
} from '../action-types'

export const getGuaranteesToExonerate = ({documentType, documentNumber, userDocument, userDocumentType, page, invoiceNumber, initialDate, finalDate }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.getGuaranteesToExonerate({documentType, documentNumber, userDocument, userDocumentType, page, invoiceNumber, initialDate, finalDate })
    .then(
      data => {
        dispatch(success(data, page));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_GUARANTEESTOEXONERATE_REQUEST } }
  function success(data, page) { return { type: GET_GUARANTEESTOEXONERATE_SUCCESS, data, page } }
  function failure(error) { return { type: GET_GUARANTEESTOEXONERATE_FAILURE, error } }
}

export const exonerateGuarante = ({userDocument, userDocumentType, userEmail, exonerateArray }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.exonerateGuarante({userDocument, userDocumentType, userEmail, exonerateArray })
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: EXONERATEGUARANTE_REQUEST } }
  function success(data) { return { type: EXONERATEGUARANTE_SUCCESS, data } }
  function failure(error) { return { type: EXONERATEGUARANTE_FAILURE, error } }
}


export const sendMasiveExoneration = ({ userDocument, userDocumentType, userEmail, file, correctionsArray, check }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.sendMasiveExoneration({ userDocument, userDocumentType, userEmail, file, correctionsArray, check })
    .then(
      data => {
        if(check) {
          dispatch(success(data));
        } else {
          dispatch(successFinal(data));
        }
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: SEND_BULKEXONERATION_REQUEST } }
  function success(data) { return { type: SEND_BULKEXONERATION_SUCCESS, data } }
  function successFinal(data) { return { type: SEND_BULKEXONERATIONFINAL_SUCCESS, data } }
  function failure(error) { return { type: SEND_BULKEXONERATION_FAILURE, error } }
}

export const exportMasiveExoneration = ({ userDocument, userDocumentType, numberFiled, status }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.exportMasiveExoneration({ userDocument, userDocumentType, numberFiled, status })
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: EXPORT_BULKEXONERATION_REQUEST } }
  function success(data) { return { type: EXPORT_BULKEXONERATION_SUCCESS, data } }
  function failure(error) { return { type: EXPORT_BULKEXONERATION_FAILURE, error } }
}

export const resetExportExonerationReport = () => {
  return dispatch => {
    dispatch(request());
  }
  function request() { return { type: RESET_EXPORT_BULKEXONERATION_REQUEST } }
}

export const resetGuaranteesToExonerate = () => {
  return dispatch => {
    dispatch(request());
  }

  function request() { return { type: RESET_GUARANTEESTOEXONERATE } }
}

export const generalError = (generalError) => { return { type: GENERAL_ERROR, generalError } }
