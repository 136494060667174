import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

import { Col, Button, Modal, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import * as actions from '../../../redux/actions';
import Loading from '../../loading';

class QueryCodeModal extends Component {
  constructor() {
    super();
    this.state = {
      queryCode: '',
    };
  }

  confirm = () => {
    const { queryCode } = this.state;
    this.props.confirm(queryCode);
    this.setState({
      queryCode: '',
    });
  };

  checkConfirm = () => {
    this.validateQuerycode();
    // if(this.props.selectedDocument) {
    //   this.handleSubmit();
    // } else {
    // }
  };

  validateQuerycode = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'validar_codigo', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    this.props.actions.validateQueryCode({
      documentType: this.props.user.userInfo.TIPO_DOCUMENTO,
      documentNumber: this.props.user.userInfo.DOCUMENTO,
      queryCode: this.state.queryCode,
    });
  };

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  getDocumentTypeName() {
    const { selectedDocument } = this.props;
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let documents = globalsJson ? globalsJson.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item) => {
      return item.ID === selectedDocument;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  }

  handleSubmit = () => {
    this.props.actions.getQuota({
      documentType: this.props.selectedDocument,
      documentNumber: this.props.documentNumber,
      userDocument: this.props.user.userInfo.DOCUMENTO,
      userDocumentType: this.props.user.userInfo.TIPO_DOCUMENTO,
      queryCode: this.state.queryCode,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.validQueryCode !== this.props.validQueryCode) {
      if (
        'CODIGO_ESTADO' in nextProps.validQueryCode &&
        nextProps.validQueryCode.CODIGO_ESTADO === 'código activo'
      ) {
        let userJson = JSON.parse(localStorage.getItem('user'));
        let globalsJson = JSON.parse(localStorage.getItem('globals'));
        let idPais =
          'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
            ? globalsJson.PAIS[0].ID_PAIS
            : '1';
        window.gtag('event', 'codigo_activo', {
          event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          event_label: userJson.userEmail,
        });
        this.confirm();
      }
    }
  }

  render() {
    const {
      show,
      onHide,
      loading,
      validQueryCode,
      clientStudy,
      queryCodeModalThreePayments,
      documentNumber,
    } = this.props;
    const { queryCode } = this.state;
    let descriptionText = clientStudy ? 'el estudio cliente' : 'la transacción';
    let inValidCode =
      validQueryCode &&
      'CODIGO_ESTADO' in validQueryCode &&
      validQueryCode.CODIGO_ESTADO === 'código inválido';
    return (
      <Modal
        show={show}
        onHide={onHide}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div className='query-code-title'>Ingresar código</div>
        </Modal.Header>
        <Modal.Body>
          <div className='terms-modal-body'>
            {loading && <Loading />}
            {queryCodeModalThreePayments ? (
              <Col xs={12} className='query-code-description'>
                El comprador con {this.getDocumentTypeName()} {documentNumber}{' '}
                cuenta con otra línea producto activa y no es posible acceder al
                servicio de{' '}
                <span className='home_query-unregistered-bold'>
                  Covifactura 3 pagos.
                </span>{' '}
                Para continuar la transacción usando el producto asignado, por
                favor digite el código de consulta correspondiente.
              </Col>
            ) : (
              <Col xs={12} className='query-code-description'>
                Para poder seguir con {descriptionText}
                <br />
                usted debe ingresar el código y hacer
                <br />
                clic en continuar
              </Col>
            )}

            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              className='login-input-container'
            >
              <TextField
                id='queryCode'
                label='Código'
                className='home-input'
                margin='dense'
                type='number'
                value={queryCode}
                onChange={(event) => {
                  this.oninputChange(event, 'queryCode');
                }}
                error={inValidCode}
                helperText={
                  inValidCode
                    ? 'Código invalido, verifique y vuelva a intentar.'
                    : null
                }
                fullWidth
              />
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6} className='query-code-buttons'>
                  <Button
                    className='button-send'
                    variant='success'
                    disabled={queryCode === ''}
                    onClick={(e) => {
                      e.preventDefault();
                      this.checkConfirm();
                    }}
                    block
                  >
                    Continuar
                  </Button>
                </Col>
                <Col xs={6} className='query-code-buttons'>
                  <Button
                    className='button-primary'
                    variant='primary'
                    onClick={(e) => {
                      e.preventDefault();
                      onHide();
                    }}
                    block
                  >
                    Volver
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

QueryCodeModal.propTypes = {
  clientStudy: PropTypes.bool,
  selectedDocument: PropTypes.string,
  documentNumber: PropTypes.string,
  confirm: PropTypes.func.isRequired,
};

QueryCodeModal.defaultProps = {
  selectedDocument: '',
  documentNumber: '',
  confirm: () => {},
  clientStudy: false,
};

const mapStateToProps = (state, props) => {
  let { user } = state.user;
  let { loading, validQueryCode } = state.transaction;
  return {
    loading,
    user,
    validQueryCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryCodeModal);
