import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './documents.scss';
import * as actions from '../../redux/actions'
import { Row, Col } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';

class Documents extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.props.actions.getDocuments(true)
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  openPdf = (pdfLink) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    window.gtag('event', 'abrir_documento', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    window.open(pdfLink,'_newtab');
  }

  goHome = () => {
    this.props.history.push(`/`);
  }

  render() {
    const { menuOpen } = this.state;
    return (
      <div className="documents">
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={12} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            <Col xs={12} className="general-main-card">
              <div xs={12} className="goback-container">
                <div
                  className="goback-action"
                  onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                  }}
                >
                  <span>
                    <img src={arrowback} alt="arrow back" className="goback-icon" />
                  </span>
                  <span className="goback-text">Volver</span>
                </div>
              </div>
              <Col xs={12}>
                <span className="documents-main-card_title">
                  Documentos
                </span>
              </Col>
              <Col xs={12}>
                <span className="documents-main-card_sub-title">
                  Seleccione el documento que desea descargar
                </span>
              </Col>
              <Col xs={12} className="documents-main-card_divider" >
                <hr />
              </Col>
              <Row>
                {this.props.documents.map((item, index) => {
                  if(item.VISIBLE === 'N') {
                    return ''
                  }
                  return (
                    <Col key={item.ID_DOC} xs={12} md={6} className="box-card-container" >
                      <Col xs={12} className="box-card height-100">
                        <Row className="height-100">
                          <Col xs="auto" className="documents_box-number-container">
                            <span className="documents_box-number">{index + 1}</span>
                          </Col>
                          <Col className="documents_box-title-container">
                            <Row>
                              <Col xs={12} className="documents_box-title">{item.DOCUMENTO}</Col>
                              <Col xs={12} className="documents_box-description">
                                {item.DESCRIPCION}
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs="auto"
                            className="documents_box-download"
                            onClick={(e) => {
                                e.preventDefault();
                                this.openPdf(item.LINK);
                            }}
                          >
                            Descargar
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {

    let {
        loading,
        documents,
    } = state.document;

    console.log('state', state);

    return {
      loading,
      documents,
    }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
