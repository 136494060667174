import {
  RESET_ALL,
  GET_GUARANTEESTONOTIFY_REQUEST,
  GET_GUARANTEESTONOTIFY_SUCCESS,
  GET_GUARANTEESTONOTIFY_FAILURE,
  SEND_NOTIFY_REQUEST,
  SEND_NOTIFY_SUCCESS,
  SEND_NOTIFY_FAILURE,
  GET_GUARANTEESTORAISE_REQUEST,
  GET_GUARANTEESTORAISE_SUCCESS,
  GET_GUARANTEESTORAISE_FAILURE,
  SEND_RAISE_REQUEST,
  SEND_RAISE_SUCCESS,
  SEND_RAISE_FAILURE,
  RESET_GUARANTEESTONOTIFY,
  SEND_BULKNOTICE_REQUEST,
  SEND_BULKNOTICE_SUCCESS,
  SEND_BULKNOTICEFINAL_SUCCESS,
  SEND_BULKNOTICE_FAILURE,
  RESET_EXPORT_BULKNOTICE_REQUEST,
  EXPORT_BULKNOTICE_REQUEST,
  EXPORT_BULKNOTICE_SUCCESS,
  EXPORT_BULKNOTICE_FAILURE,
} from '../action-types'

const initialState = {
  loading: false,
  loadingPaginate: false,
  loadingRaise: false,
  loadingRaisePaginate: false,
  notification: false,
  notifications: [],
  notificationsPageCount: 1,
  notificationError: false,
  notificationsNotRegistered: false,
  raiseNotification: false,
  raiseNotifications: [],
  raiseNotificationsPageCount: 1,
  raiseNotificationError: false,
  raiseNotificationsNotRegistered: false,
  error: false,
  bulkNoticeError: false,
  bulkNoticeResponse: false,
  bulkNoticeFinalResponse: false,
  loadingBulk: false,
  exportNotice: false,
}

const claims = (state = initialState, action) => {
  switch (action.type) {

    case GET_GUARANTEESTONOTIFY_REQUEST: {
      return {
        ...state,
        loadingPaginate: true
      }
    }

    case GET_GUARANTEESTONOTIFY_SUCCESS: {
      let notificationsTemp = 'GARANTIAS_A_MARCAR_AVISO' in action.data ? action.data.GARANTIAS_A_MARCAR_AVISO : [];
      let notificationsPageCount = notificationsTemp.length;
      let notifications = action.page === 1 ? notificationsTemp : state.notifications.concat(notificationsTemp);
      // let notificationsNotRegistered = action.page === 1 && notificationsPageCount === 0;
      let notificationsNotRegistered = false;

      return {
        ...state,
        notifications,
        notificationsPageCount,
        notificationsNotRegistered,
        notificationError: false,
        loadingPaginate: false
      }
    }

    case GET_GUARANTEESTONOTIFY_FAILURE: {
      return {
        ...state,
        notificationError: action.error,
        notificationsPageCount: 0,
        loadingPaginate: false
      }
    }

    case GET_GUARANTEESTORAISE_REQUEST: {
      return {
        ...state,
        loadingRaisePaginate: true
      }
    }

    case GET_GUARANTEESTORAISE_SUCCESS: {
      let notificationsTemp = 'GARANTIAS_A_LEVANTAR_AVISO' in action.data ? action.data.GARANTIAS_A_LEVANTAR_AVISO : [];
      let raiseNotificationsPageCount = notificationsTemp.length;
      let raiseNotifications = action.page === 1 ? notificationsTemp : state.raiseNotifications.concat(notificationsTemp);
      let raiseNotificationsNotRegistered = false;

      return {
        ...state,
        raiseNotifications,
        raiseNotificationsPageCount,
        raiseNotificationsNotRegistered,
        raiseNoticeError: false,
        loadingRaisePaginate: false
      }
    }

    case GET_GUARANTEESTORAISE_FAILURE: {
      return {
        ...state,
        raiseNoticeError: action.error,
        raiseNotificationsPageCount: 0,
        loadingRaisePaginate: false
      }
    }

    case SEND_NOTIFY_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_NOTIFY_SUCCESS: {
      let notification = action.data ;
      return {
        ...state,
        notification,
        error: false,
        loading: false
      }
    }

    case SEND_NOTIFY_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case SEND_RAISE_REQUEST: {
      return {
        ...state,
        loadingRaise: true
      }
    }

    case SEND_RAISE_SUCCESS: {
      let raiseNotification = action.data ;
      return {
        ...state,
        raiseNotification,
        error: false,
        loadingRaise: false
      }
    }

    case SEND_RAISE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingRaise: false
      }
    }

    case RESET_GUARANTEESTONOTIFY: {
      return {
        ...state,
        notification: false,
        notifications: [],
        notificationsPageCount: 1,
        notificationError: false,
        notificationsNotRegistered: false,
        raiseNotification: false,
        raiseNotifications: [],
        raiseNotificationsPageCount: 1,
        raiseNotificationError: false,
        raiseNotificationsNotRegistered: false,
        loadingRaise: false,
        loading: false,
        bulkNoticeError: false,
        bulkNoticeResponse: false,
        bulkNoticeFinalResponse: false,
        loadingBulk: false,
        exportNotice: false,
      }
    }

    case SEND_BULKNOTICE_REQUEST: {
      return {
        ...state,
        loadingBulk: true
      }
    }

    case SEND_BULKNOTICE_SUCCESS: {
      let bulkNoticeResponse = "CARGA" in action.data ? action.data.CARGA : {REGISTROS_CORRECTOS: [], REGISTROS_ERROR: []}
      return {
        ...state,
        bulkNoticeResponse,
        bulkNoticeError: false,
        loadingBulk: false
      }
    }

    case SEND_BULKNOTICEFINAL_SUCCESS: {
      let bulkNoticeFinalResponse = "CARGA" in action.data ? action.data.CARGA : []
      return {
        ...state,
        bulkNoticeFinalResponse,
        bulkNoticeError: false,
        loadingBulk: false
      }
    }

    case SEND_BULKNOTICE_FAILURE: {
      return {
        ...state,
        bulkNoticeError: action.error,
        loadingBulk: false
      }
    }

    case EXPORT_BULKNOTICE_REQUEST: {
      return {
        ...state,
        loadingBulk: true
      }
    }

    case EXPORT_BULKNOTICE_SUCCESS: {
      return {
        ...state,
        exportNotice: action.data,
        error: false,
        loadingBulk: false
      }
    }

    case RESET_EXPORT_BULKNOTICE_REQUEST: {
      return {
        ...state,
        exportNotice: false
      }
    }

    case EXPORT_BULKNOTICE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingBulk: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default claims
