import * as transactionService from '../services';
import {
  GET_QUOTA_REQUEST,
  GET_QUOTA_SUCCESS,
  GET_QUOTA_FAILURE,
  SEND_TRASACTION_REQUEST,
  SEND_TRASACTION_SUCCESS,
  SEND_TRASACTION_FAILURE,
  GET_TRASACTIONREPORT_REQUEST,
  GET_TRASACTIONREPORT_SUCCESS,
  GET_TRASACTIONREPORT_FAILURE,
  GET_SELLERSREPORT_REQUEST,
  GET_SELLERSREPORT_SUCCESS,
  GET_SELLERSREPORT_FAILURE,
  GET_SELLERSNUMBER_SUCCESS,
  GET_SELLERSNUMBER_REQUEST,
  GET_SELLERSNUMBER_FAILURE,
  EXPORT_SELLERSREPORT_REQUEST,
  EXPORT_SELLERSREPORT_SUCCESS,
  EXPORT_SELLERSREPORT_FAILURE,
  EXPORT_QUOTAREPORT_REQUEST,
  EXPORT_QUOTAREPORT_SUCCESS,
  EXPORT_QUOTAREPORT_FAILURE,
  EXPORT_BULKTRASACTION_REQUEST,
  EXPORT_BULKTRASACTION_SUCCESS,
  EXPORT_BULKTRASACTION_FAILURE,
  RESET_EXPORT_TRASACTIONREPORT_REQUEST,
  EXPORT_TRASACTIONREPORT_REQUEST,
  EXPORT_TRASACTIONREPORT_SUCCESS,
  EXPORT_TRASACTIONREPORT_FAILURE,
  SEND_BULKEMAILTRASACTION_REQUEST,
  SEND_BULKEMAILTRASACTION_SUCCESS,
  SEND_BULKEMAILTRASACTION_FAILURE,
  SEND_BULKTRASACTION_REQUEST,
  SEND_BULKTRASACTION_SUCCESS,
  SEND_BULKTRASACTION_FAILURE,
  SEND_BULKTRASACTIONFINAL_SUCCESS,
  UPDATE_STUDENT_REQUEST,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAILURE,
  VALIDATE_QUERYCODE_REQUEST,
  VALIDATE_QUERYCODE_SUCCESS,
  VALIDATE_QUERYCODE_FAILURE,
  RESET_QUOTA,
  GENERAL_ERROR,
} from '../action-types';

export const getSellersReport = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  page,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getSellersReport({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        page,
      })
      .then(
        (data) => {
          dispatch(success(data, page));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_SELLERSREPORT_REQUEST };
  }
  function success(data, page) {
    return { type: GET_SELLERSREPORT_SUCCESS, data, page };
  }
  function failure(error) {
    return { type: GET_SELLERSREPORT_FAILURE, error };
  }
};

export const getTransactionsNumber = (
  {
    documentType,
    documentNumber,
    userDocument,
    userDocumentType,
    page,
    sellerEmail = null,
  },
  loading
) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getSellersReport({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        page,
        sellerEmail,
      })
      .then(
        (data) => {
          dispatch(success(data, page));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_SELLERSNUMBER_REQUEST };
  }
  function success(data, page) {
    return { type: GET_SELLERSNUMBER_SUCCESS, data, page };
  }
  function failure(error) {
    return { type: GET_SELLERSNUMBER_FAILURE, error };
  }
};

export const exportSellersReport = ({
  consolidated,
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  sellerEmail = null,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .exportSellersReport({
        consolidated,
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        sellerEmail,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: EXPORT_SELLERSREPORT_REQUEST };
  }
  function success(data) {
    return { type: EXPORT_SELLERSREPORT_SUCCESS, data };
  }
  function failure(error) {
    return { type: EXPORT_SELLERSREPORT_FAILURE, error };
  }
};

export const exportQuotaReport = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    transactionService.exportQuotaReport({ documentType, documentNumber }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: EXPORT_QUOTAREPORT_REQUEST };
  }
  function success(data) {
    return { type: EXPORT_QUOTAREPORT_SUCCESS, data };
  }
  function failure(error) {
    return { type: EXPORT_QUOTAREPORT_FAILURE, error };
  }
};

export const exportMasiveTransaction = ({
  userDocument,
  userDocumentType,
  numberFiled,
  status,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .exportMasiveTransaction({
        userDocument,
        userDocumentType,
        numberFiled,
        status,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: EXPORT_BULKTRASACTION_REQUEST };
  }
  function success(data) {
    return { type: EXPORT_BULKTRASACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: EXPORT_BULKTRASACTION_FAILURE, error };
  }
};

export const validateQueryCode = ({
  documentType,
  documentNumber,
  queryCode,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .validateQueryCode({ documentType, documentNumber, queryCode })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: VALIDATE_QUERYCODE_REQUEST };
  }
  function success(data, page) {
    return { type: VALIDATE_QUERYCODE_SUCCESS, data, page };
  }
  function failure(error) {
    return { type: VALIDATE_QUERYCODE_FAILURE, error };
  }
};

export const getTransactionReport = ({
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  initialDate,
  finalDate,
  page,
  productNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .getTransactionReport({
        documentType,
        documentNumber,
        sellerDocumentType,
        sellerDocumentNumber,
        initialDate,
        finalDate,
        page,
        productNumber,
      })
      .then(
        (data) => {
          dispatch(success(data, page));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TRASACTIONREPORT_REQUEST };
  }
  function success(data, page) {
    return { type: GET_TRASACTIONREPORT_SUCCESS, data, page };
  }
  function failure(error) {
    return { type: GET_TRASACTIONREPORT_FAILURE, error };
  }
};

export const exportTransactionReport = ({
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  initialDate,
  finalDate,
  productNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .exportTransactionReport({
        documentType,
        documentNumber,
        sellerDocumentType,
        sellerDocumentNumber,
        initialDate,
        finalDate,
        productNumber,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: EXPORT_TRASACTIONREPORT_REQUEST };
  }
  function success(data, page) {
    return { type: EXPORT_TRASACTIONREPORT_SUCCESS, data };
  }
  function failure(error) {
    return { type: EXPORT_TRASACTIONREPORT_FAILURE, error };
  }
};

export const resetExportTransactionReport = () => {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: RESET_EXPORT_TRASACTIONREPORT_REQUEST };
  }
};

export const getQuota = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  queryCode,
}) => {
  return (dispatch) => {
    dispatch(request());
    // dispatch(success({"ACTIVIDAD":"UNIVERSIDAD","DIAS_RESERVA":"5","CUPO_COMPRADOR":[{"DOCUMENTO":"23496500","ESTADO":"HABILITADO","CUPO_ASIGNADO":"300","CUPO_DISPONIBLE":"134","TIPO_DOCUMENTO":"C","NOMBRES":"SUAREZ TORRES AURA "}]}));
    transactionService
      .getQuota({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        queryCode,
      })
      .then(
        (data) => {
          data['CODIGO_CONSULTA'] = queryCode;
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_QUOTA_REQUEST };
  }
  function success(data) {
    return { type: GET_QUOTA_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_QUOTA_FAILURE, error };
  }
};

export const updateStudent = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  studentDocument,
  studentDocumentType,
  userEmail,
  firstName,
  secondName,
  lastName,
  secondLastName,
  studentRelationship,
  studentCode,
  semester,
  career,
  approvalNumber,
  queryCode,
  studentPhone,
  studentEmail,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .updateStudent({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        studentDocument,
        studentDocumentType,
        userEmail,
        firstName,
        secondName,
        lastName,
        secondLastName,
        studentRelationship,
        studentCode,
        semester,
        career,
        approvalNumber,
        queryCode,
        studentPhone,
        studentEmail,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: UPDATE_STUDENT_REQUEST };
  }
  function success(data) {
    return { type: UPDATE_STUDENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: UPDATE_STUDENT_FAILURE, error };
  }
};

export const sendTransaction = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userEmail,
  transactionType,
  invoiceNumber,
  expirationDate,
  invoiceValue,
  transactionsArray,
  queryCode,
  bank = '0',
  accountNumber,
  municipality = '0',
  phone = '0',
  cellular = '0',
  address = '.',
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .sendTransaction({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        userEmail,
        transactionType,
        invoiceNumber,
        expirationDate,
        invoiceValue,
        transactionsArray,
        bank,
        accountNumber,
        municipality,
        phone,
        cellular,
        address,
        queryCode,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          console.log('error', error);
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_TRASACTION_REQUEST };
  }
  function success(data) {
    return { type: SEND_TRASACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_TRASACTION_FAILURE, error };
  }
};

export const sendThreePaymentsTransaction = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userEmail,
  invoiceValue,
  queryCode,
  cellular,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .sendThreePaymentsTransaction({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        userEmail,
        invoiceValue,
        queryCode,
        cellular,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          console.log('error', error);
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_TRASACTION_REQUEST };
  }
  function success(data) {
    return { type: SEND_TRASACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_TRASACTION_FAILURE, error };
  }
};

export const sendMasiveTransaction = ({
  userDocument,
  userDocumentType,
  userEmail,
  file,
  correctionsArray,
  check,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .sendMasiveTransaction({
        userDocument,
        userDocumentType,
        userEmail,
        file,
        correctionsArray,
        check,
      })
      .then(
        (data) => {
          if (check) {
            dispatch(success(data));
          } else {
            dispatch(successFinal(data));
          }
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_BULKTRASACTION_REQUEST };
  }
  function success(data) {
    return { type: SEND_BULKTRASACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_BULKTRASACTION_FAILURE, error };
  }
  function successFinal(data) {
    return { type: SEND_BULKTRASACTIONFINAL_SUCCESS, data };
  }
};

export const sendMasiveTransactionEmail = ({
  userDocument,
  userDocumentType,
  userEmail,
  file,
}) => {
  return (dispatch) => {
    dispatch(request());
    transactionService
      .sendMasiveTransactionEmail({
        userDocument,
        userDocumentType,
        userEmail,
        file,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_BULKEMAILTRASACTION_REQUEST };
  }
  function success(data) {
    return { type: SEND_BULKEMAILTRASACTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_BULKEMAILTRASACTION_FAILURE, error };
  }
};

export const resetQuota = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: RESET_QUOTA };
  }
};

export const generalError = (generalError) => {
  return { type: GENERAL_ERROR, generalError };
};
