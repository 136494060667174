import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileSaver from 'file-saver';

import './my-payments.scss';
import * as actions from '../../redux/actions';
import { Col, Button, Row, Table } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import PdfModal from './pdf-modal';
import Chat from '../chat';
import UserHeader from '../user-header';
import arrowgreen from '../../assets/icons/arrowGreen.svg';
import Loading from '../loading';
import moment from 'moment';

class MyPayments extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      invoiceId: null,
      pdfModal: false,
      pdfFile: '',
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.payzenButtonRef = React.createRef();
  }

  goHome = () => {
    this.props.actions.resetQuota();
    this.props.history.push(`/`);
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.actions.getUser();
  };

  getMyPayments = (user) => {
    this.props.actions.getMyPayments({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
    });
  };

  getPaymentDetail = (invoiceId) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'exportar_detalle_pago', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { user } = this.props;
    this.setState(
      {
        invoiceId: invoiceId,
      },
      () => {
        this.props.actions.getPaymentDetail({
          documentType: user.userInfo.TIPO_DOCUMENTO,
          documentNumber: user.userInfo.DOCUMENTO,
          invoice: invoiceId,
        });
      }
    );
  };

  getPaymentLink = (item) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'ir_pagar_link', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { user } = this.props;
    this.props.actions.getPaymentLink({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      invoice: item.NETSUITE,
      expirationDate: moment(item.FECHA, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      totalAmount: item.VALOR,
      totalTax: item.IVA,
      productCode: item.CODIGO_PRODUCTO,
    });
  };

  getPayzenPaymentLink = (item) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'ir_pagar_link', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    this.props.actions.getPayzenPaymentLink(item);
  };

  savePaymentPdf(excelBlob) {
    FileSaver.saveAs(excelBlob, `${this.state.invoiceId}.xlsx`);
  }

  opeUrl(url) {
    window.open(url, '_newtab');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payzenBody !== this.props.payzenBody) {
      this.payzenButtonRef.click();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.getMyPayments(nextProps.user);
    }
    if (nextProps.url !== this.props.url) {
      this.opeUrl(nextProps.url);
    }
    if (
      nextProps.myPaymentInvoice &&
      nextProps.myPaymentInvoice !== this.props.myPaymentInvoice
    ) {
      this.setState({
        pdfModal: true,
        pdfFile: nextProps.myPaymentInvoice,
      });
    }
    if (nextProps.myPaymentDetail) {
      this.props.actions.resetPaymentDetail();
      this.savePaymentPdf(nextProps.myPaymentDetail);
    }
  }

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  getInvoicePDF = (item) => {
    const { user } = this.props;

    let invoice = item.NUMERO_FACTURA;
    let consecutive = invoice.substring(3, invoice.length);
    console.log('consecutive', consecutive);
    let suffix = invoice.substring(0, 3);
    this.props.actions.getMyPaymentInvoice({
      suffix,
      consecutive,
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
    });
  };

  pdfModalClose = () => {
    this.setState({
      pdfModal: false,
      pdfFile: '',
    });
  };

  render() {
    const { menuOpen, pdfModal, pdfFile } = this.state;
    const { myPayments, loading, user, payzenBody } = this.props;
    let pending = myPayments.filter((item) => item.ESTADO === 'POR PAGAR');
    let history = myPayments.filter((item) => item.ESTADO !== 'POR PAGAR');
    let documentNumber =
      user && 'userInfo' in user ? user.userInfo.DOCUMENTO : 0;
    if (documentNumber.length === 10) {
      documentNumber = documentNumber.substring(0, 9);
    }

    return (
      <div className='my-payments'>
        <PdfModal
          show={pdfModal}
          pdfFile={pdfFile}
          confirm={this.pdfModalClose}
          onHide={this.pdfModalClose}
        />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={11} />
        <Header openMenu={this.openMenu} />
        <div className='page-body-container center-horizontally'>
          <div className='page-body'>
            <UserHeader />
            {true && (
              <Col xs={12} className='general-main-card'>
                {loading && <Loading />}
                <div xs={12} className='goback-container'>
                  <div
                    className='goback-action'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt='arrow back'
                        className='goback-icon'
                      />
                    </span>
                    <span className='goback-text'>Volver</span>
                  </div>
                </div>
                <Col xs={12} className='my-payments-margin'>
                  <Col xs={12} className='card-title'>
                    Mis pagos
                  </Col>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </Col>
                <form
                  method='POST'
                  className='payment-method_button-container'
                  action='https://secure.payzen.lat/vads-payment/'
                >
                  {payzenBody &&
                    Object.keys(payzenBody).map((payzenDataKey) => {
                      return (
                        <input
                          key={payzenDataKey}
                          type='hidden'
                          name={payzenDataKey}
                          value={payzenBody[payzenDataKey]}
                        />
                      );
                    })}
                  <button
                    type='submit'
                    className='payzen-button'
                    ref={(input) => (this.payzenButtonRef = input)}
                  />
                </form>
                {pending.map((item, index) => {
                  return (
                    <Col xs={12} key={index}>
                      <Row>
                        <Col
                          xs='auto'
                          className='next-payment_container next-payment_container-border'
                        >
                          <Row className='next-payment_container-row'>
                            <Col xs={12} className='next-payment_title'>
                              Próximo pago
                            </Col>
                            <Col xs={12} className='next-payment_value'>
                              {item.FECHA === ''
                                ? ''
                                : moment(item.FECHA, 'DD/MM/YYYY').format(
                                    'DD/MM/YYYY'
                                  )}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs='auto'
                          className='next-payment_container next-payment_container-border'
                        >
                          <Row className='next-payment_container-row'>
                            <Col xs={12} className='next-payment_title'>
                              Factura
                            </Col>
                            <Col xs={12} className='next-payment_value'>
                              {item.NUMERO_FACTURA}
                            </Col>
                          </Row>
                        </Col>
                        <Col className='next-payment_container'>
                          <Row className='next-payment_container-row'>
                            <Col xs={12} className='next-payment_title'>
                              Valor a pagar
                            </Col>
                            <Col xs={12} className='next-payment_value'>
                              ${this.getNumber(item.VALOR)}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={2}
                          xs={6}
                          className='next-payment_button-container'
                        >
                          <Button
                            className='button-primary'
                            variant='primary'
                            onClick={(e) => {
                              e.preventDefault();
                              this.getPaymentDetail(item.FACTURA_INTERNA);
                            }}
                            block
                          >
                            Detalle
                          </Button>
                        </Col>
                        <Col
                          md={2}
                          xs={6}
                          className='next-payment_button-container'
                        >
                          <Button
                            className='button-send'
                            variant='success'
                            onClick={(e) => {
                              e.preventDefault();
                              this.getPayzenPaymentLink(item);
                            }}
                            block
                          >
                            Pagar
                          </Button>
                        </Col>
                        <Col
                          md={3}
                          xs={6}
                          sm={6}
                          className='next-payment_button-container'
                        >
                          {/[a-zA-Z]/g.test(item.NUMERO_FACTURA) && (
                            <Button
                              className='button-primary'
                              variant='primary'
                              onClick={(e) => {
                                e.preventDefault();
                                this.getInvoicePDF(item);
                              }}
                              block
                            >
                              Ver factura digital
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}

                <Col xs={12} className='my-payments-table-container'>
                  <Table bordered responsive='sm'>
                    <thead>
                      <tr>
                        <th>Nº de factura</th>
                        <th>Fecha</th>
                        <th>Valor</th>
                        <th className='check'>Ver detalle factura</th>
                        <th>Ver factura digital</th>
                      </tr>
                    </thead>
                    <tbody>
                      {history.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.NUMERO_FACTURA}</td>
                            <td>
                              {item.FECHA === ''
                                ? ''
                                : moment(item.FECHA, 'DD/MM/YYYY').format(
                                    'DD/MM/YYYY'
                                  )}
                            </td>
                            <td>${this.getNumber(item.VALOR)}</td>
                            <td
                              className='check selectable'
                              onClick={(e) => {
                                e.preventDefault();
                                this.getPaymentDetail(item.FACTURA_INTERNA);
                              }}
                            >
                              Ver detalle factura
                              <img
                                src={arrowgreen}
                                alt='flecha verde'
                                className='link-arrow'
                              />
                            </td>
                            {/[a-zA-Z]/g.test(item.NUMERO_FACTURA) ? (
                              <td
                                className='check selectable'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.getInvoicePDF(item);
                                }}
                              >
                                Ver factura digital
                                <img
                                  src={arrowgreen}
                                  alt='flecha verde'
                                  className='link-arrow'
                                />
                              </td>
                            ) : (
                              <td className='check selectable'></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                  <form
                    name='ebillfrm'
                    action={
                      'https://covinoc.facturaenlinea.co/ContenidoCliente/FacturasClienteGen.aspx?MISDN=' +
                      documentNumber +
                      '&SPen=0'
                    }
                    method='post'
                    target='output_frame'
                  >
                    <input type='hidden' name='NIT' value='860028462' />
                    <input
                      type='hidden'
                      name='TOK'
                      value='481C7910-1B05-473D-B8FF-1E3D22BFCA17'
                    />
                    <input
                      type='submit'
                      value='Ver facturas digitales'
                      className='button-send btn btn-success btn-block'
                    />
                  </form>
                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    user,
    myPayments,
    loading,
    myPaymentDetail,
    url,
    myPaymentInvoice,
    payzenBody,
  } = state.user;
  return {
    loading,
    myPayments,
    user,
    myPaymentDetail,
    url,
    myPaymentInvoice,
    payzenBody,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPayments);
