import * as transactionService from '../services';
import {
  GET_REFUNDS_REQUEST,
  GET_REFUNDS_SUCCESS,
  GET_REFUNDS_FAILURE,
  EXPORT_REFUNDS_REQUEST,
  EXPORT_REFUNDS_SUCCESS,
  EXPORT_REFUNDS_FAILURE,
  RESET_REFUNDS_REQUEST,
  RESET_EXPORT_REFUNDS_REQUEST,
  GENERAL_ERROR,
} from '../action-types'

export const getRefunds = ({documentType, documentNumber, userDocument, userDocumentType, initialDate, finalDate, page, productNumber}) => {
  return dispatch => {
    dispatch(request());
    transactionService.getRefunds({documentType, documentNumber, userDocument, userDocumentType, initialDate, finalDate, page, productNumber})
    .then(
      data => {
        dispatch(success(data, page));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_REFUNDS_REQUEST } }
  function success(data, page) { return { type: GET_REFUNDS_SUCCESS, data, page } }
  function failure(error) { return { type: GET_REFUNDS_FAILURE, error } }
}

export const exportRefunds = ({documentType, documentNumber, userDocument, userDocumentType, initialDate, finalDate, productNumber}) => {
  return dispatch => {
    dispatch(request());
    transactionService.exportRefunds({documentType, documentNumber, userDocument, userDocumentType, initialDate, finalDate, productNumber})
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: EXPORT_REFUNDS_REQUEST } }
  function success(data) { return { type: EXPORT_REFUNDS_SUCCESS, data } }
  function failure(error) { return { type: EXPORT_REFUNDS_FAILURE, error } }
}

export const resetRefunds = () => {
  return dispatch => {
    dispatch(request());
  }
  function request() { return { type: RESET_REFUNDS_REQUEST } }
}

export const resetExportRefunds = () => {
  return dispatch => {
    dispatch(request());
  }
  function request() { return { type: RESET_EXPORT_REFUNDS_REQUEST } }
}

export const generalError = (generalError) => { return { type: GENERAL_ERROR, generalError } }
