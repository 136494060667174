import React, { Component } from 'react';

import './floating-footer.scss';
import newcovinoc from '../../assets/icons/newcovinoc.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';

class FloatingFooter extends Component {
  render() {
    // const { tab } = this.props;
    return (
      <div className='floating-footer hide-only-mobile'>
        <div className='floating-footer-descktop'>
          <span className='menu-floating-footer_text'>
            © 2021 | Un producto de la marca{' '}
          </span>
          <img
            className='menu-floating-footer_logo'
            src={newcovinoc}
            alt='covinoc logo'
          />
          <div className='vertical-line' />
          <span className='menu-floating-footer_text'>Síganos en:</span>
          <div className='menu-floating-footer_icon-container'>
            <a
              href='https://es-la.facebook.com/pg/Covifactura/posts/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='menu-floating-footer_icon'
                src={facebook}
                alt='facebook logo'
              />
            </a>
          </div>
          <div className='menu-floating-footer_icon-container'>
            <a
              href='https://www.instagram.com/covifactura/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='menu-floating-footer_icon'
                src={instagram}
                alt='instagram logo'
              />
            </a>
          </div>
          <div className='menu-floating-footer_icon-container'>
            <a
              href='https://co.linkedin.com/company/covifactura'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='menu-floating-footer_icon'
                src={linkedin}
                alt='linkedin logo'
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default FloatingFooter;
