import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../components/private-route';
import App from '../App';
import Home from '../components/home';
import Documents from '../components/documents';
import Terms from '../components/terms';
import PublicTerms from '../components/public-terms';
// import Login from '../components/login';
import Login from '../components/new-login';
import RememberPassword from '../components/remember-password';
import ChangePassword from '../components/change-password';
import ClientStudy from '../components/client-study';
import PublicClientStudy from '../components/public-client-study';
import Exoneration from '../components/exoneration';
import MyPayments from '../components/my-payments';
import Refunds from '../components/refunds';
import AdvanceAgreements from '../components/advance-agreements';
import Profile from '../components/profile';
// import Reservations from '../components/reservations';
import Reserve from '../components/reserve';
import Liquidator from '../components/liquidator';
import Transactions from '../components/transactions';
// import MassiveTransaction from '../components/massive-transaction';
import Claim from '../components/claim';
import ManageUsers from '../components/manage-users';
import Reports from '../components/reports';
import Maintenance from '../components/maintenance';

const AppRoutes = () => (
  <App>
    <Switch>
      <Route path='/reload' component={null} key='reload' />
      <PrivateRoute exact path='/' component={Home} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/recuperar-contrasena' component={RememberPassword} />
      <Route exact path='/cambiar-contrasena' component={ChangePassword} />
      <PrivateRoute exact path='/terminos-y-condiciones' component={Terms} />
      <Route exact path='/terminos-condiciones' component={PublicTerms} />
      <PrivateRoute exact path='/documentos' component={Documents} />
      <Route exact path='/estudio-cliente' component={PublicClientStudy} />
      <PrivateRoute exact path='/estudio-de-cliente' component={ClientStudy} />
      <PrivateRoute exact path='/exoneracion' component={Exoneration} />
      <PrivateRoute exact path='/mis-pagos' component={MyPayments} />
      <PrivateRoute exact path='/reintegros' component={Refunds} />
      <PrivateRoute exact path='/perfil' component={Profile} />
      <PrivateRoute
        exact
        path='/reservar/:documentType/:documentNumber/:queryCode'
        component={Reserve}
      />
      <PrivateRoute
        exact
        path='/transacciones/:documentType/:documentNumber/:queryCode'
        component={Transactions}
      />
      <PrivateRoute exact path='/reclamo-aviso-no-pago' component={Claim} />
      <PrivateRoute
        exact
        path='/administrar-usuarios'
        component={ManageUsers}
      />
      <PrivateRoute exact path='/reportes' component={Reports} />
      <PrivateRoute exact path='/liquidador' component={Liquidator} />
      <PrivateRoute
        exact
        path='/acuerdos-anticipados'
        component={AdvanceAgreements}
      />
      <Route exact path='/mantenimiento' component={Maintenance} />
      <Redirect from='*' to='/' />
    </Switch>
  </App>
);

export default AppRoutes;
