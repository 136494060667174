import React, { Component } from "react";
import { PropTypes } from "prop-types";

import { Col, Button, Modal, Row } from "react-bootstrap";
import { TextField, InputAdornment } from "@material-ui/core";

class AdvanceAgreementsModal extends Component {
  constructor() {
    super();
    this.state = {
      exonerationValue: "",
      exonerationValueMask: "",
    };
  }

  cancel = () => {
    this.props.onHide();
  };

  confirm = () => {
    this.props.confirmAdvanceAgreements();
  };

  onAdvanceAgreementsValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, "");
    inputValue = inputValue.replace(/\./g, "");
    inputValue = inputValue.replace(/´/g, "");
    let exonerationValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    this.setState({
      exonerationValue,
      exonerationValueMask: thousand,
    });
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  render() {
    const { exonerationValueMask, exonerationValue } = this.state;
    const { advanceAgreement, valueToAgreement, show, onHide } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-advance-agreements-modal-title">
            Confirmar transacción
          </div>
        </Modal.Header>
        <Modal.Body>
          {advanceAgreement && "NOMBRE" in advanceAgreement && (
            <div className="confirm-advance-agreements-modal-body">
              <Col xs={12}>
                <Row className="confirm-advance-agreements_info-container">
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-key"
                  >
                    Nombre:
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-value"
                  >
                    {advanceAgreement.NOMBRE.toLowerCase()}
                  </Col>
                </Row>
                <Row className="confirm-advance-agreements_info-container">
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-key"
                  >
                    Total títulos del comprador:
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-value"
                  >
                    {advanceAgreement.TITULOS_GARANTIZADOS}
                  </Col>
                </Row>
                <Row className="confirm-advance-agreements_info-container">
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-key"
                  >
                    Total títulos en el acuerdo:
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-value"
                  >
                    {advanceAgreement.TITULOS_A_EXONERAR}
                  </Col>
                </Row>
                <Row className="confirm-advance-agreements_info-container">
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-key"
                  >
                    Valor total del acuerdo:
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    className="confirm-advance-agreements_info-value"
                  >
                    ${this.getNumber(valueToAgreement)}
                  </Col>
                </Row>
                {parseInt(advanceAgreement.TITULOS_SIN_AVISO) > 0 && (
                  <Row className="confirm-advance-agreements_info-container">
                    <Col
                      xs={12}
                      className="confirm-advance-agreements_info-bottom"
                    >
                      *Si desea incluir los {advanceAgreement.TITULOS_SIN_AVISO}{" "}
                      títulos faltantes en el acuerdo, márqueles aviso de no
                      pago
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={6}>
                    <Button
                      className="button-primary"
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                      }}
                      block
                    >
                      Corregir
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      className="button-send"
                      variant="success"
                      block
                      onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                      }}
                    >
                      Aceptar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

AdvanceAgreementsModal.propTypes = {
  advanceAgreement: PropTypes.object,
  valueToAgreement: PropTypes.number,
  confirmAdvanceAgreements: PropTypes.func.isRequired,
};

AdvanceAgreementsModal.defaultProps = {
  advanceAgreement: {},
  valueToAgreement: 0,
  confirmAdvanceAgreements: () => {},
};

export default AdvanceAgreementsModal;
