import {
  RESET_ALL,
  SEND_RESERVE_REQUEST,
  SEND_RESERVE_SUCCESS,
  SEND_RESERVE_FAILURE,
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_FAILURE,
  RESET_RESERVE,
} from '../action-types'

const initialState = {
  loading: false,
  loadingPaginate: false,
  reserveResponse: false,
  reservations: [],
  reservationsPageCount: 1,
  reservationsNotRegistered: false,
  reserveError: false,
}

const reserves = (state = initialState, action) => {
  switch (action.type) {

    case RESET_RESERVE: {
      return {
        ...state,
        loading: false,
        reserveResponse: false,
        reserveError: false,
      }
    }

    case SEND_RESERVE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_RESERVE_SUCCESS: {
      let reserveResponse = action.data
      return {
        ...state,
        reserveResponse,
        reserveError: false,
        loading: false
      }
    }

    case SEND_RESERVE_FAILURE: {
      return {
        ...state,
        reserveError: action.error,
        loading: false
      }
    }

    case GET_RESERVATIONS_REQUEST: {
      return {
        ...state,
        loadingPaginate: true
      }
    }

    case GET_RESERVATIONS_SUCCESS: {
      let reservationsTemp = 'RESERVA_DETALLE' in action.data ? action.data.RESERVA_DETALLE : [];
      let reservationsPageCount = reservationsTemp.length;
      let reservations = action.page === 1 ? reservationsTemp : state.reservations.concat(reservationsTemp);
      let reservationsNotRegistered = action.page === 1 ? !('RESERVA_DETALLE' in action.data) : false;

      return {
        ...state,
        reservations,
        reservationsNotRegistered,
        reservationsPageCount,
        reserveError: false,
        loadingPaginate: false
      }
    }

    case GET_RESERVATIONS_FAILURE: {
      return {
        ...state,
        reserveError: action.error,
        loadingPaginate: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default reserves
