import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReduxLazyScroll from 'redux-lazy-scroll';

import './exoneration.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row, Table } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import arrowgreen from '../../assets/icons/arrowGreen.svg';
import exonerationbluk from '../../assets/icons/exonerationbluk.svg';
import searchicon from '../../assets/icons/searchicon.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import {TextField, MenuItem, Checkbox, FormControlLabel} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import PartialModal from './partial-modal';
import TotalModal from './total-modal';
import denegate from '../../assets/icons/denegate.svg';
import aproved from '../../assets/icons/aproved.svg';
import Loading from '../loading';
import closeIcon from '../../assets/icons/redequis.svg';
import moment from "moment";
import FileSaver from 'file-saver';
import ListLoader from '../list-loader';

// import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
// import MomentUtils from '@date-io/moment';
// import calendaricon from '../../assets/icons/calendaricon.svg';

moment.locale("es");
const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

class Exoneration extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      partialModal: false,
      totalModal: false,
      documentType: '',
      documentNumber: '',
      productNumber: '',
      userName: '',
      getGuaranteSelected: {},
      partialExoneration: null,
      firstFile: false,
      noticeFile: false,
      editArray: {},
      editNoticeArray: {},
      initialDate: null,
      finalDate: null,
      exportType: '',
      exportTypeNotice: '',
      bulkExonerationFileError: false,
      bulkNoticeFileError: false,
    };
    this.page = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.partialModalClose = this.partialModalClose.bind(this);
  }

  goHome = () => {
    const {
      exonerations,
      bulkExonerationResponse,
      bulkNoticeResponse,
    } = this.props;
    const {
      initialDate
    } = this.state;
    if(exonerations.length > 0 || bulkExonerationResponse || initialDate!== null || bulkNoticeResponse) {
      this.masiveFirst.files = null;
      this.masiveFirst.value = "";
      this.noticeFile.files = null;
      this.noticeFile.value = "";
      this.setState({
        documentType: '',
        documentNumber: '',
        productNumber: '',
        initialDate: null,
        finalDate: null,
        userName: '',
        getGuaranteSelected: {},
        firstFile: false,
        noticeFile: false,
        editArray: {},
        editNoticeArray: {},
        bulkExonerationFileError: false,
        bulkNoticeFileError: false,
      }, () => {
        this.props.actions.resetGuaranteesToExonerate();
        this.props.actions.resetGuaranteesToNotify();
      })
    } else {
      this.props.history.push(`/`);
    }

  }

  saveExonerationFile(excelBlob) {
    const {
      exportType
    } = this.state;
    FileSaver.saveAs(excelBlob, `reporteExoneracione${exportType}s.xlsx`);
  }

  saveNoticeFile(excelBlob) {
    const {
      exportTypeNotice
    } = this.state;
    FileSaver.saveAs(excelBlob, `reporteAvisos${exportTypeNotice}s.xlsx`);
  }

  errorFile(errorName) {
    this.masiveFirst.files = null;
    this.masiveFirst.value = "";
    this.noticeFile.files = null;
    this.noticeFile.value = "";
    this.setState({
      documentType: '',
      documentNumber: '',
      productNumber: '',
      initialDate: null,
      finalDate: null,
      userName: '',
      getGuaranteSelected: {},
      firstFile: false,
      noticeFile: false,
      editArray: {},
      editNoticeArray: {},
      [errorName]: true,
    }, () => {
      this.props.actions.resetGuaranteesToExonerate();
      this.props.actions.resetGuaranteesToNotify();
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.bulkExonerationResponse && nextProps.bulkExonerationResponse !== this.props.bulkExonerationResponse) {
      const { bulkExonerationResponse } = nextProps;
      let errorLenght = "REGISTROS_ERROR" in bulkExonerationResponse ? bulkExonerationResponse.REGISTROS_ERROR.length : 0
      let succesLenght = "REGISTROS_CORRECTOS" in bulkExonerationResponse ? bulkExonerationResponse.REGISTROS_CORRECTOS.length : 0
      if(errorLenght === 0 && succesLenght === 0) {
        this.errorFile('bulkExonerationFileError')
      } else {
        if(bulkExonerationResponse && "REGISTROS_ERROR" in bulkExonerationResponse) {
          let editArray = {};
          for (let index in bulkExonerationResponse.REGISTROS_ERROR) {
            editArray[index] = bulkExonerationResponse.REGISTROS_ERROR[index];
          }
          this.setState({
            editArray
          })
        }
      }
    }
    if(nextProps.bulkNoticeResponse && nextProps.bulkNoticeResponse !== this.props.bulkNoticeResponse) {
      const { bulkNoticeResponse } = nextProps;
      let errorLenght = "REGISTROS_ERROR" in bulkNoticeResponse ? bulkNoticeResponse.REGISTROS_ERROR.length : 0
      let succesLenght = "REGISTROS_CORRECTOS" in bulkNoticeResponse ? bulkNoticeResponse.REGISTROS_CORRECTOS.length : 0
      if(errorLenght === 0 && succesLenght === 0) {
        this.errorFile('bulkNoticeFileError')
      } else {
        if(bulkNoticeResponse && "REGISTROS_ERROR" in bulkNoticeResponse) {
          let editNoticeArray = {};
          for (let index in bulkNoticeResponse.REGISTROS_ERROR) {
            editNoticeArray[index] = bulkNoticeResponse.REGISTROS_ERROR[index];
          }
          this.setState({
            editNoticeArray
          })
        }
      }
      if(bulkNoticeResponse && "REGISTROS_ERROR" in bulkNoticeResponse) {
        let editNoticeArray = {};
        for (let index in bulkNoticeResponse.REGISTROS_ERROR) {
          editNoticeArray[index] = bulkNoticeResponse.REGISTROS_ERROR[index];
        }
        this.setState({
          editNoticeArray
        })
      }
    }
    if(nextProps.exonerations){
      this.setState({
        userName: this.state.documentNumber !== '' ? (nextProps.exonerations.length > 0 ? nextProps.exonerations[0].NOMBRE : '') : ''
      })
    }
    if (nextProps.exportExonerations) {
      this.props.actions.resetExportExonerationReport()
      this.saveExonerationFile(nextProps.exportExonerations)
    }
    if (nextProps.exportNotice) {
      this.props.actions.resetExportNoticeReport()
      this.saveNoticeFile(nextProps.exportNotice)
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.props.actions.resetGuaranteesToExonerate();
    this.props.actions.resetGuaranteesToNotify();
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
    this.props.actions.getDocuments();
  }

  newSearch = () => {
    this.page = 0;
    this.getGuaranteesToExonerate();
  }

  getGuaranteesToExonerate = () => {
    const {
      documentType,
      documentNumber,
      productNumber
    } = this.state;
    const {
      user
    } = this.props;
    this.page = this.page + 1;
    this.props.actions.getGuaranteesToExonerate({
      documentType: documentType,
      documentNumber: documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      page: this.page,
      invoiceNumber: productNumber ? productNumber : 0,
      // initialDate: initialDate ? moment(initialDate).format('DD/MM/YYYY') : '00/00/0000',
      // finalDate: finalDate ? moment(finalDate).format('DD/MM/YYYY') : '00/00/0000'
    });
  }

  scrollToMyRef = () => {
    let myDiv = document.getElementsByClassName("redux-lazy-scroll");
    myDiv[0].scrollTop = 0;
  }

  resetForm = () => {
    this.props.actions.resetQuota();
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  partialModalClose() {
    this.setState({
      partialExoneration: null,
      partialModal: false
    });
  }

  totalModalClose = () => {
    this.setState({
      totalModal: false
    });
  }

  oninputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  checkChange = (name, item) => event => {
    let getGuaranteSelected = Object.assign({}, this.state.getGuaranteSelected);
    if(name in getGuaranteSelected && !event.target.checked) {
      delete getGuaranteSelected[name];
    } else {
      getGuaranteSelected[name] = item;
    }
    this.setState({ getGuaranteSelected });
  };

  getDocumentTypeName() {
    const { globals } = this.props;
    const { documentType } = this.state;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item)=> {
      return item.ID === documentType;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  sendPartialExoneration = (guarante) => {
    this.setState({
      partialExoneration: guarante,
      partialModal: true
    });
  }

  sendTotalExoneration = () => {
    this.setState({
      totalModal: true
    });
  }

  confirmPartialExoneration = (valueToExonerate) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    window.gtag('event', 'envio_exoneracion_parcial', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      partialExoneration,
    } = this.state;
    const {
      user
    } = this.props;
    this.setState({
      partialModal: false
    });
    this.props.actions.exonerateGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      exonerateArray: JSON.stringify([
        {
          tipo_documento: partialExoneration.TIPO_DOCUMENTO,
          documento: partialExoneration.DOCUMENTO,
          autorizacion: partialExoneration.AUTORIZACION,
          titulo_valor: partialExoneration.TITULO_VALOR,
          valor_a_exonerar: valueToExonerate
        }
      ]),
    });
  }

  confirmTotalExoneration = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    window.gtag('event', 'envio_exoneracion_total', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      getGuaranteSelected,
    } = this.state;
    const {
      user
    } = this.props;
    this.setState({
      totalModal: false
    });
    let exonerateArray = [];
    for (let key in getGuaranteSelected) {
      if (getGuaranteSelected.hasOwnProperty(key)) {
        exonerateArray.push({
          tipo_documento: getGuaranteSelected[key].TIPO_DOCUMENTO,
          documento: getGuaranteSelected[key].DOCUMENTO,
          autorizacion: getGuaranteSelected[key].AUTORIZACION,
          titulo_valor: getGuaranteSelected[key].TITULO_VALOR,
          valor_a_exonerar: getGuaranteSelected[key].VALOR_A_EXONERAR
        });
      }
    }
    this.props.actions.exonerateGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      exonerateArray: JSON.stringify(exonerateArray),
    });
  }

  handleFileChange = (firstFile, keyFile) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    this.setState({
      [keyFile]: firstFile[0]
    }, () => {
      if(keyFile === 'firstFile') {
        window.gtag('event', 'carga_archivo_exoneracion_masiva', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
        this.sendMasiveExoneration();
      } else {
        window.gtag('event', 'enviar_aviso_de_no_pago_masivo', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
        this.sendMasiveNotice();
      }
    });
  }

  sendMasiveNotice = (check) => {
    const {
      user,
      bulkNoticeResponse
    } = this.props;
    const {
      noticeFile,
      editNoticeArray
    } = this.state;
    this.setState({
      confirmModal: false,
      bulkExonerationFileError: false,
      bulkNoticeFileError: false,
    });
    let correctionsArray = null;
    if(bulkNoticeResponse) {
      correctionsArray = {CARGA: {
        REGISTROS_CORRECTOS: []
      }};
      for (let index in bulkNoticeResponse.REGISTROS_CORRECTOS) {
        correctionsArray.CARGA.REGISTROS_CORRECTOS.push(bulkNoticeResponse.REGISTROS_CORRECTOS[index]);
      }
      for (let key in editNoticeArray) {
        if (editNoticeArray.hasOwnProperty(key)) {
          if(!editNoticeArray[key].deleted) {
            correctionsArray.CARGA.REGISTROS_CORRECTOS.push(editNoticeArray[key]);
          }
        }
      }
      correctionsArray = JSON.stringify(correctionsArray);
    }

    if(check) {
      let userJson = JSON.parse(localStorage.getItem('user'));
      let globalsJson = JSON.parse(localStorage.getItem('globals'));
      let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
      window.gtag('event', 'enviar_aviso_de_no_pago_masivo_confirmar', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    }
    this.props.actions.sendMasiveNotice({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      file: noticeFile,
      correctionsArray,
      check: check ? 0 : 1
    });
  }

  sendMasiveExoneration = (check) => {
    const {
      user,
      bulkExonerationResponse
    } = this.props;
    const {
      firstFile,
      editArray
    } = this.state;
    this.setState({
      confirmModal: false,
      bulkExonerationFileError: false,
      bulkNoticeFileError: false,
    });
    let correctionsArray = null;
    if(bulkExonerationResponse) {
      correctionsArray = {CARGA: {
        REGISTROS_CORRECTOS: []
      }};
      for (let index in bulkExonerationResponse.REGISTROS_CORRECTOS) {
        correctionsArray.CARGA.REGISTROS_CORRECTOS.push(bulkExonerationResponse.REGISTROS_CORRECTOS[index]);
      }
      for (let key in editArray) {
        if (editArray.hasOwnProperty(key)) {
          if(!editArray[key].deleted) {
            correctionsArray.CARGA.REGISTROS_CORRECTOS.push(editArray[key]);
          }
        }
      }
      correctionsArray = JSON.stringify(correctionsArray);
    }

    if(check) {
      let userJson = JSON.parse(localStorage.getItem('user'));
      let globalsJson = JSON.parse(localStorage.getItem('globals'));
      let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
      window.gtag('event', 'enviar_exoneracion_masiva_confirmar', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    }
    this.props.actions.sendMasiveExoneration({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      file: firstFile,
      correctionsArray,
      check: check ? 0 : 1
    });
  }

  openSelector = (keyFile) => {
    this[keyFile].click();
  }

  openLink = (documentName) => {
    const {documents} = this.props;
    var document = documents.find((element) => {
      return element.DOCUMENTO === documentName;
    });
    window.open(document.LINK,'_newtab');
  }

  onEditChange = (e, index, key, item, deleted) => {
    let editArray = Object.assign([], this.state.editArray);
    if(!(index in editArray)){
      editArray[index] = item;
    }
    if(deleted){
      editArray[index][key] = true;
    } else {
      editArray[index][key] = e.target.value;
    }
    this.setState({
      editArray
    }, () => {
      if(deleted){
        this.sendMasiveExoneration();
      }
    });
  }

  onEditNoticeChange = (e, index, key, item, deleted) => {
    let editNoticeArray = Object.assign([], this.state.editNoticeArray);
    if(!(index in editNoticeArray)){
      editNoticeArray[index] = item;
    }
    if(deleted){
      editNoticeArray[index][key] = true;
    } else {
      editNoticeArray[index][key] = e.target.value;
    }
    this.setState({
      editNoticeArray
    }, () => {
      if(deleted){
        this.sendMasiveNotice();
      }
    });
  }

  handleDateFromChange = date => {
    this.setState({ initialDate: date }, () => {
      if(this.state.finalDate !== null) {
        this.page = 0;
        this.getGuaranteesToExonerate();
      }
    });
  };

  handleDateToChange = date => {
    this.setState({ finalDate: date }, () => {
      if(this.state.initialDate !== null) {
        this.page = 0;
        this.getGuaranteesToExonerate();
      }
    });
  };

  handleBlur = () => {
    this.sendMasiveExoneration();
  }

  handleNoticeBlur = () => {
    this.sendMasiveNotice();
  }

  exportFile = (exportType, exportArray) => {

    if(exportArray.length > 0) {
      this.setState({
        exportType
      }, () => {
        let numberFiled = exportArray[0].RADICACION
        const {
          user
        } = this.props;
        this.props.actions.exportMasiveExoneration({
          userDocument: user.userInfo.DOCUMENTO,
          userDocumentType: user.userInfo.TIPO_DOCUMENTO,
          numberFiled,
          status: exportType
        });
      })
    }
  }

  exportNoticeFile = (exportTypeNotice, exportArray) => {

    if(exportArray.length > 0) {
      this.setState({
        exportTypeNotice
      }, () => {
        let numberFiled = exportArray[0].RADICACION
        const {
          user
        } = this.props;
        this.props.actions.exportMasiveNotice({
          userDocument: user.userInfo.DOCUMENTO,
          userDocumentType: user.userInfo.TIPO_DOCUMENTO,
          numberFiled,
          status: exportTypeNotice
        });
      })
    }
  }

  render() {
    const { menuOpen,
      partialModal,
      documentType,
      documentNumber,
      userName,
      getGuaranteSelected,
      partialExoneration,
      totalModal,
      editArray,
      editNoticeArray,
      // initialDate,
      // finalDate,
      productNumber,
      bulkExonerationFileError,
      bulkNoticeFileError,
    } = this.state;
    const {
      classes,
      globals,
      loading,
      loadingBulk,
      exonerations,
      exonerationsPageCount,
      exoneration,
      bulkExonerationResponse,
      bulkExonerationFinalResponse,
      bulkNoticeFinalResponse,
      loadingPaginate,
      bulkNoticeResponse,
    } = this.props;
    let bulkExonerationFinalResponseError = []
    let bulkExonerationFinalResponseSucces = []
    if (bulkExonerationFinalResponse) {
      bulkExonerationFinalResponseError = bulkExonerationFinalResponse.filter((item) => {
        return item.OBSERVACION === "Negada";
      });
      bulkExonerationFinalResponseSucces = bulkExonerationFinalResponse.filter((item) => {
        return !(item.OBSERVACION === "Negada");
      });
    }
    let bulkNoticeFinalResponseError = []
    let bulkNoticeFinalResponseSucces = []
    if (bulkNoticeFinalResponse) {
      bulkNoticeFinalResponseError = bulkNoticeFinalResponse.filter((item) => {
        return !(item.OBSERVACION === "Aviso No Pago Existoso");
      });
      bulkNoticeFinalResponseSucces = bulkNoticeFinalResponse.filter((item) => {
        return item.OBSERVACION === "Aviso No Pago Existoso";
      });
    }
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];

    return (
      <div className="exoneration">
        <PartialModal
          show={partialModal}
          onHide={this.partialModalClose}
          partialExoneration={partialExoneration}
          confirmExoneration={this.confirmPartialExoneration}
        />
        <TotalModal
          show={totalModal}
          onHide={this.totalModalClose}
          exonerations={getGuaranteSelected}
          confirmExoneration={this.confirmTotalExoneration}
        />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={8} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
              <input
                style={{display: "none"}}
                ref={input => this.noticeFile = input}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={ (e) => this.handleFileChange(e.target.files, 'noticeFile') }
              />
              <input
                style={{display: "none"}}
                ref={input => this.masiveFirst = input}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={ (e) => this.handleFileChange(e.target.files, 'firstFile') }
              />
            { (exonerations.length === 0 && exonerationsPageCount === 1 && (bulkNoticeFileError || !bulkExonerationResponse) && (bulkExonerationFileError || !bulkNoticeResponse)) && (
              <Col xs={12} className="general-main-card">
                { (loading || loadingBulk) && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                    }}
                  >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Row>
                    <Col xs={12} md={{ span: 5, offset: 1 }}>
                      <Row>
                        <Col xs={12} className="card-title green">
                          Aviso de no pago masivo
                        </Col>
                        <Col xs={12} className="icon-container">
                          <img src={exonerationbluk} alt="exoneración masiva icono" />
                        </Col>
                        <Col xs={12} className="card-description">
                          <span
                            onClick={(e) => {
                                e.preventDefault();
                                this.openLink('Formato Carga Masiva Aviso no pago');
                            }}
                            className="option-link"
                            >Descargue aquí el formato de “Aviso de no pago masivo”</span>, complete los datos solicitados y cárguelo a la plataforma en el botón “Cargar archivo”.
                        </Col>
                        <Col xs={12} md={8} className="button-send-container exoneration-margin">
                          <Button
                            className="button-send"
                            variant="success"
                            onClick={(e) => {
                                e.preventDefault();
                                this.openSelector('noticeFile');
                            }}
                            block
                          >
                            Cargar archivo
                          </Button>
                        </Col>
                        {bulkNoticeFileError && (
                          <Col xs={12} className="card-sub-title red">
                            El archivo no puede estar vacío
                          </Col>
                        )}
                      </Row>
                    </Col>
                  <Col xs={12} md={{ span: 5, offset: 1 }}>
                    <Row>
                      <Col xs={12} className="card-title">
                        Exoneración masiva
                      </Col>
                      <Col xs={12} className="icon-container">
                        <img src={exonerationbluk} alt="exoneración masiva icono" />
                      </Col>
                      <Col xs={12} className="card-description">
                        <span
                          onClick={(e) => {
                              e.preventDefault();
                              this.openLink('Formato Carga Masiva Exoneracion');
                          }}
                          className="option-link"
                          >Descargue aquí el formato de “Exoneración masiva”</span>, complete los datos solicitados y cárguelo a la plataforma en el botón “Cargar archivo”.
                      </Col>
                      <Col xs={12} md={8} className="button-send-container exoneration-margin">
                        <Button
                          className="button-send"
                          variant="success"
                          onClick={(e) => {
                              e.preventDefault();
                              this.openSelector('masiveFirst');
                          }}
                          block
                        >
                          Cargar archivo
                        </Button>
                      </Col>
                      {bulkExonerationFileError && (
                        <Col xs={12} className="card-sub-title red">
                          El archivo no puede estar vacío
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
            { (!bulkExonerationFileError && bulkExonerationResponse && !bulkExonerationFinalResponse) && (
              <Col xs={12} className="general-main-card">
                { loading && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                    >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="errors-title">
                      Exoneraciones erradas {bulkExonerationResponse.REGISTROS_ERROR.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                            <th className="delete">Borrar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkExonerationResponse.REGISTROS_ERROR.map((item, index) => {
                            if( index in editArray && editArray[index].deleted) {
                                return (
                                  ""
                                );
                            }
                            return (
                              <tr key={index}>
                                <td>
                                  <div className={'TIPO_DOCUMENTO' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "TIPO_DOCUMENTO", item)}} value={ index in editArray ? editArray[index].TIPO_DOCUMENTO : item.TIPO_DOCUMENTO} />
                                  </div>
                                  {'TIPO_DOCUMENTO' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.TIPO_DOCUMENTO}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'DOCUMENTO' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="number" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "DOCUMENTO", item)}} value={ index in editArray ? editArray[index].DOCUMENTO : item.DOCUMENTO} />
                                  </div>
                                  {'DOCUMENTO' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.DOCUMENTO}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'TITULO_VALOR' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "TITULO_VALOR", item)}} value={ index in editArray ? editArray[index].TITULO_VALOR : item.TITULO_VALOR} />
                                  </div>
                                  {'TITULO_VALOR' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.TITULO_VALOR}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'VALOR' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "VALOR", item)}} value={ index in editArray ? editArray[index].VALOR : item.VALOR} />
                                  </div>
                                  {'VALOR' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.VALOR}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'FECHA' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "FECHA", item)}} value={ index in editArray ? editArray[index].FECHA : item.FECHA} />
                                  </div>
                                  {'FECHA' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.FECHA}</div>
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="close close-table"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.onEditChange(e, index, "deleted", item, true)
                                    }}
                                    >
                                    <img src={closeIcon} alt="close icon"/>
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    {/*
                      <Col xs={12} md={{span: 4, offset:4}}>
                      <Button
                      className="button-send"
                      variant="success"
                      disabled={bulkExonerationResponse.REGISTROS_ERROR.length === 0}
                      onClick={(e) => {
                      e.preventDefault();
                      this.sendMasiveExoneration();
                      }}
                      block
                      >
                      Guardar
                      </Button>
                      </Col>
                      */}
                  </Row>
                  <Row>
                    <Col xs={12} className="success-title">
                      Exoneraciones exitosas {bulkExonerationResponse.REGISTROS_CORRECTOS.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkExonerationResponse.REGISTROS_CORRECTOS.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.TIPO_DOCUMENTO}</td>
                                <td>{item.DOCUMENTO}</td>
                                <td>{item.TITULO_VALOR}</td>
                                <td>${this.getNumber(item.VALOR)}</td>
                                <td>{item.FECHA}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} md={{span: 4, offset:4}}>
                      <Button
                        className="button-send"
                        variant="success"
                        disabled={bulkExonerationResponse.REGISTROS_ERROR.length > 0}
                        onClick={(e) => {
                            e.preventDefault();
                            this.sendMasiveExoneration(true);
                        }}
                        block
                      >
                      Exonerar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            )}
            { bulkExonerationFinalResponse && (
              <Col xs={12} className="general-main-card">
                { loading && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                    >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="errors-title">
                      Exoneraciones  negadas {bulkExonerationFinalResponseError.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                            <th className="denegate-th">Negadas</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkExonerationFinalResponseError.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className="denegate">
                                  {item.TIPO_DOCUMENTO}
                                </td>
                                <td className="denegate cross-out">
                                  {item.DOCUMENTO}
                                </td>
                                <td className="denegate cross-out">
                                  {item.NUMERO_CHQ}
                                </td>
                                <td className="denegate cross-out">
                                  ${this.getNumber(item.VALOR)}
                                </td>
                                <td className="denegate cross-out">
                                  {item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}
                                </td>
                                <td className="denegate">
                                  {item.OBSERVACION}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="download-result-file_container">
                      <button className="download-result-file" onClick={(e) => {
                          e.preventDefault();
                          let userJson = JSON.parse(localStorage.getItem('user'));
                          let globalsJson = JSON.parse(localStorage.getItem('globals'));
                          let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
                          window.gtag('event', 'descargar_exonerados_negados', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
                          this.exportFile('Negada', bulkExonerationFinalResponseError);
                        }}>
                        Descargar archivos negados
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="approved-title">
                      Exoneraciones aprobadas {bulkExonerationFinalResponseSucces.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                            <th className="approved-th">Código aprobación</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkExonerationFinalResponseSucces.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.TIPO_DOCUMENTO}</td>
                                <td>{item.DOCUMENTO}</td>
                                <td>{item.NUMERO_CHQ}</td>
                                <td>${this.getNumber(item.VALOR)}</td>
                                <td>{item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}</td>
                                <td className="approved-td">Exonerada</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} md={{span: 4, offset:4}}>
                      <Button
                        className="button-send"
                        variant="success"
                        onClick={(e) => {
                            e.preventDefault();
                            this.goHome();
                        }}
                        block
                      >
                      Volver
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="download-result-file_container">
                      <button className="download-result-file" onClick={(e) => {
                          e.preventDefault();
                          let userJson = JSON.parse(localStorage.getItem('user'));
                          let globalsJson = JSON.parse(localStorage.getItem('globals'));
                          let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
                          window.gtag('event', 'descargar_exonerados_aprobados', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
                          this.exportFile('Exonerada', bulkExonerationFinalResponseSucces);
                        }}>
                        Descargar archivos aceptados
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            )}
            { bulkNoticeFinalResponse && (
              <Col xs={12} className="general-main-card">
                { loading && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                    >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="errors-title">
                      Avisos de no pago  negados {bulkNoticeFinalResponseError.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                            <th className="denegate-th">Negados</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkNoticeFinalResponseError.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className="denegate">
                                  {item.TIPO_DOCUMENTO}
                                </td>
                                <td className="denegate cross-out">
                                  {item.DOCUMENTO}
                                </td>
                                <td className="denegate cross-out">
                                  {item.NUMERO_CHQ}
                                </td>
                                <td className="denegate cross-out">
                                  ${this.getNumber(item.VALOR)}
                                </td>
                                <td className="denegate cross-out">
                                  {item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}
                                </td>
                                <td className="denegate">
                                  Negado
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="download-result-file_container">
                      <button className="download-result-file" onClick={(e) => {
                          e.preventDefault();
                          let userJson = JSON.parse(localStorage.getItem('user'));
                          let globalsJson = JSON.parse(localStorage.getItem('globals'));
                          let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
                          window.gtag('event', 'descargar_avisos_negados', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
                          this.exportNoticeFile('Negada', bulkNoticeFinalResponseError);
                        }}>
                        Descargar archivos negados
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="approved-title">
                      Avisos de no pago marcados {bulkNoticeFinalResponseSucces.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                            <th className="approved-th">Código aprobación</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkNoticeFinalResponseSucces.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.TIPO_DOCUMENTO}</td>
                                <td>{item.DOCUMENTO}</td>
                                <td>{item.NUMERO_CHQ}</td>
                                <td>${this.getNumber(item.VALOR)}</td>
                                <td>{item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}</td>
                                <td className="approved-td">Marcado</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} md={{span: 4, offset:4}}>
                      <Button
                        className="button-send"
                        variant="success"
                        onClick={(e) => {
                            e.preventDefault();
                            this.goHome();
                        }}
                        block
                      >
                      Volver
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="download-result-file_container">
                      <button className="download-result-file" onClick={(e) => {
                          e.preventDefault();
                          let userJson = JSON.parse(localStorage.getItem('user'));
                          let globalsJson = JSON.parse(localStorage.getItem('globals'));
                          let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
                          window.gtag('event', 'descargar_avisos_aprobados', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
                          this.exportNoticeFile('Marcada', bulkNoticeFinalResponseSucces);
                        }}>
                        Descargar archivos aceptados
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            )}
            { (!bulkNoticeFileError && bulkNoticeResponse && !bulkNoticeFinalResponse) && (
              <Col xs={12} className="general-main-card">
                { loadingBulk && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                    >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="errors-title">
                      Avisos errados {bulkNoticeResponse.REGISTROS_ERROR.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                            <th className="delete">Borrar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkNoticeResponse.REGISTROS_ERROR.map((item, index) => {
                            if( index in editNoticeArray && editNoticeArray[index].deleted) {
                                return (
                                  ""
                                );
                            }
                            return (
                              <tr key={index}>
                                <td>
                                  <div className={'TIPO_DOCUMENTO' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleNoticeBlur} onChange={(e) => { this.onEditNoticeChange(e, index, "TIPO_DOCUMENTO", item)}} value={ index in editNoticeArray ? editNoticeArray[index].TIPO_DOCUMENTO : item.TIPO_DOCUMENTO} />
                                  </div>
                                  {'TIPO_DOCUMENTO' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.TIPO_DOCUMENTO}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'DOCUMENTO' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="number" onBlur={this.handleNoticeBlur} onChange={(e) => { this.onEditNoticeChange(e, index, "DOCUMENTO", item)}} value={ index in editNoticeArray ? editNoticeArray[index].DOCUMENTO : item.DOCUMENTO} />
                                  </div>
                                  {'DOCUMENTO' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.DOCUMENTO}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'TITULO_VALOR' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleNoticeBlur} onChange={(e) => { this.onEditNoticeChange(e, index, "TITULO_VALOR", item)}} value={ index in editNoticeArray ? editNoticeArray[index].TITULO_VALOR : item.TITULO_VALOR} />
                                  </div>
                                  {'TITULO_VALOR' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.TITULO_VALOR}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'VALOR' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleNoticeBlur} onChange={(e) => { this.onEditNoticeChange(e, index, "VALOR", item)}} value={ index in editNoticeArray ? editNoticeArray[index].VALOR : item.VALOR} />
                                  </div>
                                  {'VALOR' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.VALOR}</div>
                                  )}
                                </td>
                                <td>
                                  <div className={'FECHA' in item.MENSAJE ? 'error-value' : ''}>
                                    <input className="table-input" type="text" onBlur={this.handleNoticeBlur} onChange={(e) => { this.onEditNoticeChange(e, index, "FECHA", item)}} value={ index in editNoticeArray ? editNoticeArray[index].FECHA : item.FECHA} />
                                  </div>
                                  {'FECHA' in item.MENSAJE && (
                                    <div className="error-text">{item.MENSAJE.FECHA}</div>
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="close close-table"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.onEditNoticeChange(e, index, "deleted", item, true)
                                    }}
                                    >
                                    <img src={closeIcon} alt="close icon"/>
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="success-title">
                      Avisos exitosos {bulkNoticeResponse.REGISTROS_CORRECTOS.length}
                    </Col>
                    <Col xs={12} className="results-table-container">
                      <Table bordered responsive="sm">
                        <thead>
                          <tr>
                            <th>Tipo doc/to</th>
                            <th>Número documento</th>
                            <th>Número factura</th>
                            <th>Valor factura</th>
                            <th>Fecha vencimiento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkNoticeResponse.REGISTROS_CORRECTOS.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.TIPO_DOCUMENTO}</td>
                                <td>{item.DOCUMENTO}</td>
                                <td>{item.TITULO_VALOR}</td>
                                <td>${this.getNumber(item.VALOR)}</td>
                                <td>{item.FECHA}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} md={{span: 6, offset:3}}>
                      <Button
                        className="button-send"
                        variant="success"
                        disabled={bulkNoticeResponse.REGISTROS_ERROR.length > 0}
                        onClick={(e) => {
                            e.preventDefault();
                            this.sendMasiveNotice(true);
                        }}
                        block
                      >
                      Enviar avisos de no pago
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            )}
            { (exonerations.length > 0 && !exoneration) && (
              <Col xs={12} className="general-main-card">
                { loading && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                    }}
                  >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12}>
                  <Row className="exoneraton-search_name-center">
                    <Col xs={12} md={4}>
                      <Row>
                        <Col xs={12} className="exoneraton-search_name">{userName.toLowerCase()}</Col>
                        <Col xs={12} className="exoneraton-search_document">{documentType} Nº {documentNumber}</Col>
                      </Row>
                    </Col>
                    {/*
                      <Col xs={12} md={5}>
                      <Row>
                      <Col xs={12} className="exoneraton-search_title">Para realizar una búsqueda seleccione la fecha de emisión de las facturas</Col>
                      <Col xs={12} className="exoneraton-col-padding">
                      <Row>
                      <Col xs={12} md={6} className="exoneraton-date-boder">
                      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                      <DatePicker
                      margin="normal"
                      label="Desde"
                      className="home-input input-date"
                      value={initialDate}
                      locale='es-ES'
                      onChange={this.handleDateFromChange}
                      fullWidth
                      format="DD/MM/YYYY"
                      InputProps={{
                      endAdornment: (
                      <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                      ),
                      }}
                      />
                      </MuiPickersUtilsProvider>
                      </Col>
                      <Col xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                      <DatePicker
                      margin="normal"
                      label="Hasta"
                      className="home-input input-date"
                      value={finalDate}
                      locale='es-ES'
                      onChange={this.handleDateToChange}
                      fullWidth
                      format="DD/MM/YYYY"
                      InputProps={{
                      endAdornment: (
                      <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                      ),
                      }}
                      />
                      </MuiPickersUtilsProvider>
                      </Col>
                      </Row>
                      </Col>
                      </Row>
                      </Col>
                      */}
                      <Col xs={12} md={8}>
                        <Row>
                          <Col>
                            <TextField
                              id="documentNumber"
                              label="Buscar número factura"
                              className="home-input"
                              margin="dense"
                              value={productNumber}
                              onChange={(event) => {
                                this.oninputChange(event, 'productNumber');
                              }}
                              InputProps={{
                                endAdornment: (
                                  <img src={searchicon} alt="icono calendario" className="calendar-icon"/>
                                ),
                              }}
                              fullWidth
                              />
                          </Col>
                          <Col xs={5}>
                            <Button
                              className="button-send"
                              variant="success"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ getGuaranteSelected: {}}, () => {
                                  this.scrollToMyRef();
                                  this.newSearch();
                                })
                              }}
                              block
                              >
                              Buscar
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                  </Row>
                </Col>
                <Col xs={12} className="exoneration-table-container">
                  <ReduxLazyScroll
                    isFetching={loadingPaginate}
                    errorMessage=''
                    isParentScrollable={true}
                    parentHeight='250px'
                    loadMore={() => {
                      this.getGuaranteesToExonerate();
                    }}
                    hasMore={exonerationsPageCount > 0}
                  >
                    <Table bordered responsive="sm">
                      <thead>
                        <tr>
                          <th>Número producto</th>
                          {/*
                            <th>Fecha de emisión</th>
                            */}
                          <th>Valor</th>
                          <th>Exoneración parcial</th>
                          <th>Exoneración total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {exonerations.map((item, index) => {
                          console.log('item', item)
                          return (
                            <tr key={index}>
                              {item.TIPO_TITULO === 'FACTURA' && (
                                <td>{item.TITULO_VALOR}</td>
                              )}
                              {item.TIPO_TITULO !== 'FACTURA' && (
                                <td className="check">Cheque</td>
                              )}
                              {/*
                                <td>{item.FECHA_EMISION === '' ? '' : moment(item.FECHA_EMISION).format('DD/MM/YYYY')}</td>
                                */}
                              <td>${this.getNumber(item.VALOR_A_EXONERAR)}</td>
                              {(item.TIPO_TITULO === 'FACTURA' && (!item.NUMERO_RECLAMACION || item.NUMERO_RECLAMACION === "0")) && (
                                <td
                                  className="notice-td"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.sendPartialExoneration(item);
                                  }}
                                >
                                  <div className="notice-td-container">
                                    <span>
                                      Exoneración parcial
                                    </span>
                                    <img src={arrowgreen} alt="flecha verde" className="link-arrow"/>
                                  </div>
                                </td>
                              )}

                              {(item.NUMERO_RECLAMACION && item.NUMERO_RECLAMACION !== "0") && (
                                <td>
                                Desistimiento
                                </td>
                              )}



                              {(item.TIPO_TITULO !== 'FACTURA' && (!item.NUMERO_RECLAMACION || item.NUMERO_RECLAMACION === "0")) && (
                                <td
                                  className="notice-td"
                                >
                                </td>
                              )}

                              <td align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={index in getGuaranteSelected}
                                      onChange={this.checkChange(index, item)}
                                      classes={{
                                        root: classes.root,
                                        checked: classes.checked,
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    { (loadingPaginate ) && (
                      <ListLoader />
                    )}
                  </ReduxLazyScroll>
                </Col>
                <Col xs={12} md={{ span: 4, offset: 4 }} className="button-send-container exoneration-margin">
                  <Button
                    className="button-send"
                    variant="success"
                    disabled={Object.keys(getGuaranteSelected).length === 0}
                    onClick={(e) => {
                      e.preventDefault();
                      this.sendTotalExoneration();
                    }}
                    block
                  >
                    Exonerar total {Object.keys(getGuaranteSelected).length > 0 ? `(${Object.keys(getGuaranteSelected).length})` : ''}
                  </Button>
                </Col>
              </Col>
            )}
            { (productNumber === '' && exonerations.length === 0 && exonerationsPageCount === 0 && !exoneration) && (
              <Col xs={12} className="general-main-card">
                { loading && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                    }}
                  >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12}>
                  <Row className="exoneraton-search_name-center">
                    <Col xs={12} md={4} className="login-input-container">
                      <TextField
                        id="documentType"
                        select
                        label="Tipo de documento"
                        className="home-input"
                        margin="dense"
                        value={documentType}
                        onChange={(event) => {
                          this.oninputChange(event, 'documentType');
                        }}
                        fullWidth
                        >
                        {documents.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.ID}>
                              {item.LABEL}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Col>
                    <Col xs={12} md={4} className="login-input-container">
                      <TextField
                        id="documentNumber"
                        label="Número de documento"
                        className="home-input"
                        margin="dense"
                        type="number"
                        value={documentNumber}
                        onChange={(event) => {
                          this.oninputChange(event, 'documentNumber');
                        }}
                        fullWidth
                        />
                    </Col>
                    <Col xs={12} md={4}>
                      <Button
                        className="button-send"
                        variant="success"
                        onClick={(e) => {
                          e.preventDefault();
                          this.newSearch();
                        }}
                        block
                        >
                      Buscar
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                      <img src={denegate} alt="icono reservas" className="resevation-icon-denegate"/>
                    </Col>
                    <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                      {this.getDocumentTypeName()} <span className="not-reservation-text_bold">Nº {documentNumber}</span> no se encuentra en el sistema
                    </Col>
                  </Row>
                </Col>
              </Col>
            )}
            { (productNumber !== '' && exonerations.length === 0 && exonerationsPageCount === 0 && !exoneration) && (
              <Col xs={12} className="general-main-card">
                { loading && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                    }}
                  >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                {/*
                  <Col xs={12}>
                  <Row className="exoneraton-search_name-center">
                  <Col xs={12} md={5}>
                  <Row>
                  <Col xs={12} className="exoneraton-search_title">Para realizar una búsqueda seleccione la fecha de emisión de las facturas</Col>
                  <Col xs={12} className="exoneraton-col-padding">
                  <Row>
                  <Col xs={12} md={6} className="exoneraton-date-boder">
                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                  margin="normal"
                  label="Desde"
                  className="home-input input-date"
                  value={initialDate}
                  locale='es-ES'
                  onChange={this.handleDateFromChange}
                  fullWidth
                  format="DD/MM/YYYY"
                  InputProps={{
                  endAdornment: (
                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                  ),
                  }}
                  />
                  </MuiPickersUtilsProvider>
                  </Col>
                  <Col xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                  margin="normal"
                  label="Hasta"
                  className="home-input input-date"
                  value={finalDate}
                  locale='es-ES'
                  onChange={this.handleDateToChange}
                  fullWidth
                  format="DD/MM/YYYY"
                  InputProps={{
                  endAdornment: (
                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                  ),
                  }}
                  />
                  </MuiPickersUtilsProvider>
                  </Col>
                  </Row>
                  </Col>
                  </Row>
                  </Col>
                  </Row>
                </Col>
                  */}
                <Col xs={12}>
                  <Row>
                    <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                      <img src={denegate} alt="icono reservas" className="resevation-icon-denegate"/>
                    </Col>
                    {/*
                      <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                      No se encontraron facturas <span className="not-reservation-text_bold">{initialDate ? moment(initialDate).format('DD/MM/YYYY') : '00/00/0000'} y {finalDate ? moment(finalDate).format('DD/MM/YYYY') : '00/00/0000'}</span>
                      </Col>
                      */}
                    <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                      No se encontraron facturas <span className="not-reservation-text_bold"></span>
                    </Col>
                    <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                      Si desea puede realizar otra búsqueda
                    </Col>
                  </Row>
                </Col>
              </Col>
            )}
            { exoneration && (
              <Col xs={12} className="general-main-card">
                <Col xs={12} className="aproved-icon-container center-horizontally">
                  <img src={aproved} className="img-process" alt="rechazado icono" />
                </Col>
                <Col xs={12} className="aproved-title">
                  ¡EXONERACIÓN EXITOSA!
                </Col>
                <Col xs={12} md={{ span: 6, offset: 3 }} className="aproved-description">
                  Muchas gracias por utilizar los servicios de <span className="aproved-description-bold">Covifactura .</span>
                </Col>
                <Col xs={12} md={{ span: 6, offset: 3 }} className="button-send-container aproved-button-container">
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                    }}
                    block
                  >
                    Realizar nueva exoneración
                  </Button>
                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      user,
      globals
  } = state.user;
  let {
    loading,
    exonerations,
    exonerationsNotRegistered,
    exonerationsPageCount,
    exoneration,
    bulkExonerationError,
    bulkExonerationResponse,
    bulkExonerationFinalResponse,
    exportExonerations,
    loadingPaginate
  } = state.exoneration;

  let {
    bulkNoticeError,
    bulkNoticeResponse,
    bulkNoticeFinalResponse,
    loadingBulk,
    exportNotice
  } = state.claim;

  let {
      documents,
  } = state.document;

  return {
      user,
      globals,
      loading,
      exonerations,
      exonerationsNotRegistered,
      exonerationsPageCount,
      exoneration,
      bulkExonerationError,
      bulkExonerationResponse,
      bulkExonerationFinalResponse,
      documents,
      exportExonerations,
      loadingPaginate,
      bulkNoticeError,
      bulkNoticeResponse,
      bulkNoticeFinalResponse,
      loadingBulk,
      exportNotice
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Exoneration));
