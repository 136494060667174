import * as documentService from "../services";
import {
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_TERMS_REQUEST,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  GET_TERMSACEPTED_REQUEST,
  GET_TERMSACEPTED_SUCCESS,
  GET_TERMSACEPTED_FAILURE,
  SET_TERMSACEPTED_REQUEST,
  SET_TERMSACEPTED_SUCCESS,
  SET_TERMSACEPTED_FAILURE,
  GENERAL_ERROR,
} from "../action-types";

export const getDocuments = (reload) => {
  return (dispatch) => {
    dispatch(request());
    let documents = JSON.parse(localStorage.getItem("documents"));
    if (!reload && documents) {
      dispatch(success(documents));
    } else {
      documentService.getDocuments().then(
        (data) => {
          localStorage.setItem("documents", JSON.stringify(data.DOCUMENTOS));
          dispatch(success(data.DOCUMENTOS));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return { type: GET_DOCUMENTS_REQUEST };
  }
  function success(data) {
    return { type: GET_DOCUMENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_DOCUMENTS_FAILURE, error };
  }
};

export const getTerms = ({ documentType, documentNumber, termsType }) => {
  return (dispatch) => {
    dispatch(request());
    documentService.getTerms({ documentType, documentNumber, termsType }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_TERMS_REQUEST };
  }
  function success(data) {
    return { type: GET_TERMS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TERMS_FAILURE, error };
  }
};

export const getPublicTerms = ({ termsType }) => {
  return (dispatch) => {
    dispatch(request());
    documentService.getPublicTerms({ termsType }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_TERMS_REQUEST };
  }
  function success(data) {
    return { type: GET_TERMS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TERMS_FAILURE, error };
  }
};

export const getTermsAcepted = ({
  documentType,
  documentNumber,
  userEmail,
  userDocumentType,
  userDocumentNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    documentService
      .getTermsAcepted({
        documentType,
        documentNumber,
        userEmail,
        userDocumentType,
        userDocumentNumber,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_TERMSACEPTED_REQUEST };
  }
  function success(data) {
    return { type: GET_TERMSACEPTED_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_TERMSACEPTED_FAILURE, error };
  }
};

export const setTermsAcepted = ({
  documentType,
  documentNumber,
  userEmail,
  userDocumentType,
  userDocumentNumber,
  termsType,
  url,
  loginDate,
}) => {
  return (dispatch) => {
    dispatch(request());
    documentService
      .setTermsAcepted({
        documentType,
        documentNumber,
        userEmail,
        userDocumentType,
        userDocumentNumber,
        termsType,
        url,
        loginDate,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SET_TERMSACEPTED_REQUEST };
  }
  function success(data) {
    return { type: SET_TERMSACEPTED_SUCCESS, data };
  }
  function failure(error) {
    return { type: SET_TERMSACEPTED_FAILURE, error };
  }
};

export const generalError = (generalError) => {
  return { type: GENERAL_ERROR, generalError };
};
