import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import ErrorModal from './components/error-modal';

import './assets/latofonts.css';
import './assets/bootstrap.min.css';
import './App.scss';

class App extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      ready: false,
    };
  }

  termModalClose = () => {
    this.setState({
      showModal: false,
    });
  };
  componentDidMount() {
    fetch('/config.json')
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('config', JSON.stringify(data));
        this.setState({
          ready: true,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.generalError &&
      nextProps.generalError !== this.props.generalError
    ) {
      this.setState({
        showModal: true,
        generalError: nextProps.generalError,
      });
    }
  }

  static propTypes = {
    children: PropTypes.object.isRequired,
  };

  render() {
    const { children } = this.props;
    const { showModal, generalError, ready } = this.state;

    return (
      <div className='App'>
        <ErrorModal
          show={showModal}
          confirm={this.termModalClose}
          onHide={this.termModalClose}
          errorText={generalError}
        />

        {ready && children}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { generalError } = state.user;

  return {
    generalError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
