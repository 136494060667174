import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

import * as actions from '../../../redux/actions';
import { Col, Button, Modal, Row } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';
import { debounce } from 'debounce';

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      documentType: '',
      documentNumber: '',
      homeDepartment: '',
      homeCity: '',
      cellular: '',
      email: '',
      emailValid: true,
      firstName: '',
      secondName: '',
      lastName: '',
      secondLastName: '',
      branch: '',
      role: '',
      action: 'CREAR',
      newPassword: '',
      newPasswordRepeat: '',
    };

    this.isValidEmail = debounce(this.isValidEmail, 1000);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
  }

  getRoleList = (user) => {
    this.props.actions.getRoleList({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.getRoleList(nextProps.user);
    }
    if (nextProps.newUser !== this.props.newUser) {
      if (nextProps.newUser) {
        this.setState(
          {
            documentType: nextProps.newUser.documentType,
            documentNumber: nextProps.newUser.documentNumber,
            homeDepartment: nextProps.newUser.homeDepartment,
            homeCity: nextProps.newUser.homeCity,
            cellular: nextProps.newUser.cellular,
            email: nextProps.newUser.email,
            emailValid: true,
            firstName: nextProps.newUser.firstName,
            secondName: nextProps.newUser.secondName,
            lastName: nextProps.newUser.lastName,
            secondLastName: nextProps.newUser.secondLastName,
            branch: nextProps.newUser.branch,
            role: nextProps.newUser.role,
            action: nextProps.newUser.action,
          },
          () => {
            setTimeout(() => {
              this.setState({
                newPassword: '',
                newPasswordRepeat: '',
              });
            }, 500);
          }
        );
        if (nextProps.newUser.homeDepartment !== '1111') {
          this.props.actions.getCities({
            departmentId: nextProps.newUser.homeDepartment,
          });
        }
      } else {
        this.setState({
          documentType: '',
          documentNumber: '',
          homeDepartment: '',
          homeCity: '',
          cellular: '',
          email: '',
          emailValid: true,
          firstName: '',
          secondName: '',
          lastName: '',
          secondLastName: '',
          branch: '',
          role: '',
          action: 'CREAR',
          newPassword: '',
          newPasswordRepeat: '',
        });
      }
    }
  }

  isValidEmail(emailUser, input) {
    if (!emailUser) {
      this.setState({
        [input]: true,
      });
      return;
    }
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
      validEmail = true;
    }
    this.setState({
      [input]: validEmail,
    });
  }

  isEmail = (event, input) => {
    let email = event.target.value;
    let validName = input + 'Valid';
    this.isValidEmail(email, validName);
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
      validEmail = true;
    }
    if (validEmail) {
      this.setState({
        [input]: email,
        [validName]: validEmail,
      });
    } else {
      this.setState({
        [input]: email,
      });
    }
  };

  oninputChange = (event, input) => {
    if (input === 'homeDepartment') {
      if (event.target.value !== '1111') {
        this.props.actions.getCities({
          departmentId: event.target.value,
        });
      }
      this.setState({
        homeCity: '',
        [input]: event.target.value,
      });
      return;
    }
    this.setState({
      [input]: event.target.value,
    });
  };

  cancel = () => {
    this.props.onHide();
  };

  addUser = () => {
    const {
      homeDepartment,
      homeCity,
      email,
      documentType,
      documentNumber,
      firstName,
      secondName,
      lastName,
      secondLastName,
      cellular,
      branch,
      role,
      action,
      newPassword,
    } = this.state;
    let newUser = {
      documentType,
      documentNumber,
      homeDepartment,
      homeCity,
      cellular,
      email,
      firstName,
      secondName,
      lastName,
      secondLastName,
      branch,
      role,
      status: 'A',
      action,
      password: newPassword,
    };
    this.setState({
      documentType: '',
      documentNumber: '',
      homeDepartment: '',
      homeCity: '',
      cellular: '',
      email: '',
      emailValid: true,
      firstName: '',
      secondName: '',
      lastName: '',
      secondLastName: '',
      branch: '',
      role: '',
      newPassword: '',
      newPasswordRepeat: '',
    });
    this.props.addUser(newUser);
  };

  isPasswordFormat = (password) => {
    let isFormated = true;

    if (password.length < 8) {
      return false;
    }

    if (!/[a-z]/.test(password)) {
      return false;
    }

    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/[0-9]/.test(password)) {
      return false;
    }

    return isFormated;
  };

  render() {
    const {
      homeDepartment,
      homeCity,
      email,
      emailValid,
      documentType,
      documentNumber,
      firstName,
      secondName,
      lastName,
      secondLastName,
      cellular,
      branch,
      role,
      action,
      newPassword,
      newPasswordRepeat,
    } = this.state;
    const { globals, cities, roleList, show, onHide } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let departments =
      globals && globals.DEPARTAMENTOS ? [...globals.DEPARTAMENTOS] : [];
    departments = [{ LABEL: 'NACIONAL', ID: '1111' }].concat(departments);
    let citiesArray = cities ? cities : [];
    if (homeDepartment === '1111') {
      citiesArray = [{ LABEL: 'NACIONAL', ID: '1111' }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div className='confirm-reserve-title'>
            {action === 'CREAR' && 'Agregar usuario'}
            {action !== 'CREAR' && 'Actualizar datos'}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='confirm-reserve-body'>
            <Col xs={12} className='confirm-reserve_body-description'>
              <Row>
                {action === 'CREAR' && (
                  <Col xs={12} className='modal-form-title'>
                    Complete los campos a continuación
                  </Col>
                )}
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='firstName'
                    label='Primer Nombre'
                    className='home-input'
                    margin='dense'
                    value={firstName}
                    onChange={(event) => {
                      this.oninputChange(event, 'firstName');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='secondName'
                    label='Segundo Nombre'
                    className='home-input'
                    margin='dense'
                    value={secondName}
                    onChange={(event) => {
                      this.oninputChange(event, 'secondName');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='lastName'
                    label='Primer Apellido'
                    className='home-input'
                    margin='dense'
                    value={lastName}
                    onChange={(event) => {
                      this.oninputChange(event, 'lastName');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='secondLastName'
                    label='Segundo Apellido'
                    className='home-input'
                    margin='dense'
                    value={secondLastName}
                    onChange={(event) => {
                      this.oninputChange(event, 'secondLastName');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='documentType'
                    select
                    label='Tipo de documento'
                    className='home-input'
                    value={documentType}
                    onChange={(event) => {
                      this.oninputChange(event, 'documentType');
                    }}
                    margin='dense'
                    fullWidth
                  >
                    {documents.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ID}>
                          {item.LABEL}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='documentNumber'
                    label='Número de documento'
                    className='home-input'
                    margin='dense'
                    type='number'
                    value={documentNumber}
                    onChange={(event) => {
                      this.oninputChange(event, 'documentNumber');
                    }}
                    fullWidth
                  />
                </Col>
                {action === 'ACTUALIZAR' && (
                  <Col xs={12} md={6} className='login-input-container'>
                    <TextField
                      id='newPassword'
                      label='Nueva contraseña'
                      className='home-input'
                      margin='dense'
                      autoComplete='newPassword'
                      type='password'
                      readOnly
                      value={newPassword}
                      onChange={(event) => {
                        this.oninputChange(event, 'newPassword');
                      }}
                      error={
                        !!newPassword && !this.isPasswordFormat(newPassword)
                      }
                      helperText={
                        !!newPassword && !this.isPasswordFormat(newPassword)
                          ? 'La contraseña debe ser minimo de 8 caracteres y tener al menos una letra minuscula, una mayuscula y un numero'
                          : null
                      }
                      fullWidth
                    />
                  </Col>
                )}
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='email'
                    label='Correo electrónico'
                    className='home-input'
                    margin='dense'
                    value={email}
                    onChange={(event) => {
                      this.isEmail(event, 'email');
                    }}
                    error={email && !emailValid ? true : false}
                    helperText={
                      email && !emailValid
                        ? 'Escriba un formato de email válido'
                        : null
                    }
                    fullWidth
                  />
                </Col>
                {action === 'ACTUALIZAR' && (
                  <Col xs={12} md={6} className='login-input-container'>
                    <TextField
                      id='newPasswordRepeat'
                      label='Confirmar nueva contraseña'
                      className='home-input'
                      margin='dense'
                      autoComplete='newPasswordRepeat'
                      type='password'
                      value={newPasswordRepeat}
                      onChange={(event) => {
                        this.oninputChange(event, 'newPasswordRepeat');
                      }}
                      error={
                        (!!newPasswordRepeat &&
                          !this.isPasswordFormat(newPasswordRepeat)) ||
                        (!!newPasswordRepeat &&
                          !!newPassword &&
                          newPassword !== newPasswordRepeat)
                      }
                      helperText={
                        !!newPasswordRepeat &&
                        !this.isPasswordFormat(newPasswordRepeat)
                          ? 'La contraseña debe ser minimo de 8 caracteres y tener al menos una letra minuscula, una mayuscula y un numero'
                          : newPasswordRepeat &&
                            newPassword &&
                            newPassword !== newPasswordRepeat
                          ? 'Las contraseñas no coinciden'
                          : null
                      }
                      fullWidth
                    />
                  </Col>
                )}
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='cellular'
                    label='Celular'
                    className='home-input'
                    margin='dense'
                    type='number'
                    value={cellular}
                    onChange={(event) => {
                      this.oninputChange(event, 'cellular');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='homeDepartment'
                    select
                    label='Departamento'
                    className='home-input'
                    margin='dense'
                    value={homeDepartment}
                    onChange={(event) => {
                      this.oninputChange(event, 'homeDepartment');
                    }}
                    fullWidth
                  >
                    {departments.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ID}>
                          {item.LABEL}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='homeCity'
                    select
                    label='Ciudad'
                    className='home-input'
                    margin='dense'
                    value={homeCity}
                    onChange={(event) => {
                      this.oninputChange(event, 'homeCity');
                    }}
                    fullWidth
                  >
                    {citiesArray.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ID}>
                          {item.LABEL}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='branch'
                    label='Sucursal'
                    className='home-input'
                    margin='dense'
                    value={branch}
                    onChange={(event) => {
                      this.oninputChange(event, 'branch');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={6} className='login-input-container'>
                  <TextField
                    id='assignRole'
                    select
                    label='Asignar rol'
                    className='home-input'
                    margin='dense'
                    value={role}
                    onChange={(event) => {
                      this.oninputChange(event, 'role');
                    }}
                    fullWidth
                  >
                    {roleList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.IDROL}>
                          {item.DESCROL}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={{ span: 6, offset: 3 }}
              className='modal-form-buttons'
            >
              <Row>
                <Col xs={6}>
                  <Button
                    className='button-send'
                    variant='success'
                    disabled={
                      documentType === '' ||
                      documentNumber === '' ||
                      firstName === '' ||
                      lastName === '' ||
                      role === ''
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.addUser();
                    }}
                    block
                  >
                    Guardar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className='button-primary'
                    variant='primary'
                    onClick={(e) => {
                      e.preventDefault();
                      this.cancel();
                    }}
                    block
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

AddUser.propTypes = {
  addUser: PropTypes.func.isRequired,
  newUser: PropTypes.object,
};

AddUser.defaultProps = {
  addUser: () => {},
  newUser: null,
};

const mapStateToProps = (state, props) => {
  let { user, globals, cities, loading, roleList } = state.user;
  return {
    user,
    globals,
    cities,
    loading,
    roleList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
