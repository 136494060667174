import config from '../../config';
import { authHeader } from '../../helpers';

export const getGlobals = () => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(`${config().apiUrl}/inicializar`, requestOptions).then(
    handleResponse
  );
};

export const getCities = ({ departmentId }) => {
  const requestOptions = {
    method: 'GET',
  };
  let finalParams = `id_departamento=${departmentId}`;
  return fetch(
    `${config().apiUrl}/municipios?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

// export const getCities = ({departmentId}) => {
//   let finalParams = {"ProjectId":"1018"
//     ,"ApplicationId":"13"
//     ,"ButtonId":"12205"
//     ,"Table":1
//     ,"Controls":[
//                     {"Id":"Id_Departamento","Valor":departmentId}
//                 ]
//     }
//
//   let headers = {
//     'Content-Type': 'application/json',
//     'authorization': 'Bearer fnV6CmIY5c9OAlj87Y7nV_lKS-lCqU1qcpWjTz12Dty45JmsPtcjVxhOw7DDSm-0NzYwNkSmcxt-dqHahVzDoNfv9hZdvi63S8vQbLFz9-_a1IicWI16DRYyn-jatb_QIJ9Bc9T8KsJ7vweTkxQZvNO1u3lMpcSTYkFn1-sKMFy2gxbXEM19SYLQx3DAXDVDmVfbzEv05v3BJHxhI7y6J8oXFHZ6e25pTQzoV0eOXHpys-4Yj_7eW042rhRRIMTFZX7Dw68M2kRy6ypSLrtJ2E0oHSxF_nYGnp2NHuuSm4h5LGIvHLyseTjxVO_YMkdYfzdXt6sLkdf9r3AUyRojWg'
//   }
//   const requestOptions = {
//     method: 'POST',
//     body: JSON.stringify(finalParams),
//     headers
//   };
//
//   return fetch('http://desarrollo.datascoring.co:9980/api/form/process', requestOptions).then(handleResponse);
// }

export const getFamilyList = () => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(`${config().apiUrl}/listado_parentesco`, requestOptions).then(
    handleResponse
  );
};

export const getCareerList = () => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(`${config().apiUrl}/listado_carreras`, requestOptions).then(
    handleResponse
  );
};

export const isUser = ({ userEmail, password }) => {
  const requestOptions = {
    method: 'GET',
  };
  let finalParams = `usuario=${userEmail}&contrasena=${password}`;
  return fetch(`${config().apiUrl}/acceso?${finalParams}`, requestOptions).then(
    handleResponseLogin
  );
};

export const getMyPayments = ({ documentType, documentNumber }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}`;
  return fetch(
    `${config().apiUrl}/pr_mispagos?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getMyPaymentInvoice = ({
  suffix,
  consecutive,
  documentType,
  documentNumber,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let urlParam =
    'UrlEnpoint=https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos';
  let secundaryParams = `&strServicio=consultarArchivos&tipo_documento=${documentType}&documento=${documentNumber}&id_pais=1`;
  let bodyParams = `&xmlParametros=<!--Optional:--><consecutivo>${consecutive}</consecutivo><!--Optional:--><contrasenia>PwC1v8n9cS0S</contrasenia><!--Optional:--><idEmpresa>1084</idEmpresa><!--Optional:--><prefijo>${suffix}</prefijo><tipoArchivo>1</tipoArchivo><!--Optional:--><tipoDocumento>1</tipoDocumento><!--Optional:--><token>45b3616496c44518e8c23c008b87ceab37e5a5c0</token><!--Optional:--><usuario>UCovinocSAS</usuario><!--Optional:--><version>10</version>`;
  let fullParams = `${urlParam}${secundaryParams}${bodyParams}`;
  return fetch(
    `${config().apiUrl}/pr_NetsuiteFactura?${fullParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getRoleList = ({ documentType, documentNumber }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}`;
  return fetch(
    `${config().apiUrl}/pr_rol_usuario?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getPaymentDetail = ({ documentType, documentNumber, invoice }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&factura=${invoice}`;
  return fetch(
    `${config().apiUrl}/pr_exportar_mispagos_detalle_factura?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getPaymentLink = ({
  documentType,
  documentNumber,
  invoice,
  expirationDate,
  totalAmount,
  totalTax,
  productCode,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'PUT',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&factura=${invoice}&fecha_vencimiento=${expirationDate}&total_con_iva=${totalAmount}&valor_iva=${totalTax}&codigo_producto=${productCode}`;
  return fetch(
    `${config().apiUrl}/pr_generar_pago?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getPayzenPaymentLink = (payment) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  const documentNumberPayzenUrl = `&vads_cust_id=${payment.DOCUMENTO}`;
  const invoiceNumber2 = `&vads_order_info2=${payment.NUMERO_FACTURA}`;
  const invoiceNumber3 = `&vads_order_info3=${payment.FACTURA_INTERNA}`;
  const email = `&vads_cust_email=${payment.CORRREO}`;
  const documentNumberUrl = `&documento=${payment.DOCUMENTO}`;
  const documentTypeUrl = `&tipo_documento=${payment.TIPO_DOCUMENTO}`;
  const userName = `&vads_cust_first_name=${payment.AFILIADO}`;
  const usarLastName = `&vads_cust_last_name=${payment.APELLIDO}`;
  const siteName = `&vads_shop_name=${payment.APLICACION}`;
  const siteUrl = `&vads_shop_url=${payment.URL_ORIGEN}`;
  const amountUrl = `&vads_amount=${payment.VALOR}`;
  const referenceUrl = `&vads_order_id=${payment.NETSUITE}`;
  const returnUrlQuery = `&vads_url_return=${payment.URL_RETORNO}`;
  const finalParams = `${email}${invoiceNumber3}${invoiceNumber2}${documentNumberUrl}${documentTypeUrl}${userName}${usarLastName}${siteName}${siteUrl}${amountUrl}${referenceUrl}${returnUrlQuery}${documentNumberPayzenUrl}`;
  return fetch(
    `${
      config().apiUrlPayzen
    }/retornar_datos_pago_payzen_prod?id_pais=1${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getMyStatistics = ({ documentType, documentNumber }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}`;
  return fetch(
    `${config().apiUrl}/pr_esferas?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getRolPermissions = ({ documentType, documentNumber, idRol }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&idrol=${idRol}`;
  return fetch(
    `${config().apiUrl}/pr_rol_opciones?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const recoverPassword = ({ userEmail }) => {
  const requestOptions = {
    method: 'GET',
  };
  let finalParams = `usuario=${userEmail}`;
  return fetch(
    `${config().apiUrl}/recuperar_contrasena?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const rememberPassword = ({ userEmail, rememberType }) => {
  const requestOptions = {
    method: 'GET',
  };
  let finalParams = `usuario=${userEmail}&pagina=cambiar-contrasena&opcion=${rememberType}`;
  return fetch(
    `${config().apiUrl}/envio_olvide_contrasena?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const changePassword = ({ password, passwordRepeat, code }) => {
  const requestOptions = {
    method: 'PUT',
  };
  let finalParams = `confirmarcontrasena=${passwordRepeat}&nuevacontrasena=${password}&c=${code}`;
  return fetch(
    `${config().apiUrl}/olvide_contrasena?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const changeUserPassword = ({
  userEmail,
  documentType,
  documentNumber,
  password,
  passwordRepeat,
  currentPassword,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'PUT',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&usuario=${userEmail}&tipo_documento=${documentType}&documento=${documentNumber}&actualcontrasena=${currentPassword}&confirmarcontrasena=${passwordRepeat}&nuevacontrasena=${password}`;
  return fetch(
    `${config().apiUrl}/pr_cambio_contrasena?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const manageUser = ({
  userEmail,
  documentType,
  documentNumber,
  sellerEmail,
  firstName,
  secondName,
  lastName,
  secondLastName,
  role,
  action,
  sellerDocumentType,
  sellerDocumentNumber,
  sellerCellular,
  city,
  branch,
  status,
  password,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let userParams = `id_pais=${idPais}&usuario=${userEmail}&tipo_documento=${documentType}&documento=${documentNumber}`;
  let firstSellerParams = `${
    sellerEmail ? '&correo=' + sellerEmail : ''
  }&nombre_1=${firstName}&nuevacontrasena=${
    password ? password : ''
  }&apellido_1=${lastName}${
    secondLastName ? '&apellido_2=' + secondLastName : ''
  }&idrol=${role}&administrar=${action}`;
  let secondSellerParams = `&vendedor_tipo_documento=${sellerDocumentType}&vendedor_documento=${sellerDocumentNumber}${
    sellerCellular ? '&celular=' + sellerCellular : '&celular=0'
  }${city ? '&lista_municipio=' + city : ''}${
    branch ? '&sucursal=' + branch : ''
  }&estado=${status}`;
  let fullParams = `${userParams}${firstSellerParams}${secondSellerParams}`;
  return fetch(
    `${config().apiUrl}/pr_administrar_usuario?${fullParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const setBirthDate = ({
  userEmail,
  documentType,
  documentNumber,
  birthDate,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let fullParams = `id_pais=${idPais}&usuario=${userEmail}&tipo_documento=${documentType}&documento=${documentNumber}&fecha_usuario=${birthDate}`;
  return fetch(
    `${config().apiUrl}/pr_usuario_cumpleanos?${fullParams}`,
    requestOptions
  ).then(handleResponse);
};

export const sendFile = ({
  documentType,
  documentNumber,
  fileType,
  sellerDocumentType,
  sellerDocumentNumber,
  file,
  quotaNumber,
}) => {
  const headers = authHeader();
  const data = new FormData();
  data.append('file', file);
  const requestOptions = {
    method: 'POST',
    headers,
    body: data,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let quotaNumberParam = quotaNumber ? `&estudio_cupo=${quotaNumber}` : '';
  let fullParams = `id_pais=${idPais}&usuario_tipo_documento=${sellerDocumentType}&tipo_documento=${documentType}&documento=${documentNumber}&usuario_documento=${sellerDocumentNumber}&tipo=${fileType}${quotaNumberParam}`;
  return fetch(
    `${config().apiUrl}/pr_subir_archivos?${fullParams}`,
    requestOptions
  ).then(handleResponse);
};

export const sendPublicFile = ({
  fileType,
  sellerDocumentType,
  sellerDocumentNumber,
  file,
  tokenCode,
  quotaNumber,
}) => {
  const data = new FormData();
  data.append('file', file);
  const requestOptions = {
    method: 'POST',
    body: data,
  };
  let quotaNumberParam = quotaNumber ? `&estudio_cupo=${quotaNumber}` : '';
  let fullParams = `usuario_tipo_documento=${sellerDocumentType}&usuario_documento=${sellerDocumentNumber}&tipo=${fileType}&c=${tokenCode}${quotaNumberParam}`;
  return fetch(
    `${config().apiUrl}/subir_archivos?${fullParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getManagedUsers = ({
  documentType,
  documentNumber,
  userDocumentType,
  userDocumentNumber,
  page,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&usuario_tipo_documento=${userDocumentType}&usuario_documento=${userDocumentNumber}&pagina=${page}&registros=5`;
  return fetch(
    `${config().apiUrl}/pr_usuarios?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getClientTypes = ({ documentType, documentNumber }) => {
  const requestOptions = {
    method: 'GET',
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}`;
  return fetch(
    `${config().apiUrl}/listado_producto?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const requestClientStudy = ({
  userEmail,
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
  refType2,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let params1 = `usuario=${userEmail}&id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&comprador_tipo_documento=${sellerDocumentType}&comprador_documento=${sellerDocumentNumber}`;
  let params2 = `${applicantName ? '&nombre_empresa=' + applicantName : ''}${
    city ? '&lista_municipio=' + city : ''
  }${address ? '&direccion=' + address : ''}${
    phone ? '&telefono=' + phone : ''
  }${quota ? '&valor_cupo=' + quota : ''}`;
  let params3 = `${
    expeditionDate ? '&fecha_expedicion=' + expeditionDate : ''
  }${expeditionCity ? '&lista_municipio_expedicion=' + expeditionCity : ''}${
    cellular ? '&celular=' + cellular : ''
  }${email ? '&correo=' + email : ''}${
    birthDate ? '&fecha_nacimiento=' + birthDate : ''
  }`;
  let params4 = `${
    economicActivity ? '&act_economica=' + economicActivity : ''
  }${
    representativeDocumentType
      ? '&tipo_doc_rep=' + representativeDocumentType
      : ''
  }${
    representativeDocumentNumber
      ? '&doc_rep=' + representativeDocumentNumber
      : ''
  }${representativeName ? '&nombres_rep=' + representativeName : ''}${
    constactFirstName ? '&nom1_contacto=' + constactFirstName : ''
  }`;
  let params5 = `${
    constactPosition ? '&cargo_contacto=' + constactPosition : ''
  }${constactCellular ? '&cel_contacto=' + constactCellular : ''}${
    constactEmail ? '&correo_contacto=' + constactEmail : ''
  }${partnersArray ? '&StrJArray=' + partnersArray : ''}${
    creationDate ? '&inicio_actividad=' + creationDate : ''
  }&fecha_creacion=19900101`;
  let params6 = `${
    representativeFistLastName ? '&apel1_rep=' + representativeFistLastName : ''
  }${
    representativeSecondLastName
      ? '&apel2_rep=' + representativeSecondLastName
      : ''
  }&nom2_contacto=${constactSecondName}${
    constactFirstLastName ? '&apel1_contacto=' + constactFirstLastName : ''
  }${
    constactSecondLastName ? '&apel2_contacto=' + constactSecondLastName : ''
  }`;
  let params7 = `&punto_venta=${pointsOfSale}&canal_whatsapp=${modeWP}&canal_correo=${modeEmail}&canal_sms=${modeSMS}&canal_llamada=${modePhone}&tipo_empresa=${companyType}`;
  let params8 = `&tipo_ref_1=${refType}&nombre1_ref_1=${firstRefFirstName}&apellido1_ref_1=${firstRefFistLastName}&telefono_ref_1=${firstRefPhone}&correo_ref_1=${firstRefEmail}&nombre2_ref_1=${firstRefSecondName}&apellido2_ref_1=${firstRefSecondLastName}&lista_cuidad_ref_1=${firstRefCity}`;
  let params9 = `&nombre1_ref_2=${secondRefFirstName}&apellido1_ref_2=${secondRefFistLastName}&telefono_ref_2=${secondRefPhone}&correo_ref_2=${secondRefEmail}&nombre2_ref_2=${secondRefSecondName}&apellido2_ref_2=${secondRefSecondLastName}&lista_cuidad_ref_2=${secondRefCity}`;
  let param10 = `${secondRefCity ? '&tipo_ref_2=' + refType2 : ''}`;
  let finalParams = `${params1}${params2}${params3}${params4}${params5}${params6}${params7}${params8}${params9}${param10}`;
  return fetch(
    `${config().apiUrl}/pr_solicitud_cupo?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const requestThreePaymentsClientStudy = ({
  queryCode,
  userEmail,
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
  refType2,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let params1 = `tipo_transaccion=1&codigo_consulta=${queryCode}&usuario=${userEmail}&id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&comprador_tipo_documento=${sellerDocumentType}&comprador_documento=${sellerDocumentNumber}`;
  let params2 = `${applicantName ? '&nombre_empresa=' + applicantName : ''}${
    city ? '&lista_municipio=' + city : ''
  }${address ? '&direccion=' + address : ''}${
    phone ? '&telefono=' + phone : ''
  }${quota ? '&valor_cupo=' + quota : ''}`;
  let params3 = `${
    expeditionDate ? '&fecha_expedicion=' + expeditionDate : ''
  }${expeditionCity ? '&lista_municipio_expedicion=' + expeditionCity : ''}${
    cellular ? '&celular=' + cellular : ''
  }${email ? '&correo=' + email : ''}${
    birthDate ? '&fecha_nacimiento=' + birthDate : ''
  }`;
  let params4 = `${
    economicActivity ? '&act_economica=' + economicActivity : ''
  }${
    representativeDocumentType
      ? '&tipo_doc_rep=' + representativeDocumentType
      : ''
  }${
    representativeDocumentNumber
      ? '&doc_rep=' + representativeDocumentNumber
      : ''
  }${representativeName ? '&nombres_rep=' + representativeName : ''}${
    constactFirstName ? '&nom1_contacto=' + constactFirstName : ''
  }`;
  let params5 = `${
    constactPosition ? '&cargo_contacto=' + constactPosition : ''
  }${constactCellular ? '&cel_contacto=' + constactCellular : ''}${
    constactEmail ? '&correo_contacto=' + constactEmail : ''
  }${partnersArray ? '&StrJArray=' + partnersArray : ''}${
    creationDate ? '&inicio_actividad=' + creationDate : ''
  }&fecha_creacion=19900101`;
  let params6 = `${
    representativeFistLastName ? '&apel1_rep=' + representativeFistLastName : ''
  }${
    representativeSecondLastName
      ? '&apel2_rep=' + representativeSecondLastName
      : ''
  }&nom2_contacto=${constactSecondName}${
    constactFirstLastName ? '&apel1_contacto=' + constactFirstLastName : ''
  }${
    constactSecondLastName ? '&apel2_contacto=' + constactSecondLastName : ''
  }`;
  let params7 = `&punto_venta=${pointsOfSale}&canal_whatsapp=${modeWP}&canal_correo=${modeEmail}&canal_sms=${modeSMS}&canal_llamada=${modePhone}&tipo_empresa=${companyType}`;
  let params8 = `&tipo_ref_1=${refType}&nombre1_ref_1=${firstRefFirstName}&apellido1_ref_1=${firstRefFistLastName}&telefono_ref_1=${firstRefPhone}&correo_ref_1=${firstRefEmail}&nombre2_ref_1=${firstRefSecondName}&apellido2_ref_1=${firstRefSecondLastName}&lista_cuidad_ref_1=${firstRefCity}`;
  let params9 = `&nombre1_ref_2=${secondRefFirstName}&apellido1_ref_2=${secondRefFistLastName}&telefono_ref_2=${secondRefPhone}&correo_ref_2=${secondRefEmail}&nombre2_ref_2=${secondRefSecondName}&apellido2_ref_2=${secondRefSecondLastName}&lista_cuidad_ref_2=${secondRefCity}`;
  let param10 = `${secondRefCity ? '&tipo_ref_2=' + refType2 : ''}`;
  let finalParams = `${params1}${params2}${params3}${params4}${params5}${params6}${params7}${params8}${params9}${param10}`;
  return fetch(
    `${config().apiUrl}/pr_solicitud_trespagos?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const requestPublicClientStudy = ({
  sellerDocumentType,
  sellerDocumentNumber,
  tokenCode,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let params1 = `comprador_tipo_documento=${sellerDocumentType}&comprador_documento=${sellerDocumentNumber}&c=${tokenCode}`;

  let params2 = `${applicantName ? '&nombre_empresa=' + applicantName : ''}${
    city ? '&lista_municipio=' + city : ''
  }${address ? '&direccion=' + address : ''}${
    phone ? '&telefono=' + phone : ''
  }${quota ? '&valor_cupo=' + quota : ''}`;
  let params3 = `${
    expeditionDate ? '&fecha_expedicion=' + expeditionDate : ''
  }${expeditionCity ? '&lista_municipio_expedicion=' + expeditionCity : ''}${
    cellular ? '&celular=' + cellular : ''
  }${email ? '&correo=' + email : ''}${
    birthDate ? '&fecha_nacimiento=' + birthDate : ''
  }`;
  let params4 = `${
    economicActivity ? '&act_economica=' + economicActivity : ''
  }${
    representativeDocumentType
      ? '&tipo_doc_rep=' + representativeDocumentType
      : ''
  }${
    representativeDocumentNumber
      ? '&doc_rep=' + representativeDocumentNumber
      : ''
  }${representativeName ? '&nombres_rep=' + representativeName : ''}${
    constactFirstName ? '&nom1_contacto=' + constactFirstName : ''
  }`;
  let params5 = `${
    constactPosition ? '&cargo_contacto=' + constactPosition : ''
  }${constactCellular ? '&cel_contacto=' + constactCellular : ''}${
    constactEmail ? '&correo_contacto=' + constactEmail : ''
  }${partnersArray ? '&StrJArray=' + partnersArray : ''}${
    creationDate ? '&inicio_actividad=' + creationDate : ''
  }&fecha_creacion=19900101`;
  let params6 = `${
    representativeFistLastName ? '&apel1_rep=' + representativeFistLastName : ''
  }${
    representativeSecondLastName
      ? '&apel2_rep=' + representativeSecondLastName
      : ''
  }&nom2_contacto=${constactSecondName}${
    constactFirstLastName ? '&apel1_contacto=' + constactFirstLastName : ''
  }${
    constactSecondLastName ? '&apel2_contacto=' + constactSecondLastName : ''
  }`;
  let params7 = `&punto_venta=${pointsOfSale}&canal_whatsapp=${modeWP}&canal_correo=${modeEmail}&canal_sms=${modeSMS}&canal_llamada=${modePhone}&tipo_empresa=${companyType}`;
  let params8 = `&tipo_ref_1=${refType}&nombre1_ref_1=${firstRefFirstName}&apellido1_ref_1=${firstRefFistLastName}&telefono_ref_1=${firstRefPhone}&correo_ref_1=${firstRefEmail}&nombre2_ref_1=${firstRefSecondName}&apellido2_ref_1=${firstRefSecondLastName}&lista_cuidad_ref_1=${firstRefCity}`;
  let params9 = `&nombre1_ref_2=${secondRefFirstName}&apellido1_ref_2=${secondRefFistLastName}&telefono_ref_2=${secondRefPhone}&correo_ref_2=${secondRefEmail}&nombre2_ref_2=${secondRefSecondName}&apellido2_ref_2=${secondRefSecondLastName}&lista_cuidad_ref_2=${secondRefCity}`;
  let param10 = `${secondRefCity ? '&tipo_ref_2=COMERCIAL' : ''}`;
  let finalParams = `${params1}${params2}${params3}${params4}${params5}${params6}${params7}${params8}${params9}${param10}`;
  return fetch(
    `${config().apiUrl}/estudio_cliente?${finalParams}`,
    requestOptions
  ).then(handleResponseLogin);
};

export const requestPublicClientStudy_last = ({
  sellerDocumentType,
  sellerDocumentNumber,
  tokenCode,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
}) => {
  const requestOptions = {
    method: 'POST',
  };
  let params1 = `comprador_tipo_documento=${sellerDocumentType}&comprador_documento=${sellerDocumentNumber}`;
  let params2 = `${applicantName ? '&nombre_empresa=' + applicantName : ''}${
    city ? '&lista_municipio=' + city : ''
  }${address ? '&direccion=' + address : ''}${
    phone ? '&telefono=' + phone : ''
  }${quota ? '&valor_cupo=' + quota : ''}`;
  let params3 = `${
    expeditionDate ? '&fecha_expedicion=' + expeditionDate : ''
  }${expeditionCity ? '&lista_municipio_expedicion=' + expeditionCity : ''}${
    cellular ? '&celular=' + cellular : ''
  }${email ? '&correo=' + email : ''}${
    birthDate ? '&fecha_nacimiento=' + birthDate : ''
  }`;
  let params4 = `${
    economicActivity ? '&act_economica=' + economicActivity : ''
  }${
    representativeDocumentType
      ? '&tipo_doc_rep=' + representativeDocumentType
      : ''
  }${
    representativeDocumentNumber
      ? '&doc_rep=' + representativeDocumentNumber
      : ''
  }${representativeName ? '&nombres_rep=' + representativeName : ''}${
    constactFirstName ? '&nom1_contacto=' + constactFirstName : ''
  }`;
  let params5 = `${
    constactPosition ? '&cargo_contacto=' + constactPosition : ''
  }${constactCellular ? '&cel_contacto=' + constactCellular : ''}${
    constactEmail ? '&correo_contacto=' + constactEmail : ''
  }${partnersArray ? '&StrJArray=' + partnersArray : ''}${
    creationDate ? '&inicio_actividad=' + creationDate : ''
  }&fecha_creacion=19900101`;
  let params6 = `${
    representativeFistLastName ? '&apel1_rep=' + representativeFistLastName : ''
  }${
    representativeSecondLastName
      ? '&apel2_rep=' + representativeSecondLastName
      : ''
  }&nom2_contacto=${constactSecondName}${
    constactFirstLastName ? '&apel1_contacto=' + constactFirstLastName : ''
  }${
    constactSecondLastName ? '&apel2_contacto=' + constactSecondLastName : ''
  }&c=${tokenCode}`;
  let finalParams = `${params1}${params2}${params3}${params4}${params5}${params6}`;
  return fetch(
    `${config().apiUrl}/estudio_cliente?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const validateForm = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userEmail,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&usuario=${userEmail}&pagina=1&registros=100`;
  return fetch(
    `${config().apiUrl}/pr_validar_solicitud?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const saveForm = ({
  userEmail,
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeFisrtName,
  representativeSecondName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
  refType2,
  filingNumber,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let params1 = `usuario=${userEmail}&id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&comprador_tipo_documento=${sellerDocumentType}&comprador_documento=${sellerDocumentNumber}&radicado=${filingNumber}`;
  let params2 = `${applicantName ? '&nombre_empresa=' + applicantName : ''}${
    city ? '&lista_municipio=' + city : ''
  }${address ? '&direccion=' + address : ''}${
    phone ? '&telefono=' + phone : ''
  }${quota ? '&valor_cupo=' + quota : ''}`;
  let params3 = `${
    expeditionDate ? '&fecha_expedicion=' + expeditionDate : ''
  }${expeditionCity ? '&lista_municipio_expedicion=' + expeditionCity : ''}${
    cellular ? '&celular=' + cellular : ''
  }${email ? '&correo=' + email : ''}${
    birthDate ? '&fecha_nacimiento=' + birthDate : ''
  }`;
  let params4 = `${
    economicActivity ? '&act_economica=' + economicActivity : ''
  }${
    representativeDocumentType
      ? '&tipo_doc_rep=' + representativeDocumentType
      : ''
  }${
    representativeDocumentNumber
      ? '&doc_rep=' + representativeDocumentNumber
      : ''
  }${representativeFisrtName ? '&nom1_rep=' + representativeFisrtName : ''}${
    representativeSecondName ? '&nom2_rep=' + representativeSecondName : ''
  }${constactFirstName ? '&nom1_contacto=' + constactFirstName : ''}`;
  let params5 = `${
    constactPosition ? '&cargo_contacto=' + constactPosition : ''
  }${constactCellular ? '&cel_contacto=' + constactCellular : ''}${
    constactEmail ? '&correo_contacto=' + constactEmail : ''
  }${partnersArray ? '&StrJArray=' + partnersArray : ''}${
    creationDate ? '&inicio_actividad=' + creationDate : ''
  }&fecha_creacion=19900101`;
  let params6 = `${
    representativeFistLastName ? '&apel1_rep=' + representativeFistLastName : ''
  }${
    representativeSecondLastName
      ? '&apel2_rep=' + representativeSecondLastName
      : ''
  }&nom2_contacto=${constactSecondName}${
    constactFirstLastName ? '&apel1_contacto=' + constactFirstLastName : ''
  }${
    constactSecondLastName ? '&apel2_contacto=' + constactSecondLastName : ''
  }`;
  let params7 = `&punto_venta=${pointsOfSale}&canal_whatsapp=${modeWP}&canal_correo=${modeEmail}&canal_sms=${modeSMS}&canal_llamada=${modePhone}&tipo_empresa=${companyType}`;
  let params8 = `&tipo_ref_1=${refType}&nombre1_ref_1=${firstRefFirstName}&apellido1_ref_1=${firstRefFistLastName}&telefono_ref_1=${firstRefPhone}&correo_ref_1=${firstRefEmail}&nombre2_ref_1=${firstRefSecondName}&apellido2_ref_1=${firstRefSecondLastName}&lista_cuidad_ref_1=${firstRefCity}`;
  let params9 = `&nombre1_ref_2=${secondRefFirstName}&apellido1_ref_2=${secondRefFistLastName}&telefono_ref_2=${secondRefPhone}&correo_ref_2=${secondRefEmail}&nombre2_ref_2=${secondRefSecondName}&apellido2_ref_2=${secondRefSecondLastName}&lista_cuidad_ref_2=${secondRefCity}`;
  let param10 = `${secondRefCity ? '&tipo_ref_2=' + refType2 : ''}`;
  let finalParams = `${params1}${params2}${params3}${params4}${params5}${params6}${params7}${params8}${params9}${param10}`;
  return fetch(
    `${config().apiUrl}/pr_almacenar_solicitud?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const requestLinkClientStudy = ({
  userName,
  documentType,
  documentNumber,
  clientEmail,
  admissionDate,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let sendUrl = config().hostUrl + '/estudio-cliente';
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let params = `usuario=${userName}&id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&correo=${clientEmail}&pagina=${sendUrl}&fecha_ingreso=${admissionDate}`;
  return fetch(
    `${config().apiUrl}/pr_envio_estudio_cliente?${params}`,
    requestOptions
  ).then(handleResponse);
};

export const sendHash = ({
  userName,
  documentType,
  documentNumber,
  clientIp,
  admissionDate,
  quotaNumber,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let sendUrl = config().hostUrl + '/estudio-de-cliente';
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let params = `usuario=${userName}&id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&ip=${clientIp}&pagina=${sendUrl}&fecha_ingreso=${admissionDate}&id_estudio_cupo=${quotaNumber}`;
  return fetch(`${config().apiUrl}/pr_hash?${params}`, requestOptions).then(
    handleResponse
  );
};

export const sendPublicHash = ({ tokenCode, quotaNumber }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let sendUrl = config().hostUrl + '/estudio-cliente';
  let params = `pagina=${sendUrl}&id_estudio_cupo=${quotaNumber}&c=${tokenCode}`;
  return fetch(`${config().apiUrl}/hash?${params}`, requestOptions).then(
    handleResponse
  );
};

function handleResponseLogin(response) {
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      let errorMessage = false;
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponse(response) {
  if (
    response.headers.get('content-type').indexOf('application/ms-excel') !== -1
  ) {
    return response.blob().then((blob) => {
      return blob;
    });
  }
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false;
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
