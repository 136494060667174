import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../login/login.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';
import covifacturaLogo from '../../assets/logos/Logoblanco.svg';
import erroricon from '../../assets/icons/warning-icon.svg';
import TextField from '@material-ui/core/TextField';
import Footer from '../footer';
import Loading from '../loading';
import newlogo from '../../assets/logos/newlogo.svg';
import LoginBackground from '../login-background';
import FloatingFooter from '../floating-footer';
import Chat from '../chat';
import leftArrow from '../../assets/icons/left-arrow.svg';
import remembericon from '../../assets/icons/remembericon.svg';
import newEmailicon from '../../assets/icons/newEmailicon.svg';

class RememberPassword extends Component {
  constructor() {
    super();
    this.state = {
      emailUser: '',
      password: '',
      validEmail: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.tryAgain();
  }

  isValid() {
    const { username, password } = this.state;
    return !!(username && password);
  }

  isEmail = (event) => {
    let emailUser = event.target.value;
    // let validEmail = false;
    // if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
    //   validEmail = true;
    // }
    this.setState({
      emailUser,
    });
  };

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSubmit = () => {
    this.props.actions.rememberPassword({
      userEmail: this.state.emailUser,
      rememberType: 'RECUPERAR',
    });
  };

  tryAgain = () => {
    this.props.actions.cleanUser();
  };

  goLogin = () => {
    this.props.actions.cleanUser();
    this.props.history.push(`/login`);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userRemember) {
      // this.props.actions.setUser({userEmail: this.state.emailUser, password: this.state.password, token: nextProps.user.TOKEN, userInfo: nextProps.user});
      // this.props.history.push(`/`);
    }
  }

  render() {
    const { loading, errorUser, userRemember } = this.props;

    const { emailUser } = this.state;
    return (
      <div className='login'>
        {loading && <Loading />}
        <Col>
          <Row className='full-center login-container'>
            <LoginBackground />
            <div className='login-logo-tablet hide-only-mobile hide-desktop'>
              <img className='' src={newlogo} alt='covifactura logo' />
            </div>
            <div className='mobile-side login-card'>
              <Col className='' xs={12}>
                {!userRemember && (
                  <Row>
                    <Col xs={12} className='button-link-container'>
                      <Button
                        className='button-link-remember'
                        variant='link'
                        block
                        onClick={(e) => {
                          e.preventDefault();
                          this.goLogin();
                        }}
                      >
                        <img src={leftArrow} alt='covifactura logo' />
                        Volver al inicio de sesión
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Col xs={12} className='login_sub-title-container'>
                        <h3 className='login_sub-title'>
                          Recuperar contraseña
                        </h3>
                      </Col>
                      <Col xs={12} className='remember-container'>
                        <img
                          className=''
                          src={remembericon}
                          alt='covifactura logo'
                        />
                      </Col>
                      <Col
                        xs={{ span: 8, offset: 2 }}
                        className='login_error-login remember-margin'
                      >
                        Para recuperar su contraseña ingrese
                        <br /> su usuario y haga clic en continuar.
                      </Col>
                      <Col
                        xs={{ span: 8, offset: 2 }}
                        className='login-input-container'
                      >
                        <label for='emailUser'>Usuario</label>
                        <input
                          id='emailUser'
                          name='emailUser'
                          value={emailUser}
                          className='login-input'
                          onChange={this.isEmail}
                        />
                      </Col>
                      {errorUser && (
                        <Col xs={12} className='error-message remember-margin'>
                          El usuario {emailUser} no se encuentra en nuestra{' '}
                          <br />
                          base de datos. Verifique la información.
                        </Col>
                      )}
                      <Col
                        xs={{ span: 6, offset: 3 }}
                        className='button-container remember-margin'
                      >
                        <button
                          disabled={emailUser !== '' ? false : true}
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleSubmit();
                          }}
                          className='button button-primary secundary-color'
                        >
                          CONTINUAR
                        </button>
                      </Col>
                    </Col>
                  </Row>
                )}
                {/* {errorUser && (
                  <Row>
                    <Col xs={12}>
                      <Col
                        xs={12}
                        md={{ span: 8, offset: 2 }}
                        className='login_sub-title-container'
                      >
                        <h3 className='login_sub-title'>
                          Recuperar contraseña
                        </h3>
                      </Col>
                      <Col xs={12} className='login_error-logo-container'>
                        <img
                          className='login_error-logo'
                          src={erroricon}
                          alt='icono error'
                        />
                      </Col>
                      <Col
                        xs={{ span: 8, offset: 2 }}
                        className='login_error-login'
                      >
                        El usuario {emailUser} no se encuentra en nuestra base
                        de datos. Verifique la información.
                      </Col>
                      <Col
                        xs={{ span: 6, offset: 3 }}
                        className='button-container'
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.tryAgain();
                          }}
                          className='button button-primary secundary-color'
                        >
                          ACEPTAR
                        </button>
                      </Col>
                      <Col
                        xs={{ span: 8, offset: 2 }}
                        className='button-link-container'
                      >
                        <Button
                          className='button-link'
                          variant='link'
                          block
                          onClick={(e) => {
                            e.preventDefault();
                            this.goLogin();
                          }}
                        >
                          Volver al inicio
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                )} */}
                {userRemember && (
                  <Row>
                    <Col xs={12}>
                      <Col xs={12} className='login_sub-title-container'>
                        <h3 className='login_sub-title'>
                          Recuperar contraseña
                        </h3>
                      </Col>
                      <Col xs={12} className='remember-container'>
                        <img
                          className=''
                          src={newEmailicon}
                          alt='covifactura logo'
                        />
                      </Col>
                      <Col
                        xs={{ span: 8, offset: 2 }}
                        className='login_error-login remember-margin'
                      >
                        Hemos enviado un correo electrónico
                        <br /> al <strong>administrador</strong> de su cuenta
                        para
                        <br />
                        realizar el <strong>cambio de la contraseña</strong>.
                      </Col>
                      <Col
                        xs={{ span: 6, offset: 3 }}
                        className='button-container'
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.goLogin();
                          }}
                          className='button button-primary secundary-color'
                        >
                          <img
                            className='back-icon'
                            src={leftArrow}
                            alt='covifactura logo'
                          />
                          Volver
                        </button>
                      </Col>
                    </Col>
                  </Row>
                )}
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />

        {/* <Footer isLogin /> */}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { loading, userRemember, errorUser } = state.user;

  console.log('state.user', state.user);

  return {
    loading,
    errorUser,
    userRemember,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RememberPassword);
