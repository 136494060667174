import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { Modal } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import infoImg from '../../../assets/images/infoImg.jpg';

class VideoModal extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      emailValid: true,
    };
  }

  render() {
    const { show, onHide } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          <div className='modal-close-button-container'>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                onHide();
              }}
              className='modal-close-button modal-close-button-out'
              aria-label='Cerrar'
            >
              <CloseIcon
                style={{
                  color: 'black',
                }}
              />
            </IconButton>
            <video width='100%' controls autoPlay>
              <source src='/tutorial.mp4' type='video/mp4' />
            </video>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

VideoModal.propTypes = {
  confirm: PropTypes.func.isRequired,
};

VideoModal.defaultProps = {
  confirm: () => {},
};

export default VideoModal;
