import * as reserveService from '../services';
import {
  SEND_RESERVE_REQUEST,
  SEND_RESERVE_SUCCESS,
  SEND_RESERVE_FAILURE,
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_FAILURE,
  RESET_RESERVE,
  GENERAL_ERROR,
} from '../action-types'

export const sendReserve = ({documentType, documentNumber, userDocument, userDocumentType, userEmail, reserveValue, queryCode}) => {
  return dispatch => {
    dispatch(request());
    reserveService.sendReserve({documentType, documentNumber, userDocument, userDocumentType, userEmail, reserveValue, queryCode})
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: SEND_RESERVE_REQUEST } }
  function success(data) { return { type: SEND_RESERVE_SUCCESS, data } }
  function failure(error) { return { type: SEND_RESERVE_FAILURE, error } }
}

export const getReservations = ({documentType = 'C', documentNumber, userDocument, userDocumentType, page}) => {
  return dispatch => {
    dispatch(request());
    reserveService.getReservations({documentType, documentNumber, userDocument, userDocumentType, page})
    .then(
      data => {
        dispatch(success(data, page));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_RESERVATIONS_REQUEST } }
  function success(data, page) { return { type: GET_RESERVATIONS_SUCCESS, data, page } }
  function failure(error) { return { type: GET_RESERVATIONS_FAILURE, error } }
}

export const resetReserve = () => {
  return dispatch => {
    dispatch(request());
  }

  function request() { return { type: RESET_RESERVE } }
}

export const generalError = (generalError) => { return { type: GENERAL_ERROR, generalError } }
