import config from '../../config';
import { authHeader } from '../../helpers';

export const getAdvanceAgreements = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  page,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&pagina=${page}&registros=10`;
  return fetch(
    `${config().apiUrl}/pr_garantias_acuerdo_anticipado?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const uploadAdvanceAgreement = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userName,
  invoiceCount,
  valueToPay,
  valueSelected,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&usuario=${userName}&cantidad_facturas=${invoiceCount}&valor_a_exonerar=${valueToPay}&valor_abonado=${valueSelected}`;
  return fetch(
    `${config().apiUrl}/pr_carga_masiva_acuerdo_anticipado?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

function handleResponse(response) {
  if (
    response.headers.get('content-type').indexOf('application/ms-excel') !== -1
  ) {
    return response.blob().then((blob) => {
      return blob;
    });
  }
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false;
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
