import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './reserve.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import aproved from '../../assets/icons/aproved.svg';
import denegate from '../../assets/icons/denegate.svg';
import {TextField, InputAdornment} from '@material-ui/core';
import ConfirmChange from './confirm-reserve';
import Loading from '../loading';
import moment from "moment";

class Reserve extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      confirmModal: false,
      reserveSucces: false,
      reserveError: false,
      reserveValue: '',
      reserveValueMask: '',
      approvalNumber: '',
      queryCode: ''
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  resetState = () => {
    this.setState({
      menuOpen: false,
      confirmModal: false,
      reserveSucces: false,
      reserveError: false,
      reserveValue: '',
      reserveValueMask: '',
      approvalNumber: '',
      queryCode: ''
    });
  }

  goHome = () => {
    const {
      reserveSucces,
      reserveError,
    } = this.state;
    this.resetState();
    if(reserveSucces || reserveError) {
      this.props.actions.resetQuota();
      this.props.history.push(`/`);
    } else {
      this.props.history.goBack();
    }
  }

  componentDidMount() {
    let params = this.props.match.params
    let documentType = params.documentType
    let documentNumber = params.documentNumber
    let queryCode = params.queryCode
    this.resetState();
    this.setState({ documentType, documentNumber, queryCode }, () => {
      this.props.actions.getUser();
      this.props.actions.getAppGlobals();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      if(this.state.documentNumber) {
        this.getQuota(nextProps.user);
      }
    }
    if (nextProps.reserveResponse && nextProps.reserveResponse !== this.props.reserveResponse) {
      if(nextProps.reserveResponse['Clave: {}'] === '0') {
        this.setState({ reserveError: true });
      } else {
        this.setState({
          reserveSucces: true,
          approvalNumber: nextProps.reserveResponse['Clave: {}'],
        });
      }
    }
  }

  getQuota = (user) => {
    const {
      documentType,
      documentNumber,
      queryCode
    } = this.state;
    this.props.actions.getQuota({
      documentType,
      documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      queryCode
    });
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  confirmChangeClose = () => {
    this.setState({
      confirmModal: false
    });
  }

  getDocumentTypeName = (documentType) => {
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item)=> {
      return item.ID === documentType;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  onReserveValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g,'');
    inputValue = inputValue.replace(/\./g,'');
    inputValue = inputValue.replace(/´/g, "");
    let reserveValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    this.setState({
      reserveValue,
      reserveValueMask: thousand,
    });
  }

  sendReserve = () => {
    this.setState({
      confirmModal: true
    });
  }

  confirmReserve = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    window.gtag('event', 'reserva_de_cupo', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      documentType,
      documentNumber,
      reserveValue,
      queryCode
    } = this.state;
    const {
      user,
    } = this.props;
    this.setState({
      confirmModal: false
    });
    this.props.actions.sendReserve({
      documentType,
      documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      reserveValue,
      queryCode
    });
  }

  render() {
    const {
      menuOpen,
      confirmModal,
      reserveValue,
      reserveValueMask,
      reserveSucces,
      reserveError,
      approvalNumber,
    } = this.state;
    const {
      loading,
      userQuota,
      loadingReserve
    } = this.props;

    let userQuotaInfo = (userQuota && userQuota.CUPO_COMPRADOR.length > 0) ? userQuota.CUPO_COMPRADOR[0] : false;
    let days = (userQuota ? userQuota.DIAS_RESERVA: 1);

    return (
      <div className="reserve">
        <ConfirmChange
          show={confirmModal}
          onHide={this.confirmChangeClose}
          name={userQuotaInfo.NOMBRES}
          reserveValue={reserveValueMask}
          productQuota={userQuota ? this.getNumber(userQuotaInfo.CUPO_DISPONIBLE) : ''}
          reserveDays={days + ''}
          confirmReserve={this.confirmReserve}
        />
      <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={3} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            { true && (
              <Col xs={12} className="general-main-card">
                { (loading || loadingReserve) && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  { (!reserveSucces && !reserveError) && (
                    <div
                      className="goback-action"
                      onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                      }}
                      >
                      <span>
                        <img src={arrowback} alt="arrow back" className="goback-icon" />
                      </span>
                      <span className="goback-text">Volver</span>
                    </div>
                  )}
                </div>
                <Col xs={12} className="reserve-margin">
                    <Col xs={12} className="card-title">
                      Reserva de cupo
                    </Col>
                    <Col xs={12} >
                      <hr />
                    </Col>
                </Col>
                { (!reserveError && !reserveSucces) && (
                  <Col xs={12}>
                    <Col xs={12}>
                      <Row>
                        { userQuota && (
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <Row>
                                  <Col xs={12} md={6}>
                                    <Row>
                                      <Col className="reserve-body_key">Nombre</Col>
                                      <Col xs="auto" className="reserve-body_value">{ userQuotaInfo.NOMBRES.toLowerCase() }</Col>
                                    </Row>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Row>
                                      <Col className="reserve-body_key">Tipo de documento</Col>
                                      <Col xs="auto" className="reserve-body_value">{this.getDocumentTypeName(userQuotaInfo.TIPO_DOCUMENTO).toLowerCase()}</Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12}>
                                <Row>
                                  <Col xs={12} md={6}>
                                    <Row>
                                      <Col className="reserve-body_key">Nº documento</Col>
                                      <Col xs="auto" className="reserve-body_value">{ userQuotaInfo.DOCUMENTO }</Col>
                                    </Row>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Row>
                                      <Col className="reserve-body_key">Cupo disponible</Col>
                                      <Col xs="auto" className="reserve-body_value">${this.getNumber(userQuotaInfo.CUPO_DISPONIBLE)}</Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col xs={12} className="reserve-input-form">
                          <Row>
                            <Col xs={12} md={6}>
                              <Row>
                                <Col xs={12} className="reserve-contract_days">{days} Días</Col>
                                <Col xs={12} className="reserve-contract_days-terms">La reserva de cupo tiene una vigencia de {days} días apartir de esta fecha <span className="reserve-contract_days-terms-bold">{moment(new Date()).format('DD/MM/YYYY')}</span></Col>
                              </Row>
                            </Col>
                            <Col xs={12} md={6}>
                              <TextField
                                id="reserveValue"
                                label="Valor cupo"
                                className="home-input"
                                margin="dense"
                                value={reserveValueMask}
                                onChange={this.onReserveValueChange}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                fullWidth
                                />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={{span: 6, offset: 3}}>
                          <Button
                            className="button-primary"
                            variant="primary"
                            disabled={reserveValue === ''}
                            onClick={(e) => {
                                e.preventDefault();
                                this.sendReserve();
                            }}
                            block
                          >
                            Reserva de cupo
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                )}
                { reserveSucces && (
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                        <img src={aproved} alt="icono reservas" className="resevation-icon"/>
                      </Col>
                      <Col xs={12} md={{span: 6, offset: 3}} className="reserve-aproved-title">
                        ¡RESERVA DE CUPO REALIZADA!
                      </Col>
                      <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                        Su código de reserva es <span className="not-reservation-text_bold">{approvalNumber}</span>. Muchas gracias por utilizar los servicios de <span className="not-reservation-text_bold">Covifactura</span>.
                      </Col>
                      <Col xs={12} md={{span: 4, offset: 4}} className="reserve-aproved-button-container">
                        <Button
                          className="button-send"
                          variant="success"
                          onClick={(e) => {
                            e.preventDefault();
                            this.goHome();
                          }}
                          block
                          >
                          Ir a inicio
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
                { reserveError && (
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                        <img src={denegate} alt="icono reservas" className="resevation-icon-denegate"/>
                      </Col>
                      <Col xs={12} md={{span: 6, offset: 3}} className="reserve-aproved-title">
                        ¡RESERVA DE CUPO NEGADA!
                      </Col>
                      <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                        Por favor comuniquese con nuestras líneas de atencion en <span className="not-reservation-text_bold">Bogotá 633 8878</span> o nuestra <span className="not-reservation-text_bold">línea nacional  01 8000 115080</span>
                      </Col>
                      <Col xs={12} md={{span: 4, offset: 4}} className="reserve-aproved-button-container">
                        <Button
                          className="button-send"
                          variant="success"
                          onClick={(e) => {
                            e.preventDefault();
                            this.goHome();
                          }}
                          block
                        >
                          Ir a inicio
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      user,
      globals,
  } = state.user;
  let {
      loading,
      userQuota,
  } = state.transaction;
  let {
      reserveResponse,
      loading: loadingReserve,
  } = state.reserve;

  return {
      loading,
      loadingReserve,
      reserveResponse,
      user,
      userQuota,
      globals,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Reserve);
