import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './login.scss';
import * as actions from '../../redux/actions';
import { Row, Col } from 'react-bootstrap';
import loginicon from '../../assets/icons/loginicon.svg';
import newlogo from '../../assets/logos/newlogo.svg';
import whitehelmet from '../../assets/icons/whitehelmet.svg';
import Loading from '../loading';
import FloatingFooter from '../floating-footer';
import Chat from '../chat';
import LoginBackground from '../login-background';
import { debounce } from 'debounce';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import config from '../../config';

class Login extends Component {
  constructor() {
    super();
    // 52763941
    this.state = {
      emailUser: '',
      password: '',
      validEmail: true,
      showPassword: false,
      videOpen: false,
      loginToken: '',
    };
    this.isValidEmail = debounce(this.isValidEmail, 1000);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.actions.getAppGlobals(true);
    this.props.actions.getFamilyList(true);
    this.props.actions.getCareerList(true);
  }

  isValid() {
    const { username, password } = this.state;
    return !!(username && password);
  }

  isValidEmail(emailUser) {
    if (!emailUser) {
      this.setState({
        validEmail: true,
      });
      return;
    }
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
      validEmail = true;
    }
    this.setState({
      validEmail,
    });
  }

  isEmail = (event) => {
    let emailUser = event.target.value.toUpperCase();
    // this.isValidEmail(emailUser);
    // let validEmail = false
    // if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
    //   validEmail = true;
    // }
    // if (validEmail) {
    //   this.setState({
    //     validEmail,
    //     emailUser
    //   });
    // } else {
    this.setState({
      emailUser,
    });
    // }
  };

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSubmit = () => {
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'login', {
      event_category: `${this.state.emailUser}:${idPais}`,
      event_label: this.state.emailUser,
    });
    this.props.actions.isUser({
      userEmail: this.state.emailUser,
      password: this.state.password,
    });
  };

  tryAgain = () => {
    this.props.actions.cleanUser();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.props.actions.setUser({
        userEmail: this.state.emailUser,
        token: nextProps.user.TOKEN,
        userInfo: nextProps.user,
      });
    }
    if (nextProps.setUserData !== this.props.setUserData) {
      this.props.actions.getRolPermissions({
        documentType: nextProps.user.TIPO_DOCUMENTO,
        documentNumber: nextProps.user.DOCUMENTO,
        idRol: nextProps.user.ROL_USUARIO,
        reload: true,
      });
    }
    if (nextProps.permissions !== this.props.permissions) {
      let userJson = JSON.parse(localStorage.getItem('user'));
      let globalsJson = JSON.parse(localStorage.getItem('globals'));
      let idPais =
        'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
          ? globalsJson.PAIS[0].ID_PAIS
          : '1';
      window.gtag('event', 'login_exitoso', {
        event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
        event_label: userJson.userEmail,
      });
      this.props.history.push(`/?info=true`);
      // this.props.history.push(`/`);
    }
  }

  goRemember = () => {
    this.props.history.push(`/recuperar-contrasena`);
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  changePage() {
    window.location.href = `${config().hostUrl}/compradores`;
  }

  render() {
    const { emailUser, password } = this.state;
    const { loading, errorUser, loadingCareerList, loadingFamilyList } =
      this.props;

    return (
      <div className='login'>
        <Col>
          <Row className='full-center login-container'>
            <LoginBackground />
            <div className='login-logo-tablet hide-only-mobile hide-desktop'>
              <img className='' src={newlogo} alt='covifactura logo' />
            </div>
            <div className='mobile-side login-card'>
              <Col>
                <Row>
                  {(loading || loadingCareerList || loadingFamilyList) && (
                    <Loading />
                  )}
                  <Col xs={12} className='login-tabs'>
                    <Row>
                      <Col
                        xs={12}
                        className='mobile-logo-container hide-ipad hide-desktop'
                      >
                        <img
                          className='mobile-logo'
                          src={newlogo}
                          alt='covifactura icon'
                        />
                      </Col>
                      <Col xs={6}>
                        <button className='button outline-button selected-button'>
                          Afiliados
                          <div className='button-house' />
                        </button>
                      </Col>
                      <Col xs={6}>
                        <button
                          className='button button-primary'
                          onClick={this.changePage}
                        >
                          Compradores
                          <img
                            className='button-helmet'
                            src={whitehelmet}
                            alt='helmet icon'
                          />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className='login-card_title'>
                    Iniciar sesión
                  </Col>
                  <Col xs={12} className='login-card_subtitle'>
                    Ingrese su usuario y contraseña.
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className='login-input-container'
                  >
                    <label for='emailUser'>Usuario</label>
                    <input
                      id='emailUser'
                      name='emailUser'
                      value={emailUser}
                      className='login-input'
                      onChange={this.isEmail}
                    />
                  </Col>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    className='login-input-container input-password'
                  >
                    <label for='password'>Contraseña</label>
                    <input
                      id='password'
                      name='password'
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={password}
                      className='login-input'
                      onChange={this.onPasswordChange}
                    />
                    <IconButton
                      className='login-icon'
                      aria-label='Toggle password visibility'
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </Col>
                  {errorUser && (
                    <Col xs={12} className='error-message'>
                      {errorUser.errorMessage &&
                      errorUser.errorMessage === 'Sin codigo de consulta activo'
                        ? 'USUARIO SUSPENDIDO'
                        : 'USUARIO O CONTRASEÑA INVALIDA'}
                    </Col>
                  )}
                  <Col xs={{ span: 6, offset: 3 }} className='button-container'>
                    <button
                      disabled={
                        emailUser !== '' && password !== '' ? false : true
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleSubmit();
                      }}
                      className='button button-primary secundary-color'
                    >
                      Ingresar
                      <img
                        className='button-login'
                        src={loginicon}
                        alt='login icon'
                      />
                    </button>
                  </Col>
                  <Col
                    xs={12}
                    className='login-terms'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goRemember();
                    }}
                  >
                    Olvidé mi contraseña
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </Col>
        <Chat home />
        <FloatingFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    globals,
    loading,
    user,
    errorUser,
    loadingCareerList,
    loadingFamilyList,
    permissions,
    setUserData,
  } = state.user;

  return {
    globals,
    loading,
    user,
    errorUser,
    loadingCareerList,
    loadingFamilyList,
    permissions,
    setUserData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
