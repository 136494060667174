import React, { Component } from "react";

import { Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import infoImg from "../../../assets/images/pop-up-covifactura.png";

class InfoModal extends Component {
  confirm = () => {
    this.props.confirm();
  };

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-close-button-container">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              onHide();
            }}
            className="modal-close-button modal-close-button-out"
            aria-label="Cerrar"
          >
            <CloseIcon
              style={{
                color: "black",
              }}
            />
          </IconButton>
          <img src={infoImg} className="info-img" alt="info img" />
        </div>
        {/* <Modal.Body>
          <div style={{
              margin: "-1rem"
            }}>
            <table align="center" cellPadding={0} cellSpacing={0} style={{backgroundColor: '#13619e'}} width="100%"><tbody><tr>
              <td style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
                }}>
              <a href="https://tesos.covifactura.com/login" target="_blank"><img align="middle" height={56} src="https://masivapp.com/ImageFiles/MRTA1E/2019%2FAbril%2F04%2Flogotipos.png" style={{border: 0, padding: 0, margin: 0, display: 'block'}} width="100%" /></a>
                <IconButton
                  onClick={(e) => {
                      e.preventDefault();
                      onHide();
                  }}
                  aria-label="Cerrar">
                  <CloseIcon style={{
                    color: 'white',
                    }} />
                </IconButton>
          </td>
        </tr>
      </tbody></table>
      <table align="center" cellPadding={0} cellSpacing={0} style={{borderWidth: '1px', borderStyle: 'none solid', borderColor: '#d4d4d4', borderImage: 'none', backgroundColor: '#fcfcfc', paddingBottom: '10px'}} width="100%"><tbody><tr><td style={{padding: '30px 2px 10px 2px', textAlign: 'center', color: '#13619e', fontFamily: '"Lato", sans-serif', fontSize: '24px'}}><span style={{fontSize: '22px'}}>Nuestra organización y sus empresas, <br />
      un equipo para avanzar&nbsp;</span></td>
  </tr>
</tbody></table>
<table align="center" cellPadding={0} cellSpacing={0} style={{borderWidth: '1px', borderStyle: 'none solid', borderColor: '#d4d4d4', borderImage: 'none', backgroundColor: '#fcfcfc', paddingBottom: '10px'}} width="100%"><tbody><tr><td style={{padding: '0 2px 10px 2px', textAlign: 'center', color: '#767676', fontFamily: '"Lato", sans-serif', fontSize: '18px'}}><strong>Señor Afiliado,</strong>&nbsp;debido a la situación actual del país,
  que puede<br />
conllevar a endeudamientos y posteriores atrasos de pagos, <br />
queremos acompañarlo para que su negocio siga adelante.&nbsp;<br />
Por lo anterior ajustamos las medidas tomadas el 18 de marzo, <br />
basados en los decretos 531 del Ministerio del Interior <br />
y el 106 de la Alcaldía, emitidos el 8 de abril <br />
de la siguiente manera:&nbsp;</td>
</tr>
</tbody></table>
<table align="center" cellPadding={0} cellSpacing={0} style={{borderWidth: '1px', borderStyle: 'none solid', borderColor: '#d4d4d4', borderImage: 'none', backgroundColor: '#fcfcfc', paddingBottom: '10px'}} width="100%"><tbody><tr><td style={{padding: '10px 20px 10px 30px', textAlign: 'left', color: '#767676', fontFamily: '"Lato", sans-serif', fontSize: '16px'}}><p />
<p><strong>Presentación de reclamación y avisos de no pago - reintegros</strong></p>
<ul style={{color: '#767676', fontFamily: 'Lato, sans-serif', fontSize: '16px', backgroundColor: '#fcfcfc'}}>
  <li>Se amplía el plazo para presentar la reclamación y pago de la misma en 60 días hábiles adicionales a lo estipulado en el contrato. También aplica para la marcación de aviso de no pago.</li>
</ul>
<p><strong>Prórrogas cheque</strong></p>
<ul>
  <li><span style={{color: '#767676', fontFamily: 'Lato, sans-serif', fontSize: '16px', backgroundColor: '#fcfcfc'}}>Para los cheques se amplía el término de prórroga hasta 60 días</span>.</li>
</ul>
<p style={{marginBottom: '0px', padding: '0px', color: '#767676', fontFamily: 'Lato, sans-serif', fontSize: '16px', backgroundColor: '#fcfcfc'}}><strong>Cupo</strong></p>
<ul style={{color: '#767676', fontFamily: 'Lato, sans-serif', fontSize: '16px', backgroundColor: '#fcfcfc'}}>
  <li>Es importante la revisión del cupo asignado antes de realizar la compra.</li>
</ul>
</td>
</tr>
</tbody></table>
<table align="center" cellPadding={0} cellSpacing={0} style={{borderWidth: '1px', borderStyle: 'none solid', borderColor: '#d4d4d4', borderImage: 'none', backgroundColor: '#fcfcfc', paddingBottom: '10px'}} width="100%"><tbody><tr><td style={{padding: '0 2px 10px 2px', textAlign: 'center', color: '#767676', fontFamily: '"Lato", sans-serif', fontSize: '18px'}}><p>Para más información comuníquese a la línea nacional<br />
en Bogotá <strong>353 4324</strong> o al Whatsapp <a href="https://covinoc.com/w/?telefono=573187116639&utm_source=[source]&utm_medium=[medium]S&utm_campaign=[campana]&utm_content=WA&texto=Hola%20necesito%20hablar%20con%20un%20ejecutivo%20de%20servicio" style={{color: '#00bb2d'}} target="_blank"><strong>318 7116639</strong></a><br />
o a la línea nacional 018000 946964<span style={{fontSize: 'medium'}} /></p>
</td>
</tr>
</tbody></table>
<table align="center" cellPadding={0} cellSpacing={0} style={{backgroundColor: '#fcfcfc', border: '1px solid #d4d4d4', borderTopStyle: 'none', borderRightStyle: 'solid', borderBottomStyle: 'none', borderLeftStyle: 'solid'}} width="100%"><tbody><tr><td style={{paddingTop: '5px'}}><a href="https://nuestroservicio.com/covifactura/?utm_source=email&utm_medium=masiv&utm_term=base_nacional&utm_campaign=compradores_afiliados" target="_blank"><img align="middle" src="https://masiv3.s3.amazonaws.com/ImageFiles/MRTA1E/2019%2FNoviembre%2Flogosx.png" style={{border: 0, padding: 0, margin: 0, display: 'block'}} width="100%" /></a></td>
</tr>
</tbody></table>
<table align="center" cellPadding={0} cellSpacing={0} style={{backgroundColor: '#fcfcfc', border: '1px solid #d4d4d4', borderTopStyle: 'none', borderRightStyle: 'solid', borderBottomStyle: 'solid', borderLeftStyle: 'solid'}} width="100%"><tbody><tr><td style={{fontFamily: '"Lato", sans-serif', textAlign: 'center', padding: '1px 0 20px 0'}}><span style={{color: '#13619e', fontSize: '16px'}}>Tranquilo, nosotros lo apoyamos.</span><span style={{fontSize: 'medium'}}>&nbsp;</span></td>
</tr>
</tbody></table>
<table align="center" cellPadding={0} cellSpacing={0}><tbody><tr><td><img height={77} src="https://masivapp.com/ImageFiles/L71XY0/2019/10/Mail-A1r14c1.jpg" style={{border: 0, padding: 0, margin: 0, display: 'block'}} width={12} /></td>
<td style={{fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '8px', color: '#6A6A6A', textAlign: 'justify', height: '33px'}} width="614px"><br />
Este mensaje (incluyendo sus anexos) es exclusivamente para el uso de la persona o entidad para quien está dirigido: contiene información estrictamente confidencial, está legalmente protegida y por lo tanto no es de carácter público; su divulgación es sancionada por la ley. Si el lector de este mensaje no es el receptor o no es a quien está dirigido o no es el destinatario intencional, y tampoco se trata del empleado o del agente responsable de recibir esta información, se le notifica por medio del presente que cualquier uso, difusión, copiado reproducción o distribución está estrictamente prohibida. Si usted recibió este comunicado por error, favor notificarlo inmediatamente al remitente y destruir inmediatamente el mensaje. Todas las opiniones contenidas en este mensaje son propias del autor del mismo y no necesariamente coinciden con las de Covinoc S.A. o con alguna de sus empresas controladas, controladoras, afiliadas y subsidiarias. Este mensaje intencionalmente no contiene acentos
</td>
<td><img height={77} src="https://masivapp.com/ImageFiles/L71XY0/2019/10/Mail-A1r14c11.jpg" style={{borderStyle: 'none', borderColor: 'inherit', borderWidth: 0, padding: 0, display: 'block', marginLeft: 0, marginTop: 0, marginBottom: 0}} width={15} /></td>
</tr>
</tbody></table>
</div>
        </Modal.Body>
      
       */}
      </Modal>
    );
  }
}

export default InfoModal;
