import React, { Component } from 'react';

import './maintenance.scss';
import maintenancemobile from '../../assets/images/maintenancemobile.jpg';
import maintenanceweb from '../../assets/images/maintenanceweb.jpg';

class Maintenance extends Component {

  render() {
    return (
      <div className="maintenance">
        <img className="maintenance-img-web hide-mobile" src={maintenanceweb} alt='imagen mantenimiento' />
        <img className="maintenance-img hide-desktop" src={maintenancemobile} alt='imagen mantenimiento' />
      </div>
    );
  }
}

export default Maintenance;
