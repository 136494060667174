import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import '../login/login.scss';
import * as actions from '../../redux/actions'
import { Row, Col, Button } from 'react-bootstrap';
import covifacturaLogo from '../../assets/logos/Logoblanco.svg';
import TextField from '@material-ui/core/TextField';
import Footer from '../footer';
import Loading from '../loading';

class ChangePassword extends Component {

  constructor() {
    super();
    this.state = {
      code: '',
      password: '',
      passwordRepeat: '',
    };

  }

  componentDidMount() {
    let paramsString = this.props.location.search
    const params = new URLSearchParams(paramsString);
    const code = params.get('c');
    this.setState({
      code
    })
    this.loadData()
  }

  loadData() {
    this.tryAgain();
  }

  isValid() {
    const { username, password } = this.state;
    return !!(username && password);
  }

  isPasswordFormat = (password) => {
    let isFormated = true

    if(password.length < 8) {
      return false
    }

    if(!(/[a-z]/.test(password))) {
      return false
    }

    if(!(/[A-Z]/.test(password))) {
      return false
    }

    if(!(/[0-9]/.test(password))) {
      return false
    }

    return isFormated
  }

  handleSubmit = () => {
    const {
      password,
      passwordRepeat,
      code
    } = this.state;
    this.props.actions.changePassword({
      password,
      passwordRepeat,
      code
    });
  }

  tryAgain = () => {
      this.props.actions.cleanUser();
  }

  goLogin = () => {
      this.props.history.push(`/login`)
  }

  componentWillReceiveProps(nextProps) {
      if (nextProps.userChange) {
        // this.props.actions.setUser({userEmail: this.state.emailUser, password: this.state.password, token: nextProps.user.TOKEN, userInfo: nextProps.user});
        // this.props.history.push(`/`);
      }
  }

  oninputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  render() {
    const { loading, userChange } = this.props;

    const {
      password,
      passwordRepeat,
      code
    } = this.state;
    return (
      <div className="login full-screen">
        { loading && (
          <Loading />
        )}
        <Col className="image-cover full-screen hide-mobile" md={8} >
          <Row>
            <Col md={6}>
              <h2 className="login_title">Respaldamos a sus clientes</h2>
              <div className="login_title_line-container">
                <div className="login_title_line"></div><div className="login_title_line-point"></div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="full-screen login-scroll" xs={12} md={4} >
          { !userChange && (
            <Row>
              <Col xs={12}>
                <Col xs={12} className="center-horizontally login-logo-container">
                  <img className="login-logo" src={covifacturaLogo} alt='covifactura logo'/>
                </Col>
                <Col xs={12} className="login_sub-title-container">
                  <h3 className="login_sub-title">RECUPERAR CONTRASEÑA</h3>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login-input-container">
                  <TextField
                    id="password"
                    label="Nueva contraseña"
                    type="password"
                    className="login-input"
                    margin="dense"
                    onChange={(event) => {
                      this.oninputChange(event, 'password');
                    }}
                    value={password}
                    error={!!password && !this.isPasswordFormat(password)}
                    helperText={!!password && !this.isPasswordFormat(password) ? 'La contraseña debe ser minimo de 8 caracteres y tener al menos una letra minuscula, una mayuscula y un numero': null}
                    fullWidth
                    />
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login-input-container">
                  <TextField
                    id="passwordRepeat"
                    label="Confirmar nueva contraseña"
                    type="password"
                    className="login-input"
                    margin="dense"
                    onChange={(event) => {
                      this.oninputChange(event, 'passwordRepeat');
                    }}
                    value={passwordRepeat}
                    error={(!!passwordRepeat && !this.isPasswordFormat(passwordRepeat)) || ((!!passwordRepeat && !!password) && password !== passwordRepeat)}
                    helperText={!!passwordRepeat && !this.isPasswordFormat(passwordRepeat) ? 'La contraseña debe ser minimo de 8 caracteres y tener al menos una letra minuscula, una mayuscula y un numero': ((passwordRepeat && password) && password !== passwordRepeat ? 'Las contraseñas no coinciden': null)}
                    fullWidth
                  />
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-send-container">
                  <Button
                    className="button-send"
                    variant="success"
                    disabled={!this.isPasswordFormat(passwordRepeat) || !this.isPasswordFormat(passwordRepeat) ||  password !== passwordRepeat || !code }
                    block
                    onClick={(e) => {
                        e.preventDefault()
                        this.handleSubmit()
                    }}
                  >
                    CONTINUAR
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
          { userChange && (
            <Row>
              <Col xs={12}>
                <Col xs={12} className="center-horizontally login-logo-container">
                  <img className="login-logo" src={covifacturaLogo} alt='covifactura logo'/>
                </Col>
                <Col xs={12} md={{ span: 8, offset: 2 }} className="login_sub-title-container">
                  <h3 className="login_sub-title">RECUPERAR CONTRASEÑA</h3>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login_error-login remember_send-email">
                  Hemos actualizado la contraseña correctamente.
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-send-container">
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    onClick={(e) => {
                        e.preventDefault()
                        this.goLogin()
                    }}
                  >
                    ACEPTAR
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
        </Col>
        <Footer isLogin />
      </div>
    );
  }
}


const mapStateToProps = (state, props) => {

  let {
      loading,
      errorUser,
      userChange
  } = state.user;

  console.log('state.user', state.user);

  return {
      loading,
      errorUser,
      userChange
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
