import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';
import {TextField, MenuItem} from '@material-ui/core';

class LocationDatesModal extends Component {
  constructor() {
    super();
    this.state = {
      department: '',
      city: '',
      phoneNumber: '',
      cellularNumber: '',
      address: '',
    };

  }

  confirm = () => {
    this.props.confirmLocation(this.state);
  }

  oninputChange = (event, input) => {
    if(input === 'department') {
      this.props.getCities(event.target.value);
      this.setState({
        city: '',
        [input] : event.target.value
      });
      return
    }
    this.setState({
      [input] : event.target.value
    });
  }

  render() {
    const {
      departments,
      cities,
      show,
      onHide,
    } = this.props;
    const {
      department,
      city,
      phoneNumber,
      cellularNumber,
      address,
    } = this.state;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="location-modal-title">
            Actualizar datos de localización
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="location-modal-body">
            <Col xs={12}>
              <Row>
                <Col xs={12} md={6} className="login-input-container">
                  <TextField
                    id="department"
                    select
                    label="Departamento"
                    className="home-input"
                    margin="dense"
                    value={department}
                    onChange={(event) => {
                      this.oninputChange(event, 'department');
                    }}
                    fullWidth
                    >
                    {departments.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ID}>
                          {item.LABEL}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Col>
                <Col xs={12} md={6} className="login-input-container">
                  <TextField
                    id="city"
                    select
                    label="Ciudad"
                    className="home-input"
                    margin="dense"
                    value={city}
                    onChange={(event) => {
                      this.oninputChange(event, 'city');
                    }}
                    fullWidth
                    >
                    {cities.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ID}>
                          {item.LABEL}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Col>
                <Col xs={12} md={6} className="login-input-container">
                  <TextField
                    id="phoneNumber"
                    label="Teléfono"
                    className="home-input"
                    inputProps={{
                      maxLength: 7,
                    }}
                    value={phoneNumber}
                    onChange={(event) => {
                      this.oninputChange(event, 'phoneNumber');
                    }}
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                    type="text"
                    margin="dense"
                    fullWidth
                    />
                </Col>
                <Col xs={12} md={6} className="login-input-container">
                  <TextField
                    id="cellularNumber"
                    label="Celular"
                    className="home-input"
                    margin="dense"
                    value={cellularNumber}
                    onChange={(event) => {
                      this.oninputChange(event, 'cellularNumber');
                    }}
                    type="number"
                    fullWidth
                    />
                </Col>
                <Col xs={12} md={6} className="login-input-container">
                  <TextField
                    id="address"
                    label="Dirección"
                    className="home-input"
                    margin="dense"
                    inputProps={{ pattern: "^[a-zA-Z0-9]+$" }}
                    value={address}
                    onChange={(event) => {
                      event.target.value = event.target.value.replace(/[^\w\s]/gi, "")
                      this.oninputChange(event, 'address');
                    }}
                    fullWidth
                    />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={{ span: 4, offset: 4 }} className="location-modal_done-button">
              <Button
                className="button-send"
                variant="success"
                disabled={department === '' || city === '' || phoneNumber === '' || cellularNumber === '' || address === ''}
                onClick={(e) => {
                    e.preventDefault();
                    this.confirm();
                }}
                block>
                Aceptar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

LocationDatesModal.propTypes = {
  departments: PropTypes.array,
  cities: PropTypes.array,
  getCities: PropTypes.func.isRequired,
  confirmLocation: PropTypes.func.isRequired,
};

LocationDatesModal.defaultProps = {
  departments: '',
  cities: '',
  invoiceNumber: '',
  selectedDate: '',
  getCities: () => {},
  confirmLocation: () => {},
};

export default LocationDatesModal;
