import React, { Component } from 'react';

import './loading-absolute.scss';
import loading from '../../assets/icons/loadingblue.svg';

class LoadingAbsolute extends Component {

  render() {
    return (
      <div className="loading-absolute" >
        <img src={loading} className="loader" alt="loading" />
      </div>
    );
  }
}

export default LoadingAbsolute;
