import React, { Component } from "react";
import PropTypes from 'prop-types';

import { Modal } from "react-bootstrap";

class PdfModal extends Component {
  confirm = () => {
    this.props.confirm();
  };

  render() {
    const { show, onHide, pdfFile } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        className="full-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <embed
            src={`data:application/pdf;base64,${pdfFile}`}
            type="application/pdf"
            width="100%"
            height="100%"
          ></embed>
        </Modal.Body>
      </Modal>
    );
  }
}

PdfModal.propTypes = {
  pdfFile: PropTypes.string.isRequired,
};

export default PdfModal;
