import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'debounce';

import './transactions.scss';
import * as actions from '../../redux/actions';
import { Col, Button, Row } from 'react-bootstrap';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import calendaricon from '../../assets/icons/calendaricon.svg';
import arrowback from '../../assets/icons/arrowback.svg';
import aproved from '../../assets/icons/aproved.svg';
import denegate from '../../assets/icons/denegate.svg';
import threePaymentsIcon from '../../assets/images/trespagos.png';
import { TextField, MenuItem, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import ConfirmChange from './confirm-trasaction';
import ConfirmCheckChange from './confirm-check';
import Loading from '../loading';
import LocationDatesModal from './location-dates-modal';
import StudyModalSucces from '../client-study/study-succes';
import ErrorModal from '../error-modal';

moment.locale('es');
class Transactions extends Component {
  constructor() {
    super();
    this.state = {
      reservationValue: false,
      menuOpen: false,
      confirmModal: false,
      disabledSendButton: false,
      confirmCheckModal: false,
      individual: true,
      individualSucces: false,
      individualError: false,
      individualErrorNumber: 0,
      selectedDate: null,
      selectedCheckDate: null,
      documentType: '',
      documentNumer: '',
      invoiceValue: '',
      invoiceValueMask: '',
      invoiceNumber: '',
      approvalNumber: '',
      bankSelected: '',
      checkType: 'D',
      accountNumber: '',
      titleValue: '',
      checkValue: '',
      checkValueMask: '',
      checksArray: [],
      activity: false,
      selectedStudentDocument: '',
      documentStudentNumber: '',
      firstName: '',
      secondName: '',
      lastName: '',
      secondLastName: '',
      studentRelationship: '',
      studentCode: '',
      semester: '',
      career: '',
      locationDatesModal: false,
      municipality: '',
      phone: '',
      cellular: '',
      address: '',
      queryCode: '',
      email: '',
      emailValid: true,
      phone: '',
      errorMessage: null,
      threePayments: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.confirmDayCheck = debounce(this.confirmDayCheck, 500);
    this.confirmInvoice = debounce(this.confirmInvoice, 500);
  }

  componentDidMount() {
    let params = this.props.match.params;
    let documentType = params.documentType;
    let documentNumber = params.documentNumber;
    let queryCode = params.queryCode;
    let query = new URLSearchParams(this.props.location.search);
    let reservationValue = query.get('valor') ? query.get('valor') : false;
    const isThreePayments = query.get('threepayments');

    const newState = {
      documentType,
      documentNumber,
      queryCode,
      reservationValue,
    };

    if (isThreePayments) {
      newState['threePayments'] = true;
    }

    this.setState(newState, () => {
      this.props.actions.getUser();
      this.props.actions.getAppGlobals();
      this.props.actions.getFamilyList();
      this.props.actions.getCareerList();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      if (this.state.documentNumber) {
        this.getQuota(nextProps.user);
      }
    }
    if (nextProps.userQuota !== this.props.userQuota) {
      if (!(nextProps.userQuota.FACTURA === 'SI' || this.state.threePayments)) {
        this.changeTab(false);
      }
      if (nextProps.userQuota.ACTIVIDAD === 'UNIVERSIDAD') {
        this.setState({
          activity: true,
        });
      }
      if (nextProps.userQuota.LOCALIZACION === 'S') {
        this.setState({
          locationDatesModal: true,
        });
      }
    }
    if (nextProps.transactionResponse !== this.props.transactionResponse) {
      this.setState({
        disabledSendButton: false,
      });
      if (!this.state.threePayments) {
        if (
          nextProps.transactionResponse &&
          (nextProps.transactionResponse.RAZON === 0 ||
            nextProps.transactionResponse['RAZON '] === 0)
        ) {
          const { individual, activity } = this.state;
          this.setState(
            {
              individualSucces: true,
              approvalNumber:
                nextProps.transactionResponse.CLAVE ||
                nextProps.transactionResponse['CLAVE '],
            },
            () => {
              if (!individual && activity) {
                this.updateStudent();
              }
            }
          );
        } else if (
          nextProps.transactionResponse &&
          nextProps.transactionResponse.RAZON !== 0
        ) {
          this.setState({
            individualError: true,
            individualErrorNumber: nextProps.transactionResponse.RAZON,
            errorMessage:
              'MENSAJE' in nextProps.transactionResponse
                ? nextProps.transactionResponse.MENSAJE
                : null,
          });
        }
      }
    }
    if (
      nextProps.transactionResponseError !== this.props.transactionResponseError
    ) {
      this.setState({
        individualError: !!nextProps.transactionResponseError,
      });
    }
  }

  updateStudent = () => {
    const {
      documentType,
      documentNumber,
      firstName,
      secondName,
      lastName,
      secondLastName,
      studentRelationship,
      studentCode,
      semester,
      career,
      approvalNumber,
      selectedStudentDocument,
      documentStudentNumber,
      queryCode,
      email,
      phone,
    } = this.state;
    const { user } = this.props;
    this.props.actions.updateStudent({
      documentType,
      documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      studentDocument: documentStudentNumber,
      studentDocumentType: selectedStudentDocument,
      userEmail: user.userEmail,
      firstName,
      secondName,
      lastName,
      secondLastName,
      studentRelationship,
      studentCode,
      semester,
      career,
      approvalNumber,
      queryCode,
      studentPhone: phone,
      studentEmail: email,
    });
  };

  goHome = () => {
    const { userQuota } = this.props;
    let userQuotaInfo =
      userQuota &&
      'CUPO_COMPRADOR' in userQuota &&
      userQuota.CUPO_COMPRADOR.length > 0
        ? userQuota.CUPO_COMPRADOR[0]
        : false;

    const { individualSucces, individualError } = this.state;
    if (individualSucces || individualError) {
      this.resetForm();
      this.props.history.push(`/`);
    } else {
      if (userQuotaInfo && userQuotaInfo.ESTADO === 'PERMITIR SIN CUPO') {
        this.resetForm();
        this.props.history.push(`/`);
      } else {
        this.props.history.goBack();
      }
    }
  };

  resetForm = () => {
    this.props.actions.resetQuota();
  };

  sendInvoice = () => {
    this.setState({
      confirmModal: true,
      disabledSendButton: true,
    });
  };

  closeInvoiceModal = () => {
    this.setState({
      confirmModal: false,
    });
    this.confirmInvoice();
  };

  confirmInvoice = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'realizar_transaccion', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const {
      documentType,
      documentNumber,
      invoiceNumber,
      invoiceValue,
      selectedDate,
      municipality,
      phone,
      cellular,
      address,
      queryCode,
      threePayments,
    } = this.state;
    const { user } = this.props;
    if (threePayments) {
      this.props.actions.sendThreePaymentsTransaction({
        documentType,
        documentNumber,
        userDocument: user.userInfo.DOCUMENTO,
        userDocumentType: user.userInfo.TIPO_DOCUMENTO,
        userEmail: user.userEmail,
        invoiceValue,
        queryCode,
        cellular: cellular ? cellular : '0',
      });
    } else {
      let transactionsArray = [
        {
          fecha: moment(selectedDate).format('YYYYMMDD'),
          valor: invoiceValue,
          titulo: invoiceNumber,
        },
      ];
      this.props.actions.sendTransaction({
        documentType,
        documentNumber,
        userDocument: user.userInfo.DOCUMENTO,
        userDocumentType: user.userInfo.TIPO_DOCUMENTO,
        userEmail: user.userEmail,
        transactionType: 'F',
        invoiceNumber,
        expirationDate: moment(selectedDate).format('YYYYMMDD'),
        invoiceValue,
        transactionsArray: JSON.stringify(transactionsArray),
        queryCode,
        bank: '0',
        accountNumber: null,
        municipality: municipality ? municipality : '0',
        phone: phone ? phone : '0',
        cellular: cellular ? cellular : '0',
        address: address ? address : '.',
      });
    }
  };

  sendDayCheck = () => {
    this.setState({
      confirmCheckModal: true,
    });
  };

  confirmDayCheck = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'realizar_transaccion', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const {
      documentType,
      documentNumber,
      bankSelected,
      accountNumber,
      titleValue,
      checkValue,
      checkType,
      selectedCheckDate,
      checksArray,
      queryCode,
      municipality,
      phone,
      cellular,
      address,
    } = this.state;
    const { user } = this.props;
    this.setState({
      confirmCheckModal: false,
    });
    let totalValue = parseFloat(checkValue);
    let transactionsArray = [
      {
        fecha: checkType === 'P' ? selectedCheckDate : moment(new Date()),
        valor: checkValue,
        titulo: titleValue,
      },
    ];
    if (checkType === 'P') {
      for (let index in checksArray) {
        totalValue += parseFloat(checksArray[index].checkValue);
        transactionsArray.push({
          fecha: checksArray[index].selectedCheckDate,
          valor: checksArray[index].checkValue,
          titulo: checksArray[index].titleValue,
        });
      }
    }
    let sortedArray = transactionsArray.sort((a, b) => a.fecha - b.fecha);
    for (let index in sortedArray) {
      sortedArray[index].fecha = moment(sortedArray[index].fecha).format(
        'YYYYMMDD'
      );
      sortedArray[index].titulo = parseInt(index) + 1;
    }
    this.props.actions.sendTransaction({
      documentType,
      documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      transactionType: checkType,
      invoiceNumber: 1,
      expirationDate:
        checkType === 'P'
          ? moment(selectedCheckDate).format('YYYYMMDD')
          : moment(new Date()).format('YYYYMMDD'),
      invoiceValue: checkType === 'P' ? totalValue : checkValue,
      transactionsArray: JSON.stringify(sortedArray),
      queryCode,
      bank: bankSelected,
      accountNumber: accountNumber,
      municipality: municipality ? municipality : '0',
      phone: phone ? phone : '0',
      cellular: cellular ? cellular : '0',
      address: address ? address : '.',
    });
  };

  getQuota = (user) => {
    const { documentType, documentNumber, queryCode } = this.state;
    this.props.actions.getQuota({
      documentType: documentType,
      documentNumber: documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      queryCode: queryCode,
    });
  };

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  confirmChangeClose = () => {
    this.setState({
      confirmModal: false,
    });
  };

  locationDatesModalClose = () => {
    // this.setState({
    //   locationDatesModal: false
    // });
  };

  changeTab = (tab) => {
    const { individualSucces, individualError } = this.state;
    if (!individualSucces && !individualError) {
      this.setState({
        individual: tab,
      });
    }
  };

  confirmChangeClose = () => {
    this.setState({
      confirmModal: false,
      disabledSendButton: false,
    });
  };

  confirmChangeCheckClose = () => {
    this.setState({
      confirmCheckModal: false,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleCheckDateChange = (date) => {
    this.setState({ selectedCheckDate: date });
  };

  getDocumentTypeName = (documentType) => {
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item) => {
      return item.ID === documentType;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  };

  getBankName = (bankId) => {
    const { globals } = this.props;
    let banks = globals ? globals.BANCOS : [];
    let bankItem = banks.find((item) => {
      return item.ID === bankId;
    });
    return bankItem ? bankItem.LABEL : '';
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  onInvoiceValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, '');
    inputValue = inputValue.replace(/\./g, '');
    inputValue = inputValue.replace(/´/g, '');
    let invoiceValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    this.setState({
      invoiceValue,
      invoiceValueMask: thousand,
    });
  };

  onInvoiceNumberChange = (event) => {
    this.setState({
      invoiceNumber: event.target.value,
    });
  };

  onBankSelectedChange = (event) => {
    let bankSelected = event.target.value;
    this.setState({
      bankSelected,
    });
  };

  onCheckTypeChange = (event) => {
    let checkType = event.target.value;
    this.setState({
      checkType,
    });
  };

  onAccountNumberChange = (event) => {
    let accountNumber = event.target.value;
    this.setState({
      accountNumber,
    });
  };

  onTitleValueChange = (event) => {
    let titleValue = event.target.value;
    this.setState({
      titleValue,
    });
  };

  onCheckValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, '');
    inputValue = inputValue.replace(/\./g, '');
    inputValue = inputValue.replace(/´/g, '');
    let checkValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    this.setState({
      checkValue,
      checkValueMask: thousand,
    });
  };

  onTitleArrayValueChange = (event, index) => {
    let titleValue = event.target.value;
    let checksArray = Object.assign([], this.state.checksArray);
    checksArray[index].titleValue = titleValue;
    this.setState({
      checksArray,
    });
  };

  handleArrayCheckDateChange = (date, index) => {
    let checksArray = Object.assign([], this.state.checksArray);
    checksArray[index].selectedCheckDate = date;
    this.setState({
      checksArray,
    });
  };

  onArrayCheckValueChange = (event, index) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, '');
    inputValue = inputValue.replace(/\./g, '');
    inputValue = inputValue.replace(/´/g, '');
    let checkValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    let checksArray = Object.assign([], this.state.checksArray);
    checksArray[index].checkValue = checkValue;
    checksArray[index].checkValueMask = thousand;
    this.setState({
      checksArray,
    });
  };

  addCheck = () => {
    let checksArray = Object.assign([], this.state.checksArray);
    checksArray.push({
      titleValue: '',
      checkValue: '',
      checkValueMask: '',
      selectedCheckDate: null,
    });
    this.setState({
      checksArray,
    });
  };

  isCheckArrayValid = () => {
    let checksArray = Object.assign([], this.state.checksArray);
    let isValid = true;
    for (let index in checksArray) {
      if (
        checksArray[index].checkValue === '' ||
        checksArray[index].selectedCheckDate === null
      ) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  selectDocument = (event) => {
    let selectedStudentDocument = event.target.value;
    this.setState({
      selectedStudentDocument,
    });
  };

  onDocumentNumberChange = (event) => {
    this.setState({
      documentStudentNumber: event.target.value,
    });
  };

  onFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  onSecondNameChange = (event) => {
    this.setState({
      secondName: event.target.value,
    });
  };

  onLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  onSecondLastNameChange = (event) => {
    this.setState({
      secondLastName: event.target.value,
    });
  };

  onStudentRelationshipChange = (event) => {
    this.setState({
      studentRelationship: event.target.value,
    });
  };

  onStudentCodeChange = (event) => {
    this.setState({
      studentCode: event.target.value,
    });
  };

  onSemesterChange = (event) => {
    this.setState({
      semester: event.target.value,
    });
  };

  onCareerChange = (event) => {
    this.setState({
      career: event.target.value,
    });
  };

  isStudentValid = () => {
    let isValid = true;
    const {
      selectedStudentDocument,
      documentStudentNumber,
      firstName,
      lastName,
      secondLastName,
      studentRelationship,
      studentCode,
      semester,
      career,
      emailValid,
      phone,
    } = this.state;
    if (
      selectedStudentDocument === '' ||
      documentStudentNumber === '' ||
      firstName === '' ||
      lastName === '' ||
      secondLastName === '' ||
      studentRelationship === '' ||
      studentCode === '' ||
      semester === '' ||
      career === ''
    ) {
      isValid = false;
    }
    if (!emailValid || phone === '') {
      isValid = false;
    }
    return isValid;
  };

  getCities = (departmentId) => {
    this.props.actions.getCities({
      departmentId,
    });
  };

  confirmLocation = (locationData) => {
    this.setState({
      municipality: locationData.city,
      phone: locationData.phoneNumber,
      cellular: locationData.cellularNumber,
      address: locationData.address,
      locationDatesModal: false,
    });
  };

  isValidEmail(emailUser, input) {
    if (!emailUser) {
      this.setState({
        [input]: true,
      });
      return;
    }
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
      validEmail = true;
    }
    this.setState({
      [input]: validEmail,
    });
  }

  isEmail = (event, input) => {
    let email = event.target.value;
    let validName = input + 'Valid';
    this.isValidEmail(email, validName);
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
      validEmail = true;
    }
    if (validEmail) {
      this.setState({
        [input]: email,
        [validName]: validEmail,
      });
    } else {
      this.setState({
        [input]: email,
      });
    }
  };

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  isValueError = (value) => {
    return false;
    // const {
    //   userQuota
    // } = this.props;
    // let userQuotaInfo = (userQuota && userQuota.CUPO_COMPRADOR.length > 0) ? userQuota.CUPO_COMPRADOR[0] : false;
    // if (userQuotaInfo.CUPO_DISPONIBLE && parseFloat(value) > parseFloat(userQuotaInfo.CUPO_DISPONIBLE)) {
    //   return true
    // } else {
    //   return false
    // }
  };

  render() {
    const {
      menuOpen,
      individual,
      selectedDate,
      selectedCheckDate,
      confirmModal,
      individualSucces,
      individualError,
      individualErrorNumber,
      confirmCheckModal,
      invoiceValueMask,
      invoiceNumber,
      invoiceValue,
      approvalNumber,
      bankSelected,
      checkType,
      accountNumber,
      titleValue,
      checkValue,
      checkValueMask,
      checksArray,
      activity,
      selectedStudentDocument,
      documentStudentNumber,
      firstName,
      secondName,
      lastName,
      secondLastName,
      studentRelationship,
      studentCode,
      semester,
      career,
      email,
      emailValid,
      phone,
      reservationValue,
      locationDatesModal,
      errorMessage,
      threePayments,
      cellular,
      documentNumber,
      documentType,
      disabledSendButton,
    } = this.state;

    const {
      userQuota,
      loading,
      globals,
      careerList,
      familyList,
      cities,
      transactionResponse,
    } = this.props;
    let userQuotaInfo =
      userQuota &&
      'CUPO_COMPRADOR' in userQuota &&
      userQuota.CUPO_COMPRADOR.length > 0
        ? userQuota.CUPO_COMPRADOR[0]
        : false;
    let maxDays =
      userQuota && userQuota.DIAS_FACTURA
        ? parseInt(userQuota.DIAS_FACTURA)
        : 60;
    let finalMaxDays = maxDays === 0 || maxDays < 0 ? 60 : maxDays;
    let checkAvailable = userQuota && userQuota.CHEQUE === 'SI';
    let invoiceAvailable =
      userQuota && (userQuota.FACTURA === 'SI' || threePayments);
    let banks = globals ? globals.BANCOS : [];
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let departments =
      globals && globals.DEPARTAMENTOS ? globals.DEPARTAMENTOS : [];
    let citiesArray = cities ? cities : [];
    const username =
      userQuota && userQuotaInfo
        ? userQuotaInfo.NOMBRES
        : userQuota
        ? userQuota.NOMBRES
        : false;

    let date1 = '';
    let date2 = '';
    let date3 = '';
    let consecutive = '';
    let showForm = null;
    let errorModalText = transactionResponse
      ? transactionResponse['MENSAJE_EXTERNO ']
      : '';

    if (
      threePayments &&
      transactionResponse &&
      'RAZON ' in transactionResponse
    ) {
      switch (parseInt(transactionResponse['RAZON '])) {
        case 0:
          if (
            threePayments &&
            'FECHAS_CUOTAS ' in transactionResponse &&
            'VALOR_CUOTAS ' in transactionResponse
          ) {
            const datesArray = transactionResponse['FECHAS_CUOTAS '].split('~');
            const consecutiveArray =
              transactionResponse['VALOR_CUOTAS '].split('~');
            date1 = moment(datesArray[0], 'YYYYMMDD').format('DD/MM/YYYY');
            date2 = moment(datesArray[1], 'YYYYMMDD').format('DD/MM/YYYY');
            date3 = moment(datesArray[2], 'YYYYMMDD').format('DD/MM/YYYY');
            consecutive = consecutiveArray[3];
          }
          showForm = 0;
          break;
        case 1:
          showForm = 3;
          break;
        case 2:
          showForm = 2;
          break;
        case 3:
          showForm = 4;
          break;
        default:
          showForm = null;
          break;
      }
    }

    return (
      <div className='transactions'>
        <ConfirmChange
          show={confirmModal}
          onHide={this.confirmChangeClose}
          name={username}
          confirmInvoice={this.closeInvoiceModal}
          invoiceValue={invoiceValueMask}
          invoiceNumber={invoiceNumber}
          selectedDate={moment(selectedDate).format('DD/MM/YYYY')}
          threePayments={threePayments}
        />
        <StudyModalSucces
          show={showForm === 2}
          onHide={this.goHome}
          showInfo
          title={
            threePayments
              ? 'Transacción radicada'
              : '¡Tu solicitud ha sido radicada!'
          }
          code={
            transactionResponse && 'ESTUDIO_CUPO ' in transactionResponse
              ? transactionResponse['ESTUDIO_CUPO ']
              : ''
          }
          quotaValueMask={invoiceValueMask}
          name={username}
          threePayments={threePayments}
        />
        <StudyModalSucces
          show={showForm === 3}
          onHide={this.goHome}
          title={
            threePayments ? 'Transacción negada' : 'Tu solicitud ha sido negada'
          }
          code={
            transactionResponse && 'ESTUDIO_CUPO ' in transactionResponse
              ? transactionResponse['ESTUDIO_CUPO ']
              : ''
          }
          quotaValueMask={invoiceValueMask}
          threePayments={threePayments}
          name={username}
        />
        <StudyModalSucces
          show={showForm === 0}
          onHide={this.goHome}
          date1={date1}
          date2={date2}
          date3={date3}
          consecutive={consecutive}
          title={'Transacción exitosa'}
          threePayments={threePayments}
          code={
            transactionResponse && 'ESTUDIO_CUPO ' in transactionResponse
              ? transactionResponse['ESTUDIO_CUPO ']
              : ''
          }
          quotaValueMask={invoiceValueMask}
          name={username}
        />
        <ErrorModal
          show={showForm === 4}
          confirm={this.goHome}
          onHide={this.goHome}
          errorText={decodeURIComponent(escape(errorModalText))}
          errorTitle={' '}
        />
        <ConfirmCheckChange
          show={confirmCheckModal}
          onHide={this.confirmChangeCheckClose}
          confirmDayCheck={this.confirmDayCheck}
          name={username}
          bankName={this.getBankName(bankSelected)}
          checkType={checkType}
          accountNumber={accountNumber}
          titleValue={titleValue}
          checkValue={checkValueMask}
          checksArray={checksArray}
          selectedCheckDate={moment(selectedCheckDate).format('DD/MM/YYYY')}
        />
        {/*
         */}
        <LocationDatesModal
          show={locationDatesModal}
          departments={departments}
          cities={citiesArray}
          getCities={this.getCities}
          onHide={this.locationDatesModalClose}
          confirmLocation={this.confirmLocation}
        />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={2} />
        <Header openMenu={this.openMenu} />
        <div className='page-body-container center-horizontally'>
          <div className='page-body'>
            <UserHeader />
            <Col xs={12} className='general-main-card'>
              {loading && <Loading />}
              <div xs={12} className='goback-container'>
                {!individualSucces && !individualError && (
                  <div
                    className='goback-action'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt='arrow back'
                        className='goback-icon'
                      />
                    </span>
                    <span className='goback-text'>Volver</span>
                  </div>
                )}
              </div>
              <Col xs={12} className='transactions-margin'>
                <Row>
                  <Col xs={6} className='card-title'>
                    Transacción
                  </Col>
                  {threePayments && (
                    <Col xs={6} className='card-logo'>
                      <img src={threePaymentsIcon} alt='icono tres pagos' />
                    </Col>
                  )}
                </Row>
                <Col xs={12}>
                  <hr />
                </Col>
              </Col>
              <Col xs={12}>
                <Col xs={12}>
                  <Row>
                    {userQuota && (
                      <Col xs={12}>
                        <Row>
                          <Col xs={12}>
                            <Row>
                              {userQuotaInfo.ESTADO !== 'PERMITIR SIN CUPO' && (
                                <Col xs={12} md={6}>
                                  <Row>
                                    <Col className='transactions-body_key'>
                                      Nombre
                                    </Col>
                                    {username && (
                                      <Col
                                        xs='auto'
                                        className='transactions-body_value'
                                      >
                                        {username.toLowerCase()}
                                      </Col>
                                    )}
                                    {!username && (
                                      <Col
                                        xs='auto'
                                        className='transactions-body_value'
                                      >
                                        --
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              )}
                              <Col xs={12} md={6}>
                                <Row>
                                  <Col className='transactions-body_key'>
                                    Tipo de documento
                                  </Col>
                                  <Col
                                    xs='auto'
                                    className='transactions-body_value'
                                  >
                                    {this.getDocumentTypeName(
                                      documentType
                                    ).toLowerCase()}
                                  </Col>
                                </Row>
                              </Col>
                              {userQuotaInfo.ESTADO === 'PERMITIR SIN CUPO' && (
                                <Col xs={12} md={6}>
                                  <Row>
                                    <Col className='transactions-body_key'>
                                      Nº documento
                                    </Col>
                                    <Col
                                      xs='auto'
                                      className='transactions-body_value'
                                    >
                                      {documentNumber}
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          </Col>
                          {userQuotaInfo.ESTADO !== 'PERMITIR SIN CUPO' && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={12} md={6}>
                                  <Row>
                                    <Col className='transactions-body_key'>
                                      Nº documento
                                    </Col>
                                    <Col
                                      xs='auto'
                                      className='transactions-body_value'
                                    >
                                      {documentNumber}
                                    </Col>
                                  </Row>
                                </Col>
                                {!reservationValue && !threePayments && (
                                  <Col xs={12} md={6}>
                                    <Row>
                                      <Col className='transactions-body_key'>
                                        {userQuotaInfo &&
                                        'ALERTA' in userQuotaInfo &&
                                        userQuotaInfo.ALERTA === 'CON ACUERDO'
                                          ? 'Cupo disponible acuerdo'
                                          : 'Cupo disponible'}
                                      </Col>
                                      <Col
                                        xs='auto'
                                        className='transactions-body_value'
                                      >
                                        {userQuotaInfo &&
                                          'ALERTA' in userQuotaInfo &&
                                          (userQuotaInfo.ALERTA ===
                                            'CON ACUERDO' ||
                                            userQuotaInfo.ALERTA ===
                                              'OPCION ACUERDO') &&
                                          `${this.getNumber(
                                            userQuotaInfo.CUPO_DISPONIBLE_ACUERDO ||
                                              0
                                          )}`}
                                        {(!(
                                          userQuotaInfo &&
                                          'ALERTA' in userQuotaInfo
                                        ) ||
                                          (userQuotaInfo.ALERTA !==
                                            'CON ACUERDO' &&
                                            userQuotaInfo.ALERTA !==
                                              'OPCION ACUERDO')) &&
                                          `${this.getNumber(
                                            userQuotaInfo.CUPO_DISPONIBLE || 0
                                          )}`}
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {reservationValue && (
                                  <Col xs={12} md={6}>
                                    <Row>
                                      <Col className='transactions-body_key'>
                                        Cupo reservado
                                      </Col>
                                      <Col
                                        xs='auto'
                                        className='transactions-body_value'
                                      >
                                        ${this.getNumber(reservationValue || 0)}
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    )}
                    <Col xs={12} className='tab-container'>
                      <Row>
                        <Col
                          className={
                            !invoiceAvailable
                              ? 'tab-text-disabled'
                              : individual
                              ? 'tab-text-selected'
                              : 'tab-text'
                          }
                          onClick={() => {
                            if (invoiceAvailable) {
                              this.changeTab(true);
                            }
                          }}
                        >
                          Factura
                        </Col>
                        {!threePayments && (
                          <Col
                            className={
                              !checkAvailable
                                ? 'tab-text-disabled'
                                : individual
                                ? 'tab-text'
                                : 'tab-text-selected tab-check'
                            }
                            onClick={() => {
                              if (checkAvailable) {
                                this.changeTab(false);
                              }
                            }}
                          >
                            Cheque
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {!individual && !individualSucces && !individualError && (
                      <Col xs={12}>
                        <Row>
                          <Col xs={12} md={6} className='login-input-container'>
                            <TextField
                              id='checkType'
                              select
                              label='Tipo de cheque'
                              className='home-input'
                              margin='dense'
                              value={checkType}
                              onChange={this.onCheckTypeChange}
                              fullWidth
                            >
                              <MenuItem key={1} value='D'>
                                Al día
                              </MenuItem>
                              <MenuItem key={2} value='P'>
                                Posfechado
                              </MenuItem>
                            </TextField>
                          </Col>
                          <Col xs={12} md={6} className='login-input-container'>
                            <TextField
                              id='bankingEntity'
                              select
                              label='Entidad bancaria'
                              className='home-input'
                              value={bankSelected}
                              onChange={this.onBankSelectedChange}
                              margin='dense'
                              fullWidth
                            >
                              {banks.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.ID}>
                                    {item.LABEL}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Col>
                          <Col xs={12} md={6} className='login-input-container'>
                            <TextField
                              id='accountNumber'
                              label='Número de cuenta'
                              value={accountNumber}
                              onChange={this.onAccountNumberChange}
                              className='home-input'
                              margin='dense'
                              fullWidth
                            />
                          </Col>
                          {/*
                            <Col xs={12} md={6} className="login-input-container">
                            <TextField
                            id="checkNumber"
                            label="Número de cheque"
                            className="home-input"
                            value={titleValue}
                            onChange={this.onTitleValueChange}
                            margin="dense"
                            fullWidth
                            />
                            </Col>
                            */}
                          <Col xs={12} md={6} className='login-input-container'>
                            <TextField
                              id='check-value'
                              label='Valor'
                              className='home-input'
                              margin='dense'
                              value={checkValueMask}
                              onChange={this.onCheckValueChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              error={this.isValueError(checkValue)}
                              helperText={
                                this.isValueError(checkValue)
                                  ? 'El monto de la transacción no puede ser mayor al valor reservado.'
                                  : null
                              }
                              fullWidth
                            />
                          </Col>
                          {checkType === 'P' && (
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container date-picker-container'
                            >
                              <MuiPickersUtilsProvider
                                utils={MomentUtils}
                                moment={moment}
                              >
                                <DatePicker
                                  keyboard
                                  disableOpenOnEnter
                                  placeholder='DD/MM/YYYY'
                                  invalidDateMessage='Formato inválido'
                                  mask={(value) =>
                                    // handle clearing outside if value can be changed outside of the component
                                    value
                                      ? [
                                          /\d/,
                                          /\d/,
                                          '/',
                                          /\d/,
                                          /\d/,
                                          '/',
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                        ]
                                      : []
                                  }
                                  locale='es-ES'
                                  animateYearScrolling={false}
                                  onInputChange={(e) => {
                                    let value = e.target.value;
                                    value = value.replace(/_/g, '');
                                    if (value.length === 10) {
                                      this.handleCheckDateChange(
                                        moment(value, 'DD/MM/YYYY'),
                                        'selectedCheckDate'
                                      );
                                    }
                                  }}
                                  margin='normal'
                                  label='Fecha de vencimiento'
                                  className='home-input'
                                  value={selectedCheckDate}
                                  onChange={(date) => {
                                    this.handleCheckDateChange(
                                      date,
                                      'selectedCheckDate'
                                    );
                                  }}
                                  fullWidth
                                  format='DD/MM/YYYY'
                                  keyboardIcon={
                                    <img
                                      src={calendaricon}
                                      alt='icono calendario'
                                      className='calendar-icon'
                                    />
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </Col>
                          )}
                          {activity && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={12}>
                                  <Row>
                                    <Col xs={12} className='new-check-title'>
                                      Datos del estudiante
                                    </Col>
                                    <Col xs={12}>
                                      <Row>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='documentType'
                                            select
                                            label='Tipo de documento'
                                            className='home-input'
                                            margin='dense'
                                            value={selectedStudentDocument}
                                            onChange={this.selectDocument}
                                            fullWidth
                                          >
                                            {documents.map((item, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={item.ID}
                                                >
                                                  {item.LABEL}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='documentNumber'
                                            label='Número de documento'
                                            className='home-input'
                                            margin='dense'
                                            type='number'
                                            value={documentStudentNumber}
                                            onChange={
                                              this.onDocumentNumberChange
                                            }
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='firstName'
                                            label='Primer nombre'
                                            className='home-input'
                                            margin='dense'
                                            value={firstName}
                                            onChange={this.onFirstNameChange}
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='secondName'
                                            label='Segundo nombre'
                                            className='home-input'
                                            margin='dense'
                                            value={secondName}
                                            onChange={this.onSecondNameChange}
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='lastName'
                                            label='Primer apellido'
                                            className='home-input'
                                            margin='dense'
                                            value={lastName}
                                            onChange={this.onLastNameChange}
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='secondLastName'
                                            label='Segundo apellido'
                                            className='home-input'
                                            margin='dense'
                                            value={secondLastName}
                                            onChange={
                                              this.onSecondLastNameChange
                                            }
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='email'
                                            label='Correo'
                                            className='home-input'
                                            margin='dense'
                                            value={email}
                                            onChange={(event) => {
                                              this.isEmail(event, 'email');
                                            }}
                                            error={
                                              email && !emailValid
                                                ? true
                                                : false
                                            }
                                            helperText={
                                              email && !emailValid
                                                ? 'Escriba un formato de email válido'
                                                : null
                                            }
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='phone'
                                            label='Celular'
                                            className='home-input'
                                            margin='dense'
                                            type='number'
                                            value={phone}
                                            onChange={(event) => {
                                              this.oninputChange(
                                                event,
                                                'phone'
                                              );
                                            }}
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='studentRelationship'
                                            select
                                            label='Relación con el estudiante'
                                            className='home-input'
                                            margin='dense'
                                            value={studentRelationship}
                                            onChange={
                                              this.onStudentRelationshipChange
                                            }
                                            fullWidth
                                          >
                                            {familyList &&
                                              familyList.map((item, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={item.PARENTESCO}
                                                  >
                                                    {item.PARENTESCO}
                                                  </MenuItem>
                                                );
                                              })}
                                          </TextField>
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='studentCode'
                                            label='Orden de matricula'
                                            className='home-input'
                                            type='number'
                                            margin='dense'
                                            value={studentCode}
                                            onChange={this.onStudentCodeChange}
                                            fullWidth
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='semester'
                                            select
                                            label='Semestre'
                                            className='home-input'
                                            margin='dense'
                                            value={semester}
                                            onChange={this.onSemesterChange}
                                            fullWidth
                                          >
                                            <MenuItem value='1'>1</MenuItem>
                                            <MenuItem value='2'>2</MenuItem>
                                            <MenuItem value='3'>3</MenuItem>
                                            <MenuItem value='4'>4</MenuItem>
                                            <MenuItem value='5'>5</MenuItem>
                                            <MenuItem value='6'>6</MenuItem>
                                            <MenuItem value='7'>7</MenuItem>
                                            <MenuItem value='8'>8</MenuItem>
                                            <MenuItem value='9'>9</MenuItem>
                                            <MenuItem value='10'>10</MenuItem>
                                          </TextField>
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={6}
                                          className='login-input-container'
                                        >
                                          <TextField
                                            id='career'
                                            select
                                            label='Carrera'
                                            className='home-input'
                                            margin='dense'
                                            value={career}
                                            onChange={this.onCareerChange}
                                            fullWidth
                                          >
                                            {careerList &&
                                              careerList.map((item, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={item.CARRERA}
                                                  >
                                                    {item.CARRERA}
                                                  </MenuItem>
                                                );
                                              })}
                                          </TextField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {checkType === 'P' && (
                            <Col xs={12}>
                              <Row>
                                {checksArray.length > 0 &&
                                  checksArray.map((item, index) => {
                                    return (
                                      <Col xs={12} key={index}>
                                        <Row>
                                          <Col
                                            xs={12}
                                            className='new-check-title'
                                          >
                                            Complete los campos del siguiente
                                            cheque
                                          </Col>
                                          <Col xs={12}>
                                            <Row>
                                              {/*
                                              <Col xs={12} md={6} className="login-input-container">
                                              <TextField
                                              id={`checkNumber-${index}`}
                                              label="Número de cheque"
                                              className="home-input"
                                              value={item.titleValue}
                                              onChange={ (e) => {
                                              this.onTitleArrayValueChange(e, index);
                                              }}
                                              margin="dense"
                                              fullWidth
                                              />
                                              </Col>
                                              */}
                                              <Col
                                                xs={12}
                                                md={6}
                                                className='login-input-container date-picker-container'
                                              >
                                                <MuiPickersUtilsProvider
                                                  utils={MomentUtils}
                                                  moment={moment}
                                                >
                                                  <DatePicker
                                                    keyboard
                                                    disableOpenOnEnter
                                                    placeholder='DD/MM/YYYY'
                                                    invalidDateMessage='Formato inválido'
                                                    mask={(value) =>
                                                      // handle clearing outside if value can be changed outside of the component
                                                      value
                                                        ? [
                                                            /\d/,
                                                            /\d/,
                                                            '/',
                                                            /\d/,
                                                            /\d/,
                                                            '/',
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                          ]
                                                        : []
                                                    }
                                                    locale='es-ES'
                                                    animateYearScrolling={false}
                                                    onInputChange={(e) => {
                                                      let value =
                                                        e.target.value;
                                                      value = value.replace(
                                                        /_/g,
                                                        ''
                                                      );
                                                      if (value.length === 10) {
                                                        this.handleArrayCheckDateChange(
                                                          moment(
                                                            value,
                                                            'DD/MM/YYYY'
                                                          ),
                                                          index
                                                        );
                                                      }
                                                    }}
                                                    margin='normal'
                                                    label='Fecha de vencimiento'
                                                    className='home-input'
                                                    value={
                                                      item.selectedCheckDate
                                                    }
                                                    onChange={(date) => {
                                                      this.handleArrayCheckDateChange(
                                                        date,
                                                        index
                                                      );
                                                    }}
                                                    fullWidth
                                                    format='DD/MM/YYYY'
                                                    keyboardIcon={
                                                      <img
                                                        src={calendaricon}
                                                        alt='icono calendario'
                                                        className='calendar-icon'
                                                      />
                                                    }
                                                  />
                                                </MuiPickersUtilsProvider>
                                              </Col>
                                              <Col
                                                xs={12}
                                                md={6}
                                                className='login-input-container'
                                              >
                                                <TextField
                                                  id={`check-value-${index}`}
                                                  label='Valor'
                                                  className='home-input'
                                                  margin='dense'
                                                  value={item.checkValueMask}
                                                  onChange={(e) => {
                                                    this.onArrayCheckValueChange(
                                                      e,
                                                      index
                                                    );
                                                  }}
                                                  error={this.isValueError(
                                                    checkValue
                                                  )}
                                                  helperText={
                                                    this.isValueError(
                                                      checkValue
                                                    )
                                                      ? 'El monto de la transacción no puede ser mayor al cupo disponible.'
                                                      : null
                                                  }
                                                  InputProps={{
                                                    startAdornment: (
                                                      <InputAdornment position='start'>
                                                        $
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  fullWidth
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Col>
                                    );
                                  })}
                                <Col xs={12} className='add-check-container'>
                                  <span
                                    className='add-check'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.addCheck();
                                    }}
                                  >
                                    + Cheques
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          <Col
                            xs={12}
                            md={{ span: 6, offset: 3 }}
                            className='button-send-container'
                          >
                            <Button
                              className='button-send'
                              variant='success'
                              disabled={
                                !(
                                  !this.isValueError(checkValue) &&
                                  (activity ? this.isStudentValid() : true) &&
                                  (checkType === 'P'
                                    ? this.isCheckArrayValid() &&
                                      selectedCheckDate
                                    : true) &&
                                  bankSelected !== '' &&
                                  checkType !== '' &&
                                  accountNumber !== '' &&
                                  checkValue !== ''
                                )
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.sendDayCheck();
                              }}
                              block
                            >
                              Realizar transacción
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {individualSucces && (
                      <Col xs={12}>
                        <Row>
                          <Col
                            xs={12}
                            md={{ span: 4, offset: 4 }}
                            className='reservation-loading-text'
                          >
                            <img
                              src={aproved}
                              alt='icono reservas'
                              className='resevation-icon'
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={{ span: 6, offset: 3 }}
                            className='reserve-aproved-title'
                          >
                            ¡TRANSACCIÓN EXITOSA!
                          </Col>
                          <Col
                            xs={12}
                            md={{ span: 6, offset: 3 }}
                            className='not-reservation-text'
                          >
                            Su código de aprobación es{' '}
                            <span className='not-reservation-text_bold'>
                              {approvalNumber}
                            </span>
                            . Muchas gracias por utilizar los servicios de{' '}
                            <span className='not-reservation-text_bold'>
                              Covifactura
                            </span>
                            .
                          </Col>
                          <Col
                            xs={12}
                            md={{ span: 4, offset: 4 }}
                            className='reserve-aproved-button-container'
                          >
                            <Button
                              className='button-send'
                              variant='success'
                              onClick={(e) => {
                                e.preventDefault();
                                this.goHome();
                              }}
                              block
                            >
                              Ir a inicio
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {individualError && (
                      <Col xs={12}>
                        <Row>
                          <Col
                            xs={12}
                            md={{ span: 4, offset: 4 }}
                            className='reservation-loading-text'
                          >
                            <img
                              src={denegate}
                              alt='icono reservas'
                              className='resevation-icon-denegate'
                            />
                          </Col>
                          {individualErrorNumber === 3 && (
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className='reserve-aproved-title'
                            >
                              ¡DATOS INVALIDOS!
                            </Col>
                          )}
                          {individualErrorNumber !== 3 && (
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className='reserve-aproved-title'
                            >
                              ¡TRANSACCIÓN NEGADA!
                            </Col>
                          )}
                          {individualErrorNumber === 3 && errorMessage ? (
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className='not-reservation-text'
                            >
                              {errorMessage}
                            </Col>
                          ) : (
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className='not-reservation-text'
                            >
                              Por favor comuniquese con nuestras líneas de
                              atencion en{' '}
                              <span className='not-reservation-text_bold'>
                                Bogotá 353 4324
                              </span>{' '}
                              o nuestra{' '}
                              <span className='not-reservation-text_bold'>
                                línea nacional 01 8000 115080
                              </span>
                            </Col>
                          )}

                          <Col
                            xs={12}
                            md={{ span: 4, offset: 4 }}
                            className='reserve-aproved-button-container'
                          >
                            <Button
                              className='button-send'
                              variant='success'
                              onClick={(e) => {
                                e.preventDefault();
                                this.goHome();
                              }}
                              block
                            >
                              Ir a inicio
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {individual && !individualSucces && !individualError && (
                      <Col xs={12}>
                        <Row>
                          <Col xs={12} md={6} className='login-input-container'>
                            <TextField
                              id='invoiceValue'
                              label='Valor factura'
                              className='home-input'
                              margin='dense'
                              value={invoiceValueMask}
                              onChange={this.onInvoiceValueChange}
                              error={this.isValueError(invoiceValue)}
                              helperText={
                                this.isValueError(invoiceValue)
                                  ? 'El monto de la transacción no puede ser mayor al cupo disponible.'
                                  : null
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                          </Col>
                          {threePayments && (
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='cellular'
                                label='Celular *'
                                className='home-input'
                                margin='dense'
                                type='number'
                                value={cellular}
                                inputProps={{ maxLength: 10 }}
                                onChange={(event) => {
                                  this.oninputChange(event, 'cellular');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                          )}
                          {!threePayments && (
                            <>
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='invoiceNumber'
                                  label='Número de factura'
                                  value={invoiceNumber}
                                  onChange={this.onInvoiceNumberChange}
                                  className='home-input'
                                  margin='dense'
                                  fullWidth
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container date-picker-container'
                              >
                                <MuiPickersUtilsProvider
                                  utils={MomentUtils}
                                  moment={moment}
                                >
                                  <DatePicker
                                    keyboard
                                    disableOpenOnEnter
                                    placeholder='DD/MM/YYYY'
                                    invalidDateMessage='Formato inválido'
                                    mask={(value) =>
                                      // handle clearing outside if value can be changed outside of the component
                                      value
                                        ? [
                                            /\d/,
                                            /\d/,
                                            '/',
                                            /\d/,
                                            /\d/,
                                            '/',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                          ]
                                        : []
                                    }
                                    locale='es-ES'
                                    animateYearScrolling={false}
                                    onInputChange={(e) => {
                                      let value = e.target.value;
                                      value = value.replace(/_/g, '');
                                      if (value.length === 10) {
                                        this.handleDateChange(
                                          moment(value, 'DD/MM/YYYY')
                                        );
                                      }
                                    }}
                                    minDate={moment(new Date())}
                                    maxDate={moment(new Date()).add(
                                      finalMaxDays,
                                      'days'
                                    )}
                                    margin='normal'
                                    label='Fecha de vencimiento'
                                    className='home-input'
                                    value={selectedDate}
                                    onChange={this.handleDateChange}
                                    fullWidth
                                    format='DD/MM/YYYY'
                                    keyboardIcon={
                                      <img
                                        src={calendaricon}
                                        alt='icono calendario'
                                        className='calendar-icon'
                                      />
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </Col>
                            </>
                          )}
                          <Col
                            xs={12}
                            md={{ span: 6, offset: 3 }}
                            className='button-send-container'
                          >
                            <Button
                              className='button-send'
                              variant='success'
                              disabled={
                                threePayments
                                  ? !(
                                      !this.isValueError(invoiceValue) &&
                                      invoiceValue !== '' &&
                                      cellular
                                    )
                                  : !(
                                      !this.isValueError(invoiceValue) &&
                                      invoiceValue !== '' &&
                                      invoiceNumber !== '' &&
                                      selectedDate
                                    ) || disabledSendButton
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.sendInvoice();
                              }}
                              block
                            >
                              Realizar transacción
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Col>
            </Col>
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { user, globals, careerList, familyList, cities } = state.user;
  let {
    loading,
    userQuota,
    transactionResponse,
    transactionError,
    transactionResponseError,
  } = state.transaction;

  return {
    loading,
    user,
    userQuota,
    globals,
    transactionResponse,
    transactionError,
    careerList,
    familyList,
    cities,
    transactionResponseError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
