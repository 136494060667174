import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';
import aproved from '../../../assets/icons/aproved.svg';


class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  getFormattedDocumentType = (documentType) => {
    let formattedDocumentType = documentType;
    if (documentType === 'C') {
        formattedDocumentType = 'CC';
    }
    if (documentType === 'E') {
        formattedDocumentType = 'CE';
    }
    return formattedDocumentType;
  }

  render() {
    const {
      show,
      onHide,
      newUser,
      updateModal,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Agregar usuario
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="reservation-loading-text">
                      <img src={aproved} alt="icono reservas" className="resevation-icon"/>
                    </Col>
                    <Col xs={12} className="reserve-aproved-title">
                      { !updateModal && (
                        '¡USUARIO CREADO!'
                      )}
                      { updateModal && (
                        '¡USUARIO ACTUALIZADO!'
                      )}
                    </Col>
                    { !updateModal && (
                      <Col xs={12} className="not-reservation-text">
                        El usuario identificado con <span className="not-reservation-text_bold">{newUser ? this.getFormattedDocumentType(newUser.documentType) : ''} {newUser ? newUser.documentNumber : ''}</span> fue creado satisfactoriamente, la información de acceso ya fue enviada al correo: <span className="not-reservation-text_bold">{newUser ? newUser.email : ''}</span>
                      </Col>
                    )}
                    { updateModal && (
                      <Col xs={12} className="not-reservation-text">
                        El usuario identificado con <span className="not-reservation-text_bold">{newUser ? this.getFormattedDocumentType(newUser.documentType) : ''} {newUser ? newUser.documentNumber : ''}</span> fue actualizado satisfactoriamente
                      </Col>
                    )}

                    <Col xs={12} md={{span: 4, offset: 4}} className="reserve-aproved-button-container">
                      <Button
                        className="button-send"
                        variant="success"
                        onClick={(e) => {
                            e.preventDefault();
                            this.cancel();
                        }}
                        block
                      >
                        Aceptar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  newUser: PropTypes.object,
  updateModal: PropTypes.bool,
};

ConfirmChange.defaultProps = {
  newUser: null,
  updateModal: false,
};

export default ConfirmChange;
