import config from '../../config';
import { authHeader } from '../../helpers';

export const sendTransaction = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userEmail,
  transactionType,
  invoiceNumber,
  expirationDate,
  invoiceValue,
  transactionsArray,
  bank,
  accountNumber,
  municipality,
  phone,
  cellular,
  address,
  queryCode,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let requiredParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&usuario=${userEmail}&tipo_transaccion=${transactionType}&num_titulovalor=${invoiceNumber}&fechavence=${expirationDate}&valor_transaccion=${invoiceValue}&StrJArray=${transactionsArray}`;
  let optionalParams = `&banco=${bank}&cuenta=${
    accountNumber ? accountNumber : expirationDate
  }&lista_municipio=${municipality}&telefono=${phone}&celular=${cellular}&direccion=${address}&codigo_consulta=${queryCode}`;
  let finalParams = `${requiredParams}${optionalParams}`;
  return fetch(
    `${config().apiUrl}/pr_transaccion?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const sendThreePaymentsTransaction = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userEmail,
  invoiceValue,
  queryCode,
  cellular,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let requiredParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&usuario=${userEmail}&tipo_transaccion=2&valor_cupo=${invoiceValue}`;
  let optionalParams = `&celular=${cellular}&codigo_consulta=${queryCode}`;
  let finalParams = `${requiredParams}${optionalParams}`;
  return fetch(
    `${config().apiUrl}/pr_solicitud_trespagos?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const sendMasiveTransaction = ({
  userDocument,
  userDocumentType,
  userEmail,
  file,
  correctionsArray,
  check,
}) => {
  const headers = authHeader();
  let data = new FormData();
  if (correctionsArray) {
    headers['Content-Type'] = 'application/json';
    data = correctionsArray;
  } else {
    data.append('file', file);
  }

  const requestOptions = {
    method: 'PUT',
    headers,
    body: data,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&usuario=${userEmail}&verificar=${check}`;
  return fetch(
    `${config().apiUrl}/pr_carga_masiva?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const exportMasiveTransaction = ({
  userDocument,
  userDocumentType,
  numberFiled,
  status,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&radicado=${numberFiled}&estado=${status}`;
  return fetch(
    `${config().apiUrl}/pr_exportar_carga_masiva?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const sendMasiveTransactionEmail = ({
  userDocument,
  userDocumentType,
  userEmail,
  file,
}) => {
  const headers = authHeader();
  const data = new FormData();
  data.append('file', file);
  const requestOptions = {
    method: 'POST',
    headers,
    body: data,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&usuario=${userEmail}`;
  return fetch(
    `${config().apiUrl}/pr_carga_masiva_correo?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const updateStudent = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  studentDocument,
  studentDocumentType,
  userEmail,
  firstName,
  secondName,
  lastName,
  secondLastName,
  studentRelationship,
  studentCode,
  semester,
  career,
  approvalNumber,
  queryCode,
  studentPhone,
  studentEmail,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let requiredParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&usuario=${userEmail}&estudiante_celular=${studentPhone}&estudiante_mail=${studentEmail}`;
  let studentParams = `&estudiante_tipo_documento=${studentDocumentType}&estudiante_documento=${studentDocument}&estudiante_nombre_1=${firstName}&estudiante_nombre_2=${secondName}&estudiante_apellido_1=${lastName}&estudiante_apellido_2=${secondLastName}&semestre=${semester}&carrera=${career}&autorizacion=${approvalNumber}&codigo=${studentCode}&parentesco=${studentRelationship}&codigo_consulta=${queryCode}`;
  let finalParams = `${requiredParams}${studentParams}`;
  return fetch(
    `${config().apiUrl}/pr_estudiante?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getQuota = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  queryCode,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&codigo_consulta=${queryCode}`;
  return fetch(`${config().apiUrl}/pr_cupo?${finalParams}`, requestOptions).then(
    handleResponse
  );
};

export const getSellersReport = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  page,
  sellerEmail,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&usuario_documento=${documentNumber}&usuario_tipo_documento=${documentType}&pagina=${page}&registros=5${
    sellerEmail ? '&vendedor_usuario=' + sellerEmail : '&vendedor_usuario=0'
  }`;
  return fetch(
    `${config().apiUrl}/pr_vendedores?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const exportSellersReport = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  sellerEmail,
  consolidated,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let uri = consolidated
    ? 'pr_exportar_vendedores'
    : 'pr_exportar_vendedores_detalle';
  console.log('uri', uri);

  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&usuario_documento=${documentNumber}&usuario_tipo_documento=${documentType}${
    sellerEmail ? '&vendedor_usuario=' + sellerEmail : '&vendedor_usuario=0'
  }`;
  return fetch(`${config().apiUrl}/${uri}?${finalParams}`, requestOptions).then(
    handleResponse
  );
};

export const exportQuotaReport = ({ documentType, documentNumber }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}`;
  return fetch(
    `${config().apiUrl}/pr_exportar_cupo?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const getTransactionReport = ({
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  initialDate,
  finalDate,
  page,
  productNumber,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&comprador_tipo_documento=${
    sellerDocumentType ? sellerDocumentType : 'C'
  }&comprador_documento=${
    sellerDocumentNumber ? sellerDocumentNumber : 0
  }&fecha_inicial=${initialDate}&fecha_final=${finalDate}&pagina=${page}&registros=10&titulo_valor=${
    productNumber ? productNumber : 0
  }`;
  return fetch(
    `${config().apiUrl}/pr_reporte_transacciones?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const validateQueryCode = ({
  documentType,
  documentNumber,
  queryCode,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&codigo_consulta=${queryCode}`;
  return fetch(
    `${config().apiUrl}/pr_validar_codigo_consulta?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

export const exportTransactionReport = ({
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  initialDate,
  finalDate,
  productNumber,
}) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais =
    'PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]
      ? globals.PAIS[0].ID_PAIS
      : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&comprador_tipo_documento=${
    sellerDocumentType ? sellerDocumentType : 'C'
  }&comprador_documento=${
    sellerDocumentNumber ? sellerDocumentNumber : 0
  }&fecha_inicial=${initialDate}&fecha_final=${finalDate}&titulo_valor=${
    productNumber ? productNumber : 0
  }`;
  return fetch(
    `${config().apiUrl}/pr_exportar_reporte_transacciones?${finalParams}`,
    requestOptions
  ).then(handleResponse);
};

function handleResponse(response) {
  if (
    response.headers.get('content-type').indexOf('application/ms-excel') !== -1
  ) {
    return response.blob().then((blob) => {
      return blob;
    });
  }
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false;
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
