import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReduxLazyScroll from "redux-lazy-scroll";

import "./claim.scss";
import * as actions from "../../redux/actions";
import { Col, Button, Row, Table } from "react-bootstrap";
import Footer from "../footer";
import Header from "../header";
import Menu from "../menu";
import Chat from "../chat";
import UserHeader from "../user-header";
import arrowback from "../../assets/icons/arrowback.svg";
import arrowgreen from "../../assets/icons/arrowGreen.svg";
import reservesearch from "../../assets/icons/reserve-search.svg";
import denegate from "../../assets/icons/denegate.svg";
import aproved from "../../assets/icons/aproved.svg";
import searchicon from "../../assets/icons/searchicon.svg";
import papersicon from "../../assets/icons/papersicon.svg";
import checkicon from "../../assets/icons/checkicon.svg";
import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import ConfirmIndividualNotice from "./confirm-individual-notice";
import ConfirmNotice from "./confirm-notice";
import ConfirmIndividualRaise from "./confirm-individual-raise";
import ConfirmIndividualExoneration from "./confirm-individual-exoneration";
import ConfirmRaise from "./confirm-raise";
import ConfirmClaim from "./confirm-claim";
import moment from "moment";
import Loading from "../loading";
import ListLoader from "../list-loader";
import TotalModal from "../exoneration/total-modal";
import PartialModal from "../exoneration/partial-modal";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import calendaricon from "../../assets/icons/calendaricon.svg";

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: green[600],
    "&$checked": {
      color: green[500],
    },
  },
  checked: {},
};

const searchSelect = [
  { id: "document", value: "Documento" },
  { id: "invoice", value: "Factura" },
  { id: "date", value: "Fecha" },
];

class Claim extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      notice: 1,
      noticeDetail: false,
      noticeError: false,
      noticeSucces: false,
      raiseNoticeDetail: false,
      raiseNoticeError: false,
      claimError: false,
      claimSucces: false,
      confirmIndividualNotice: false,
      confirmIndividualRaise: false,
      confirmIndividualExoneration: false,
      confirmNotice: false,
      confirmRaise: false,
      confirmClaim: false,
      claimUpload: false,
      claimUploadSuccess: true,
      documentType: "",
      documentNumber: "",
      productNumber: "",
      raiseDocumentType: "",
      raiseDocumentNumber: "",
      raiseProductNumber: "",
      exonerationSearchSelect: "document",
      noticeSearchSelect: "document",
      exonerationDocumentType: "",
      exonerationDocumentNumber: "",
      exonerationProductNumber: "",
      exonerationDetail: false,
      exonerationError: false,
      exonerationSucces: false,
      userName: "",
      raiseUserName: "",
      getGuaranteSelected: {},
      getRaiseGuaranteSelected: {},
      getExonerationGuaranteSelected: {},
      initialDate: null,
      finalDate: null,
      exonerationInitialDate: null,
      exonerationFinalDate: null,
      raiseInitialDate: null,
      raiseFinalDate: null,
      totalModal: false,
      partialExoneration: null,
      partialModal: false,
    };
    this.page = 0;
    this.raisePage = 0;
    this.exonerationPage = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  goHome = () => {
    const {
      noticeDetail,
      notice,
      raiseNoticeDetail,
      exonerationDetail,
    } = this.state;
    const {
      notification,
      notificationsNotRegistered,
      raiseNotification,
      raiseNotificationsNotRegistered,
      exonerationsNotRegistered,
      exoneration,
    } = this.props;
    if (
      (noticeDetail && notice === 1) ||
      notification ||
      notificationsNotRegistered
    ) {
      this.setState(
        {
          documentType: "",
          documentNumber: "",
          productNumber: "",
          initialDate: null,
          finalDate: null,
          userName: "",
          getGuaranteSelected: {},
        },
        () => {
          this.page = 0;
          this.props.actions.resetGuaranteesToNotify();
        }
      );
    } else if (
      (raiseNoticeDetail && notice === 3) ||
      raiseNotification ||
      raiseNotificationsNotRegistered
    ) {
      this.setState(
        {
          raiseDocumentType: "",
          raiseDocumentNumber: "",
          raiseProductNumber: "",
          raiseInitialDate: null,
          raiseFinalDate: null,
          raiseUserName: "",
          getRaiseGuaranteSelected: {},
        },
        () => {
          this.raisePage = 0;
          this.props.actions.resetGuaranteesToNotify();
        }
      );
    } else if (
      (exonerationDetail && notice === 4) ||
      exoneration ||
      exonerationsNotRegistered
    ) {
      this.setState(
        {
          exonerationDocumentType: "",
          exonerationDocumentNumber: "",
          exonerationProductNumber: "",
          exonerationInitialDate: null,
          exonerationFinalDate: null,
          getExonerationGuaranteSelected: {},
        },
        () => {
          this.exonerationPage = 0;
          this.props.actions.resetGuaranteesToExonerate();
        }
      );
    } else {
      this.props.history.push(`/`);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
    this.setState(
      {
        documentType: "",
        documentNumber: "",
        productNumber: "",
        initialDate: null,
        finalDate: null,
        userName: "",
        getGuaranteSelected: {},
        raiseDocumentType: "",
        raiseDocumentNumber: "",
        raiseProductNumber: "",
        exonerationDocumentType: "",
        exonerationDocumentNumber: "",
        exonerationProductNumber: "",
        raiseInitialDate: null,
        raiseFinalDate: null,
        raiseUserName: "",
        getRaiseGuaranteSelected: {},
      },
      () => {
        this.props.actions.resetGuaranteesToNotify();
        this.props.actions.resetGuaranteesToExonerate();
      }
    );
  }

  newSearch = () => {
    this.page = 0;
    this.getGuaranteesToNotify();
  };

  newRaiseSearch = () => {
    this.raisePage = 0;
    this.getGuaranteesToRaise();
  };

  newExonerationSearch = () => {
    this.exonerationPage = 0;
    this.getGuaranteesToExonerate();
  };

  getGuaranteesToNotify = () => {
    const {
      documentType,
      documentNumber,
      productNumber,
      initialDate,
      finalDate,
    } = this.state;
    const { user } = this.props;
    this.page = this.page + 1;
    this.props.actions.getGuaranteesToNotify({
      documentType: documentType === "" ? "C" : documentType,
      documentNumber: documentNumber === "" ? 0 : documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      page: this.page,
      invoiceNumber: productNumber ? productNumber : 0,
      initialDate: initialDate
        ? moment(initialDate).format("YYYYMMDD")
        : "19000101",
      finalDate: finalDate ? moment(finalDate).format("YYYYMMDD") : "19000101",
    });
  };

  getGuaranteesToRaise = () => {
    const {
      raiseDocumentType,
      raiseDocumentNumber,
      raiseProductNumber,
    } = this.state;
    const { user } = this.props;
    this.raisePage = this.raisePage + 1;
    this.props.actions.getGuaranteesToRaise({
      documentType: raiseDocumentType === "" ? "C" : raiseDocumentType,
      documentNumber: raiseDocumentNumber === "" ? 0 : raiseDocumentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      page: this.raisePage,
      invoiceNumber: raiseProductNumber ? raiseProductNumber : 0,
      // initialDate: initialDate ? moment(initialDate).format('DD/MM/YYYY') : '00/00/0000',
      // finalDate: finalDate ? moment(finalDate).format('DD/MM/YYYY') : '00/00/0000'
    });
  };

  getGuaranteesToExonerate = () => {
    const {
      exonerationDocumentType,
      exonerationDocumentNumber,
      exonerationProductNumber,
      exonerationInitialDate,
      exonerationFinalDate,
    } = this.state;
    const { user } = this.props;
    this.exonerationPage = this.exonerationPage + 1;
    this.props.actions.getGuaranteesToExonerate({
      documentType:
        exonerationDocumentType === "" ? "C" : exonerationDocumentType,
      documentNumber:
        exonerationDocumentNumber === "" ? 0 : exonerationDocumentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      page: this.exonerationPage,
      invoiceNumber: exonerationProductNumber ? exonerationProductNumber : 0,
      initialDate: exonerationInitialDate
        ? moment(exonerationInitialDate).format("YYYYMMDD")
        : "19000101",
      finalDate: exonerationFinalDate
        ? moment(exonerationFinalDate).format("YYYYMMDD")
        : "19000101",
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications) {
      this.setState({
        noticeDetail:
          this.state.documentNumber !== "" ||
          this.state.initialDate ||
          this.state.productNumber !== "",
      });
    }
    if (nextProps.exonerations) {
      this.setState({
        exonerationDetail:
          this.state.exonerationDocumentNumber !== "" ||
          this.state.exonerationInitialDate ||
          this.state.exonerationProductNumber !== "",
      });
    }
    if (nextProps.raiseNotifications) {
      if (this.state.raiseProductNumber === "") {
        this.setState({
          raiseNoticeDetail:
            this.state.raiseDocumentNumber !== ""
              ? nextProps.raiseNotifications.length > 0
              : false,
          raiseUserName:
            this.state.raiseDocumentNumber !== ""
              ? nextProps.raiseNotifications.length > 0
                ? nextProps.raiseNotifications[0].NOMBRE
                : ""
              : "",
        });
      }
    }
  }

  scrollToMyRef = () => {
    if (this.state.notice === 1 && !this.props.notificationsNotRegistered) {
      let myDiv = document.getElementsByClassName("redux-lazy-scroll");
      myDiv[0].scrollTop = 0;
    }
    if (
      this.state.notice === 3 &&
      !this.props.raiseNotificationsNotRegistered
    ) {
      let myDiv = document.getElementsByClassName("redux-lazy-scroll");
      myDiv[0].scrollTop = 0;
    }
  };

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  changeTab = (tab) => {
    this.setState({
      notice: tab,
    });
  };

  sendIndividualNotice = (guarante) => {
    this.setState({
      individualNotice: guarante,
      confirmIndividualNotice: true,
    });
  };

  sendIndividualRaise = (guarante) => {
    this.setState({
      individualRaise: guarante,
      confirmIndividualRaise: true,
    });
  };

  sendIndividualExoneration = (guarante) => {
    this.setState({
      individualExoneration: guarante,
      confirmIndividualExoneration: true,
    });
  };

  confirmIndividualNotice = (individualNotice) => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "enviar_avisonopago_individual", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { user } = this.props;
    this.setState({
      confirmIndividualNotice: false,
    });
    this.props.actions.notifyGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      notifyArray: JSON.stringify([
        {
          tipo_documento: individualNotice.TIPO_DOCUMENTO,
          documento: individualNotice.DOCUMENTO,
          autorizacion: individualNotice.AUTORIZACION,
          titulo_valor: individualNotice.TITULO_VALOR,
          valor_garantizado: individualNotice.VALOR_GARANTIZADO,
        },
      ]),
    });
  };

  confirmIndividualRaise = (individualRaise) => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "levantar_aviso_individual", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { user } = this.props;
    this.setState({
      confirmIndividualRaise: false,
    });
    this.props.actions.raiseGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      notifyArray: JSON.stringify([
        {
          tipo_documento: individualRaise.TIPO_DOCUMENTO,
          documento: individualRaise.DOCUMENTO,
          autorizacion: individualRaise.AUTORIZACION,
          titulo_valor: individualRaise.TITULO_VALOR,
          valor_garantizado: individualRaise.VALOR_GARANTIZADO,
        },
      ]),
    });
  };

  confirmIndividualNoticeClose = () => {
    this.setState({
      confirmIndividualNotice: false,
    });
  };

  confirmIndividualRaiseClose = () => {
    this.setState({
      confirmIndividualRaise: false,
    });
  };

  confirmIndividualExonerationClose = () => {
    this.setState({
      confirmIndividualExoneration: false,
    });
  };

  sendNotice = () => {
    this.setState({
      confirmNotice: true,
    });
  };

  sendRaise = () => {
    this.setState({
      confirmRaise: true,
    });
  };

  confirmNotice = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "enviar_aviso_multiple", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { user } = this.props;
    const { getGuaranteSelected } = this.state;
    this.setState({
      confirmNotice: false,
    });
    let notifyArray = [];
    for (let key in getGuaranteSelected) {
      if (getGuaranteSelected.hasOwnProperty(key)) {
        notifyArray.push({
          tipo_documento: getGuaranteSelected[key].TIPO_DOCUMENTO,
          documento: getGuaranteSelected[key].DOCUMENTO,
          autorizacion: getGuaranteSelected[key].AUTORIZACION,
          titulo_valor: getGuaranteSelected[key].TITULO_VALOR,
          valor_garantizado: getGuaranteSelected[key].VALOR_GARANTIZADO,
        });
      }
    }
    this.props.actions.notifyGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      notifyArray: JSON.stringify(notifyArray),
    });
  };

  confirmRaise = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "levantar_aviso_multiple", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { user } = this.props;
    const { getRaiseGuaranteSelected } = this.state;
    this.setState({
      confirmRaise: false,
    });
    let notifyArray = [];
    for (let key in getRaiseGuaranteSelected) {
      if (getRaiseGuaranteSelected.hasOwnProperty(key)) {
        notifyArray.push({
          tipo_documento: getRaiseGuaranteSelected[key].TIPO_DOCUMENTO,
          documento: getRaiseGuaranteSelected[key].DOCUMENTO,
          autorizacion: getRaiseGuaranteSelected[key].AUTORIZACION,
          titulo_valor: getRaiseGuaranteSelected[key].TITULO_VALOR,
          valor_garantizado: getRaiseGuaranteSelected[key].VALOR_GARANTIZADO,
        });
      }
    }
    this.props.actions.raiseGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      notifyArray: JSON.stringify(notifyArray),
    });
  };

  confirmNoticeClose = () => {
    this.setState({
      confirmNotice: false,
    });
  };

  confirmRaiseClose = () => {
    this.setState({
      confirmRaise: false,
    });
  };

  confirmClaimClose = () => {
    this.setState({
      confirmClaim: false,
    });
  };

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  checkChange = (name, item) => (event) => {
    let getGuaranteSelected = Object.assign({}, this.state.getGuaranteSelected);
    if (name in getGuaranteSelected && !event.target.checked) {
      delete getGuaranteSelected[name];
    } else {
      getGuaranteSelected[name] = item;
    }
    this.setState({ getGuaranteSelected });
  };

  checkExonerationChange = (name, item) => (event) => {
    let getExonerationGuaranteSelected = Object.assign(
      {},
      this.state.getExonerationGuaranteSelected
    );
    if (name in getExonerationGuaranteSelected && !event.target.checked) {
      delete getExonerationGuaranteSelected[name];
    } else {
      getExonerationGuaranteSelected[name] = item;
    }
    this.setState({ getExonerationGuaranteSelected });
  };

  checkRaiseChange = (name, item) => (event) => {
    let getRaiseGuaranteSelected = Object.assign(
      {},
      this.state.getRaiseGuaranteSelected
    );
    if (name in getRaiseGuaranteSelected && !event.target.checked) {
      delete getRaiseGuaranteSelected[name];
    } else {
      getRaiseGuaranteSelected[name] = item;
    }
    this.setState({ getRaiseGuaranteSelected });
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getDocumentTypeName() {
    const { globals } = this.props;
    const { documentType } = this.state;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item) => {
      return item.ID === documentType;
    });
    return docuemntItem ? docuemntItem.LABEL : "";
  }

  getRaiseDocumentTypeName() {
    const { globals } = this.props;
    const { raiseDocumentType } = this.state;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item) => {
      return item.ID === raiseDocumentType;
    });
    return docuemntItem ? docuemntItem.LABEL : "";
  }

  getExonerationDocumentTypeName() {
    const { globals } = this.props;
    const { exonerationDocumentType } = this.state;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item) => {
      return item.ID === exonerationDocumentType;
    });
    return docuemntItem ? docuemntItem.LABEL : "";
  }

  handleDateChange = (date, dateKey) => {
    this.setState({ [dateKey]: date });
  };

  handleRaiseDateFromChange = (date) => {
    this.setState({ raiseInitialDate: date }, () => {
      if (this.state.raiseFinalDate !== null) {
        this.raisePage = 0;
        this.getGuaranteesToRaise();
      }
    });
  };

  handleRaiseDateToChange = (date) => {
    this.setState({ raiseFinalDate: date }, () => {
      if (this.state.raiseInitialDate !== null) {
        this.raisePage = 0;
        this.getGuaranteesToRaise();
      }
    });
  };

  sendTotalExoneration = () => {
    this.setState({
      totalModal: true,
    });
  };

  sendPartialExoneration = (guarante) => {
    this.setState({
      partialExoneration: guarante,
      partialModal: true,
    });
  };

  totalModalClose = () => {
    this.setState({
      totalModal: false,
    });
  };

  partialModalClose = () => {
    this.setState({
      partialExoneration: null,
      partialModal: false,
    });
  };

  confirmTotalExoneration = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "envio_exoneracion_total", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { getExonerationGuaranteSelected } = this.state;
    this.setState({
      totalModal: false,
    });
    let exonerateArray = [];
    for (let key in getExonerationGuaranteSelected) {
      if (getExonerationGuaranteSelected.hasOwnProperty(key)) {
        exonerateArray.push({
          tipo_documento: getExonerationGuaranteSelected[key].TIPO_DOCUMENTO,
          documento: getExonerationGuaranteSelected[key].DOCUMENTO,
          autorizacion: getExonerationGuaranteSelected[key].AUTORIZACION,
          titulo_valor: getExonerationGuaranteSelected[key].TITULO_VALOR,
          valor_a_exonerar:
            getExonerationGuaranteSelected[key].VALOR_A_EXONERAR,
        });
      }
    }
    this.confirmTotalExonerationAction(exonerateArray);
  };

  confirmIndividualExoneration = (individualRaise) => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "envio_exoneracion_individual", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    this.setState({
      confirmIndividualExoneration: false,
    });

    let exonerateArray = [];
    exonerateArray.push({
      tipo_documento: individualRaise.TIPO_DOCUMENTO,
      documento: individualRaise.DOCUMENTO,
      autorizacion: individualRaise.AUTORIZACION,
      titulo_valor: individualRaise.TITULO_VALOR,
      valor_a_exonerar: individualRaise.VALOR_A_EXONERAR,
    });

    this.confirmTotalExonerationAction(exonerateArray);
  };

  confirmPartialExoneration = (valueToExonerate, valueToClear) => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    window.gtag("event", "envio_exoneracion_parcial", {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const { partialExoneration } = this.state;
    this.setState({
      partialModal: false,
      [valueToClear]: null,
    });
    this.confirmTotalExonerationAction([
      {
        tipo_documento: partialExoneration.TIPO_DOCUMENTO,
        documento: partialExoneration.DOCUMENTO,
        autorizacion: partialExoneration.AUTORIZACION,
        titulo_valor: partialExoneration.TITULO_VALOR,
        valor_a_exonerar: valueToExonerate,
      },
    ]);
  };

  confirmTotalExonerationAction = (exonerateArray) => {
    const { user } = this.props;
    this.props.actions.exonerateGuarante({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      exonerateArray: JSON.stringify(exonerateArray),
    });
  };

  onExonerationValueChange = (index, event, valueToPay) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, "");
    inputValue = inputValue.replace(/\./g, "");
    inputValue = inputValue.replace(/´/g, "");
    let exonerationValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    this.setState({
      [`exonerationValue${index}`]: exonerationValue,
      [`exonerationValueMask${index}`]: thousand,
    });
    if (parseInt(exonerationValue) > parseInt(valueToPay)) {
      this.setState({
        [`exonerationValueErrorDisabled${index}`]: true,
      });
    } else {
      this.setState({
        [`exonerationValueErrorDisabled${index}`]: false,
        [`exonerationError${index}`]: false,
      });
    }
    this.showValueError(index, exonerationValue, valueToPay);
  };

  showValueError = (index, inputValue, valueToPay) => {
    if (parseInt(inputValue) > parseInt(valueToPay)) {
      this.setState({
        [`exonerationValueError${index}`]: true,
      });
    } else {
      this.setState({
        [`exonerationValueError${index}`]: false,
      });
    }
  };

  isIndexExonerationValue = (index) => {
    let partialValue = this.state[`exonerationValue${index}`];
    return !!partialValue;
  };

  renderExonerationSearchSelect = () => {
    const {
      exonerationSearchSelect,
      exonerationDocumentType,
      exonerationDocumentNumber,
      exonerationInitialDate,
      exonerationFinalDate,
      exonerationProductNumber,
    } = this.state;
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let searchForm = "";
    switch (exonerationSearchSelect) {
      case "invoice":
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col>
                <TextField
                  id="exonerationProductNumber"
                  label="Buscar número de factura"
                  className="home-input"
                  margin="dense"
                  value={exonerationProductNumber}
                  onChange={(event) => {
                    this.oninputChange(event, "exonerationProductNumber");
                  }}
                  InputProps={{
                    endAdornment: (
                      <img
                        src={searchicon}
                        alt="icono calendario"
                        className="calendar-icon"
                      />
                    ),
                  }}
                  fullWidth
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={exonerationProductNumber === ""}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        exonerationDocumentType: "",
                        exonerationDocumentNumber: "",
                        exonerationInitialDate: null,
                        exonerationFinalDate: null,
                        getExonerationGuaranteSelected: {},
                      },
                      () => {
                        this.props.actions.resetGuaranteesToExonerate();
                        this.scrollToMyRef();
                        this.newExonerationSearch();
                      }
                    );
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        );
        break;
      case "date":
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col xs={12} md={4} className="exoneraton-date-boder">
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                    margin="normal"
                    label="Desde"
                    className="home-input input-date"
                    value={exonerationInitialDate}
                    locale="es-ES"
                    onChange={(date) => {
                      this.handleDateChange(date, "exonerationInitialDate");
                    }}
                    fullWidth
                    format="DD/MM/YYYY"
                    keyboardIcon={
                      <img
                        src={calendaricon}
                        alt="icono calendario"
                        className="calendar-icon"
                      />
                    }
                    keyboard
                    disableOpenOnEnter
                    placeholder="DD/MM/YYYY"
                    invalidDateMessage="Formato inválido"
                    mask={(value) =>
                      // handle clearing outside if value can be changed outside of the component
                      value
                        ? [
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                        : []
                    }
                    onInputChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, "");
                      if (value.length === 10) {
                        this.handleDateChange(
                          moment(value, "DD/MM/YYYY"),
                          "exonerationInitialDate"
                        );
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                    margin="normal"
                    label="Hasta"
                    className="home-input input-date"
                    value={exonerationFinalDate}
                    locale="es-ES"
                    onChange={(date) => {
                      this.handleDateChange(date, "exonerationFinalDate");
                    }}
                    fullWidth
                    format="DD/MM/YYYY"
                    keyboardIcon={
                      <img
                        src={calendaricon}
                        alt="icono calendario"
                        className="calendar-icon"
                      />
                    }
                    keyboard
                    disableOpenOnEnter
                    placeholder="DD/MM/YYYY"
                    invalidDateMessage="Formato inválido"
                    mask={(value) =>
                      // handle clearing outside if value can be changed outside of the component
                      value
                        ? [
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                        : []
                    }
                    onInputChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, "");
                      if (value.length === 10) {
                        this.handleDateChange(
                          moment(value, "DD/MM/YYYY"),
                          "exonerationFinalDate"
                        );
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={!(exonerationInitialDate && exonerationFinalDate)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        exonerationDocumentType: "",
                        exonerationDocumentNumber: "",
                        exonerationProductNumber: "",
                        getExonerationGuaranteSelected: {},
                      },
                      () => {
                        this.props.actions.resetGuaranteesToExonerate();
                        this.scrollToMyRef();
                        this.newExonerationSearch();
                      }
                    );
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        );
        break;
      case "document":
        searchForm = (
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="exonerationDocumentType"
                  select
                  label="Tipo de documento"
                  className="home-input"
                  margin="dense"
                  value={exonerationDocumentType}
                  onChange={(event) => {
                    this.oninputChange(event, "exonerationDocumentType");
                  }}
                  fullWidth
                >
                  {documents.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ID}>
                        {item.LABEL}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="exonerationDocumentNumber"
                  label="Número de documento"
                  className="home-input"
                  margin="dense"
                  type="number"
                  value={exonerationDocumentNumber}
                  onChange={(event) => {
                    this.oninputChange(event, "exonerationDocumentNumber");
                  }}
                  fullWidth
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={
                    exonerationDocumentType === "" ||
                    exonerationDocumentNumber === ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        exonerationInitialDate: null,
                        exonerationFinalDate: null,
                        exonerationProductNumber: "",
                        getExonerationGuaranteSelected: {},
                      },
                      () => {
                        this.props.actions.resetGuaranteesToExonerate();
                        this.scrollToMyRef();
                        this.newExonerationSearch();
                      }
                    );
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        );
        break;
      default:
    }
    return searchForm;
  };

  renderNoticeSearchSelect = () => {
    const {
      noticeSearchSelect,
      documentType,
      documentNumber,
      initialDate,
      finalDate,
      productNumber,
    } = this.state;
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let searchForm = "";
    switch (noticeSearchSelect) {
      case "invoice":
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col>
                <TextField
                  id="productNumber"
                  label="Buscar número de factura"
                  className="home-input"
                  margin="dense"
                  value={productNumber}
                  onChange={(event) => {
                    this.oninputChange(event, "productNumber");
                  }}
                  InputProps={{
                    endAdornment: (
                      <img
                        src={searchicon}
                        alt="icono calendario"
                        className="calendar-icon"
                      />
                    ),
                  }}
                  fullWidth
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={productNumber === ""}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        documentType: "",
                        documentNumber: "",
                        initialDate: null,
                        finalDate: null,
                        getGuaranteSelected: {},
                      },
                      () => {
                        this.props.actions.resetGuaranteesToNotify();
                        this.scrollToMyRef();
                        this.newSearch();
                      }
                    );
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        );
        break;
      case "date":
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col xs={12} md={4} className="exoneraton-date-boder">
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                    margin="normal"
                    label="Desde"
                    className="home-input input-date"
                    value={initialDate}
                    locale="es-ES"
                    onChange={(date) => {
                      this.handleDateChange(date, "initialDate");
                    }}
                    fullWidth
                    format="DD/MM/YYYY"
                    keyboardIcon={
                      <img
                        src={calendaricon}
                        alt="icono calendario"
                        className="calendar-icon"
                      />
                    }
                    keyboard
                    disableOpenOnEnter
                    placeholder="DD/MM/YYYY"
                    invalidDateMessage="Formato inválido"
                    mask={(value) =>
                      // handle clearing outside if value can be changed outside of the component
                      value
                        ? [
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                        : []
                    }
                    onInputChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, "");
                      if (value.length === 10) {
                        this.handleDateChange(
                          moment(value, "DD/MM/YYYY"),
                          "initialDate"
                        );
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                    margin="normal"
                    label="Hasta"
                    className="home-input input-date"
                    value={finalDate}
                    locale="es-ES"
                    onChange={(date) => {
                      this.handleDateChange(date, "finalDate");
                    }}
                    fullWidth
                    format="DD/MM/YYYY"
                    keyboardIcon={
                      <img
                        src={calendaricon}
                        alt="icono calendario"
                        className="calendar-icon"
                      />
                    }
                    keyboard
                    disableOpenOnEnter
                    placeholder="DD/MM/YYYY"
                    invalidDateMessage="Formato inválido"
                    mask={(value) =>
                      // handle clearing outside if value can be changed outside of the component
                      value
                        ? [
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                        : []
                    }
                    onInputChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/_/g, "");
                      if (value.length === 10) {
                        this.handleDateChange(
                          moment(value, "DD/MM/YYYY"),
                          "finalDate"
                        );
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={!(initialDate && finalDate)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        documentType: "",
                        documentNumber: "",
                        productNumber: "",
                        getGuaranteSelected: {},
                      },
                      () => {
                        this.props.actions.resetGuaranteesToNotify();
                        this.scrollToMyRef();
                        this.newSearch();
                      }
                    );
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        );
        break;
      case "document":
        searchForm = (
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="documentType"
                  select
                  label="Tipo de documento"
                  className="home-input"
                  margin="dense"
                  value={documentType}
                  onChange={(event) => {
                    this.oninputChange(event, "documentType");
                  }}
                  fullWidth
                >
                  {documents.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ID}>
                        {item.LABEL}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="documentNumber"
                  label="Número de documento"
                  className="home-input"
                  margin="dense"
                  type="number"
                  value={documentNumber}
                  onChange={(event) => {
                    this.oninputChange(event, "documentNumber");
                  }}
                  fullWidth
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={documentType === "" || documentNumber === ""}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        productNumber: "",
                        initialDate: null,
                        finalDate: null,
                        getGuaranteSelected: {},
                      },
                      () => {
                        this.props.actions.resetGuaranteesToNotify();
                        this.scrollToMyRef();
                        this.newSearch();
                      }
                    );
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        );
        break;
      default:
    }
    return searchForm;
  };

  render() {
    const {
      menuOpen,
      notice,
      noticeError,
      raiseNoticeError,
      noticeDetail,
      raiseNoticeDetail,
      confirmIndividualNotice,
      confirmIndividualRaise,
      confirmIndividualExoneration,
      confirmNotice,
      confirmRaise,
      claimError,
      claimSucces,
      confirmClaim,
      claimUpload,
      claimUploadSuccess,
      documentType,
      raiseDocumentType,
      documentNumber,
      raiseDocumentNumber,
      exonerationDocumentType,
      exonerationDocumentNumber,
      exonerationProductNumber,
      exonerationDetail,
      exonerationError,
      userName,
      raiseUserName,
      getGuaranteSelected,
      getRaiseGuaranteSelected,
      individualNotice,
      individualRaise,
      individualExoneration,
      // initialDate,
      // finalDate,
      productNumber,
      raiseProductNumber,
      getExonerationGuaranteSelected,
      totalModal,
      partialExoneration,
      partialModal,
      exonerationSearchSelect,
      noticeSearchSelect,
    } = this.state;
    const {
      classes,
      globals,
      loading,
      loadingPaginate,
      loadingRaise,
      loadingRaisePaginate,
      notifications,
      raiseNotifications,
      notificationsPageCount,
      notificationsNotRegistered,
      raiseNotificationsNotRegistered,
      raiseNotificationsPageCount,
      user,
      notification,
      raiseNotification,
      exoneration,
      exonerationsNotRegistered,
      exonerationLoadingPaginate,
      exonerationsPageCount,
      exonerations,
      exonerationLoading,
    } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    return (
      <div className="claim">
        <PartialModal
          show={partialModal}
          onHide={this.partialModalClose}
          partialExoneration={partialExoneration}
          confirmExoneration={this.confirmPartialExoneration}
        />
        <TotalModal
          show={totalModal}
          onHide={this.totalModalClose}
          exonerations={getExonerationGuaranteSelected}
          confirmExoneration={this.confirmTotalExoneration}
        />
        <ConfirmIndividualNotice
          show={confirmIndividualNotice}
          onHide={this.confirmIndividualNoticeClose}
          individualNotice={individualNotice}
          confirmIndividualNotice={this.confirmIndividualNotice}
        />
        <ConfirmNotice
          show={confirmNotice}
          onHide={this.confirmNoticeClose}
          notifications={getGuaranteSelected}
          confirmNotice={this.confirmNotice}
        />
        <ConfirmIndividualRaise
          show={confirmIndividualRaise}
          onHide={this.confirmIndividualRaiseClose}
          individualRaise={individualRaise}
          confirmIndividualRaise={this.confirmIndividualRaise}
        />
        <ConfirmIndividualExoneration
          show={confirmIndividualExoneration}
          onHide={this.confirmIndividualExonerationClose}
          individualExoneration={individualExoneration}
          confirmIndividualExoneration={this.confirmIndividualExoneration}
        />
        <ConfirmRaise
          show={confirmRaise}
          onHide={this.confirmRaiseClose}
          notifications={getRaiseGuaranteSelected}
          confirmRaise={this.confirmRaise}
        />
        <ConfirmClaim show={confirmClaim} onHide={this.confirmClaimClose} />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={7} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            {true && (
              <Col xs={12} className="general-main-card">
                {(loading || loadingRaise || exonerationLoading) && <Loading />}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt="arrow back"
                        className="goback-icon"
                      />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                {/*
                  <Col xs={12} className="claim-margin">
                  <Col xs={12} className="card-title-transaction">
                  Aviso de no pago
                  </Col>
                  </Col>
                  */}
                <Col xs={12}>
                  <Col xs={12} className="">
                    <Row>
                      <Col xs={12} className="tab-container">
                        <Row>
                          <Col
                            xs={6}
                            className={
                              notice === 1 ? "tab-text-selected" : "tab-text"
                            }
                            onClick={() => {
                              this.changeTab(1);
                            }}
                          >
                            Aviso de no pago
                          </Col>
                          {/*
                            <Col
                            xs={6}
                            className={notice === 2 ? 'tab-text-selected tab-check' : 'tab-text'}
                            onClick={ () => {
                            this.changeTab(2);
                            }}
                            >Reclamar</Col>
                            */}
                          {/*
                              <Col
                              xs={6}
                              className={notice === 3 ? 'tab-text-selected' : 'tab-text'}
                              onClick={ () => {
                              this.changeTab(3);
                              }}
                              >Levantar aviso</Col>
                              */}
                          <Col
                            xs={6}
                            className={
                              notice === 4 ? "tab-text-selected" : "tab-text"
                            }
                            onClick={() => {
                              this.changeTab(4);
                            }}
                          >
                            Exoneración
                          </Col>
                        </Row>
                      </Col>
                      {user && notice === 1 && !noticeError && !notification && (
                        <Col xs={12}>
                          <Row>
                            {/*
                              <Col xs={12} className="search-notice-icon-container">
                              <img src={reservesearch} alt="busqueda documento iconos" className="search-notice-icon" />
                              </Col>
                              <Col xs={12} md={{span: 6, offset:3 }} className="search-notice-text">Para realizar un aviso de no pago, ingrese el tipo y número de identificación y de clic en “Buscar”.</Col>
                              */}
                            <Col xs={12} className="claim-margin">
                              <Row className="search-notice-form">
                                <Col
                                  xs={12}
                                  md={3}
                                  className="login-input-container"
                                >
                                  <TextField
                                    id="noticeSearchSelect"
                                    select
                                    label="Filtro de busqueda"
                                    className="home-input"
                                    margin="dense"
                                    value={noticeSearchSelect}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        "noticeSearchSelect"
                                      );
                                    }}
                                    fullWidth
                                  >
                                    {searchSelect.map((item, index) => {
                                      return (
                                        <MenuItem key={index} value={item.id}>
                                          {item.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                </Col>
                                {this.renderNoticeSearchSelect()}
                              </Row>
                            </Col>
                            {!notificationsNotRegistered && (
                              <Col xs={12} className="results-table-container">
                                <ReduxLazyScroll
                                  isFetching={loadingPaginate}
                                  errorMessage=""
                                  isParentScrollable={true}
                                  parentHeight="250px"
                                  loadMore={() => {
                                    this.getGuaranteesToNotify();
                                  }}
                                  hasMore={notificationsPageCount > 0}
                                >
                                  <Table bordered responsive="sm">
                                    <thead>
                                      <tr>
                                        <th># Factura</th>
                                        <th>Tipo de d/mento</th>
                                        <th>Número documento</th>
                                        <th>Titular</th>
                                        <th>Fecha de vencimiento</th>
                                        <th>Días aviso</th>
                                        <th>Valor producto</th>
                                        {!noticeDetail && (
                                          <th className="notice-th">
                                            Aviso de no pago
                                          </th>
                                        )}
                                        {noticeDetail && (
                                          <th>Aviso de no pago individual</th>
                                        )}
                                        {noticeDetail && (
                                          <th>Aviso de no pago</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {notifications.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            {item.TIPO_TITULO === "FACTURA" && (
                                              <td>{item.TITULO_VALOR}</td>
                                            )}
                                            {item.TIPO_TITULO !== "FACTURA" && (
                                              <td className="notice-td-check">
                                                Cheque
                                              </td>
                                            )}
                                            <td>{item.TIPO_DOCUMENTO}</td>
                                            <td>{item.DOCUMENTO}</td>
                                            <td>{item.NOMBRE}</td>
                                            <td>
                                              {item.VENCIMIENTO === ""
                                                ? ""
                                                : moment(
                                                    item.VENCIMIENTO
                                                  ).format("DD/MM/YYYY")}
                                            </td>
                                            <td>{item.DIAS_MARCAR_AVISO}</td>
                                            <td>
                                              $
                                              {this.getNumber(
                                                item.VALOR_GARANTIZADO
                                              )}
                                            </td>
                                            <td
                                              className="notice-td"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.sendIndividualNotice(item);
                                              }}
                                            >
                                              <div className="notice-td-container">
                                                <span>Aviso de no pago</span>
                                                <img
                                                  src={arrowgreen}
                                                  alt="flecha verde"
                                                  className="link-arrow"
                                                />
                                              </div>
                                            </td>
                                            {noticeDetail && (
                                              <td align="center">
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={
                                                        index in
                                                        getGuaranteSelected
                                                      }
                                                      onChange={this.checkChange(
                                                        index,
                                                        item
                                                      )}
                                                      classes={{
                                                        root: classes.root,
                                                        checked:
                                                          classes.checked,
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              </td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                  {loadingPaginate && <ListLoader />}
                                </ReduxLazyScroll>
                              </Col>
                            )}
                            {noticeDetail && (
                              <Col xs={12} md={{ span: 4, offset: 2 }}>
                                <Button
                                  className="button-send"
                                  variant="success"
                                  disabled={
                                    Object.keys(getGuaranteSelected).length ===
                                    0
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.sendNotice();
                                  }}
                                  block
                                >
                                  Aviso total{" "}
                                  {Object.keys(getGuaranteSelected).length > 0
                                    ? `(${
                                        Object.keys(getGuaranteSelected).length
                                      })`
                                    : ""}
                                </Button>
                              </Col>
                            )}

                            {noticeDetail && (
                              <Col xs={12} md={4}>
                                <Button
                                  className="button-primary"
                                  variant="primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.goHome();
                                  }}
                                  block
                                >
                                  Limpiar
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      )}
                      {user &&
                        notice === 4 &&
                        !exonerationError &&
                        !exoneration && (
                          <Col xs={12}>
                            <Row>
                              <Col xs={12} className="claim-margin">
                                <Row className="search-notice-form">
                                  <Col
                                    xs={12}
                                    md={3}
                                    className="login-input-container"
                                  >
                                    <TextField
                                      id="exonerationSearchSelect"
                                      select
                                      label="Filtro de busqueda"
                                      className="home-input"
                                      margin="dense"
                                      value={exonerationSearchSelect}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          "exonerationSearchSelect"
                                        );
                                      }}
                                      fullWidth
                                    >
                                      {searchSelect.map((item, index) => {
                                        return (
                                          <MenuItem key={index} value={item.id}>
                                            {item.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                  </Col>
                                  {this.renderExonerationSearchSelect()}
                                </Row>
                              </Col>
                              {!exonerationsNotRegistered && (
                                <Col
                                  xs={12}
                                  className="results-table-container"
                                >
                                  <ReduxLazyScroll
                                    isFetching={exonerationLoadingPaginate}
                                    errorMessage=""
                                    isParentScrollable={true}
                                    parentHeight="250px"
                                    loadMore={() => {
                                      this.getGuaranteesToExonerate();
                                    }}
                                    hasMore={exonerationsPageCount > 0}
                                  >
                                    <Table bordered responsive="sm">
                                      <thead>
                                        <tr>
                                          <th># Factura</th>
                                          <th>Tipo de d/mento</th>
                                          <th>Número documento</th>
                                          <th>Titular</th>
                                          <th>Fecha de vencimiento</th>
                                          <th>Días exonerar</th>
                                          <th>Valor producto</th>
                                          <th className="notice-th">
                                            Exoneración parcial
                                          </th>
                                          <th className="notice-th">
                                            Exonerar
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {exonerations.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              {item.TIPO_TITULO ===
                                                "FACTURA" && (
                                                <td>{item.TITULO_VALOR}</td>
                                              )}
                                              {item.TIPO_TITULO !==
                                                "FACTURA" && (
                                                <td className="notice-td-check">
                                                  Cheque
                                                </td>
                                              )}
                                              <td>{item.TIPO_DOCUMENTO}</td>
                                              <td>{item.DOCUMENTO}</td>
                                              <td>{item.NOMBRE}</td>
                                              <td>
                                                {item.VENCIMIENTO === ""
                                                  ? ""
                                                  : moment(
                                                      item.VENCIMIENTO
                                                    ).format("DD/MM/YYYY")}
                                              </td>
                                              <td>{item.DIAS_EXONERAR}</td>
                                              <td>
                                                $
                                                {this.getNumber(
                                                  item.VALOR_A_EXONERAR
                                                )}
                                              </td>
                                              {/*
                                              { exonerationDetail ? (
                                              <td
                                              className="notice-td"
                                              onClick={(e) => {
                                              e.preventDefault();
                                              this.sendPartialExoneration(item);
                                              }}
                                              >
                                              <div className="notice-td-container">
                                              <span>
                                              Exoneración parcial
                                              </span>
                                              <img src={arrowgreen} alt="flecha verde" className="link-arrow"/>
                                              </div>
                                              </td>
                                              ) : (

                                              )}
                                              */}
                                              {parseInt(
                                                item.NUMERO_RECLAMACION
                                              ) === 0 &&
                                              parseInt(item.ACUERDO) < 1 ? (
                                                <td className="notice-td">
                                                  <TextField
                                                    id="amount"
                                                    label="Valor"
                                                    className="home-input"
                                                    margin="dense"
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          $
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    value={
                                                      this.isIndexExonerationValue(
                                                        index
                                                      )
                                                        ? this.state[
                                                            `exonerationValueMask${index}`
                                                          ]
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      this.onExonerationValueChange(
                                                        index,
                                                        e,
                                                        item.VALOR_A_EXONERAR
                                                      );
                                                    }}
                                                    fullWidth
                                                  />
                                                  {!!this.state[
                                                    `exonerationValueError${index}`
                                                  ] && (
                                                    <div className="input-error">
                                                      <span>
                                                        El valor debe menor a{" "}
                                                        <br />$
                                                        {this.getNumber(
                                                          item.VALOR_A_EXONERAR
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                              ) : (
                                                <td className="notice-td"></td>
                                              )}
                                              {parseInt(item.ACUERDO) > 0 && (
                                                <td className="notice-td">
                                                  Con acuerdo
                                                </td>
                                              )}
                                              {exonerationDetail &&
                                                !this.isIndexExonerationValue(
                                                  index
                                                ) &&
                                                parseInt(item.ACUERDO) < 1 && (
                                                  <td align="center">
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={
                                                            index in
                                                            getExonerationGuaranteSelected
                                                          }
                                                          onChange={this.checkExonerationChange(
                                                            index,
                                                            item
                                                          )}
                                                          classes={{
                                                            root: classes.root,
                                                            checked:
                                                              classes.checked,
                                                          }}
                                                        />
                                                      }
                                                      label=""
                                                    />
                                                  </td>
                                                )}
                                              {!(
                                                exonerationDetail &&
                                                !this.isIndexExonerationValue(
                                                  index
                                                )
                                              ) &&
                                                parseInt(item.ACUERDO) < 1 && (
                                                  <td
                                                    className={`${
                                                      this.isIndexExonerationValue(
                                                        index
                                                      )
                                                        ? "notice-td-link"
                                                        : "notice-td"
                                                    }${
                                                      this.isIndexExonerationValue(
                                                        index
                                                      ) &&
                                                      !!!this.state[
                                                        `exonerationValueErrorDisabled${index}`
                                                      ]
                                                        ? " enabled-link"
                                                        : ""
                                                    }`}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      if (
                                                        this.isIndexExonerationValue(
                                                          index
                                                        ) &&
                                                        !!!this.state[
                                                          `exonerationValueErrorDisabled${index}`
                                                        ]
                                                      ) {
                                                        this.setState(
                                                          {
                                                            partialExoneration: item,
                                                          },
                                                          () => {
                                                            this.confirmPartialExoneration(
                                                              this.state[
                                                                `exonerationValue${index}`
                                                              ],
                                                              `exonerationValue${index}`
                                                            );
                                                          }
                                                        );
                                                      } else if (
                                                        !this.isIndexExonerationValue(
                                                          index
                                                        )
                                                      ) {
                                                        this.sendIndividualExoneration(
                                                          item
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <span>
                                                      {this.isIndexExonerationValue(
                                                        index
                                                      )
                                                        ? "Exoneración"
                                                        : "Exonerar total"}
                                                    </span>
                                                    <img
                                                      src={arrowgreen}
                                                      alt="flecha verde"
                                                      className="link-arrow"
                                                    />
                                                  </td>
                                                )}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                    {exonerationLoadingPaginate && (
                                      <ListLoader />
                                    )}
                                  </ReduxLazyScroll>
                                </Col>
                              )}
                              {exonerationDetail && (
                                <Col xs={12} md={{ span: 4, offset: 2 }}>
                                  <Button
                                    className="button-send"
                                    variant="success"
                                    disabled={
                                      Object.keys(
                                        getExonerationGuaranteSelected
                                      ).length === 0
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.sendTotalExoneration();
                                    }}
                                    block
                                  >
                                    Exonerar total{" "}
                                    {Object.keys(getExonerationGuaranteSelected)
                                      .length > 0
                                      ? `(${
                                          Object.keys(
                                            getExonerationGuaranteSelected
                                          ).length
                                        })`
                                      : ""}
                                  </Button>
                                </Col>
                              )}
                              {exonerationDetail && (
                                <Col xs={12} md={4}>
                                  <Button
                                    className="button-primary"
                                    variant="primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.goHome();
                                    }}
                                    block
                                  >
                                    Limpiar
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}
                      {raiseProductNumber === "" &&
                        user &&
                        notice === 3 &&
                        !raiseNoticeDetail &&
                        !raiseNoticeError &&
                        !raiseNotification && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                className="search-notice-icon-container"
                              >
                                <img
                                  src={reservesearch}
                                  alt="busqueda documento iconos"
                                  className="search-notice-icon"
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="search-notice-text"
                              >
                                Para levantar un aviso de no pago, ingrese el
                                tipo y número de identificación y de clic en
                                “Buscar”.
                              </Col>
                              <Col xs={12} className="claim-margin">
                                <Row className="search-notice-form">
                                  <Col
                                    xs={12}
                                    md={4}
                                    className="login-input-container"
                                  >
                                    <TextField
                                      id="raiseDocumentType"
                                      select
                                      label="Tipo de documento"
                                      className="home-input"
                                      margin="dense"
                                      value={raiseDocumentType}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          "raiseDocumentType"
                                        );
                                      }}
                                      fullWidth
                                    >
                                      {documents.map((item, index) => {
                                        return (
                                          <MenuItem key={index} value={item.ID}>
                                            {item.LABEL}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={4}
                                    className="login-input-container"
                                  >
                                    <TextField
                                      id="raiseDocumentNumber"
                                      label="Número de documento"
                                      className="home-input"
                                      margin="dense"
                                      type="number"
                                      value={raiseDocumentNumber}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          "raiseDocumentNumber"
                                        );
                                      }}
                                      fullWidth
                                    />
                                  </Col>
                                  <Col xs={4}>
                                    <Button
                                      className="button-send"
                                      variant="success"
                                      disabled={
                                        raiseDocumentType === "" ||
                                        raiseDocumentNumber === ""
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.scrollToMyRef();
                                        this.newRaiseSearch();
                                      }}
                                      block
                                    >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                              {!raiseNotificationsNotRegistered && (
                                <Col
                                  xs={12}
                                  className="results-table-container"
                                >
                                  <ReduxLazyScroll
                                    isFetching={loadingRaisePaginate}
                                    errorMessage=""
                                    isParentScrollable={true}
                                    parentHeight="250px"
                                    loadMore={() => {
                                      this.getGuaranteesToRaise();
                                    }}
                                    hasMore={raiseNotificationsPageCount > 0}
                                  >
                                    <Table bordered responsive="sm">
                                      <thead>
                                        <tr>
                                          <th># Factura</th>
                                          <th>Tipo de d/mento</th>
                                          <th>Número documento</th>
                                          <th>Titular</th>
                                          <th>Fecha de vencimiento</th>
                                          <th>Días aviso</th>
                                          <th>Valor producto</th>
                                          <th className="notice-th">
                                            Levantar aviso
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {raiseNotifications.map(
                                          (item, index) => {
                                            return (
                                              <tr key={index}>
                                                {item.TIPO_TITULO ===
                                                  "FACTURA" && (
                                                  <td>{item.TITULO_VALOR}</td>
                                                )}
                                                {item.TIPO_TITULO !==
                                                  "FACTURA" && (
                                                  <td className="notice-td-check">
                                                    Cheque
                                                  </td>
                                                )}
                                                <td>{item.TIPO_DOCUMENTO}</td>
                                                <td>{item.DOCUMENTO}</td>
                                                <td>{item.NOMBRE}</td>
                                                <td>
                                                  {item.VENCIMIENTO === ""
                                                    ? ""
                                                    : moment(
                                                        item.VENCIMIENTO
                                                      ).format("DD/MM/YYYY")}
                                                </td>
                                                <td>
                                                  {item.DIAS_MARCAR_AVISO}
                                                </td>
                                                <td>
                                                  $
                                                  {this.getNumber(
                                                    item.VALOR_GARANTIZADO
                                                  )}
                                                </td>
                                                <td
                                                  className="notice-td"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.sendIndividualRaise(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  <div className="notice-td-container">
                                                    <span>Levantar aviso</span>
                                                    <img
                                                      src={arrowgreen}
                                                      alt="flecha verde"
                                                      className="link-arrow"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                    {loadingRaisePaginate && <ListLoader />}
                                  </ReduxLazyScroll>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}
                      {notice === 1 &&
                        noticeDetail &&
                        !noticeError &&
                        !notification &&
                        false && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                className="claim-margin claim-margin-top"
                              >
                                <Row className="date-search-row">
                                  <Col
                                    xs={12}
                                    md={4}
                                    className="exoneraton-search_name-container"
                                  >
                                    <Row>
                                      <Col
                                        xs={12}
                                        className="exoneraton-search_name"
                                      >
                                        {userName.toLowerCase()}
                                      </Col>
                                      <Col
                                        xs={12}
                                        className="exoneraton-search_document"
                                      >
                                        {documentType} Nº {documentNumber}
                                      </Col>
                                    </Row>
                                  </Col>
                                  {/*
                                  <Col xs={12} md={5}>
                                  <Row>
                                  <Col xs={12} className="exoneraton-search_title">Para realizar una búsqueda seleccione la fecha de emisión de las facturas</Col>
                                  <Col xs={12} className="exoneraton-col-padding">
                                  <Row>
                                  <Col xs={12} md={6} className="exoneraton-date-boder">
                                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                  <DatePicker
                                  margin="normal"
                                  label="Desde"
                                  className="home-input input-date"
                                  value={initialDate}
                                  locale='es-ES'
                                  onChange={this.handleDateFromChange}
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  InputProps={{
                                  endAdornment: (
                                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                                  ),
                                  }}
                                  />
                                  </MuiPickersUtilsProvider>
                                  </Col>
                                  <Col xs={12} md={6}>
                                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                  <DatePicker
                                  margin="normal"
                                  label="Hasta"
                                  className="home-input input-date"
                                  value={finalDate}
                                  locale='es-ES'
                                  onChange={this.handleDateToChange}
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  InputProps={{
                                  endAdornment: (
                                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                                  ),
                                  }}
                                  />
                                  </MuiPickersUtilsProvider>
                                  </Col>
                                  </Row>
                                  </Col>
                                  </Row>
                                  </Col>
                                  */}
                                  <Col xs={12} md={8}>
                                    <Row>
                                      <Col>
                                        <TextField
                                          id="documentNumber"
                                          label="Buscar número de factura"
                                          className="home-input"
                                          margin="dense"
                                          value={productNumber}
                                          onChange={(event) => {
                                            this.oninputChange(
                                              event,
                                              "productNumber"
                                            );
                                          }}
                                          InputProps={{
                                            endAdornment: (
                                              <img
                                                src={searchicon}
                                                alt="icono calendario"
                                                className="calendar-icon"
                                              />
                                            ),
                                          }}
                                          fullWidth
                                        />
                                      </Col>
                                      <Col xs={5}>
                                        <Button
                                          className="button-send"
                                          variant="success"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState(
                                              { getGuaranteSelected: {} },
                                              () => {
                                                this.scrollToMyRef();
                                                this.newSearch();
                                              }
                                            );
                                          }}
                                          block
                                        >
                                          Buscar
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              {!notificationsNotRegistered && (
                                <Col
                                  xs={12}
                                  className="results-table-container"
                                >
                                  <ReduxLazyScroll
                                    isFetching={loading}
                                    errorMessage=""
                                    isParentScrollable={true}
                                    parentHeight="250px"
                                    loadMore={() => {
                                      this.getGuaranteesToNotify();
                                    }}
                                    hasMore={notificationsPageCount > 0}
                                  >
                                    <Table bordered responsive="sm">
                                      <thead>
                                        <tr>
                                          {/*
                                          <th>Fecha de emisión</th>
                                          <th>Fecha de vencimiento</th>
                                          */}
                                          <th># Factura</th>
                                          <th>Valor factura</th>
                                          <th>Fecha de vencimiento</th>
                                          <th>Días aviso</th>
                                          <th>Aviso de no pago individual</th>
                                          <th>Aviso de no pago</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {notifications.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              {/*
                                              <td>{item.FECHA_EMISION === '' ? '' : moment(item.FECHA_EMISION).format('DD/MM/YYYY')}</td>
                                              <td>{item.VENCIMIENTO === '' ? '' : moment(item.VENCIMIENTO).format('DD/MM/YYYY')}</td>
                                              */}
                                              {item.TIPO_TITULO ===
                                                "FACTURA" && (
                                                <td>{item.TITULO_VALOR}</td>
                                              )}
                                              {item.TIPO_TITULO !==
                                                "FACTURA" && (
                                                <td className="notice-td-check">
                                                  Cheque
                                                </td>
                                              )}
                                              <td>
                                                $
                                                {this.getNumber(
                                                  item.VALOR_GARANTIZADO
                                                )}
                                              </td>
                                              <td>
                                                {item.VENCIMIENTO === ""
                                                  ? ""
                                                  : moment(
                                                      item.VENCIMIENTO
                                                    ).format("DD/MM/YYYY")}
                                              </td>
                                              <td>{item.DIAS_MARCAR_AVISO}</td>
                                              <td
                                                className="notice-td"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.sendIndividualNotice(
                                                    item
                                                  );
                                                }}
                                              >
                                                <div className="notice-td-container">
                                                  <span>Aviso de no pago</span>
                                                  <img
                                                    src={arrowgreen}
                                                    alt="flecha verde"
                                                    className="link-arrow"
                                                  />
                                                </div>
                                              </td>
                                              <td align="center">
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={
                                                        index in
                                                        getGuaranteSelected
                                                      }
                                                      onChange={this.checkChange(
                                                        index,
                                                        item
                                                      )}
                                                      classes={{
                                                        root: classes.root,
                                                        checked:
                                                          classes.checked,
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </ReduxLazyScroll>
                                </Col>
                              )}
                              {!notificationsNotRegistered && (
                                <Col xs={12} md={{ span: 4, offset: 2 }}>
                                  <Button
                                    className="button-send"
                                    variant="success"
                                    disabled={
                                      Object.keys(getGuaranteSelected)
                                        .length === 0
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.sendNotice();
                                    }}
                                    block
                                  >
                                    Aviso total{" "}
                                    {Object.keys(getGuaranteSelected).length > 0
                                      ? `(${
                                          Object.keys(getGuaranteSelected)
                                            .length
                                        })`
                                      : ""}
                                  </Button>
                                </Col>
                              )}
                              {!notificationsNotRegistered && (
                                <Col xs={12} md={4}>
                                  <Button
                                    className="button-primary"
                                    variant="primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.goHome();
                                    }}
                                    block
                                  >
                                    Volver a buscar
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}
                      {notice === 3 &&
                        raiseNoticeDetail &&
                        !raiseNoticeError &&
                        !raiseNotification && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                className="claim-margin claim-margin-top"
                              >
                                <Row className="date-search-row">
                                  <Col
                                    xs={12}
                                    md={4}
                                    className="exoneraton-search_name-container"
                                  >
                                    <Row>
                                      <Col
                                        xs={12}
                                        className="exoneraton-search_name"
                                      >
                                        {raiseUserName.toLowerCase()}
                                      </Col>
                                      <Col
                                        xs={12}
                                        className="exoneraton-search_document"
                                      >
                                        {raiseDocumentType} Nº{" "}
                                        {raiseDocumentNumber}
                                      </Col>
                                    </Row>
                                  </Col>
                                  {/*
                                  <Col xs={12} md={5}>
                                  <Row>
                                  <Col xs={12} className="exoneraton-search_title">Para realizar una búsqueda seleccione la fecha de emisión de las facturas</Col>
                                  <Col xs={12} className="exoneraton-col-padding">
                                  <Row>
                                  <Col xs={12} md={6} className="exoneraton-date-boder">
                                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                  <DatePicker
                                  margin="normal"
                                  label="Desde"
                                  className="home-input input-date"
                                  value={initialDate}
                                  locale='es-ES'
                                  onChange={this.handleDateFromChange}
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  InputProps={{
                                  endAdornment: (
                                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                                  ),
                                  }}
                                  />
                                  </MuiPickersUtilsProvider>
                                  </Col>
                                  <Col xs={12} md={6}>
                                  <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                  <DatePicker
                                  margin="normal"
                                  label="Hasta"
                                  className="home-input input-date"
                                  value={finalDate}
                                  locale='es-ES'
                                  onChange={this.handleDateToChange}
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  InputProps={{
                                  endAdornment: (
                                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                                  ),
                                  }}
                                  />
                                  </MuiPickersUtilsProvider>
                                  </Col>
                                  </Row>
                                  </Col>
                                  </Row>
                                  </Col>
                                  */}
                                  <Col xs={12} md={8}>
                                    <Row>
                                      <Col>
                                        <TextField
                                          id="raiseProductNumber"
                                          label="Buscar número de factura"
                                          className="home-input"
                                          margin="dense"
                                          value={raiseProductNumber}
                                          onChange={(event) => {
                                            this.oninputChange(
                                              event,
                                              "raiseProductNumber"
                                            );
                                          }}
                                          InputProps={{
                                            endAdornment: (
                                              <img
                                                src={searchicon}
                                                alt="icono calendario"
                                                className="calendar-icon"
                                              />
                                            ),
                                          }}
                                          fullWidth
                                        />
                                      </Col>
                                      <Col xs={5}>
                                        <Button
                                          className="button-send"
                                          variant="success"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState(
                                              { getRaiseGuaranteSelected: {} },
                                              () => {
                                                this.scrollToMyRef();
                                                this.newRaiseSearch();
                                              }
                                            );
                                          }}
                                          block
                                        >
                                          Buscar
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              {!raiseNotificationsNotRegistered && (
                                <Col
                                  xs={12}
                                  className="results-table-container"
                                >
                                  <ReduxLazyScroll
                                    isFetching={loadingRaise}
                                    errorMessage=""
                                    isParentScrollable={true}
                                    parentHeight="250px"
                                    loadMore={() => {
                                      this.getGuaranteesToRaise();
                                    }}
                                    hasMore={raiseNotificationsPageCount > 0}
                                  >
                                    <Table bordered responsive="sm">
                                      <thead>
                                        <tr>
                                          {/*
                                          <th>Fecha de emisión</th>
                                          <th>Fecha de vencimiento</th>
                                          */}
                                          <th># Factura</th>
                                          <th>Valor factura</th>
                                          <th>Fecha de vencimiento</th>
                                          <th>
                                            Levantar aviso de no pago individual
                                          </th>
                                          <th>Levantar aviso de no pago</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {raiseNotifications.map(
                                          (item, index) => {
                                            return (
                                              <tr key={index}>
                                                {/*
                                              <td>{item.FECHA_EMISION === '' ? '' : moment(item.FECHA_EMISION).format('DD/MM/YYYY')}</td>
                                              <td>{item.VENCIMIENTO === '' ? '' : moment(item.VENCIMIENTO).format('DD/MM/YYYY')}</td>
                                              */}
                                                {item.TIPO_TITULO ===
                                                  "FACTURA" && (
                                                  <td>{item.TITULO_VALOR}</td>
                                                )}
                                                {item.TIPO_TITULO !==
                                                  "FACTURA" && (
                                                  <td className="notice-td-check">
                                                    Cheque
                                                  </td>
                                                )}
                                                <td>
                                                  $
                                                  {this.getNumber(
                                                    item.VALOR_GARANTIZADO
                                                  )}
                                                </td>
                                                <td>
                                                  {item.VENCIMIENTO === ""
                                                    ? ""
                                                    : moment(
                                                        item.VENCIMIENTO
                                                      ).format("DD/MM/YYYY")}
                                                </td>
                                                <td
                                                  className="notice-td"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.sendIndividualRaise(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  <div className="notice-td-container">
                                                    <span>Levantar aviso</span>
                                                    <img
                                                      src={arrowgreen}
                                                      alt="flecha verde"
                                                      className="link-arrow"
                                                    />
                                                  </div>
                                                </td>
                                                <td align="center">
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          index in
                                                          getRaiseGuaranteSelected
                                                        }
                                                        onChange={this.checkRaiseChange(
                                                          index,
                                                          item
                                                        )}
                                                        classes={{
                                                          root: classes.root,
                                                          checked:
                                                            classes.checked,
                                                        }}
                                                      />
                                                    }
                                                    label=""
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </ReduxLazyScroll>
                                </Col>
                              )}
                              {!raiseNotificationsNotRegistered && (
                                <Col xs={12} md={{ span: 6, offset: 2 }}>
                                  <Button
                                    className="button-send"
                                    variant="success"
                                    disabled={
                                      Object.keys(getRaiseGuaranteSelected)
                                        .length === 0
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.sendRaise();
                                    }}
                                    block
                                  >
                                    Levantar aviso total{" "}
                                    {Object.keys(getRaiseGuaranteSelected)
                                      .length > 0
                                      ? `(${
                                          Object.keys(getRaiseGuaranteSelected)
                                            .length
                                        })`
                                      : ""}
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}
                      {productNumber === "" &&
                        notice === 1 &&
                        documentNumber !== "" &&
                        notificationsNotRegistered && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                md={{ span: 4, offset: 4 }}
                                className="reservation-loading-text"
                              >
                                <img
                                  src={denegate}
                                  alt="icono reservas"
                                  className="resevation-icon-denegate"
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="not-reservation-text"
                              >
                                {this.getDocumentTypeName()}{" "}
                                <span className="not-reservation-text_bold">
                                  Nº {documentNumber}
                                </span>{" "}
                                no se encuentra registrada en el sistema
                              </Col>
                            </Row>
                          </Col>
                        )}
                      {raiseProductNumber === "" &&
                        notice === 3 &&
                        raiseDocumentNumber !== "" &&
                        raiseNotificationsNotRegistered && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                md={{ span: 4, offset: 4 }}
                                className="reservation-loading-text"
                              >
                                <img
                                  src={denegate}
                                  alt="icono reservas"
                                  className="resevation-icon-denegate"
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="not-reservation-text"
                              >
                                {this.getRaiseDocumentTypeName()}{" "}
                                <span className="not-reservation-text_bold">
                                  Nº {raiseDocumentNumber}
                                </span>{" "}
                                no se encuentra registrada en el sistema
                              </Col>
                            </Row>
                          </Col>
                        )}
                      {notice === 1 && notification && (
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              md={{ span: 4, offset: 4 }}
                              className="reservation-loading-text"
                            >
                              <img
                                src={aproved}
                                alt="icono reservas"
                                className="resevation-icon"
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="reserve-aproved-title"
                            >
                              ¡AVISO DE NO PAGO REALIZADO!
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="not-reservation-text"
                            >
                              Muchas gracias por utilizar los servicios de{" "}
                              <span className="not-reservation-text_bold">
                                Covifactura
                              </span>{" "}
                              .{" "}
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 4, offset: 4 }}
                              className="reserve-aproved-button-container"
                            >
                              <Button
                                className="button-send"
                                variant="success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.goHome();
                                }}
                                block
                              >
                                Realizar nuevo aviso
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {notice === 4 && exoneration && (
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              md={{ span: 4, offset: 4 }}
                              className="reservation-loading-text"
                            >
                              <img
                                src={aproved}
                                alt="icono reservas"
                                className="resevation-icon"
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="reserve-aproved-title"
                            >
                              ¡EXONERACIÓN REALIZADA!
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="not-reservation-text"
                            >
                              Muchas gracias por utilizar los servicios de{" "}
                              <span className="not-reservation-text_bold">
                                Covifactura
                              </span>{" "}
                              .{" "}
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="reserve-aproved-button-container"
                            >
                              <Button
                                className="button-send"
                                variant="success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.goHome();
                                }}
                                block
                              >
                                Realizar nueva exoneración
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {notice === 3 && raiseNotification && (
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              md={{ span: 4, offset: 4 }}
                              className="reservation-loading-text"
                            >
                              <img
                                src={aproved}
                                alt="icono reservas"
                                className="resevation-icon"
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="reserve-aproved-title"
                            >
                              ¡AVISO DE NO PAGO LEVANTADO!
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 6, offset: 3 }}
                              className="not-reservation-text"
                            >
                              Muchas gracias por utilizar los servicios de{" "}
                              <span className="not-reservation-text_bold">
                                Covifactura
                              </span>{" "}
                              .{" "}
                            </Col>
                            <Col
                              xs={12}
                              md={{ span: 4, offset: 4 }}
                              className="reserve-aproved-button-container"
                            >
                              <Button
                                className="button-send"
                                variant="success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.goHome();
                                }}
                                block
                              >
                                Levantar nuevo aviso
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {notice === 2 &&
                        !claimError &&
                        !claimSucces &&
                        !claimUpload &&
                        !claimUploadSuccess && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                className="claim-margin claim-margin-top"
                              >
                                <Row>
                                  <Col xs={12} md={8}>
                                    <Row>
                                      <Col>
                                        <div className="search-input-container">
                                          <span>
                                            <img
                                              src={searchicon}
                                              alt="Icono buscar"
                                            />
                                          </span>
                                          <input placeholder="Buscar número de factura" />
                                        </div>
                                      </Col>
                                      <Col xs={5}>
                                        <Button
                                          className="button-send"
                                          variant="success"
                                          block
                                        >
                                          Buscar
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12} className="results-table-container">
                                <Table bordered responsive="sm">
                                  <thead>
                                    <tr>
                                      <th>Titular</th>
                                      <th>Producto</th>
                                      <th>Valor producto</th>
                                      <th>Número producto</th>
                                      <th>Días</th>
                                      <th className="notice-th">Reclamar</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Camilo Antonio Lopez</td>
                                      <td className="invoice">Factura</td>
                                      <td>$1’250.000</td>
                                      <td>79008543-1</td>
                                      <td>1</td>
                                      <td className="notice-td">
                                        <div className="notice-td-container">
                                          <span>Reclamar</span>
                                          <img
                                            src={arrowgreen}
                                            alt="flecha verde"
                                            className="link-arrow"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Camilo Antonio Lopez</td>
                                      <td className="invoice">Factura</td>
                                      <td>$1’250.000</td>
                                      <td>79008543-1</td>
                                      <td>2</td>
                                      <td className="notice-td">
                                        <div className="notice-td-container">
                                          <span>Reclamar</span>
                                          <img
                                            src={arrowgreen}
                                            alt="flecha verde"
                                            className="link-arrow"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Camilo Antonio Lopez</td>
                                      <td className="check">Cheque</td>
                                      <td>$1’250.000</td>
                                      <td>79008543-1</td>
                                      <td>4</td>
                                      <td className="notice-td">
                                        <div className="notice-td-container">
                                          <span>Reclamar</span>
                                          <img
                                            src={arrowgreen}
                                            alt="flecha verde"
                                            className="link-arrow"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                              <Col xs={12} md={{ span: 4, offset: 4 }}>
                                <Button
                                  className="button-send"
                                  variant="success"
                                  block
                                >
                                  Aviso total (2)
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        )}

                      {productNumber !== "" &&
                        notice === 1 &&
                        documentNumber !== "" &&
                        notificationsNotRegistered && (
                          <Col xs={12}>
                            <Row>
                              {/*
                              <Col xs={12} className="claim-margin claim-margin-top">
                              <Row className="date-search-row">
                              <Col xs={12} md={5}>
                              <Row>
                              <Col xs={12} className="exoneraton-search_title">Para realizar una búsqueda seleccione la fecha de emisión de las facturas</Col>
                              <Col xs={12} className="exoneraton-col-padding">
                              <Row>
                              <Col xs={12} md={6} className="exoneraton-date-boder">
                              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                              <DatePicker
                              margin="normal"
                              label="Desde"
                              className="home-input input-date"
                              value={initialDate}
                              locale='es-ES'
                              onChange={this.handleDateFromChange}
                              fullWidth
                              format="DD/MM/YYYY"
                              InputProps={{
                              endAdornment: (
                              <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                              ),
                              }}
                              />
                              </MuiPickersUtilsProvider>
                              </Col>
                              <Col xs={12} md={6}>
                              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                              <DatePicker
                              margin="normal"
                              label="Hasta"
                              className="home-input input-date"
                              value={finalDate}
                              locale='es-ES'
                              onChange={this.handleDateToChange}
                              fullWidth
                              format="DD/MM/YYYY"
                              InputProps={{
                              endAdornment: (
                              <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                              ),
                              }}
                              />
                              </MuiPickersUtilsProvider>
                              </Col>
                              </Row>
                              </Col>
                              </Row>
                              </Col>
                              </Row>
                            </Col>
                              */}
                              <Col
                                xs={12}
                                md={{ span: 4, offset: 4 }}
                                className="reservation-loading-text"
                              >
                                <img
                                  src={denegate}
                                  alt="icono reservas"
                                  className="resevation-icon-denegate"
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="not-reservation-text"
                              >
                                No se encontraron facturas{" "}
                                <span className="not-reservation-text_bold"></span>
                              </Col>
                              {/*
                              <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                              No se encontraron facturas entre <span className="not-reservation-text_bold">{initialDate ? moment(initialDate).format('DD/MM/YYYY') : '00/00/0000'} y {finalDate ? moment(finalDate).format('DD/MM/YYYY') : '00/00/0000'}</span>
                              </Col>
                              */}
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="not-reservation-text"
                              >
                                Si desea puede realizar otra búsqueda
                              </Col>
                            </Row>
                          </Col>
                        )}

                      {raiseProductNumber !== "" &&
                        notice === 3 &&
                        raiseDocumentNumber !== "" &&
                        raiseNotificationsNotRegistered && (
                          <Col xs={12}>
                            <Row>
                              {/*
                              <Col xs={12} className="claim-margin claim-margin-top">
                              <Row className="date-search-row">
                              <Col xs={12} md={5}>
                              <Row>
                              <Col xs={12} className="exoneraton-search_title">Para realizar una búsqueda seleccione la fecha de emisión de las facturas</Col>
                              <Col xs={12} className="exoneraton-col-padding">
                              <Row>
                              <Col xs={12} md={6} className="exoneraton-date-boder">
                              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                              <DatePicker
                              margin="normal"
                              label="Desde"
                              className="home-input input-date"
                              value={initialDate}
                              locale='es-ES'
                              onChange={this.handleDateFromChange}
                              fullWidth
                              format="DD/MM/YYYY"
                              InputProps={{
                              endAdornment: (
                              <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                              ),
                              }}
                              />
                              </MuiPickersUtilsProvider>
                              </Col>
                              <Col xs={12} md={6}>
                              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                              <DatePicker
                              margin="normal"
                              label="Hasta"
                              className="home-input input-date"
                              value={finalDate}
                              locale='es-ES'
                              onChange={this.handleDateToChange}
                              fullWidth
                              format="DD/MM/YYYY"
                              InputProps={{
                              endAdornment: (
                              <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                              ),
                              }}
                              />
                              </MuiPickersUtilsProvider>
                              </Col>
                              </Row>
                              </Col>
                              </Row>
                              </Col>
                              </Row>
                            </Col>
                              */}
                              <Col
                                xs={12}
                                md={{ span: 4, offset: 4 }}
                                className="reservation-loading-text"
                              >
                                <img
                                  src={denegate}
                                  alt="icono reservas"
                                  className="resevation-icon-denegate"
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="not-reservation-text"
                              >
                                No se encontraron facturas{" "}
                                <span className="not-reservation-text_bold"></span>
                              </Col>
                              {/*
                              <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                              No se encontraron facturas entre <span className="not-reservation-text_bold">{initialDate ? moment(initialDate).format('DD/MM/YYYY') : '00/00/0000'} y {finalDate ? moment(finalDate).format('DD/MM/YYYY') : '00/00/0000'}</span>
                              </Col>
                              */}
                              <Col
                                xs={12}
                                md={{ span: 6, offset: 3 }}
                                className="not-reservation-text"
                              >
                                Si desea puede realizar otra búsqueda
                              </Col>
                            </Row>
                          </Col>
                        )}

                      {notice === 2 && claimUpload && (
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              md={{ span: 8, offset: 2 }}
                              className="reservation-loading-text"
                            >
                              <Row>
                                <Col xs="auto">
                                  <img
                                    src={papersicon}
                                    alt="icono reservas"
                                    className="claim-upload-icon"
                                  />
                                </Col>
                                <Col xs={12} md>
                                  <Row>
                                    <Col
                                      xs={12}
                                      className="not-reservation-text claim-margin claim-upload-left"
                                    >
                                      Recuerde que debe subir en línea los
                                      siguientes documentos para hacer efectiva
                                      la reclamación de esta factura:
                                    </Col>
                                    <Col
                                      xs={12}
                                      className="not-reservation-text not-reservation-text_bold claim-upload-left"
                                    >
                                      · Factura original
                                    </Col>
                                    <Col
                                      xs={12}
                                      className="not-reservation-text not-reservation-text_bold claim-upload-left claim-margin"
                                    >
                                      · Endoso de la factura
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={10}
                                      className="claim-margin"
                                    >
                                      <Button
                                        className="button-send"
                                        variant="success"
                                        block
                                      >
                                        Cargar archivos
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {notice === 2 && claimUploadSuccess && (
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              md={{ span: 8, offset: 2 }}
                              className="reservation-loading-text"
                            >
                              <Row>
                                <Col xs="auto" className="margin-succes">
                                  <img
                                    src={papersicon}
                                    alt="icono reservas"
                                    className="claim-upload-icon"
                                  />
                                </Col>
                                <Col xs={12} md>
                                  <Row>
                                    <Col
                                      xs={12}
                                      className="claim-upload-title claim-upload-left check-icon-container"
                                    >
                                      Archivo cargado satisfactoriamente
                                      <img
                                        src={checkicon}
                                        alt="chulo icono"
                                        className="check-icon"
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      className="not-reservation-text claim-margin claim-upload-left"
                                    >
                                      En el transcurso de las próximas{" "}
                                      <span className="not-reservation-text_bold">
                                        24
                                      </span>{" "}
                                      horas no estaremos comunicando con usted
                                      para darle una respuesta
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={10}
                                      className="claim-margin"
                                    >
                                      <Button
                                        className="button-send"
                                        variant="success"
                                        block
                                      >
                                        Nueva reclamación
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { user, globals } = state.user;

  let {
    loading,
    notifications,
    notificationsPageCount,
    notificationsNotRegistered,
    notification,
    raiseNotification,
    raiseNotifications,
    raiseNotificationsPageCount,
    raiseNotificationsNotRegistered,
    loadingRaise,
    loadingPaginate,
    loadingRaisePaginate,
  } = state.claim;

  let {
    loading: exonerationLoading,
    exonerations,
    exonerationsNotRegistered,
    exonerationsPageCount,
    exoneration,
    bulkExonerationError,
    bulkExonerationResponse,
    bulkExonerationFinalResponse,
    exportExonerations,
    loadingPaginate: exonerationLoadingPaginate,
  } = state.exoneration;

  return {
    user,
    globals,
    loading,
    notifications,
    notificationsPageCount,
    notificationsNotRegistered,
    notification,
    raiseNotification,
    raiseNotifications,
    raiseNotificationsPageCount,
    raiseNotificationsNotRegistered,
    loadingRaise,
    loadingPaginate,
    loadingRaisePaginate,
    exonerationLoading,
    exonerations,
    exonerationsNotRegistered,
    exonerationsPageCount,
    exoneration,
    bulkExonerationError,
    bulkExonerationResponse,
    bulkExonerationFinalResponse,
    exportExonerations,
    exonerationLoadingPaginate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Claim)
);
