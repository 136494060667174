import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import './user-header.scss';
import * as actions from '../../redux/actions'
import { Col, Row } from 'react-bootstrap';
import userLogo from '../../assets/logos/user-logo.svg';
import companyLogo from '../../assets/logos/company-logo.svg';

class UserHeader extends Component {

  constructor() {
    super();
    let randNumber = Math.random() * 100;
    this.state = {
      imageNumber: randNumber,
    };

  }

  componentDidMount() {
    this.loadData()
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined)
    }
  }

  loadData() {
    this.props.actions.getUser();
  }

  openMenu() {
    this.props.openMenu()
  }

  getSellersReport = (user) => {
    this.props.actions.getTransactionsNumber({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      documentNumber: 0,
      documentType: user.userInfo.TIPO_DOCUMENTO_USUARIO,
      page: 1,
      sellerEmail: user.userEmail
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user && nextProps.transactionsNumber.length === 0) {
      this.getSellersReport(nextProps.user);
    }
  }

  changeImgNumber() {
    let randNumber = Math.random() * 100;
    this.setState({
      imageNumber: randNumber
    })
  }

  render() {
    const { imageNumber } = this.state;
    const { user, transactionsNumber } = this.props;
    let userPhoto = user && user.userInfo && user.userInfo.FOTO_USUARIO ? user.userInfo.FOTO_USUARIO + '?' + imageNumber : userLogo;
    let companyPhoto = user && user.userInfo && user.userInfo.LOGO_EMPRESA ? user.userInfo.LOGO_EMPRESA : companyLogo;
    let sucursal = user && user.userInfo && user.userInfo.SUCURSAL_USUARIO ? user.userInfo.SUCURSAL_USUARIO : 'Suba';
    let nombreUsuario = user && user.userInfo && user.userInfo.NOMBRE ? user.userInfo.NOMBRE : '';
    let userTransactions = transactionsNumber.length > 0 ? transactionsNumber[0].NUMERO_GARANTIAS : 0;
    return (
      <Row className="user-header">
        <Col xs={12} md={6} className="user-header-profile-container" >
          <Row>
            <Col xs='auto'>
              <div className="user-logo-container">
                <img className="user-logo" src={userPhoto} alt=''/>
              </div>
            </Col>
            <Col className="center-vertically">
              <Row>
                <Col xs={12}>
                  <Row>
                    <span className="user-header-profile-title">Bienvenido</span>
                  </Row>
                </Col>
                <Col xs={12} className="user-header-profile-name">
                  <Row>
                    <span>{nombreUsuario.toLowerCase()}</span>
                  </Row>
                </Col>
                <Col xs={12} className="user-header-profile-subtitle">
                  <Row>
                    <span>{userTransactions} transacciones realizadas</span>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} className="user-header-profile-container">
          <Row>
            <Col xs={12} className="right-hotizontally-desktop center-horizontally-mobile company-logo-container">
              <img className="company-logo" src={companyPhoto} alt='company logo'/>
            </Col>
            <Col xs={12} className="right-hotizontally-desktop center-horizontally-mobile">
              <span className="user-header-company-name">Sucursal {sucursal.toLowerCase()}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      user,
  } = state.user;
  let {
      transactionsNumber,
  } = state.transaction;

  return {
      user,
      transactionsNumber
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
