import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './client-study.scss';
import * as actions from '../../redux/actions';
import { Col, Button, Row } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import arrowgreen from '../../assets/icons/arrowGreen.svg';
import banner from '../../assets/images/banner.png';
import calendaricon from '../../assets/icons/calendaricon.svg';
import processIcon from '../../assets/icons/process.svg';
import tooltipimg from '../../assets/icons/tooltip.svg';
import threePaymentsIcon from '../../assets/images/trespagos.png';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import Loading from '../loading';
import UserHeader from '../user-header';
import {
  TextField,
  MenuItem,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Select,
  Tooltip,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import { debounce } from 'debounce';
import QueryCodeModal from '../home/querycode-modal';
import EmailModal from './email-modal';
import EmailModalSucces from './email-modal-succes';
import StudyModalSucces from './study-succes';
import VideoModal from './video-modal';
import ErrorModal from '../error-modal';

moment.locale('es');

const initialDateFields = [
  'Menos de 1 año',
  '1 a 5 años',
  '5 a 10 años',
  'Más de 10 años',
];

const addressFields = [
  'Calle',
  'Carrera',
  'Avenida',
  'Avenida Carrera',
  'Avenida Calle',
  'Circular',
  'Circunvalar',
  'Diagonal',
  'Manzana',
  'Transversal',
  'Vía',
  'Otra',
];

const documentTypesPartners = [
  { LABEL: 'CEDULA DE CIUDADANIA', ID: 'C' },
  { LABEL: 'CEDULA DE EXTRANJERIA', ID: 'E' },
];

const companyTypeFields = [
  { LABEL: 'Unipersonal', ID: 'UNIPERSONAL' },
  { LABEL: 'Sociedad', ID: 'SOCIEDAD' },
];

class ClientStudy extends Component {
  constructor() {
    super();
    // this.state = {
    //   menuOpen: false,
    //   termsAccepted: true,
    //   ref1TypCompany: 'legal',
    //   ref2TypCompany: 'legal',
    //   ref1TypPerson: 'legal',
    //   ref2TypPerson: 'legal',
    //   addressType: 'Calle',
    //   addressFirst: 'cl 24 a norte',
    //   addressSecond: '18',
    //   addressThird: '20',
    //   addressOther: 'cl 24 a norte 18 20 conjunto alcalá casa 47',
    //   addressValid: true,
    //   firstRefFirstName: 'Alberto',
    //   firstRefSecondName: '',
    //   firstRefFistLastName: 'Ramirez',
    //   firstRefSecondLastName: '',
    //   firstRefDepartment: '91',
    //   firstRefCity: '91263',
    //   firstRefPhone: '3113457684',
    //   firstRefEmail: 'Alberto@gmail.com',
    //   firstRefEmailValid: true,
    //   secondRefFirstName: 'Raul',
    //   secondRefSecondName: '',
    //   secondRefFistLastName: 'Hernandez',
    //   secondRefSecondLastName: '',
    //   secondRefDepartment: '91',
    //   secondRefCity: '91263',
    //   secondRefPhone: '3243456786',
    //   secondRefEmail: 'raul@gmail.com',
    //   secondRefEmailValid: true,
    //   clientType: 'legal',
    //   pointsOfSale: 'S',
    //   modeWP: 'N',
    //   modeEmail: 'N',
    //   modeSMS: 'N',
    //   modePhone: 'N',
    //   expeditionDate: moment('24/10/2019', 'DD/MM/YYYY'),
    //   documentType: 'C',
    //   documentNumber: '860222222',
    //   checkDigit: '1',
    //   expeditionDepartment: '91',
    //   expeditionCity: '85263',
    //   companyType: 'UNIPERSONAL',
    //   fullName: 'Pedro Pelaez',
    //   homeDepartment: '91',
    //   homeCity: '91263',
    //   cellular: '3002101010',
    //   phone: '3420011',
    //   email: 'prueba@prueba.com',
    //   emailValid: true,
    //   birthDate: moment('28/10/2019', 'DD/MM/YYYY'),
    //   economicActivity: 'mercadeo',
    //   quotaValue: '30000000',
    //   quotaValueMask: '30.000.000',
    //   companyName: 'prueba platafomra',
    //   legalRepresentativeDocumentType: 'C',
    //   legalRepresentativeDocumentNumber: '52100100',
    //   legalRepresentativeFirstName: 'Laura',
    //   legalRepresentativeSecondName: '',
    //   legalRepresentativeFistLastName: 'Perez',
    //   constactPosition: 'mercadeo',
    //   constactFirstName: 'Claudia',
    //   constactSecondName: '',
    //   constactFirstLastName: 'Perez',
    //   constactSecondLastName: '',
    //   constactCellular: '3002202020',
    //   constactEmail: 'prueba@prueba.com',
    //   constactEmailValid: true,
    //   partnersArray: [
    //     {
    //       documentType: 'C',
    //       documentNumber: '52101101',
    //       firstName: 'Patricia',
    //       secondName: '',
    //       firstLastName: 'Cespedes',
    //       secondLastName: 'Martinez',
    //     },
    //   ],
    //   contractNumber: '',
    //   queryCodeModal: false,
    //   emailModal: false,
    //   queryCode: '',
    //   initialDate: 'Menos de 1 año',
    // };

    this.state = {
      menuOpen: false,
      clientType: 'legal',
      ref1TypCompany: 'legal',
      ref2TypCompany: 'legal',
      ref1TypPerson: 'legal',
      ref2TypPerson: 'legal',
      pointsOfSale: 'S',
      modeWP: 'N',
      modeEmail: 'N',
      modeSMS: 'N',
      modePhone: 'N',
      expeditionDate: null,
      addressType: '',
      addressFirst: '',
      addressSecond: '',
      addressThird: '',
      addressOther: '',
      addressValid: true,
      documentType: '',
      documentNumber: '',
      checkDigit: '',
      expeditionDepartment: '',
      expeditionCity: '',
      companyType: '',
      termsAccepted: true,
      firstRefFirstName: '',
      firstRefSecondName: '',
      firstRefFistLastName: '',
      firstRefSecondLastName: '',
      firstRefDepartment: '',
      firstRefCity: '',
      firstRefPhone: '',
      firstRefEmail: '',
      firstRefEmailValid: true,
      secondRefFirstName: '',
      secondRefSecondName: '',
      secondRefFistLastName: '',
      secondRefSecondLastName: '',
      secondRefDepartment: '',
      secondRefCity: '',
      secondRefPhone: '',
      secondRefEmail: '',
      secondRefEmailValid: true,
      legalRepresentativeFirstName: '',
      legalRepresentativeSecondName: '',
      legalRepresentativeFistLastName: '',
      legalRepresentativeSecondLastName: '',
      homeDepartment: '',
      homeCity: '',
      address: '',
      cellular: '',
      phone: '',
      email: '',
      emailValid: true,
      birthDate: null,
      economicActivity: '',
      quotaValue: '',
      quotaValueMask: '',
      companyName: '',
      legalRepresentativeDocumentType: '',
      legalRepresentativeDocumentNumber: '',
      constactPosition: '',
      constactFirstName: '',
      constactSecondName: '',
      constactFirstLastName: '',
      constactSecondLastName: '',
      constactCellular: '',
      constactEmail: '',
      constactEmailValid: true,
      partnersArray: [
        {
          documentType: '',
          documentNumber: '',
          firstName: '',
          secondName: '',
          firstLastName: '',
          secondLastName: '',
        },
      ],
      contractNumber: '',
      queryCodeModal: false,
      emailModal: false,
      queryCode: '',
      initialDate: null,
      emailModalSucces: false,
      filingNumber: 0,
      lastSaveDate: null,
      existError: false,
      threePayments: false,
      videoModal: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.isValidEmail = debounce(this.isValidEmail, 1000);
  }

  componentDidMount() {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'entra_estudio_cliente', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.clientStudyRequest &&
      nextProps.clientStudyRequest !== this.props.clientStudyRequest
    ) {
      let userJson = JSON.parse(localStorage.getItem('user'));
      let globalsJson = JSON.parse(localStorage.getItem('globals'));
      let idPais =
        'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
          ? globalsJson.PAIS[0].ID_PAIS
          : '1';
      if (nextProps.clientStudyRequest.DESCRIPCION === 'TRANSACCION NEGADA') {
        window.gtag('event', 'estudio_negado', {
          event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          event_label: userJson.userEmail,
        });
      } else if (
        nextProps.clientStudyRequest.DESCRIPCION === 'DATOS INVALIDOS'
      ) {
        window.gtag('event', 'estudio_datos_invalidos', {
          event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          event_label: userJson.userEmail,
        });
      } else {
        if (parseInt(nextProps.clientStudyRequest['RAZON ']) === 0) {
          if ('ESTUDIO_CUPO ' in nextProps.clientStudyRequest) {
            this.sendHash(nextProps.clientStudyRequest['ESTUDIO_CUPO ']);
          }
          window.gtag('event', 'estudio_aprobado', {
            event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
            event_label: userJson.userEmail,
          });
        } else {
          window.gtag('event', 'estudio_radicado', {
            event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
            event_label: userJson.userEmail,
          });
        }
      }
    }
    if (
      nextProps.clientStudyLinkRequest !== this.props.clientStudyLinkRequest &&
      nextProps.clientStudyLinkRequest
    ) {
      this.setState(
        {
          emailModalSucces: true,
        },
        () => {
          this.props.actions.resettLinkClientStudy();
        }
      );
    }
    if (
      nextProps.formValidate !== this.props.formValidate &&
      nextProps.formValidate
    ) {
      if ('RADICADO' in nextProps.formValidate) {
        this.setState({
          filingNumber: nextProps.formValidate['RADICADO'],
        });
      }
      if ('FORMULARIO' in nextProps.formValidate) {
        let saveForm = nextProps.formValidate['FORMULARIO'][0];
        console.log('saveForm', saveForm);
        let expeditionDepartment =
          saveForm.CODIGO_DANE_EXPEDICION &&
          saveForm.CODIGO_DANE_EXPEDICION !== '0'
            ? saveForm.CODIGO_DANE_EXPEDICION.substring(0, 2)
            : '';
        let homeDepartment =
          saveForm.CODIGO_DANE && saveForm.CODIGO_DANE !== '0'
            ? saveForm.CODIGO_DANE.substring(0, 2)
            : '';
        let firstRefDepartment =
          saveForm.CODIGO_DANE_REF_1 && saveForm.CODIGO_DANE_REF_1 !== '0'
            ? saveForm.CODIGO_DANE_REF_1.substring(0, 2)
            : '';
        let secondRefDepartment =
          saveForm.CODIGO_DANE_REF_2 && saveForm.CODIGO_DANE_REF_2 !== '0'
            ? saveForm.CODIGO_DANE_REF_2.substring(0, 2)
            : '';
        if (expeditionDepartment) {
          if (saveForm.CODIGO_DANE_EXPEDICION.length < 5) {
            expeditionDepartment =
              '0' + saveForm.CODIGO_DANE_EXPEDICION.substring(0, 1);
          }
          this.getCities(expeditionDepartment);
        }
        if (homeDepartment) {
          if (saveForm.CODIGO_DANE.length < 5) {
            homeDepartment = '0' + saveForm.CODIGO_DANE.substring(0, 1);
          }
          this.getCities(homeDepartment, 'homeCities');
        }

        if (firstRefDepartment) {
          if (saveForm.CODIGO_DANE_REF_1.length < 5) {
            firstRefDepartment =
              '0' + saveForm.CODIGO_DANE_REF_1.substring(0, 1);
          }
          this.getCities(firstRefDepartment, 'firstRefCities');
        }
        if (secondRefDepartment) {
          if (saveForm.CODIGO_DANE_REF_2.length < 5) {
            secondRefDepartment =
              '0' + saveForm.CODIGO_DANE_REF_2.substring(0, 1);
          }
          this.getCities(secondRefDepartment, 'secondRefCities');
        }

        let partnersArray = [];

        if ('FORMULARIO_SOCIOS' in nextProps.formValidate) {
          let partnersForm = nextProps.formValidate['FORMULARIO_SOCIOS'];
          for (const key in partnersForm) {
            if (partnersForm.hasOwnProperty(key)) {
              const partner = partnersForm[key];
              partnersArray.push({
                documentType: partner.TIPO_DOCUMENTO_SOCIO,
                documentNumber: partner.DOCUMENTO_SOCIO,
                firstName: partner.NOMBRE_1_SOCIO,
                secondName: partner.NOMBRE_2_SOCIO,
                firstLastName: partner.APELLIDO_1_SOCIO,
                secondLastName: partner.APELLIDO_2_SOCIO,
              });
            }
          }
        }

        this.setState({
          clientType:
            saveForm.COMPRADOR_TIPO_DOCUMENTO !== 'N' ? 'natural' : 'legal',
          pointsOfSale: saveForm.I_PUNTO_VENTA,
          modeWP: saveForm.I_PREFIERE_WHATSAPP,
          modeEmail: saveForm.I_PREFIERE_EMAIL,
          modeSMS: saveForm.I_PREFIERE_SMS,
          modePhone: saveForm.I_PREFIERE_LLAMADA,
          expeditionDate: saveForm.FECHA_EXPEDICION
            ? moment(saveForm.FECHA_EXPEDICION).format('YYYY-MM-DD HH:mm:ss')
            : null,
          companyType: saveForm.TIPO_EMPRESA,
          // documentType: saveForm.COMPRADOR_TIPO_DOCUMENTO,
          // documentNumber: saveForm.COMPRADOR_DOCUMENTO,
          expeditionDepartment,
          expeditionCity: saveForm.CODIGO_DANE_EXPEDICION,
          firstRefFirstName: saveForm.NOMBRE_1_REF_1,
          firstRefSecondName: saveForm.NOMBRE_2_REF_1,
          firstRefFistLastName: saveForm.APELLIDO_1_REF_1,
          firstRefSecondLastName: saveForm.APELLIDO_2_REF_1,
          firstRefDepartment,
          firstRefCity: saveForm.CODIGO_DANE_REF_1,
          firstRefPhone:
            saveForm.TELEFONO_REF_1 && saveForm.TELEFONO_REF_1 !== '0'
              ? saveForm.TELEFONO_REF_1
              : '',
          firstRefEmail: saveForm.EMAIL_REF_1,
          secondRefFirstName: saveForm.NOMBRE_1_REF_2,
          secondRefSecondName: saveForm.NOMBRE_2_REF_2,
          secondRefFistLastName: saveForm.APELLIDO_1_REF_2,
          secondRefSecondLastName: saveForm.APELLIDO_2_REF_2,
          secondRefDepartment,
          secondRefCity: saveForm.CODIGO_DANE_REF_2,
          secondRefPhone:
            saveForm.TELEFONO_REF_2 && saveForm.TELEFONO_REF_2 !== '0'
              ? saveForm.TELEFONO_REF_2
              : '',
          secondRefEmail: saveForm.EMAIL_REF_2,
          legalRepresentativeFirstName: saveForm.NOMBRE_1,
          legalRepresentativeSecondName: saveForm.NOMBRE_2,
          legalRepresentativeFistLastName: saveForm.APELLIDO_1,
          legalRepresentativeSecondLastName: saveForm.APELLIDO_2,
          homeDepartment,
          homeCity: saveForm.CODIGO_DANE,
          // address: "",
          cellular:
            saveForm.CELULAR && saveForm.CELULAR !== '0'
              ? saveForm.CELULAR
              : '',
          phone:
            saveForm.TELEFONO && saveForm.TELEFONO !== '0'
              ? saveForm.TELEFONO
              : '',
          email: saveForm.CORREO,
          birthDate: saveForm.FECHA_NACIMIENTO
            ? moment(saveForm.FECHA_NACIMIENTO).format('YYYY-MM-DD HH:mm:ss')
            : null,
          economicActivity: saveForm.ACTIVIDAD_ECONOMICA,
          quotaValue:
            saveForm.CUPO_SOLICITADO && saveForm.CUPO_SOLICITADO !== '0'
              ? saveForm.CUPO_SOLICITADO
              : '',
          quotaValueMask:
            saveForm.CUPO_SOLICITADO && saveForm.CUPO_SOLICITADO !== '0'
              ? saveForm.CUPO_SOLICITADO
              : '',
          companyName: saveForm.RAZON_SOCIAL,
          legalRepresentativeDocumentType: saveForm.TIPO_DOCUMENTO_REPRESENTATE,
          legalRepresentativeDocumentNumber: saveForm.DOCUMENTO_REPRESENTANTE,
          constactPosition: saveForm.CARGO_CONTACTO,
          constactFirstName: saveForm.NOMBRE_1_CONTACTO,
          constactSecondName: saveForm.NOMBRE_2_CONTACTO,
          constactFirstLastName: saveForm.APELLIDO_1_CONTACTO,
          constactSecondLastName: saveForm.APELLIDO_2_CONTACTO,
          constactCellular:
            saveForm.TELEFONO_CONTACTO && saveForm.TELEFONO_CONTACTO !== '0'
              ? saveForm.TELEFONO_CONTACTO
              : '',
          constactEmail: saveForm.CORREO_CONTACTO,
          initialDate: saveForm.INICIO_ACTIVIDAD,
          ref1TypPerson:
            saveForm.TIPO_REF_1 === 'COMERCIAL' ? 'legal' : 'natural',
          ref2TypPerson:
            saveForm.TIPO_REF_2 === 'COMERCIAL' ? 'legal' : 'natural',
          ref1TypCompany:
            saveForm.TIPO_REF_1 === 'COMERCIAL' ? 'legal' : 'natural',
          ref2TypCompany:
            saveForm.TIPO_REF_2 === 'COMERCIAL' ? 'legal' : 'natural',
          partnersArray,
        });
      }
      if (
        'EXISTE_COMPRADOR' in nextProps.formValidate &&
        nextProps.formValidate['EXISTE_COMPRADOR'] ===
          'COMPRADOR CON CUPO ASIGNADO'
      ) {
        this.setState({
          existError: true,
        });
      }
    }
    if (nextProps.saveForm !== this.props.saveForm && nextProps.saveForm) {
      this.setState({
        filingNumber: nextProps.saveForm.data.RADICADO,
        lastSaveDate: nextProps.saveForm.time,
      });
      console.log('nextProps.saveForm', nextProps.saveForm);
    }
  }

  sendHash = (quotaNumber) => {
    const { user } = this.props;

    this.props.actions.sendHash({
      userName: user.userEmail,
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      clientIp: user.userInfo.IP,
      admissionDate: user.userInfo.FECHA_INGRESO,
      quotaNumber: quotaNumber,
    });
  };

  getClientTypes = (user) => {
    this.props.actions.getClientTypes({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
    });
  };

  loadData() {
    let paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const queryCode = params.get('threepayments');
    const documentType = params.get('documentType');
    const documentNumber = params.get('documentNumber');
    if (queryCode) {
      let newState = {
        threePayments: true,
        queryCode,
      };
      if (documentType && documentNumber) {
        newState['documentType'] = documentType;
        newState['documentNumber'] = documentNumber;
        if (documentType === 'N' && documentNumber.length === 10) {
          newState['documentNumber'] = documentNumber.substring(0, 9);
          newState['checkDigit'] = documentNumber.substr(
            documentNumber.length - 1
          );
        }
        if (documentType) {
          newState['clientType'] = documentType !== 'N' ? 'natural' : 'legal';
        }
      }
      this.setState(newState, () => {
        if (documentType && documentNumber) {
          this.validateForm();
        }
      });
    }
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
    this.props.actions.getDocuments();
  }

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  openQueryCodeModal = () => {
    this.setState({
      queryCodeModal: true,
    });
  };

  openEmailModal = () => {
    this.setState({
      emailModal: true,
    });
  };

  queryEmailModal = () => {
    this.setState({
      emailModal: false,
    });
  };

  closeEmailModalSucces = () => {
    this.setState({
      emailModalSucces: false,
    });
  };

  setQueryCode = (queryCode) => {
    this.setState({
      queryCodeModal: false,
      queryCode,
    });
  };

  handleDateChange = (date, input) => {
    this.setState({ [input]: date });
  };

  checkChange = () => (event) => {
    this.setState({ termsAccepted: event.target.checked });
  };

  checkChannelsChange = (value, key) => {
    this.setState({ [key]: value });
  };

  getCities = (departmentId, cityArray) => {
    let body = {
      departmentId,
    };
    if (cityArray) {
      body['cityArray'] = cityArray;
    }
    console.log('cityArray', cityArray);
    console.log('body', body);
    this.props.actions.getCities(body);
  };

  oninputChange = (event, input, cityArray) => {
    if (input === 'expeditionDepartment') {
      this.props.actions.getCities({
        departmentId: event.target.value,
      });
      this.setState({
        expeditionCity: '',
        [input]: event.target.value,
      });
      return;
    }
    if (
      input === 'cellular' ||
      input === 'constactCellular' ||
      input === 'firstRefPhone' ||
      input === 'secondRefPhone'
    ) {
      if (event.target.value.length < 11) {
        this.setState({
          [input]: event.target.value,
        });
      }
      return;
    }
    if (input === 'phone') {
      if (event.target.value.length < 8) {
        this.setState({
          [input]: event.target.value,
        });
      }
      return;
    }
    if (
      input === 'homeDepartment' ||
      input === 'firstRefDepartment' ||
      input === 'secondRefDepartment'
    ) {
      this.props.actions.getCities({
        departmentId: event.target.value,
        cityArray: cityArray,
      });
      this.setState({
        [input]: event.target.value,
      });
      return;
    }
    if (input === 'documentNumber' && event.target.value.length === 0) {
      this.clearData();
      return;
    }
    this.setState(
      {
        [input]: event.target.value,
      },
      () => {
        const {
          addressType,
          addressFirst,
          addressSecond,
          addressThird,
          addressOther,
          addressValid,
        } = this.state;
        let isValid = true;
        if (addressType === 'Otra') {
          if (addressOther !== '') {
            if (!/\d/.test(addressOther)) {
              isValid = false;
            }
          }
        } else {
          if (addressFirst !== '' && addressSecond && addressThird !== '') {
            if (
              !(
                /\d/.test(addressFirst) &&
                /\d/.test(addressSecond) &&
                /\d/.test(addressThird)
              )
            ) {
              isValid = false;
            }
          }
        }
        if (addressValid !== isValid) {
          this.setState({
            addressValid: isValid,
          });
        }
      }
    );
  };

  onQuotaValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, '');
    inputValue = inputValue.replace(/\./g, '');
    inputValue = inputValue.replace(/´/g, '');
    let quotaValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    this.setState({
      quotaValue,
      quotaValueMask: thousand,
    });
  };

  isValidEmail(emailUser, input) {
    if (!emailUser) {
      this.setState({
        [input]: true,
      });
      return;
    }
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_]+\.[A-Za-z-_.]+$/.test(emailUser)) {
      validEmail = true;
    }
    this.setState({
      [input]: validEmail,
    });
  }

  isEmail = (event, input) => {
    let email = event.target.value;
    let validName = input + 'Valid';
    this.isValidEmail(email, validName);
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_]+\.[A-Za-z-_.]+$/.test(email)) {
      validEmail = true;
    }
    if (validEmail) {
      this.setState({
        [input]: email,
        [validName]: validEmail,
      });
    } else {
      this.setState({
        [input]: email,
      });
    }
  };

  goHome = () => {
    this.props.actions.cleanUser();
    this.props.history.push(`/`);
  };

  goTerms = () => {
    this.props.history.push(`/terminos-y-condiciones`);
  };

  clearErroModal = () => {
    this.props.actions.cleanUser();
    this.setState({
      existError: false,
    });
  };

  openVideoModal = () => {
    this.setState({
      videoModal: true,
    });
  };

  closeVideoModal = () => {
    this.setState({
      videoModal: false,
    });
  };

  clearData = () => {
    this.props.actions.cleanUser();
    this.setState({
      menuOpen: false,
      pointsOfSale: 'S',
      modeWP: 'N',
      modeEmail: 'N',
      modeSMS: 'N',
      modePhone: 'N',
      expeditionDate: null,
      addressType: '',
      addressFirst: '',
      addressSecond: '',
      addressThird: '',
      addressOther: '',
      addressValid: true,
      documentType: '',
      documentNumber: '',
      checkDigit: '',
      expeditionDepartment: '',
      expeditionCity: '',
      companyType: '',
      termsAccepted: true,
      firstRefFirstName: '',
      firstRefSecondName: '',
      firstRefFistLastName: '',
      firstRefSecondLastName: '',
      firstRefDepartment: '',
      firstRefCity: '',
      firstRefPhone: '',
      firstRefEmail: '',
      firstRefEmailValid: true,
      secondRefFirstName: '',
      secondRefSecondName: '',
      secondRefFistLastName: '',
      secondRefSecondLastName: '',
      secondRefDepartment: '',
      secondRefCity: '',
      secondRefPhone: '',
      secondRefEmail: '',
      secondRefEmailValid: true,
      legalRepresentativeFirstName: '',
      legalRepresentativeSecondName: '',
      legalRepresentativeFistLastName: '',
      legalRepresentativeSecondLastName: '',
      homeDepartment: '',
      homeCity: '',
      address: '',
      cellular: '',
      phone: '',
      email: '',
      emailValid: true,
      birthDate: null,
      economicActivity: '',
      quotaValue: '',
      quotaValueMask: '',
      companyName: '',
      legalRepresentativeDocumentType: '',
      legalRepresentativeDocumentNumber: '',
      constactPosition: '',
      constactFirstName: '',
      constactSecondName: '',
      constactFirstLastName: '',
      constactSecondLastName: '',
      constactCellular: '',
      constactEmail: '',
      constactEmailValid: true,
      partnersArray: [
        {
          documentType: '',
          documentNumber: '',
          firstName: '',
          secondName: '',
          firstLastName: '',
          secondLastName: '',
        },
      ],
      contractNumber: '',
      queryCodeModal: false,
      emailModal: false,
      queryCode: '',
      initialDate: null,
      emailModalSucces: false,
    });
    if (this.state.threePayments) {
      this.props.history.push(`/`);
    }
  };

  onPartnersArrayValueChange = (event, keyName, index) => {
    let partnersArray = Object.assign([], this.state.partnersArray);
    partnersArray[index][keyName] = event.target.value;
    this.setState({
      partnersArray,
    });
  };

  addPartner = () => {
    let partnersArray = Object.assign([], this.state.partnersArray);
    partnersArray.push({
      documentType: '',
      documentNumber: '',
      firstName: '',
      secondName: '',
      firstLastName: '',
      secondLastName: '',
    });
    this.setState({
      partnersArray,
    });
  };

  removePartner = () => {
    let partnersArray = Object.assign([], this.state.partnersArray);
    partnersArray.pop();
    this.setState({
      partnersArray,
    });
  };

  partnersValid = () => {
    const { partnersArray, companyType } = this.state;
    let valid = true;
    if (companyType === 'UNIPERSONAL') {
      return true;
    }
    for (let index in partnersArray) {
      if (
        partnersArray[index].documentType === '' ||
        partnersArray[index].documentNumber === '' ||
        partnersArray[index].firstName === '' ||
        partnersArray[index].firstLastName === ''
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  onSendEmail = (email) => {
    this.setState(
      {
        emailModal: false,
      },
      () => {
        const { user } = this.props;
        this.props.actions.requestLinkClientStudy({
          userName: user.userEmail,
          documentType: user.userInfo.TIPO_DOCUMENTO,
          documentNumber: user.userInfo.DOCUMENTO,
          clientEmail: email,
          admissionDate: user.userInfo.FECHA_INGRESO,
        });
      }
    );
  };

  onSendRequest = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'enviar_estudio_cliente', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    const {
      expeditionDate,
      documentType,
      documentNumber,
      expeditionCity,
      homeCity,
      cellular,
      phone,
      email,
      birthDate,
      economicActivity,
      quotaValue,
      companyName,
      legalRepresentativeDocumentType,
      legalRepresentativeDocumentNumber,
      constactPosition,
      constactFirstName,
      constactCellular,
      constactEmail,
      partnersArray,
      legalRepresentativeFirstName,
      legalRepresentativeSecondName,
      legalRepresentativeFistLastName,
      legalRepresentativeSecondLastName,
      constactSecondName,
      constactFirstLastName,
      constactSecondLastName,
      initialDate,
      clientType,
      firstRefFirstName,
      firstRefSecondName,
      firstRefFistLastName,
      firstRefSecondLastName,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      secondRefFirstName,
      secondRefSecondName,
      secondRefFistLastName,
      secondRefSecondLastName,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      addressType,
      addressFirst,
      addressSecond,
      addressThird,
      addressOther,
      pointsOfSale,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      companyType,
      checkDigit,
      ref1TypCompany,
      ref2TypCompany,
      ref1TypPerson,
      ref2TypPerson,
      queryCode,
      threePayments,
    } = this.state;
    const { user } = this.props;
    let tempPartnersArray = [];
    for (let index in partnersArray) {
      tempPartnersArray.push({
        tipo_documento_socio: partnersArray[index].documentType,
        documento_socio: partnersArray[index].documentNumber,
        nom1_socio: partnersArray[index].firstName,
        nom2_socio: partnersArray[index].secondName,
        apel1_socio: partnersArray[index].firstLastName,
        apel2_socio: partnersArray[index].secondLastName,
      });
    }
    let parsedArray =
      clientType === 'legal' && companyType === 'SOCIEDAD'
        ? JSON.stringify(tempPartnersArray)
        : null;

    const clientRequest = {
      userEmail: user.userEmail,
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      sellerDocumentType: clientType !== 'legal' ? documentType : 'N',
      sellerDocumentNumber:
        clientType !== 'legal'
          ? documentNumber
          : `${documentNumber}${checkDigit}`,
      applicantName: companyName ? companyName : null,
      city: homeCity,
      address:
        addressType === 'Otra'
          ? addressOther
          : `${addressType} ${addressFirst} ${addressSecond} - ${addressThird}`,
      phone: phone,
      quota: quotaValue,
      expeditionDate: expeditionDate
        ? moment(expeditionDate).format('YYYYMMDD')
        : null,
      expeditionCity,
      cellular,
      email,
      birthDate: birthDate ? moment(birthDate).format('YYYYMMDD') : null,
      economicActivity,
      representativeDocumentType: legalRepresentativeDocumentType,
      representativeDocumentNumber: legalRepresentativeDocumentNumber,
      representativeName: legalRepresentativeFirstName
        ? `${legalRepresentativeFirstName}${
            legalRepresentativeSecondName
              ? ' ' + legalRepresentativeSecondName
              : ''
          }`
        : null,
      constactFirstName,
      constactPosition,
      constactCellular,
      constactEmail,
      partnersArray: parsedArray,
      representativeFistLastName: legalRepresentativeFistLastName
        ? legalRepresentativeFistLastName
        : null,
      representativeSecondLastName: legalRepresentativeSecondLastName
        ? legalRepresentativeSecondLastName
        : null,
      constactSecondName: constactSecondName ? constactSecondName : '',
      constactFirstLastName: constactFirstLastName
        ? constactFirstLastName
        : null,
      constactSecondLastName: constactSecondLastName
        ? constactSecondLastName
        : null,
      creationDate: initialDate ? initialDate : null,
      firstRefFirstName,
      firstRefSecondName,
      firstRefFistLastName,
      firstRefSecondLastName,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      secondRefFirstName,
      secondRefSecondName,
      secondRefFistLastName,
      secondRefSecondLastName,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      pointsOfSale,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      companyType,
      refType:
        clientType !== 'legal'
          ? ref1TypPerson !== 'legal'
            ? 'PERSONAL'
            : 'COMERCIAL'
          : ref1TypCompany !== 'legal'
          ? 'PERSONAL'
          : 'COMERCIAL',
      refType2:
        clientType !== 'legal'
          ? ref2TypPerson !== 'legal'
            ? 'PERSONAL'
            : 'COMERCIAL'
          : ref2TypCompany !== 'legal'
          ? 'PERSONAL'
          : 'COMERCIAL',
      queryCode,
    };

    if (threePayments) {
      this.props.actions.requestThreePaymentsClientStudy(clientRequest);
    } else {
      this.props.actions.requestClientStudy(clientRequest);
    }

    // parsedArray = parsedArray.replace(/["']/g, "");
    // creationDate: moment().subtract(1, 'years').format('DD/MM/YYYY'),
  };

  handleFileChange = (keyFile, imageFile) => {
    // const { user } = this.props;
    this.setState({
      [keyFile]: imageFile[0],
    });
  };

  isvalidNit = () => {
    const {
      expeditionDate,
      documentNumber,
      checkDigit,
      expeditionCity,
      homeCity,
      cellular,
      phone,
      email,
      birthDate,
      economicActivity,
      quotaValue,
      companyName,
      legalRepresentativeDocumentType,
      legalRepresentativeDocumentNumber,
      constactPosition,
      constactFirstName,
      constactCellular,
      constactEmail,
      legalRepresentativeFirstName,
      legalRepresentativeFistLastName,
      constactFirstLastName,
      initialDate,
      termsAccepted,
      firstRefFirstName,
      firstRefFistLastName,
      firstRefDepartment,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      firstRefEmailValid,
      secondRefFirstName,
      secondRefFistLastName,
      secondRefDepartment,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      secondRefEmailValid,
      addressType,
      addressFirst,
      addressSecond,
      addressThird,
      addressOther,
      addressValid,
      companyType,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      ref1TypCompany,
    } = this.state;
    let isvalidNit = true;
    if (!(this.partnersValid() && companyName !== '')) {
      isvalidNit = false;
    }
    if (
      !(
        economicActivity !== '' &&
        legalRepresentativeDocumentNumber !== '' &&
        legalRepresentativeDocumentType !== ''
      )
    ) {
      isvalidNit = false;
    }
    if (
      !(
        birthDate !== null &&
        quotaValue !== '' &&
        constactPosition !== '' &&
        constactFirstName !== '' &&
        constactCellular !== ''
      )
    ) {
      isvalidNit = false;
    }
    if (
      !(
        constactEmail !== '' &&
        documentNumber !== '' &&
        checkDigit !== '' &&
        expeditionDate !== null &&
        constactFirstLastName !== ''
      )
    ) {
      isvalidNit = false;
    }

    if (
      legalRepresentativeDocumentType !== 'E' &&
      (expeditionCity === null || expeditionCity === '')
    ) {
      isvalidNit = false;
    }
    if (!(legalRepresentativeFirstName !== '' && homeCity !== '')) {
      isvalidNit = false;
    }
    if (
      !(
        cellular !== '' &&
        email !== '' &&
        legalRepresentativeFistLastName !== '' &&
        initialDate !== ''
      )
    ) {
      isvalidNit = false;
    }
    if (
      !(
        termsAccepted &&
        firstRefFirstName !== '' &&
        firstRefDepartment !== '' &&
        firstRefCity !== '' &&
        firstRefPhone !== ''
      )
    ) {
      isvalidNit = false;
    }
    if (ref1TypCompany !== 'legal' && firstRefFistLastName === '') {
      isvalidNit = false;
    }
    // if (
    //   !(
    //     secondRefFistLastName !== "" &&
    //     secondRefDepartment !== "" &&
    //     secondRefCity !== "" &&
    //     secondRefPhone !== "" &&
    //     secondRefFirstName !== "" &&
    //     secondRefEmail !== "" &&
    //     secondRefEmailValid
    //   )
    // ) {
    //   isvalidNit = false;
    // }
    if (!(companyType !== '' && addressType !== '' && addressValid)) {
      isvalidNit = false;
    }
    if (addressType === 'Otra') {
      if (!(addressOther !== '')) {
        isvalidNit = false;
      }
    } else {
      if (!(addressFirst !== '' && addressSecond && addressThird !== '')) {
        isvalidNit = false;
      }
    }
    if (
      !(
        modeWP === 'S' ||
        modeEmail === 'S' ||
        modeSMS === 'S' ||
        modePhone === 'S'
      )
    ) {
      isvalidNit = false;
    }
    return isvalidNit;
  };

  isvalidC = () => {
    const {
      expeditionDate,
      documentType,
      documentNumber,
      expeditionCity,
      homeCity,
      cellular,
      phone,
      email,
      birthDate,
      economicActivity,
      quotaValue,
      legalRepresentativeFirstName,
      legalRepresentativeFistLastName,
      initialDate,
      termsAccepted,
      firstRefFirstName,
      firstRefFistLastName,
      firstRefDepartment,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      firstRefEmailValid,
      secondRefFirstName,
      secondRefFistLastName,
      secondRefDepartment,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      secondRefEmailValid,
      addressType,
      addressFirst,
      addressSecond,
      addressThird,
      addressOther,
      addressValid,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      ref1TypPerson,
    } = this.state;
    let isvalidC = true;
    if (
      !(documentType !== '' && documentNumber !== '' && expeditionDate !== null)
    ) {
      isvalidC = false;
    }
    if (
      documentType !== 'E' &&
      (expeditionCity === null || expeditionCity === '')
    ) {
      isvalidC = false;
    }
    if (
      !(
        legalRepresentativeFirstName !== '' &&
        legalRepresentativeFistLastName !== ''
      )
    ) {
      isvalidC = false;
    }
    if (!(homeCity !== '' && economicActivity !== '' && quotaValue !== '')) {
      isvalidC = false;
    }
    if (
      !(
        cellular !== '' &&
        email !== '' &&
        birthDate !== null &&
        initialDate !== ''
      )
    ) {
      isvalidC = false;
    }

    if (
      !(
        termsAccepted &&
        firstRefFirstName !== '' &&
        firstRefDepartment !== '' &&
        firstRefCity !== '' &&
        firstRefPhone !== ''
      )
    ) {
      isvalidC = false;
    }
    if (ref1TypPerson !== 'legal' && firstRefFistLastName === '') {
      isvalidC = false;
    }

    // if (
    //   !(
    //     secondRefFistLastName !== "" &&
    //     secondRefDepartment !== "" &&
    //     secondRefCity !== "" &&
    //     secondRefPhone !== "" &&
    //     secondRefFirstName !== "" &&
    //     secondRefEmail !== "" &&
    //     secondRefEmailValid
    //   )
    // ) {
    //   isvalidC = false;
    // }

    if (!(addressType !== '' && addressValid)) {
      isvalidC = false;
    }

    if (addressType === 'Otra') {
      if (!(addressOther !== '')) {
        isvalidC = false;
      }
    } else {
      if (!(addressFirst !== '' && addressSecond && addressThird !== '')) {
        isvalidC = false;
      }
    }

    if (
      !(
        modeWP === 'S' ||
        modeEmail === 'S' ||
        modeSMS === 'S' ||
        modePhone === 'S'
      )
    ) {
      isvalidC = false;
    }

    return isvalidC;
  };

  copyInfo = () => {
    const {
      cellular,
      email,
      legalRepresentativeFirstName,
      legalRepresentativeSecondName,
      legalRepresentativeFistLastName,
      legalRepresentativeSecondLastName,
    } = this.state;
    this.setState({
      constactCellular: cellular,
      constactEmail: email,
      constactFirstName: legalRepresentativeFirstName,
      constactSecondName: legalRepresentativeSecondName,
      constactFirstLastName: legalRepresentativeFistLastName,
      constactSecondLastName: legalRepresentativeSecondLastName,
    });
  };

  copyInfoPartners = () => {
    const {
      legalRepresentativeFirstName,
      legalRepresentativeSecondName,
      legalRepresentativeFistLastName,
      legalRepresentativeSecondLastName,
      legalRepresentativeDocumentType,
      legalRepresentativeDocumentNumber,
    } = this.state;

    let partnersArray = Object.assign([], this.state.partnersArray);
    let copyData = {
      documentType: legalRepresentativeDocumentType,
      documentNumber: legalRepresentativeDocumentNumber,
      firstName: legalRepresentativeFirstName,
      secondName: legalRepresentativeSecondName,
      firstLastName: legalRepresentativeFistLastName,
      secondLastName: legalRepresentativeSecondLastName,
    };
    if (partnersArray.length > 0) {
      partnersArray[0] = copyData;
    } else {
      partnersArray.push(copyData);
    }
    this.setState({
      partnersArray,
    });
  };

  openLink = () => {
    const { documents } = this.props;
    var document = documents.find((element) => {
      return element.DOCUMENTO === 'Formato Autorizacion Cara 2';
    });
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'descarga_formato_fau', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    window.open(document.LINK, '_newtab');
  };

  validateForm = () => {
    const { documentType, documentNumber, checkDigit, clientType } = this.state;
    const { user } = this.props;
    if (documentNumber) {
      this.props.actions.validateForm({
        documentType: clientType !== 'legal' ? documentType : 'N',
        documentNumber:
          clientType !== 'legal'
            ? documentNumber
            : `${documentNumber}${checkDigit}`,
        userDocument: user.userInfo.DOCUMENTO,
        userDocumentType: user.userInfo.TIPO_DOCUMENTO,
        userEmail: user.userEmail,
      });
    }
  };

  saveForm = () => {
    const {
      expeditionDate,
      documentType,
      documentNumber,
      expeditionCity,
      homeCity,
      cellular,
      phone,
      email,
      birthDate,
      economicActivity,
      quotaValue,
      companyName,
      legalRepresentativeDocumentType,
      legalRepresentativeDocumentNumber,
      constactPosition,
      constactFirstName,
      constactCellular,
      constactEmail,
      partnersArray,
      legalRepresentativeFirstName,
      legalRepresentativeSecondName,
      legalRepresentativeFistLastName,
      legalRepresentativeSecondLastName,
      constactSecondName,
      constactFirstLastName,
      constactSecondLastName,
      initialDate,
      clientType,
      firstRefFirstName,
      firstRefSecondName,
      firstRefFistLastName,
      firstRefSecondLastName,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      secondRefFirstName,
      secondRefSecondName,
      secondRefFistLastName,
      secondRefSecondLastName,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      addressType,
      addressFirst,
      addressSecond,
      addressThird,
      addressOther,
      pointsOfSale,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      companyType,
      checkDigit,
      filingNumber,
      ref1TypPerson,
      ref2TypPerson,
      ref1TypCompany,
      ref2TypCompany,
    } = this.state;
    if (!documentNumber) {
      return;
    }
    const { user } = this.props;
    let tempPartnersArray = [];
    for (let index in partnersArray) {
      tempPartnersArray.push({
        tipo_documento_socio: partnersArray[index].documentType,
        documento_socio: partnersArray[index].documentNumber,
        nom1_socio: partnersArray[index].firstName,
        nom2_socio: partnersArray[index].secondName,
        apel1_socio: partnersArray[index].firstLastName,
        apel2_socio: partnersArray[index].secondLastName,
      });
    }
    let parsedArray =
      clientType === 'legal' && companyType === 'SOCIEDAD'
        ? JSON.stringify(tempPartnersArray)
        : null;
    // parsedArray = parsedArray.replace(/["']/g, "");
    this.props.actions.saveForm({
      userEmail: user.userEmail,
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      sellerDocumentType: clientType !== 'legal' ? documentType : 'N',
      sellerDocumentNumber:
        clientType !== 'legal'
          ? documentNumber
          : `${documentNumber}${checkDigit}`,
      applicantName: companyName ? companyName : null,
      city: homeCity ? homeCity : null,
      address:
        !addressType || !addressFirst || !addressSecond || !addressThird
          ? ''
          : addressType === 'Otra'
          ? addressOther
          : `${addressType} ${addressFirst} ${addressSecond} - ${addressThird}`,
      phone: phone,
      quota: quotaValue,
      expeditionDate: expeditionDate
        ? moment(expeditionDate).format('YYYYMMDD')
        : null,
      expeditionCity,
      cellular,
      email,
      birthDate: birthDate ? moment(birthDate).format('YYYYMMDD') : null,
      economicActivity,
      representativeDocumentType: legalRepresentativeDocumentType,
      representativeDocumentNumber: legalRepresentativeDocumentNumber,
      representativeFisrtName: legalRepresentativeFirstName
        ? legalRepresentativeFirstName
        : '',
      representativeSecondName: legalRepresentativeSecondName
        ? legalRepresentativeSecondName
        : '',
      constactFirstName,
      constactPosition,
      constactCellular,
      constactEmail,
      partnersArray: parsedArray,
      representativeFistLastName: legalRepresentativeFistLastName
        ? legalRepresentativeFistLastName
        : null,
      representativeSecondLastName: legalRepresentativeSecondLastName
        ? legalRepresentativeSecondLastName
        : null,
      constactSecondName: constactSecondName ? constactSecondName : '',
      constactFirstLastName: constactFirstLastName
        ? constactFirstLastName
        : null,
      constactSecondLastName: constactSecondLastName
        ? constactSecondLastName
        : null,
      creationDate: initialDate ? initialDate : null,
      firstRefFirstName,
      firstRefSecondName,
      firstRefFistLastName,
      firstRefSecondLastName,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      secondRefFirstName,
      secondRefSecondName,
      secondRefFistLastName,
      secondRefSecondLastName,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      pointsOfSale,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      companyType,
      refType:
        clientType !== 'legal'
          ? ref1TypPerson !== 'legal'
            ? 'PERSONAL'
            : 'COMERCIAL'
          : ref1TypCompany !== 'legal'
          ? 'PERSONAL'
          : 'COMERCIAL',
      refType2:
        clientType !== 'legal'
          ? ref2TypPerson !== 'legal'
            ? 'PERSONAL'
            : 'COMERCIAL'
          : ref2TypCompany !== 'legal'
          ? 'PERSONAL'
          : 'COMERCIAL',
      filingNumber,
    });
  };

  render() {
    const {
      menuOpen,
      expeditionDate,
      documentType,
      documentNumber,
      checkDigit,
      expeditionDepartment,
      expeditionCity,
      homeDepartment,
      homeCity,
      cellular,
      phone,
      email,
      birthDate,
      economicActivity,
      quotaValueMask,
      emailValid,
      companyName,
      legalRepresentativeDocumentType,
      legalRepresentativeDocumentNumber,
      constactPosition,
      constactFirstName,
      constactCellular,
      constactEmail,
      constactEmailValid,
      partnersArray,
      legalRepresentativeFirstName,
      legalRepresentativeSecondName,
      legalRepresentativeFistLastName,
      legalRepresentativeSecondLastName,
      constactSecondName,
      constactFirstLastName,
      constactSecondLastName,
      queryCodeModal,
      initialDate,
      emailModal,
      emailModalSucces,
      quotaValue,
      clientType,
      pointsOfSale,
      addressType,
      addressFirst,
      addressSecond,
      addressThird,
      addressOther,
      addressValid,
      modeWP,
      modeEmail,
      modeSMS,
      modePhone,
      firstRefFirstName,
      firstRefSecondName,
      firstRefFistLastName,
      firstRefSecondLastName,
      firstRefDepartment,
      firstRefCity,
      firstRefPhone,
      firstRefEmail,
      firstRefEmailValid,
      secondRefFirstName,
      secondRefSecondName,
      secondRefFistLastName,
      secondRefSecondLastName,
      secondRefDepartment,
      secondRefCity,
      secondRefPhone,
      secondRefEmail,
      secondRefEmailValid,
      companyType,
      termsAccepted,
      ref1TypCompany,
      ref2TypCompany,
      ref1TypPerson,
      ref2TypPerson,
      lastSaveDate,
      existError,
      threePayments,
      videoModal,
    } = this.state;
    const {
      globals,
      loading,
      cities,
      homeCities,
      firstRefCities,
      secondRefCities,
      clientStudyRequest,
    } = this.props;
    let departments =
      globals && globals.DEPARTAMENTOS ? globals.DEPARTAMENTOS : [];
    let citiesArray = cities ? cities : [];
    let homeCitiesArray = homeCities ? homeCities : [];
    let firstRefCitiesArray = firstRefCities ? firstRefCities : [];
    let secondRefCitiesArray = secondRefCities ? secondRefCities : [];
    let errorModalText = clientStudyRequest
      ? clientStudyRequest['MENSAJE_EXTERNO ']
      : '';
    let showForm = 1;
    let date1 = '';
    let date2 = '';
    let date3 = '';
    let consecutive = '';

    if (clientStudyRequest && 'RAZON ' in clientStudyRequest) {
      switch (parseInt(clientStudyRequest['RAZON '])) {
        case 0:
          if (
            threePayments &&
            'FECHAS_CUOTAS ' in clientStudyRequest &&
            'VALOR_CUOTAS ' in clientStudyRequest
          ) {
            const datesArray = clientStudyRequest['FECHAS_CUOTAS '].split('~');
            const consecutiveArray =
              clientStudyRequest['VALOR_CUOTAS '].split('~');
            date1 = moment(datesArray[0], 'YYYYMMDD').format('DD/MM/YYYY');
            date2 = moment(datesArray[1], 'YYYYMMDD').format('DD/MM/YYYY');
            date3 = moment(datesArray[2], 'YYYYMMDD').format('DD/MM/YYYY');
            consecutive = consecutiveArray[3];
          }
          showForm = 0;
          break;
        case 1:
          showForm = 3;
          break;
        case 2:
          showForm = 2;
          break;
        case 3:
          showForm = 4;
          break;
        default:
          showForm = 1;
          break;
      }
    }
    // let showForm =
    //   parseInt(clientStudyRequest["RAZON "]) === 3
    //     ? 4
    //     : clientStudyRequest
    //     ? clientStudyRequest.DESCRIPCION === "TRANSACCION NEGADA" ||
    //       clientStudyRequest.DESCRIPCION === "DATOS INVALIDOS"
    //       ? 3
    //       : parseInt(clientStudyRequest["RAZON "]) === 0
    //       ? 0
    //       : 2
    //     : 1;
    // console.log('clientStudyRequest', clientStudyRequest)
    let approvedQuota =
      clientStudyRequest && 'ESTUDIO_CUPO ' in clientStudyRequest
        ? clientStudyRequest['CUPO']
        : 0;
    let thousand = approvedQuota
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }

    return (
      <div className='client-study'>
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={15} />
        <Header openMenu={this.openMenu} />
        <QueryCodeModal
          show={queryCodeModal}
          confirm={this.setQueryCode}
          onHide={this.goHome}
          clientStudy
        />
        <EmailModal
          show={emailModal}
          confirm={this.onSendEmail}
          onHide={this.queryEmailModal}
          clientStudy
        />
        <EmailModalSucces
          show={emailModalSucces}
          onHide={this.closeEmailModalSucces}
        />
        <StudyModalSucces
          show={showForm === 2}
          onHide={this.clearData}
          quotaValue={quotaValue}
          showInfo
          title={
            threePayments
              ? 'Transacción radicada'
              : '¡Tu solicitud ha sido radicada!'
          }
          code={
            clientStudyRequest && 'ESTUDIO_CUPO ' in clientStudyRequest
              ? clientStudyRequest['ESTUDIO_CUPO ']
              : ''
          }
          documentNumber={
            clientType !== 'legal'
              ? documentNumber
              : `${documentNumber}${checkDigit}`
          }
          quotaValueMask={quotaValueMask}
          name={
            clientType === 'legal'
              ? companyName
              : `${legalRepresentativeFirstName} ${legalRepresentativeFistLastName}`
          }
          threePayments={threePayments}
        />
        <StudyModalSucces
          show={showForm === 3}
          onHide={this.clearData}
          quotaValue={quotaValue}
          title={
            threePayments ? 'Transacción negada' : 'Tu solicitud ha sido negada'
          }
          code={
            clientStudyRequest && 'ESTUDIO_CUPO ' in clientStudyRequest
              ? clientStudyRequest['ESTUDIO_CUPO ']
              : ''
          }
          documentNumber={
            clientType !== 'legal'
              ? documentNumber
              : `${documentNumber}${checkDigit}`
          }
          quotaValueMask={quotaValueMask}
          threePayments={threePayments}
          name={
            clientType === 'legal'
              ? companyName
              : `${legalRepresentativeFirstName} ${legalRepresentativeFistLastName}`
          }
        />
        <StudyModalSucces
          show={showForm === 0}
          onHide={this.clearData}
          quotaValue={quotaValue}
          date1={date1}
          date2={date2}
          date3={date3}
          consecutive={consecutive}
          title={
            threePayments
              ? 'Transacción exitosa'
              : '¡Tu solicitud ha sido aprobada!'
          }
          threePayments={threePayments}
          code={
            clientStudyRequest && 'ESTUDIO_CUPO ' in clientStudyRequest
              ? clientStudyRequest['ESTUDIO_CUPO ']
              : ''
          }
          approvedQuota={thousand}
          documentNumber={
            clientType !== 'legal'
              ? documentNumber
              : `${documentNumber}${checkDigit}`
          }
          quotaValueMask={quotaValueMask}
          name={
            clientType === 'legal'
              ? companyName
              : `${legalRepresentativeFirstName} ${legalRepresentativeFistLastName}`
          }
        />
        <ErrorModal
          show={showForm === 4}
          confirm={this.clearErroModal}
          onHide={this.clearErroModal}
          errorText={decodeURIComponent(escape(errorModalText))}
          errorTitle={' '}
        />
        {videoModal && <VideoModal show={true} onHide={this.closeVideoModal} />}
        <ErrorModal
          show={existError}
          confirm={this.clearErroModal}
          onHide={this.clearErroModal}
          errorText='Este comprador ya tiene un cupo asignado'
          errorTitle={' '}
        />
        <div className='page-body-container center-horizontally'>
          <div className='page-body'>
            <UserHeader />
            {showForm === 1 && (
              <Col xs={12} className='general-main-card'>
                {loading && <Loading />}
                {!threePayments && (
                  <div className='banner-container'>
                    <img
                      src={banner}
                      className='banner'
                      alt='flecha verde'
                      onClick={this.openVideoModal}
                    />
                  </div>
                )}
                <div xs={12} className='goback-container'>
                  <div
                    className='goback-action'
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt='arrow back'
                        className='goback-icon'
                      />
                    </span>
                    <span className='goback-text'>Volver</span>
                  </div>
                </div>
                <div className='sub-container'>
                  <Col xs={12}>
                    <Row>
                      <Col xs={6}>
                        {threePayments && (
                          <span className='client-study-main-card_title'>
                            {threePayments ? 'Transacción' : 'Estudio cliente'}
                          </span>
                        )}
                      </Col>
                      {threePayments ? (
                        <Col xs={6} className='card-logo'>
                          <img src={threePaymentsIcon} alt='icono tres pagos' />
                        </Col>
                      ) : (
                        <Col
                          xs={6}
                          className='client-study-main-card_link-container'
                          onClick={(e) => {
                            e.preventDefault();
                            this.openEmailModal();
                          }}
                        >
                          <span className='client-study-main-card_link'>
                            Enviar al cliente
                            <img
                              src={arrowgreen}
                              alt='flecha verde'
                              className='link-arrow'
                            />
                          </span>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={12} className='client-study-main-card_divider'>
                    <hr />
                  </Col>
                  <Col xs={12} className='client-study-main-card_body-title'>
                    Complete los campos a continuación (campos obligatorios *)
                  </Col>
                  <Col xs={12} className='client-study-main-card_body-title'>
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        className='login-input-container client-study-main-card_radio-container'
                      >
                        <RadioGroup
                          aria-label='clientType1'
                          name='clientType1'
                          value={clientType}
                          onChange={(event) => {
                            this.oninputChange(event, 'clientType');
                          }}
                        >
                          <FormControlLabel
                            value='legal'
                            className='client-study-main-card_radio'
                            control={<Radio color='primary' />}
                            label='Persona Jurídica'
                            labelPlacement='end'
                          />
                        </RadioGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className='login-input-container client-study-main-card_radio-container'
                      >
                        <RadioGroup
                          aria-label='clientType2'
                          name='clientType2'
                          value={clientType}
                          onChange={(event) => {
                            console.log('onChange', event.target.value);
                            this.oninputChange(event, 'clientType');
                          }}
                        >
                          <FormControlLabel
                            value='natural'
                            className='client-study-main-card_radio'
                            control={<Radio color='primary' />}
                            label='Persona Natural'
                            labelPlacement='end'
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                  </Col>
                  {clientType !== 'legal' && (
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='documentType'
                            select
                            label='Tipo de documento *'
                            className='home-input'
                            value={documentType}
                            disabled={threePayments}
                            onChange={(event) => {
                              this.oninputChange(event, 'documentType');
                            }}
                            margin='dense'
                            fullWidth
                          >
                            {documentTypesPartners.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='documentNumber'
                            label='Número de documento *'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={documentNumber}
                            disabled={threePayments}
                            onChange={(event) => {
                              this.oninputChange(event, 'documentNumber');
                            }}
                            fullWidth
                            onBlur={this.validateForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeFirstName'
                            label='Primer Nombre *'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeFirstName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeFirstName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeSecondName'
                            label='Segundo Nombre'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeSecondName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeSecondName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeFistLastName'
                            label='Primer apellido *'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeFistLastName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeFistLastName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeSecondLastName'
                            label='Segundo Apellido'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeSecondLastName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeSecondLastName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className='login-input-container date-picker-container'
                        >
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            moment={moment}
                          >
                            <DatePicker
                              keyboard
                              disableOpenOnEnter
                              placeholder='DD/MM/YYYY'
                              invalidDateMessage='Formato inválido'
                              mask={(value) =>
                                value
                                  ? [
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]
                                  : []
                              }
                              locale='es-ES'
                              animateYearScrolling={false}
                              onInputChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/_/g, '');
                                if (value.length === 10) {
                                  this.handleDateChange(
                                    moment(value, 'DD/MM/YYYY'),
                                    'expeditionDate'
                                  );
                                }
                              }}
                              maxDate={moment(new Date())}
                              margin='normal'
                              label='Fecha de expedición *'
                              className='home-input'
                              value={expeditionDate}
                              onChange={(date) => {
                                this.handleDateChange(date, 'expeditionDate');
                              }}
                              fullWidth
                              onBlur={this.saveForm}
                              format='DD/MM/YYYY'
                              keyboardIcon={
                                <img
                                  src={calendaricon}
                                  alt='icono calendario'
                                  className='calendar-icon'
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='expeditionDepartment'
                            select
                            label={`Departamento de expedición${
                              documentType === 'E' ? '' : ' *'
                            }`}
                            className='home-input'
                            margin='dense'
                            value={expeditionDepartment}
                            onChange={(event) => {
                              this.oninputChange(event, 'expeditionDepartment');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {departments.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='expeditionCity'
                            select
                            label={`Ciudad de expedición${
                              documentType === 'E' ? '' : ' *'
                            }`}
                            className='home-input'
                            margin='dense'
                            value={expeditionCity}
                            onChange={(event) => {
                              this.oninputChange(event, 'expeditionCity');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {citiesArray.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='homeDepartment'
                            select
                            label='Departamento de residencia *'
                            className='home-input'
                            margin='dense'
                            value={homeDepartment}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'homeDepartment',
                                'homeCities'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {departments.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='homeCity'
                            select
                            label='Ciudad de residencia *'
                            className='home-input'
                            margin='dense'
                            value={homeCity}
                            onChange={(event) => {
                              this.oninputChange(event, 'homeCity');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {homeCitiesArray.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <Row>
                            <Col xs={12} className='client-study_address-title'>
                              Dirección *
                            </Col>
                            <Col xs={12}>
                              {addressType !== 'Otra' ? (
                                <Row>
                                  <Col xs='auto'>
                                    <Select
                                      value={addressType}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          'addressType'
                                        );
                                      }}
                                      className='client-study_address-select'
                                      name='addressType'
                                    >
                                      {addressFields.map((item, index) => {
                                        return (
                                          <MenuItem key={item} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Col>
                                  <Col className='address-padding'>
                                    <TextField
                                      id='addressFirst'
                                      className='home-input'
                                      value={addressFirst}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressFirst'
                                        );
                                      }}
                                    />
                                  </Col>
                                  <div>#</div>
                                  <Col className='address-padding'>
                                    <TextField
                                      id='addressSecond'
                                      className='home-input'
                                      value={addressSecond}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressSecond'
                                        );
                                      }}
                                    />
                                  </Col>
                                  <div>-</div>
                                  <Col className='address-padding-left'>
                                    <TextField
                                      id='addressThird'
                                      className='home-input'
                                      value={addressThird}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressThird'
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col xs='auto'>
                                    <Select
                                      className='client-study_address-select'
                                      value={addressType}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          'addressType'
                                        );
                                      }}
                                      name='addressType'
                                    >
                                      {addressFields.map((item, index) => {
                                        return (
                                          <MenuItem key={item} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Col>
                                  <Col className='address-padding-left'>
                                    <TextField
                                      id='addressOther'
                                      className='home-input address-other'
                                      value={addressOther}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressOther'
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            {!addressValid && (
                              <Col
                                xs={12}
                                className='client-study_address-error'
                              >
                                Debe tener al menos un numero en cada casilla
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='cellular'
                            label='Celular *'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={cellular}
                            inputProps={{ maxLength: 10 }}
                            onChange={(event) => {
                              this.oninputChange(event, 'cellular');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='phone'
                            label='Teléfono fijo'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={phone}
                            inputProps={{ maxLength: 7 }}
                            onChange={(event) => {
                              this.oninputChange(event, 'phone');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='email'
                            label='Correo *'
                            className='home-input'
                            margin='dense'
                            value={email}
                            onChange={(event) => {
                              this.isEmail(event, 'email');
                            }}
                            error={email && !emailValid ? true : false}
                            helperText={
                              email && !emailValid
                                ? 'Escriba un formato de email válido'
                                : null
                            }
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className='login-input-container date-picker-container'
                        >
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            moment={moment}
                          >
                            <DatePicker
                              keyboard
                              disableOpenOnEnter
                              placeholder='DD/MM/YYYY'
                              invalidDateMessage='Formato inválido'
                              mask={(value) =>
                                value
                                  ? [
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]
                                  : []
                              }
                              maxDate={moment(new Date())}
                              margin='normal'
                              label='Fecha de nacimiento *'
                              className='home-input'
                              value={birthDate}
                              animateYearScrolling={false}
                              onInputChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/_/g, '');
                                if (value.length === 10) {
                                  this.handleDateChange(
                                    moment(value, 'DD/MM/YYYY'),
                                    'birthDate'
                                  );
                                }
                              }}
                              onChange={(date) => {
                                this.handleDateChange(date, 'birthDate');
                              }}
                              fullWidth
                              onBlur={this.saveForm}
                              format='DD/MM/YYYY'
                              keyboardIcon={
                                <img
                                  src={calendaricon}
                                  alt='icono calendario'
                                  className='calendar-icon'
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='economicActivity'
                            label='Actividad económica *'
                            className='home-input'
                            margin='dense'
                            value={economicActivity}
                            onChange={(event) => {
                              this.oninputChange(event, 'economicActivity');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='initialDate'
                            name='initialDate'
                            select
                            label='¿Cuanto lleva en actividad comercial? *'
                            className='home-input'
                            margin='dense'
                            value={initialDate}
                            onChange={(event) => {
                              this.oninputChange(event, 'initialDate');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            {initialDateFields.map((item, index) => {
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>

                        <Col xs={12} md={6} className='login-input-container'>
                          <Row>
                            <Col
                              xs={12}
                              className='client-study_points-of-sale'
                            >
                              ¿Tiene puntos de venta?
                            </Col>
                            <Col xs='auto'>
                              <RadioGroup
                                aria-label='pointsOfSale'
                                name='pointsOfSale'
                                value={pointsOfSale}
                                onChange={(event) => {
                                  this.oninputChange(event, 'pointsOfSale');
                                }}
                              >
                                <FormControlLabel
                                  value='S'
                                  className='client-study-main-card_radio'
                                  control={<Radio color='primary' />}
                                  label='Si'
                                  labelPlacement='end'
                                />
                              </RadioGroup>
                            </Col>
                            <Col xs='auto'>
                              <RadioGroup
                                aria-label='pointsOfSale'
                                name='pointsOfSale'
                                value={pointsOfSale}
                                onChange={(event) => {
                                  this.oninputChange(event, 'pointsOfSale');
                                }}
                              >
                                <FormControlLabel
                                  value='N'
                                  className='client-study-main-card_radio'
                                  control={<Radio color='primary' />}
                                  label='No'
                                  labelPlacement='end'
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='quota'
                            label={
                              threePayments
                                ? 'Valor de la factura *'
                                : 'Cupo a solicitar *'
                            }
                            className='home-input'
                            margin='dense'
                            value={quotaValueMask}
                            onChange={this.onQuotaValueChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          className='client-study-main-card_body-title'
                        >
                          <Row>
                            <Col
                              xs={12}
                              className='client-study_points-of-sale'
                            >
                              ¿Por cual de estos canales desea ser contactado? *
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modeWP === 'S'}
                                    onChange={(event) => {
                                      let newValue = modeWP === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modeWP'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='Whastapp'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modeEmail === 'S'}
                                    onChange={(event) => {
                                      let newValue =
                                        modeEmail === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modeEmail'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='Correo electrónico'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modeSMS === 'S'}
                                    onChange={(event) => {
                                      let newValue =
                                        modeSMS === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modeSMS'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='SMS'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modePhone === 'S'}
                                    onChange={(event) => {
                                      let newValue =
                                        modePhone === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modePhone'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='Llamada'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs={12} className='client-study_mode-helper'>
                              *Recuerda que puedes seleccionar mas de una opción
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              Referencia comercial 1
                            </Col>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref1TypPerson1'
                                    name='ref1TypPerson1'
                                    value={ref1TypPerson}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref1TypPerson'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='legal'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Jurídica'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref1TypPerson2'
                                    name='ref1TypPerson2'
                                    value={ref1TypPerson}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref1TypPerson'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='natural'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Natural'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefFirstName'
                                label={
                                  ref1TypPerson !== 'legal'
                                    ? 'Primer nombre *'
                                    : 'Razon social *'
                                }
                                className='home-input'
                                margin='dense'
                                value={firstRefFirstName}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'firstRefFirstName'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            {ref1TypPerson !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='firstRefSecondName'
                                  label='Segundo nombre'
                                  className='home-input'
                                  margin='dense'
                                  value={firstRefSecondName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'firstRefSecondName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref1TypPerson !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='firstRefFistLastName'
                                  label='Primer apellido *'
                                  className='home-input'
                                  margin='dense'
                                  value={firstRefFistLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'firstRefFistLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref1TypPerson !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='firstRefSecondLastName'
                                  label='Segundo apellido'
                                  className='home-input'
                                  margin='dense'
                                  value={firstRefSecondLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'firstRefSecondLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefDepartment'
                                select
                                label='Departamento de residencia *'
                                className='home-input'
                                margin='dense'
                                value={firstRefDepartment}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'firstRefDepartment',
                                    'firstRefCities'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {departments.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefCity'
                                select
                                label='ciudad de residencia *'
                                className='home-input'
                                margin='dense'
                                value={firstRefCity}
                                onChange={(event) => {
                                  this.oninputChange(event, 'firstRefCity');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {firstRefCitiesArray.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefPhone'
                                label='Teléfono *'
                                className='home-input'
                                margin='dense'
                                type='number'
                                value={firstRefPhone}
                                onChange={(event) => {
                                  this.oninputChange(event, 'firstRefPhone');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefEmail'
                                label='Correo electrónico'
                                className='home-input'
                                margin='dense'
                                value={firstRefEmail}
                                onChange={(event) => {
                                  this.isEmail(event, 'firstRefEmail');
                                }}
                                error={
                                  firstRefEmail && !firstRefEmailValid
                                    ? true
                                    : false
                                }
                                helperText={
                                  firstRefEmail && !firstRefEmailValid
                                    ? 'Escriba un formato de email válido'
                                    : null
                                }
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              Referencia comercial 2
                            </Col>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref2TypPerson1'
                                    name='ref2TypPerson1'
                                    value={ref2TypPerson}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref2TypPerson'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='legal'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Jurídica'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref2TypPerson2'
                                    name='ref2TypPerson2'
                                    value={ref2TypPerson}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref2TypPerson'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='natural'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Natural'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefFirstName'
                                label={
                                  ref2TypPerson !== 'legal'
                                    ? 'Primer nombre'
                                    : 'Razon social'
                                }
                                className='home-input'
                                margin='dense'
                                value={secondRefFirstName}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'secondRefFirstName'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            {ref2TypPerson !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='secondRefSecondName'
                                  label='Segundo nombre'
                                  className='home-input'
                                  margin='dense'
                                  value={secondRefSecondName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'secondRefSecondName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref2TypPerson !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='secondRefFistLastName'
                                  label='Primer apellido'
                                  className='home-input'
                                  margin='dense'
                                  value={secondRefFistLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'secondRefFistLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref2TypPerson !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='secondRefSecondLastName'
                                  label='Segundo apellido'
                                  className='home-input'
                                  margin='dense'
                                  value={secondRefSecondLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'secondRefSecondLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefDepartment'
                                select
                                label='Departamento de residencia'
                                className='home-input'
                                margin='dense'
                                value={secondRefDepartment}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'secondRefDepartment',
                                    'secondRefCities'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {departments.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefCity'
                                select
                                label='ciudad de residencia'
                                className='home-input'
                                margin='dense'
                                value={secondRefCity}
                                onChange={(event) => {
                                  this.oninputChange(event, 'secondRefCity');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {secondRefCitiesArray.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefPhone'
                                label='Teléfono'
                                className='home-input'
                                margin='dense'
                                type='number'
                                value={secondRefPhone}
                                onChange={(event) => {
                                  this.oninputChange(event, 'secondRefPhone');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefEmail'
                                label='Correo electrónico'
                                className='home-input'
                                margin='dense'
                                value={secondRefEmail}
                                onChange={(event) => {
                                  this.isEmail(event, 'secondRefEmail');
                                }}
                                error={
                                  secondRefEmail && !secondRefEmailValid
                                    ? true
                                    : false
                                }
                                helperText={
                                  secondRefEmail && !secondRefEmailValid
                                    ? 'Escriba un formato de email válido'
                                    : null
                                }
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {clientType === 'legal' && (
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} md={6} className='login-input-container'>
                          <Row>
                            <Col xs={12} className='client-study_address-title'>
                              Número de NIT *
                            </Col>
                            <Col xs={12}>
                              <Row>
                                <Col className='client-study_nit-container'>
                                  <TextField
                                    id='documentNumber'
                                    className='home-input'
                                    type='number'
                                    value={documentNumber}
                                    disabled={threePayments}
                                    onChange={(event) => {
                                      if (event.target.value.length > 8) {
                                        this.checkDigitInput.focus();
                                      }
                                      if (event.target.value.length < 10) {
                                        this.oninputChange(
                                          event,
                                          'documentNumber'
                                        );
                                      }
                                    }}
                                  />
                                </Col>
                                <div>-</div>
                                <Col xs={2} className='address-padding-left'>
                                  <TextField
                                    id='checkDigit'
                                    className='home-input check-digit'
                                    type='number'
                                    inputRef={(input) =>
                                      (this.checkDigitInput = input)
                                    }
                                    value={checkDigit}
                                    onChange={(event) => {
                                      if (event.target.value.length < 2) {
                                        this.oninputChange(event, 'checkDigit');
                                      }
                                    }}
                                    onBlur={this.validateForm}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='companyName'
                            label='Nombre de la empresa *'
                            className='home-input'
                            margin='dense'
                            value={companyName}
                            onChange={(event) => {
                              this.oninputChange(event, 'companyName');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='economicActivity'
                            label='Actividad económica *'
                            className='home-input'
                            margin='dense'
                            value={economicActivity}
                            onChange={(event) => {
                              this.oninputChange(event, 'economicActivity');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='initialDate'
                            select
                            label='¿Cuanto lleva en actividad comercial? *'
                            className='home-input'
                            margin='dense'
                            value={initialDate}
                            onChange={(event) => {
                              this.oninputChange(event, 'initialDate');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            {initialDateFields.map((item, index) => {
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='companyType'
                            select
                            label='Tipo de empresa *'
                            className='home-input'
                            margin='dense'
                            value={companyType}
                            onChange={(event) => {
                              this.oninputChange(event, 'companyType');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            {companyTypeFields.map((item, index) => {
                              return (
                                <MenuItem key={item.ID} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='homeDepartment'
                            select
                            label='Departamento *'
                            className='home-input'
                            margin='dense'
                            value={homeDepartment}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'homeDepartment',
                                'homeCities'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {departments.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='homeCity'
                            select
                            label='Ciudad *'
                            className='home-input'
                            margin='dense'
                            value={homeCity}
                            onChange={(event) => {
                              this.oninputChange(event, 'homeCity');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {homeCitiesArray.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <Row>
                            <Col xs={12} className='client-study_address-title'>
                              Dirección *
                            </Col>
                            <Col xs={12}>
                              {addressType !== 'Otra' ? (
                                <Row>
                                  <Col xs='auto'>
                                    <Select
                                      value={addressType}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          'addressType'
                                        );
                                      }}
                                      className='client-study_address-select'
                                      name='addressType'
                                    >
                                      {addressFields.map((item, index) => {
                                        return (
                                          <MenuItem key={item} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Col>
                                  <Col className='address-padding'>
                                    <TextField
                                      id='addressFirst'
                                      className='home-input'
                                      value={addressFirst}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressFirst'
                                        );
                                      }}
                                    />
                                  </Col>
                                  <div>#</div>
                                  <Col className='address-padding'>
                                    <TextField
                                      id='addressSecond'
                                      className='home-input'
                                      value={addressSecond}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressSecond'
                                        );
                                      }}
                                    />
                                  </Col>
                                  <div>-</div>
                                  <Col className='address-padding-left'>
                                    <TextField
                                      id='addressThird'
                                      className='home-input'
                                      value={addressThird}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressThird'
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col xs='auto'>
                                    <Select
                                      className='client-study_address-select'
                                      value={addressType}
                                      onChange={(event) => {
                                        this.oninputChange(
                                          event,
                                          'addressType'
                                        );
                                      }}
                                      name='addressType'
                                    >
                                      {addressFields.map((item, index) => {
                                        return (
                                          <MenuItem key={item} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Col>
                                  <Col className='address-padding-left'>
                                    <TextField
                                      id='addressOther'
                                      className='home-input address-other'
                                      value={addressOther}
                                      inputProps={{ pattern: '^[a-zA-Z0-9]+$' }}
                                      onChange={(event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\w\s]/gi,
                                            ''
                                          );
                                        this.oninputChange(
                                          event,
                                          'addressOther'
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            {!addressValid && (
                              <Col
                                xs={12}
                                className='client-study_address-error'
                              >
                                Debe tener al menos un numero en cada casilla
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='phone'
                            label='Teléfono fijo'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={phone}
                            inputProps={{ maxLength: 7 }}
                            onChange={(event) => {
                              this.oninputChange(event, 'phone');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <Row>
                            <Col
                              xs={12}
                              className='client-study_points-of-sale'
                            >
                              ¿Tiene puntos de venta?
                            </Col>
                            <Col xs='auto'>
                              <RadioGroup
                                aria-label='pointsOfSale'
                                name='pointsOfSale'
                                value={pointsOfSale}
                                onChange={(event) => {
                                  this.oninputChange(event, 'pointsOfSale');
                                }}
                              >
                                <FormControlLabel
                                  value='S'
                                  className='client-study-main-card_radio'
                                  control={<Radio color='primary' />}
                                  label='Si'
                                  labelPlacement='end'
                                />
                              </RadioGroup>
                            </Col>
                            <Col xs='auto'>
                              <RadioGroup
                                aria-label='pointsOfSale'
                                name='pointsOfSale'
                                value={pointsOfSale}
                                onChange={(event) => {
                                  this.oninputChange(event, 'pointsOfSale');
                                }}
                              >
                                <FormControlLabel
                                  value='N'
                                  className='client-study-main-card_radio'
                                  control={<Radio color='primary' />}
                                  label='No'
                                  labelPlacement='end'
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='quota'
                            label={
                              threePayments
                                ? 'Valor de la factura *'
                                : 'Cupo a solicitar *'
                            }
                            className='home-input'
                            margin='dense'
                            value={quotaValueMask}
                            onChange={this.onQuotaValueChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>

                        <Col
                          xs={12}
                          className='client-study-main-card_body-title'
                        >
                          Información representante legal
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeFirstName'
                            label='Primer nombre *'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeFirstName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeFirstName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeSecondName'
                            label='Segundo Nombre'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeSecondName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeSecondName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeFistLastName'
                            label='Primer apellido *'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeFistLastName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeFistLastName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeSecondLastName'
                            label='Segundo apellido'
                            className='home-input'
                            margin='dense'
                            value={legalRepresentativeSecondLastName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeSecondLastName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeDocumentType'
                            select
                            label='Tipo de documento *'
                            className='home-input'
                            value={legalRepresentativeDocumentType}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeDocumentType'
                              );
                            }}
                            margin='dense'
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            {documentTypesPartners.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='legalRepresentativeDocumentNumber'
                            label='Número de documento *'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={legalRepresentativeDocumentNumber}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'legalRepresentativeDocumentNumber'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className='login-input-container date-picker-container'
                        >
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            moment={moment}
                          >
                            <DatePicker
                              keyboard
                              disableOpenOnEnter
                              placeholder='DD/MM/YYYY'
                              invalidDateMessage='Formato inválido'
                              mask={(value) =>
                                // handle clearing outside if value can be changed outside of the component
                                value
                                  ? [
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]
                                  : []
                              }
                              maxDate={moment(new Date())}
                              margin='normal'
                              label='Fecha de expedición *'
                              className='home-input'
                              value={expeditionDate}
                              animateYearScrolling={false}
                              onInputChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/_/g, '');
                                if (value.length === 10) {
                                  this.handleDateChange(
                                    moment(value, 'DD/MM/YYYY'),
                                    'expeditionDate'
                                  );
                                }
                              }}
                              onChange={(date) => {
                                this.handleDateChange(date, 'expeditionDate');
                              }}
                              fullWidth
                              onBlur={this.saveForm}
                              format='DD/MM/YYYY'
                              keyboardIcon={
                                <img
                                  src={calendaricon}
                                  alt='icono calendario'
                                  className='calendar-icon'
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='expeditionDepartment'
                            select
                            label={`Departamento de expedición${
                              legalRepresentativeDocumentType === 'E'
                                ? ''
                                : ' *'
                            }`}
                            className='home-input'
                            margin='dense'
                            value={expeditionDepartment}
                            onChange={(event) => {
                              this.oninputChange(event, 'expeditionDepartment');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {departments.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='expeditionCity'
                            select
                            label={`Ciudad de expedición${
                              legalRepresentativeDocumentType === 'E'
                                ? ''
                                : ' *'
                            }`}
                            className='home-input'
                            margin='dense'
                            value={expeditionCity}
                            onChange={(event) => {
                              this.oninputChange(event, 'expeditionCity');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          >
                            <MenuItem value=''></MenuItem>
                            {citiesArray.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='email'
                            label='Correo *'
                            className='home-input'
                            margin='dense'
                            value={email}
                            onChange={(event) => {
                              this.isEmail(event, 'email');
                            }}
                            error={email && !emailValid ? true : false}
                            helperText={
                              email && !emailValid
                                ? 'Escriba un formato de email válido'
                                : null
                            }
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='cellular'
                            label='Celular *'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={cellular}
                            inputProps={{ maxLength: 10 }}
                            onChange={(event) => {
                              this.oninputChange(event, 'cellular');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          className='client-study-main-card_body-title'
                        >
                          Información de contacto
                          <div>
                            <button
                              className='client-study_copy-info'
                              onClick={(e) => {
                                e.preventDefault();
                                this.copyInfo();
                              }}
                            >
                              (Copiar información representante legal)
                            </button>
                          </div>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactFirstName'
                            label='Primer nombre *'
                            className='home-input'
                            margin='dense'
                            value={constactFirstName}
                            onChange={(event) => {
                              this.oninputChange(event, 'constactFirstName');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactSecondName'
                            label='Segundo nombre'
                            className='home-input'
                            margin='dense'
                            value={constactSecondName}
                            onChange={(event) => {
                              this.oninputChange(event, 'constactSecondName');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactFirstLastName'
                            label='Primer apellido *'
                            className='home-input'
                            margin='dense'
                            value={constactFirstLastName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'constactFirstLastName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactSecondLastName'
                            label='Segundo apellido'
                            className='home-input'
                            margin='dense'
                            value={constactSecondLastName}
                            onChange={(event) => {
                              this.oninputChange(
                                event,
                                'constactSecondLastName'
                              );
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          className='login-input-container date-picker-container'
                        >
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            moment={moment}
                          >
                            <DatePicker
                              keyboard
                              disableOpenOnEnter
                              placeholder='DD/MM/YYYY'
                              invalidDateMessage='Formato inválido'
                              mask={(value) =>
                                // handle clearing outside if value can be changed outside of the component
                                value
                                  ? [
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      '/',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]
                                  : []
                              }
                              maxDate={moment(new Date())}
                              margin='normal'
                              label='Fecha de nacimiento *'
                              className='home-input'
                              value={birthDate}
                              animateYearScrolling={false}
                              onInputChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/_/g, '');
                                if (value.length === 10) {
                                  this.handleDateChange(
                                    moment(value, 'DD/MM/YYYY'),
                                    'birthDate'
                                  );
                                }
                              }}
                              onChange={(date) => {
                                this.handleDateChange(date, 'birthDate');
                              }}
                              fullWidth
                              onBlur={this.saveForm}
                              format='DD/MM/YYYY'
                              keyboardIcon={
                                <img
                                  src={calendaricon}
                                  alt='icono calendario'
                                  className='calendar-icon'
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactPosition'
                            label='Cargo *'
                            className='home-input'
                            margin='dense'
                            value={constactPosition}
                            onChange={(event) => {
                              this.oninputChange(event, 'constactPosition');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactEmail'
                            label='Correo *'
                            className='home-input'
                            margin='dense'
                            value={constactEmail}
                            onChange={(event) => {
                              this.isEmail(event, 'constactEmail');
                            }}
                            error={
                              constactEmail && !constactEmailValid
                                ? true
                                : false
                            }
                            helperText={
                              constactEmail && !constactEmailValid
                                ? 'Escriba un formato de email válido'
                                : null
                            }
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col xs={12} md={6} className='login-input-container'>
                          <TextField
                            id='constactCellular'
                            label='Celular *'
                            className='home-input'
                            margin='dense'
                            type='number'
                            value={constactCellular}
                            onChange={(event) => {
                              this.oninputChange(event, 'constactCellular');
                            }}
                            fullWidth
                            onBlur={this.saveForm}
                          />
                        </Col>
                        <Col
                          xs={12}
                          className='client-study-main-card_body-title'
                        >
                          <Row>
                            <Col
                              xs={12}
                              className='client-study_points-of-sale'
                            >
                              ¿Por cual de estos canales desea ser contactado? *
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modeWP === 'S'}
                                    onChange={(event) => {
                                      let newValue = modeWP === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modeWP'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='Whastapp'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modeEmail === 'S'}
                                    onChange={(event) => {
                                      let newValue =
                                        modeEmail === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modeEmail'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='Correo electrónico'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modeSMS === 'S'}
                                    onChange={(event) => {
                                      let newValue =
                                        modeSMS === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modeSMS'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='SMS'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs='auto'>
                              <FormControlLabel
                                value='S'
                                className='client-study-main-card_radio'
                                control={
                                  <Checkbox
                                    checked={modePhone === 'S'}
                                    onChange={(event) => {
                                      let newValue =
                                        modePhone === 'S' ? 'N' : 'S';
                                      this.checkChannelsChange(
                                        newValue,
                                        'modePhone'
                                      );
                                    }}
                                    color='primary'
                                  />
                                }
                                label='Llamada'
                                labelPlacement='end'
                              />
                            </Col>
                            <Col xs={12} className='client-study_mode-helper'>
                              *Recuerda que puedes seleccionar mas de una opción
                            </Col>
                          </Row>
                        </Col>
                        {companyType === 'SOCIEDAD' && (
                          <Col xs={12}>
                            <Row>
                              <Col
                                xs={12}
                                className='client-study-main-card_body-title'
                              >
                                <div>
                                  Información de dueños
                                  <Tooltip
                                    title='Máximo 10 socios'
                                    placement='top'
                                  >
                                    <img
                                      src={tooltipimg}
                                      className='tooltip-img'
                                      alt='icono-tooltip'
                                    />
                                  </Tooltip>
                                </div>
                                <div>
                                  <button
                                    className='client-study_copy-info'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.copyInfoPartners();
                                    }}
                                  >
                                    (Copiar información representante legal)
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {companyType === 'SOCIEDAD' && (
                          <Col xs={12}>
                            {partnersArray.length > 0 &&
                              partnersArray.map((partner, index) => {
                                return (
                                  <Row
                                    key={index}
                                    className='partner-list-container'
                                  >
                                    <Col
                                      xs={12}
                                      md={6}
                                      className='login-input-container'
                                    >
                                      <TextField
                                        id={'documentType' + index}
                                        select
                                        label='Tipo de documento *'
                                        className='home-input'
                                        value={partner.documentType}
                                        onChange={(event) => {
                                          this.onPartnersArrayValueChange(
                                            event,
                                            'documentType',
                                            index
                                          );
                                        }}
                                        margin='dense'
                                        fullWidth
                                        onBlur={this.saveForm}
                                      >
                                        {documentTypesPartners.map(
                                          (item, indexDocument) => {
                                            return (
                                              <MenuItem
                                                key={indexDocument}
                                                value={item.ID}
                                              >
                                                {item.LABEL}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </TextField>
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className='login-input-container'
                                    >
                                      <TextField
                                        id={'documentNumber' + index}
                                        label='Número de documento *'
                                        className='home-input'
                                        margin='dense'
                                        type='number'
                                        value={partner.documentNumber}
                                        onChange={(event) => {
                                          this.onPartnersArrayValueChange(
                                            event,
                                            'documentNumber',
                                            index
                                          );
                                        }}
                                        fullWidth
                                        onBlur={this.saveForm}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className='login-input-container'
                                    >
                                      <TextField
                                        id={'firstName' + index}
                                        label='Primer nombre *'
                                        className='home-input'
                                        margin='dense'
                                        value={partner.firstName}
                                        onChange={(event) => {
                                          this.onPartnersArrayValueChange(
                                            event,
                                            'firstName',
                                            index
                                          );
                                        }}
                                        fullWidth
                                        onBlur={this.saveForm}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className='login-input-container'
                                    >
                                      <TextField
                                        id={'secondName' + index}
                                        label='Segundo nombre'
                                        className='home-input'
                                        margin='dense'
                                        value={partner.secondName}
                                        onChange={(event) => {
                                          this.onPartnersArrayValueChange(
                                            event,
                                            'secondName',
                                            index
                                          );
                                        }}
                                        fullWidth
                                        onBlur={this.saveForm}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className='login-input-container'
                                    >
                                      <TextField
                                        id={'firstLastName' + index}
                                        label='Primer apellido *'
                                        className='home-input'
                                        margin='dense'
                                        value={partner.firstLastName}
                                        onChange={(event) => {
                                          this.onPartnersArrayValueChange(
                                            event,
                                            'firstLastName',
                                            index
                                          );
                                        }}
                                        fullWidth
                                        onBlur={this.saveForm}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className='login-input-container'
                                    >
                                      <TextField
                                        id={'secondLastName' + index}
                                        label='Segundo apellido'
                                        className='home-input'
                                        margin='dense'
                                        value={partner.secondLastName}
                                        onChange={(event) => {
                                          this.onPartnersArrayValueChange(
                                            event,
                                            'secondLastName',
                                            index
                                          );
                                        }}
                                        fullWidth
                                        onBlur={this.saveForm}
                                      />
                                    </Col>
                                  </Row>
                                );
                              })}
                            <Row className='partners-button-container'>
                              {partnersArray.length > 1 && (
                                <Col xs='auto' className='remove-partner'>
                                  <button
                                    className='remove-partner-button'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.removePartner();
                                    }}
                                  >
                                    -
                                  </button>
                                </Col>
                              )}
                              {partnersArray.length < 10 && (
                                <Col xs='auto' className='add-partner'>
                                  <button
                                    className='add-partner-button'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.addPartner();
                                    }}
                                  >
                                    +
                                  </button>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}
                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              Referencia comercial 1
                            </Col>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref1TypCompany1'
                                    name='ref1TypCompany1'
                                    value={ref1TypCompany}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref1TypCompany'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='legal'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Jurídica'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref1TypCompany2'
                                    name='ref1TypCompany2'
                                    value={ref1TypCompany}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref1TypCompany'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='natural'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Natural'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefFirstName'
                                label={
                                  ref1TypCompany !== 'legal'
                                    ? 'Primer nombre *'
                                    : 'Razon social *'
                                }
                                className='home-input'
                                margin='dense'
                                value={firstRefFirstName}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'firstRefFirstName'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            {ref1TypCompany !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='firstRefSecondName'
                                  label='Segundo nombre'
                                  className='home-input'
                                  margin='dense'
                                  value={firstRefSecondName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'firstRefSecondName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref1TypCompany !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='firstRefFistLastName'
                                  label='Primer apellido *'
                                  className='home-input'
                                  margin='dense'
                                  value={firstRefFistLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'firstRefFistLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref1TypCompany !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='firstRefSecondLastName'
                                  label='Segundo apellido'
                                  className='home-input'
                                  margin='dense'
                                  value={firstRefSecondLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'firstRefSecondLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefDepartment'
                                select
                                label='Departamento de residencia *'
                                className='home-input'
                                margin='dense'
                                value={firstRefDepartment}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'firstRefDepartment',
                                    'firstRefCities'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {departments.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefCity'
                                select
                                label='ciudad de residencia *'
                                className='home-input'
                                margin='dense'
                                value={firstRefCity}
                                onChange={(event) => {
                                  this.oninputChange(event, 'firstRefCity');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {firstRefCitiesArray.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefPhone'
                                label='Teléfono *'
                                className='home-input'
                                margin='dense'
                                type='number'
                                value={firstRefPhone}
                                onChange={(event) => {
                                  this.oninputChange(event, 'firstRefPhone');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='firstRefEmail'
                                label='Correo electrónico'
                                className='home-input'
                                margin='dense'
                                value={firstRefEmail}
                                onChange={(event) => {
                                  this.isEmail(event, 'firstRefEmail');
                                }}
                                error={
                                  firstRefEmail && !firstRefEmailValid
                                    ? true
                                    : false
                                }
                                helperText={
                                  firstRefEmail && !firstRefEmailValid
                                    ? 'Escriba un formato de email válido'
                                    : null
                                }
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={12}>
                          <Row>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              Referencia comercial 2
                            </Col>
                            <Col
                              xs={12}
                              className='client-study-main-card_body-title'
                            >
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref2TypCompany1'
                                    name='ref2TypCompany1'
                                    value={ref2TypCompany}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref2TypCompany'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='legal'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Jurídica'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  className='login-input-container client-study-main-card_radio-container'
                                >
                                  <RadioGroup
                                    aria-label='ref2TypCompany2'
                                    name='ref2TypCompany2'
                                    value={ref2TypCompany}
                                    onChange={(event) => {
                                      this.oninputChange(
                                        event,
                                        'ref2TypCompany'
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='natural'
                                      className='client-study-main-card_radio'
                                      control={<Radio color='primary' />}
                                      label='Persona Natural'
                                      labelPlacement='end'
                                    />
                                  </RadioGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefFirstName'
                                label={
                                  ref2TypCompany !== 'legal'
                                    ? 'Primer nombre'
                                    : 'Razon social'
                                }
                                className='home-input'
                                margin='dense'
                                value={secondRefFirstName}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'secondRefFirstName'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            {ref2TypCompany !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='secondRefSecondName'
                                  label='Segundo nombre'
                                  className='home-input'
                                  margin='dense'
                                  value={secondRefSecondName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'secondRefSecondName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref2TypCompany !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='secondRefFistLastName'
                                  label='Primer apellido'
                                  className='home-input'
                                  margin='dense'
                                  value={secondRefFistLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'secondRefFistLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            {ref2TypCompany !== 'legal' && (
                              <Col
                                xs={12}
                                md={6}
                                className='login-input-container'
                              >
                                <TextField
                                  id='secondRefSecondLastName'
                                  label='Segundo apellido'
                                  className='home-input'
                                  margin='dense'
                                  value={secondRefSecondLastName}
                                  onChange={(event) => {
                                    this.oninputChange(
                                      event,
                                      'secondRefSecondLastName'
                                    );
                                  }}
                                  fullWidth
                                  onBlur={this.saveForm}
                                />
                              </Col>
                            )}
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefDepartment'
                                select
                                label='Departamento de residencia'
                                className='home-input'
                                margin='dense'
                                value={secondRefDepartment}
                                onChange={(event) => {
                                  this.oninputChange(
                                    event,
                                    'secondRefDepartment',
                                    'secondRefCities'
                                  );
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {departments.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefCity'
                                select
                                label='ciudad de residencia'
                                className='home-input'
                                margin='dense'
                                value={secondRefCity}
                                onChange={(event) => {
                                  this.oninputChange(event, 'secondRefCity');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              >
                                <MenuItem value=''></MenuItem>
                                {secondRefCitiesArray.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.ID}>
                                      {item.LABEL}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefPhone'
                                label='Teléfono'
                                className='home-input'
                                margin='dense'
                                type='number'
                                value={secondRefPhone}
                                onChange={(event) => {
                                  this.oninputChange(event, 'secondRefPhone');
                                }}
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className='login-input-container'
                            >
                              <TextField
                                id='secondRefEmail'
                                label='Correo electrónico'
                                className='home-input'
                                margin='dense'
                                value={secondRefEmail}
                                onChange={(event) => {
                                  this.isEmail(event, 'secondRefEmail');
                                }}
                                error={
                                  secondRefEmail && !secondRefEmailValid
                                    ? true
                                    : false
                                }
                                helperText={
                                  secondRefEmail && !secondRefEmailValid
                                    ? 'Escriba un formato de email válido'
                                    : null
                                }
                                fullWidth
                                onBlur={this.saveForm}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col
                    xs={12}
                    className='client-study-main-card_button-container'
                  >
                    <Row>
                      <Col xs={12} className='client-study_terms-container'>
                        <Row>
                          <Col
                            xs='auto'
                            className='client-study_terms-checkbox-container'
                          >
                            <Checkbox
                              checked={termsAccepted}
                              onChange={this.checkChange()}
                              className='client-study_terms-checkbox'
                            />
                          </Col>
                          <Col className='client-study_terms-text'>
                            Declaro que toda la información que he diligenciado
                            es veraz, auténtica y comprobable; he realizado la
                            verificación de la misma y dispongo de la
                            documentación original (cédula y FAU) suscrita por
                            el Comprador y/o Cliente la cual será entregada a
                            Covinoc en el momento que lo requiera.
                          </Col>
                        </Row>
                      </Col>

                      {lastSaveDate && (
                        <Col xs={12} className='client-study_terms-container'>
                          <Row>
                            <Col className='client-study_terms-text last-save'>
                              Última vez guardado{' '}
                              {moment(lastSaveDate).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col xs={12} md={{ span: 4, offset: 4 }}>
                        <Button
                          className='button-send'
                          variant='success'
                          disabled={
                            clientType === 'legal'
                              ? !this.isvalidNit()
                              : !this.isvalidC()
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.onSendRequest();
                          }}
                          block
                        >
                          Finalizar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Col>
            )}
            {false && (
              <Col xs={12} className='general-main-card'>
                <Col
                  xs={12}
                  className='denegate-icon-container center-horizontally'
                >
                  <img
                    src={processIcon}
                    className='img-process'
                    alt='rechazado icono'
                  />
                </Col>
                <Col xs={12} className='client-study_denegate-title'>
                  ¡ESTUDIO DE CLIENTE EN PROCESO!
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className='client-study_denegate-description client-study_proces-description'
                >
                  Su solicitud se encuentra en evaluación con el código de
                  estudio{' '}
                  <span className='client-study_denegate-description-bold'>
                    Nº 187432
                  </span>
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className='client-study_denegate-description'
                >
                  Nos contactaremos con usted dentro de las siguientes{' '}
                  <span className='client-study_denegate-description-bold'>
                    48 horas
                  </span>
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className='button-send-container client-study_denegate-button-container'
                >
                  <Button className='button-send' variant='success' block>
                    Ir a inicio
                  </Button>
                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { documents } = state.document;
  return {
    documents,
    ...state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientStudy);
