import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './menu.scss';
import * as actions from '../../redux/actions';
import { Col, Row } from 'react-bootstrap';
import companyLogo from '../../assets/logos/company-logo.svg';
import userLogo from '../../assets/logos/user-logo.svg';
import closeIcon from '../../assets/icons/equis.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class Menu extends Component {
  constructor(props) {
    super(props);
    let randNumber = Math.random() * 100;
    this.state = {
      imageNumber: randNumber,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.loadData();
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  loadData() {
    this.props.actions.getUser();
  }

  getRolPermissions(user) {
    this.props.actions.getRolPermissions({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      idRol: user.userInfo.ROL_USUARIO,
      reload: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.getRolPermissions(nextProps.user);
    }
    if (nextProps.permissions) {
      if (this.props.pageId !== 100) {
        let permissions =
          'MENU' in nextProps.permissions ? nextProps.permissions.MENU : [];
        let permission = permissions.find(
          (permission) => parseInt(permission.IDOPCION) === this.props.pageId
        );
        if (!permission) {
          this.props.history.push(`/`);
        }
      }
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeMenu();
    }
  }

  closeMenu() {
    this.props.closeMenu();
  }

  goDocuments = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_documentos', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/documentos') {
      this.reload();
    } else {
      this.props.history.push(`/documentos`);
    }
  };

  goLiquidator = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_liquidador', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/liquidador') {
      this.reload();
    } else {
      this.props.history.push(`/liquidador`);
    }
  };

  goReports = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_reportes', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/reportes') {
      this.reload();
    } else {
      this.props.history.push(`/reportes`);
    }
  };

  advanceAgreements = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_acuerdos_anticipados', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/acuerdos-anticipados') {
      this.reload();
    } else {
      this.props.history.push(`/acuerdos-anticipados`);
    }
  };

  goMyPayments = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_mispagos', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/mis-pagos') {
      this.reload();
    } else {
      this.props.history.push(`/mis-pagos`);
    }
  };

  goRefunds = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_reintegros', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/reintegros') {
      this.reload();
    } else {
      this.props.history.push(`/reintegros`);
    }
  };

  goClientStudy = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_estudiocliente', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/estudio-de-cliente') {
      this.reload();
    } else {
      this.props.history.push(`/estudio-de-cliente`);
    }
  };

  goExoneration = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_exoneracion', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/exoneracion') {
      this.reload();
    } else {
      this.props.history.push(`/exoneracion`);
    }
  };

  goClaim = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_reclamoavisonopago', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/reclamo-aviso-no-pago') {
      this.reload();
    } else {
      this.props.history.push(`/reclamo-aviso-no-pago`);
    }
  };

  goBulkTransactions = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_transacciones', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/') {
      this.reload();
    } else {
      this.props.actions.resetQuota();
      this.props.history.push(`/`);
    }
  };

  goProfile = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_perfil', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/perfil') {
      this.reload();
    } else {
      this.props.history.push(`/perfil`);
    }
  };

  signoff = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'cerrar_sesion', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    localStorage.removeItem('documents');
    window.location.reload(true);
  };

  goManageUsers = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais =
      'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : '1';
    window.gtag('event', 'navegar_administrarusuarios', {
      event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      event_label: userJson.userEmail,
    });
    let pathname = this.props.location.pathname;
    if (pathname === '/administrar-usuarios') {
      this.reload();
    } else {
      this.props.history.push(`/administrar-usuarios`);
    }
  };

  reload = () => {
    const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
    this.props.actions.resetRedux();
    setTimeout(() => {
      this.props.history.replace(current);
    });
  };

  getItemClass = (itemId, exist) => {
    const { permissions } = this.props;
    let permissionsAray =
      permissions && 'MENU' in permissions ? permissions.MENU : [];
    let permission = permissionsAray.find(
      (permission) => parseInt(permission.IDOPCION) === itemId
    );
    if (permission && permission.ESTADO === 'A') {
      return exist ? true : 'menu-item';
    } else {
      return exist ? false : 'menu-item menu-item-disabled';
    }
  };

  showItem = (itemId) => {
    const { permissions } = this.props;
    let permissionsAray =
      permissions && 'MENU' in permissions ? permissions.MENU : [];
    let permission = permissionsAray.find(
      (permission) => parseInt(permission.IDOPCION) === itemId
    );
    if (!permission || permission.ESTADO === 'O') {
      return false;
    } else {
      return true;
    }
  };

  getItemClassRaw = (show) => {
    return show ? 'menu-item' : 'menu-item menu-item-disabled';
  };

  changeImgNumber() {
    let randNumber = Math.random() * 100;
    this.setState({
      imageNumber: randNumber,
    });
  }

  render() {
    const { imageNumber } = this.state;
    const { user, isOpen } = this.props;
    let userPhoto =
      user && user.userInfo && user.userInfo.FOTO_USUARIO
        ? user.userInfo.FOTO_USUARIO + '?' + imageNumber
        : userLogo;
    let companyPhoto =
      user && user.userInfo && user.userInfo.LOGO_EMPRESA
        ? user.userInfo.LOGO_EMPRESA
        : companyLogo;
    let sucursal =
      user && user.userInfo && user.userInfo.SUCURSAL_USUARIO
        ? user.userInfo.SUCURSAL_USUARIO
        : 'Suba';
    let nombreUsuario =
      user && user.userInfo && user.userInfo.NOMBRE ? user.userInfo.NOMBRE : '';
    let clientStudyPermission =
      user && user.userInfo && user.userInfo.ESTUDIO_CUPO === 'SI';
    return (
      <div
        ref={this.setWrapperRef}
        className={
          isOpen
            ? 'menu menu-open col-md-auto col-12'
            : 'menu col-md-auto col-12'
        }
      >
        <Row>
          <Col xs={12} className='menu-header'>
            <div className='close-container'>
              <button
                className='close'
                onClick={(e) => {
                  e.preventDefault();
                  this.closeMenu();
                }}
              >
                <img src={closeIcon} alt='close icon' />
              </button>
            </div>
            <div className='company-logo-container menu-header-logo'>
              <img
                className='company-logo'
                src={companyPhoto}
                alt='company logo'
              />
            </div>
          </Col>
        </Row>
        <Col>
          <Row>
            <Col xs={12} className='menu-subheader'>
              <Row>
                <Col xs='auto'>
                  <div className='user-logo-container'>
                    <img className='user-logo-second' src={userPhoto} alt='' />
                  </div>
                </Col>
                <Col className='center-vertically'>
                  <Row>
                    <Col xs={12} className='menu-profile-name'>
                      <Row>
                        <span>{nombreUsuario.toLowerCase()}</span>
                      </Row>
                    </Col>
                    <Col xs={12} className='menu-profile-subtitle'>
                      <Row>
                        <span>Sucursal {sucursal.toLowerCase()}</span>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {this.showItem(1) && (
              <Col
                xs={12}
                className={this.getItemClass(1)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(1, true)) {
                    this.goBulkTransactions();
                  }
                }}
              >
                <h3 className='menu-item-text'>Realizar transacción</h3>
              </Col>
            )}
            {this.showItem(15) && (
              <Col
                xs={12}
                className={this.getItemClassRaw(clientStudyPermission)}
                // this.getItemClass(15)
                onClick={(e) => {
                  e.preventDefault();
                  // || this.getItemClass(15, true)
                  if (clientStudyPermission) {
                    this.goClientStudy();
                  }
                }}
              >
                <h3 className='menu-item-text'>Estudio cliente</h3>
              </Col>
            )}
            {this.showItem(6) && (
              <Col
                xs={12}
                className={this.getItemClass(6)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(6, true)) {
                    this.goClaim();
                  }
                }}
              >
                <h3 className='menu-item-text'>
                  Aviso de no pago y exoneración individual
                </h3>
                {/*
                <h3 className="menu-item-text">Reclamación y aviso de no pago</h3>
                */}
              </Col>
            )}
            {this.showItem(8) && (
              <Col
                xs={12}
                className={this.getItemClass(8)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(8, true)) {
                    this.goExoneration();
                  }
                }}
              >
                <h3 className='menu-item-text'>
                  Aviso de no pago y exoneración masiva
                </h3>
              </Col>
            )}
            {this.showItem(21) && (
              <Col
                xs={12}
                className={this.getItemClass(21)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(21, true)) {
                    this.advanceAgreements();
                  }
                }}
              >
                <h3 className='menu-item-text'>Acuerdos anticipados</h3>
              </Col>
            )}
            {this.showItem(10) && (
              <Col
                xs={12}
                className={this.getItemClass(10)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(10, true)) {
                    this.goRefunds();
                  }
                }}
              >
                <h3 className='menu-item-text'>Reintegros</h3>
              </Col>
            )}
            {this.showItem(9) && (
              <Col
                xs={12}
                className={this.getItemClass(9)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(9, true)) {
                    this.goReports();
                  }
                }}
              >
                <h3 className='menu-item-text'>Reportes</h3>
              </Col>
            )}
            {this.showItem(11) && (
              <Col
                xs={12}
                className={this.getItemClass(11)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(11, true)) {
                    this.goMyPayments();
                  }
                }}
              >
                <h3 className='menu-item-text'>Mis pagos</h3>
              </Col>
            )}
            {this.showItem(12) && (
              <Col
                xs={12}
                className={this.getItemClass(12)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(12, true)) {
                    this.goDocuments();
                  }
                }}
              >
                <h3 className='menu-item-text'>Documentos</h3>
              </Col>
            )}
            {/*
              <Col
              xs={12}
              className={this.getItemClass(20)}
              onClick={(e) => {
              e.preventDefault();
              if(this.getItemClass(20, true)) {
              this.goLiquidator();
              }
              }}
              >
              <h3 className="menu-item-text">Liquidador</h3>
              </Col>
              */}
            {/*
              <Col xs={12} className="menu-item">
              <h3 className="menu-item-text">Liquidador</h3>
              </Col>
              */}
            {this.showItem(13) && (
              <Col
                xs={12}
                className={this.getItemClass(13)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(13, true)) {
                    this.goManageUsers();
                  }
                }}
              >
                <h3 className='menu-item-text'>Administrar usuarios</h3>
              </Col>
            )}
            {this.showItem(14) && (
              <Col
                xs={12}
                className={this.getItemClass(14)}
                onClick={(e) => {
                  e.preventDefault();
                  if (this.getItemClass(14, true)) {
                    this.goProfile();
                  }
                }}
              >
                <h3 className='menu-item-text'>Editar perfil</h3>
              </Col>
            )}
            <Col
              xs={12}
              className='menu-item'
              onClick={(e) => {
                e.preventDefault();
                this.signoff();
              }}
            >
              <h3 className='menu-item-text'>
                <ExitToAppIcon /> Cerrar Sesión
              </h3>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

Menu.propTypes = {
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  pageId: PropTypes.number.isRequired,
};

Menu.defaultProps = {
  isOpen: false,
  closeMenu: () => {},
  pageId: 100,
};

const mapStateToProps = (state, props) => {
  let { user, permissions } = state.user;

  return {
    user,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
