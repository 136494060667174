export default function () {
  // "apiUrl": "http://200.41.76.20:8080/ServiciosCovifactura/servicios",
  // "apiUrlPayzen": "http://200.41.76.20:8080/PayzenCorporativo/servicios",
  // "hostUrl": "http://200.32.80.181:8088"

  // "apiUrl": "https://clientes.covifactura.com:18443/ServiciosCovifactura/servicios",
  // "apiUrlPayzen": "https://ws.micovinoc.com:18443/PayzenCorporativo/servicios",
  // "hostUrl": "https://clientes.covifactura.com"

  const config = JSON.parse(localStorage.getItem('config')) || {};
  return config;
}
