import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';
import { TextField, InputAdornment } from '@material-ui/core';

class PartialModal extends Component {

  constructor() {
    super();
    this.state = {
      exonerationValue: '',
      exonerationValueMask: '',
    };
  }

  cancel = () => {
    this.props.onHide();
  }

  confirm = (full) => {
    const {
      exonerationValue
    } = this.state;
    const {
      partialExoneration
    } = this.props;
    let finalExonerationValue = full ? partialExoneration.VALOR_A_EXONERAR : exonerationValue;
    this.props.confirmExoneration(finalExonerationValue);
    this.setState({
      exonerationValue: '',
      exonerationValueMask: '',
    })
  }

  onExonerationValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g,'');
    inputValue = inputValue.replace(/\./g,'');
    inputValue = inputValue.replace(/´/g, "");
    let exonerationValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    this.setState({
      exonerationValue,
      exonerationValueMask: thousand,
    });
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  render() {
    const {
      exonerationValueMask,
      exonerationValue,
    } = this.state;
    const {
      partialExoneration,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="partial-modal-title">
            Exoneración parcial
          </div>
        </Modal.Header>
        <Modal.Body>
          {partialExoneration && (
            <div className="partial-modal-body">
              <Col xs={12}>
                <Row className="partial_info-container">
                  <Col xs={12} md={6} className="partial_info-key">
                    Factura a nombre:
                  </Col>
                  <Col xs={12} md={6} className="partial_info-value">
                    {partialExoneration.NOMBRE.toLowerCase()}
                  </Col>
                </Row>
                <Row className="partial_info-container">
                  <Col xs={12} md={6} className="partial_info-key">
                    Valor:
                  </Col>
                  <Col xs={12} md={6} className="partial_info-value">
                    ${this.getNumber(partialExoneration.VALOR_A_EXONERAR)}
                  </Col>
                </Row>
                <Row className="partial_info-button-container">
                  <Col xs={12} md={{ span: 7, offset: 5 }}>
                    <Button
                      className="button-send"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        this.confirm(true);
                      }}
                      block
                      >
                      Exonerar valor total
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Row className="partial_info-container">
                <div className="partial_info-separator">
                  <hr />
                </div>
              </Row>
              <Row className="partial_info-container">
                <Col xs={12} md={6} className="partial_info-key partial_info-center">
                  Exonerar otro valor
                </Col>
                <Col xs={12} md={6} className="partial_info-value">
                  <TextField
                    id="amount"
                    label="Valor"
                    className="home-input"
                    margin="dense"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    value={exonerationValueMask}
                    onChange={this.onExonerationValueChange}
                    fullWidth
                    />
                </Col>
              </Row>
              <Row className="partial_info-end-button-container">
                <Col xs={12} md={{ span: 7, offset: 5 }}>
                  <Button
                    className="button-send"
                    variant="success"
                    disabled={exonerationValueMask === '' || parseFloat(exonerationValue) > parseFloat(partialExoneration.VALOR_A_EXONERAR)}
                    onClick={(e) => {
                      e.preventDefault();
                      this.confirm();
                    }}
                    block
                    >
                    Exonerar valor
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

PartialModal.propTypes = {
  partialExoneration: PropTypes.object,
  confirmExoneration: PropTypes.func.isRequired,
};

PartialModal.defaultProps = {
  partialExoneration: {
      "DOCUMENTO": "23496500",
      "AUTORIZACION": "416619",
      "FECHA": "2019-04-02 15:22:36.0",
      "TIPO_TITULO": "FACTURA",
      "TITULO_VALOR": "FCTR123",
      "VALOR_A_EXONERAR": "10",
      "DIAS_EXONERAR": "64",
      "TIPO_DOCUMENTO": "C",
      "VALOR_GARANTIZADO": "10",
      "RN": "3",
      "VENCIMIENTO": "2019-04-15 00:00:00.0",
      "N_REGISTROS": "4",
      "NOMBRE": "AURA  SUAREZ TORRES"
   },
  confirmExoneration: () => {},
};

export default PartialModal;
