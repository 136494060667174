import React, { Component } from 'react';

import { Col, Button, Modal } from 'react-bootstrap';

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-change-title">
            Cambio realizado
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-change-body">
            <Col xs={{ span: 10, offset: 1}} className="confirm-change_body-description">
              El cambio de su contraseña ha sido realizado
            </Col>
            <Col xs={12}>
              <Button
                className="button-send"
                variant="success"
                onClick={(e) => {
                    e.preventDefault();
                    this.cancel();
                }}
                block
              >
                Aceptar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ConfirmChange;
